import { AdvancedMarker, InfoWindow, Pin, useAdvancedMarkerRef, AdvancedMarkerElement } from '@vis.gl/react-google-maps';
import { useRef, useEffect, useState } from 'react';
import InfoWindowLocal from './InfoWindowLocal';
import { Box, ClickAwayListener } from '@mui/material';

export default function MarkerComWindow(props) {
    const { poi } = props

    const [infoWindowOpen, setInfoWindowOpen] = useState(false);

    const markerClick = (value) => {
        setInfoWindowOpen(value)
    }

    const handleClickOutside = () => {
        setInfoWindowOpen(false)
    };

    const porCentagem = (valorOriginal, valorVenda, porCento) => {
        return valorOriginal > 0 && 100 - ( valorVenda/valorOriginal * 100) > porCento
    }

    return (
        <>
            <AdvancedMarker
                key={poi.titulo}
                position={poi.location}
                onClick={() => markerClick(true)}
            >
                <Pin
                    background={poi.vendido === 1 ? '#787f8a' : (poi.tipoNegocio === 1 ? '#b811b5' : '#fc032c')}
                    glyphColor={poi.vendido === 1 ? '#787f8a' : (poi.tipoNegocio === 1 ? porCentagem(poi.valorOriginal, poi.valorVenda, 35) ? '#e7fa1b' : '#b811b5' : '#fc032c')}
                    borderColor={poi.tipoNegocio === 1 ? '#000' : '#000'}
                    scale={0.9}
                />
                {infoWindowOpen && (
                    <InfoWindow position={poi.location} onClose={() => markerClick(false)} maxWidth={350}>
                        <InfoWindowLocal
                            poi={poi}
                            handleClickOutside={handleClickOutside}
                        />
                    </InfoWindow>
                )}
            </AdvancedMarker>
        </>
    )
}