import { Box, Typography } from '@mui/material';
import * as Cores  from '../../../util/constantes';

export default function FabPesquisarDeck(props) {

    const { handleAbrirAlterarNomeDeck, handleAlterarNomeDeck, nomeAlterar, deck } = props

    return (
        <Box sx={{marginInline: '8px', padding: '8px', background: 'white', borderRadius: '25px', border: '1px solid' + Cores.COR_SECUNDARIA_ROSA }}>
            <Box onClick={(e) => nomeAlterar === deck.nome ? handleAlterarNomeDeck(e, deck) : handleAbrirAlterarNomeDeck(e, deck.nome)}>
                {nomeAlterar === deck.nome ?
                    <Typography>Salvar</Typography> :
                    <Typography >Renomear</Typography>
                }
            </Box>
        </Box>
    )
}
