import { Box, CssBaseline, ThemeProvider, Typography } from "@mui/material";
import { pink, purple } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { useEffect, useState } from 'react';
import CookieConsent from "react-cookie-consent";
import { Helmet } from "react-helmet";
import {
	BrowserRouter,
	Route,
	Routes
} from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import './App.css';
import Page from './page/Page';
import Aposta from "./page/aposta/Aposta";
import * as Constantes from "./util/constantes";
import * as Cores from "./util/Cores";
import PoliticaPrivacidade from "./util/files/PoliticaPrivacidade";
import TermosDeUso from "./util/files/TermosDeUso";
import {APIProvider} from '@vis.gl/react-google-maps';

const roxoEscuro = purple[900]
const roxo = Cores.COR_PRINCIPAL
const roxoClaro = purple[300]
const rosaEscuro = pink[500]
const rosa = pink[300]
const rosaClaro = Constantes.COR_SECUNDARIA_ROSA
const preto = 'black'
const branco = 'white'

const temaPokemon = createTheme({

	palette: {
		//background: {default: Constantes.COR_SECUNDARIA_FUNDO},
		primary: {
			light: roxoClaro,
			main: roxo,
			dark: roxoEscuro,
		},
		secondary: {
			light: rosaClaro,
			main: Constantes.COR_SECUNDARIA_ROSA,
			dark: rosaEscuro,
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '30px',
					fontSize: '14px',
					textTransform: 'unset',
					// '&.MuiButton-outlined': {
					// 	border: '2px solid ' + Constantes.COR_SECUNDARIA_ROSA,
					// 	background: Constantes.COR_SECUNDARIA_ROSA_FUNDO,
					// 	"&:hover": {
					// 		color:  Constantes.COR_SECUNDARIA_ROSA_ESCURO,
					// 		border: '2px solid ' + Constantes.COR_SECUNDARIA_ROSA_ESCURO,
					// 		background: Constantes.COR_SECUNDARIA_ROSA_FUNDO_ESCURO,
					// 	}
					// }
				}
			}
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					"::-webkit-scrollbar": {
						width: "3px",
					},
					"::-webkit-scrollbar-track": {
						backgroundColor: '#fff5f5'
					},
					"::-webkit-scrollbar-thumb": {
						background: Constantes.COR_SECUNDARIA_ROSA,
						borderRadius: "15px",
						height: "2px"
					},
					"::-webkit-scrollbar-thumb:hover": {
						background: Constantes.COR_SECUNDARIA_ROSA,
						maxHeight: "10px"
					},
				}
			}
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					textAlign: 'left',
					width: '100%',
				}
			}
		},
		MuiToggleButton: {
			styleOverrides: {
				root: {
					backgroundColor: rosa,
					"&.Mui-selected": {
						color: preto,
						backgroundColor: rosaClaro,
						"&:hover": {
							color: branco,
							backgroundColor: rosaClaro
						}
					},
					"&:hover": {
						color: branco,
						backgroundColor: rosa
					}
				}
			}
		}
	}
});


const initialValues = {
	roles: Constantes.ROLE_ADMIN,
	id: 0,
	username: '',
	email: '',
	senha: '',
	token: '',
	data: new Date().getTime()
}

function App() {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [userDetails, setUserDetails] = useState(secureLocalStorage.getItem('userDetails'));

	useEffect(() => {
		secureLocalStorage.setItem('userDetails', { ...userDetails, data: new Date().getTime() })
	}, [userDetails]);

	useEffect(() => {
		let user = userDetails
		let dataExpira
		if (user !== undefined && user !== null) {
			dataExpira = new Date(user.data + Constantes.TIME_EXPIRAR_LOGIN)
		}
		if (user === null || user === undefined) {
			setUserDetails(initialValues)
			setIsLoggedIn(false)
		} else if (new Date() > dataExpira) {
			setUserDetails(initialValues)
			setIsLoggedIn(false)
		} else if (user.id > 0) {
			setUserDetails(user)
			setIsLoggedIn(true)
		} else {
			setUserDetails(initialValues)
			setIsLoggedIn(false)
		}
	}, []);



	return (
		<Box className="App" >
			<Helmet>
				<title>Summoner Cat</title>
				<link rel="canonical" href="https://summonercat.com" />
				<meta name="description" content="Gerencie coleções de cartas." />
				<meta name="keywords" content="Pokemon, Digimon, TCG, cartas, card game, card" />
				<meta name="author" content="Summoner Cat" />
				<meta name="theme-color" content={Cores.COR_PRINCIPAL} />
			</Helmet>
			
			<ThemeProvider theme={temaPokemon}>
				<CssBaseline />
				<BrowserRouter>
					<Routes>
						<Route path="/politica" element={<PoliticaPrivacidade />} />
						<Route path="/termos" element={<TermosDeUso />} />
						<Route path="/dashboard" element={<Page
							setUserDetails={setUserDetails}
							isLoggedIn={isLoggedIn}
							setIsLoggedIn={setIsLoggedIn}
							userDetails={userDetails}
							corpo={'dashboard'} />} />
						<Route path="/login" element={<Page
							setUserDetails={setUserDetails}
							isLoggedIn={isLoggedIn}
							setIsLoggedIn={setIsLoggedIn}
							userDetails={userDetails}
							corpo={'login'} />} />
						<Route path="/loja" element={<Page
							setUserDetails={setUserDetails}
							isLoggedIn={isLoggedIn}
							setIsLoggedIn={setIsLoggedIn}
							userDetails={userDetails}
							corpo={'loja'} />} />
						<Route path="/aposta" element={<Aposta />} />
						<Route path="/" element={<Page
							setUserDetails={setUserDetails}
							isLoggedIn={isLoggedIn}
							setIsLoggedIn={setIsLoggedIn}
							userDetails={userDetails}
							corpo={'home'} />} />
						<Route path="/tutorial" element={<Page
							setUserDetails={setUserDetails}
							isLoggedIn={isLoggedIn}
							setIsLoggedIn={setIsLoggedIn}
							userDetails={userDetails}
							corpo={'tutorial'} />} />
						<Route path="/sobre" element={<Page
							setUserDetails={setUserDetails}
							isLoggedIn={isLoggedIn}
							setIsLoggedIn={setIsLoggedIn}
							userDetails={userDetails}
							corpo={'sobre'} />} />
						<Route path="/cartas" element={<Page
							setUserDetails={setUserDetails}
							userDetails={userDetails}
							setIsLoggedIn={setIsLoggedIn}
							isLoggedIn={isLoggedIn}
							corpo={'cartas'} />} />
						<Route path="/perfil" element={<Page
							setUserDetails={setUserDetails}
							userDetails={userDetails}
							setIsLoggedIn={setIsLoggedIn}
							isLoggedIn={isLoggedIn}
							corpo={'perfil'} />} />
						<Route path={"/" + Constantes.PAGE_ALTERAR_SENHA} element={<Page
							setUserDetails={setUserDetails}
							userDetails={userDetails}
							setIsLoggedIn={setIsLoggedIn}
							isLoggedIn={isLoggedIn}
							corpo={Constantes.PAGE_ALTERAR_SENHA} />} />
						<Route path={"/" + Constantes.PAGE_CHECKOUT} element={<Page
							setUserDetails={setUserDetails}
							userDetails={userDetails}
							setIsLoggedIn={setIsLoggedIn}
							isLoggedIn={isLoggedIn}
							corpo={Constantes.PAGE_CHECKOUT} />} />
						<Route path={"/" + Constantes.PAGE_ATIVAR_CONTA} element={<Page
							setUserDetails={setUserDetails}
							userDetails={userDetails}
							setIsLoggedIn={setIsLoggedIn}
							isLoggedIn={isLoggedIn}
							corpo={Constantes.PAGE_ATIVAR_CONTA} />} />
						<Route path="/teste" element={<Page
							setUserDetails={setUserDetails}
							userDetails={userDetails}
							setIsLoggedIn={setIsLoggedIn}
							isLoggedIn={isLoggedIn}
							corpo={'teste'} />} />
					</Routes>
				</BrowserRouter>
			</ThemeProvider>
			
		</Box>
	);
}

export default App;
