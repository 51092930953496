import { Box, ClickAwayListener, Typography } from "@mui/material";
import * as Mascaras from '../../util/Mascaras';

export default function InfoWindowLocal(props) {
    const { poi, handleClickOutside } = props

    return (
        <ClickAwayListener onClickAway={() => handleClickOutside()}>
            <Box >
                <Typography><a href={poi.url} target="_blank">{poi.titulo}</a></Typography>
                <Typography>{poi.endereco}</Typography>
                <img style={{ borderRadius: '5px' }} className="main-card" height='200px' width={'250px'} data-src={poi.imagemUrl} src={poi.imagemUrl} />
                <Typography>{Mascaras.dinheiroCifra(poi.valorVenda)}</Typography>
                {(poi.valorOriginal > 0 && poi.valorOriginal !== poi.valorVenda) && <Typography><s>{Mascaras.dinheiroCifra(poi.valorOriginal)}</s></Typography>}
            </Box>
        </ClickAwayListener>
    )
}