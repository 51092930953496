import { Box, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Media from "react-media";
import secureLocalStorage from "react-secure-storage";
import Popup from "../components/Popup";
import Controls from "../components/controls/Controls";
import Footer from "../components/footer/Footer";
import FooterMobile from "../components/footer/FooterMobile";
import * as Mensagens from "../util/Mensagens";
import * as Utils from "../util/Utils";
import * as Constantes from "../util/constantes";
import CartasWrapper from "./Cartas/CartasWrapper";
import AlterarSenha from "./alterar-senha/AlterarSenha";
import AtivarConta from "./ativar-conta/AtivarConta";
import Checkout from "./checkout/Checkout";
import Dashboard from "./dashboard/Dashboard";
import TutorialWrapper from "./dev/tutorial/TutorialWrapper";
import HomeWrapper from "./home/HomeWrapper";
import LoginWrapper from "./login/LoginWrapper";
import LojaWrapper from "./loja/LojaWrapper";
import PerfilWrapper from "./perfil/PerfilWrapper";
import SobreWrapper from "./sobre/SobreWrapper";
import TesteWrapper from "./teste/TesteWrapper";


export default function Wrapper(props) {
    const { corpo, setCarrinho, carrinho, alterarCarrinho, removerCarrinho, isLoggedIn, userDetails, setUserDetails, setIsLoggedIn } = props
    const { width } = Utils.useWindowDimensions();
    const [paperWidth, setPaperWidth] = useState('')
    const [margin, setMargin] = useState('')
    const [mostrarPropaganda, setMostrarPropaganda] = useState(false);

    const isMobile = width < Constantes.MOBILE_SCREEN_SIZE

    useEffect(() => {
        let mostrar = secureLocalStorage.getItem('mostrarPropaganda')?.valor
        setMostrarPropaganda(mostrar === undefined ? true : mostrar)
    }, []);

    const handleOkPropaganda = () => {
        secureLocalStorage.setItem('mostrarPropaganda', { valor: false })
        setMostrarPropaganda(false)
    }

    useEffect(() => {
        if (isMobile) {
            setPaperWidth('100%')
        } else {
            setPaperWidth('80%')
            setMargin('10%')
        }

    }, [])

    return (
        <Box sx={{
            width: paperWidth, marginInline: margin, marginTop: isMobile ? '16.5%' : '4%'
        }}>
            {corpo === 'cartas' ?
                <CartasWrapper
                    alterarCarrinho={alterarCarrinho}
                    removerCarrinho={removerCarrinho}
                    isLoggedIn={isLoggedIn}
                    userDetails={userDetails}
                />
                :
                null
            }

            {corpo === 'loja' ?
                <LojaWrapper
                    alterarCarrinho={alterarCarrinho}
                    removerCarrinho={removerCarrinho}
                    isLoggedIn={isLoggedIn}
                    userDetails={userDetails}
                />
                :
                null
            }
            {corpo === 'perfil' ?
                <PerfilWrapper
                    isLoggedIn={isLoggedIn}
                    userDetails={userDetails}
                    setUserDetails={setUserDetails} />
                :
                null
            }
             {corpo === 'teste' ?
                <TesteWrapper
                    isLoggedIn={isLoggedIn}
                    userDetails={userDetails}
                    setUserDetails={setUserDetails} />
                :
                null
            }
            {corpo === 'sobre' ?
                <SobreWrapper
                    isLoggedIn={isLoggedIn}
                    userDetails={userDetails} />
                :
                null
            }
            {corpo === 'dashboard' ?
                <Dashboard
                    alterarCarrinho={alterarCarrinho}
                    removerCarrinho={removerCarrinho}
                    isLoggedIn={isLoggedIn}
                    userDetails={userDetails} />
                :
                null
            }

            {corpo === 'home' ?
                <HomeWrapper
                    isLoggedIn={isLoggedIn}
                    userDetails={userDetails}
                    alterarCarrinho={alterarCarrinho}
                    removerCarrinho={removerCarrinho}
                    isMobile={isMobile}
                />
                :
                null
            }
            {corpo === 'tutorial' ?
                <TutorialWrapper
                    isLoggedIn={isLoggedIn}
                    userDetails={userDetails} />
                :
                null
            }
            {corpo === Constantes.PAGE_ALTERAR_SENHA ?
                <AlterarSenha
                    isLoggedIn={isLoggedIn}
                    userDetails={userDetails} />
                :
                null
            }
            {corpo === Constantes.PAGE_ATIVAR_CONTA ?
                <AtivarConta
                    isLoggedIn={isLoggedIn}
                    userDetails={userDetails} />
                :
                null
            }
            {corpo === 'login' ?
                <LoginWrapper
                    alterarCarrinho={alterarCarrinho}
                    removerCarrinho={removerCarrinho}
                    isLoggedIn={isLoggedIn}
                    userDetails={userDetails}
                    setIsLoggedIn={setIsLoggedIn}
                    setUserDetails={setUserDetails}
                />
                :
                null
            }
            {corpo === Constantes.PAGE_CHECKOUT ?
                <Checkout
                    isLoggedIn={isLoggedIn}
                    userDetails={userDetails}
                    carrinho={carrinho}
                    setCarrinho={setCarrinho}
                    alterarCarrinho={alterarCarrinho}
                    removerCarrinho={removerCarrinho}
                    isMobile={isMobile}
                />
                :
                null
            }

            {corpo !== Constantes.PAGE_CHECKOUT &&
                <Box>
                    {isMobile ?
                        <FooterMobile
                            userDetails={userDetails}
                        />
                        :
                        <Footer
                            userDetails={userDetails}
                        />
                    }
                </Box>
            }

            <Popup
                maxWidth={'sm'}
                openPopup={mostrarPropaganda}
                setOpenPopup={setMostrarPropaganda}
                title={"Bem-vindo(a)"}
                width='550px'
            >
                <Box>
                    <Typography>
                        {Mensagens.PRIMEIRO_ACESSO_MENSAGEM}
                    </Typography>
                    <Controls.Button
                        sx={{ marginTop: '6%' }}
                        text='Ok!'
                        onClick={() => handleOkPropaganda()}
                    />
                </Box>
            </Popup>

        </Box>

    )
}