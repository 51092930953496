import { Box } from "@mui/material";
import { useState } from 'react';
import ControleQuantCarta from '../../../components/ControleQuantCarta';
import Popup from '../../../components/Popup';
import * as Cores from "../../../util/Cores";
import * as Utils from '../../../util/Utils';
import * as Constantes from "../../../util/constantes";
import VisualizarCarta from "../visualizarCarta/VisualizarCarta";

export default function ListaCartaDeck(props) {
    const { cardsDeck, setCardsDeck, adicionarCarta, removerCarta, isLoggedIn, userDetails, isMobile } = props
    const [openPopup, setOpenPopup] = useState(false)
    const [cartaInPopup, setCartaInPopup] = useState({ cartaTO: { nome: '' } })
    const { width } = Utils.useWindowDimensions();

    const openInPopup = (carta) => {
        setOpenPopup(true)
        setCartaInPopup(carta)
    }

    const atualizarCarta = (carta, is, tipo) => {
        setCardsDeck(cardsDeck.map((card) => {
            if (card.cartaTO.id === carta.cartaTO.id) {
                if (tipo === Constantes.TIPO_LISTA_COLECAO) {
                    return { ...carta, ehColecao: !carta.ehColecao }
                } if (tipo === Constantes.TIPO_LISTA_DESEJO) {
                    return { ...carta, ehDesejo: !carta.ehDesejo }
                } if (tipo === Constantes.TIPO_LISTA_TROCA) {
                    return { ...carta, ehTroca: !carta.ehTroca }
                }

            } else {
                return card
            }
        }))
    }

    const separarCartas = (cartas) => {
        return isMobile ? Utils.dividirArrayEmGrupos(cartas, Constantes.CARD_PER_ROW_MOBILE + 1)
            : Utils.dividirArrayEmGrupos(cartas, Constantes.CARD_PER_ROW + 3)
    }

    const renderizarCartas = () => {
        let cardsEscolhidos = []
        return (
            separarCartas(cardsDeck).map((linha, linhaKey) => {
                return (
                    <Box key={linhaKey} sx={{ display: 'flex', justifyContent: 'left', paddingInline: '1%' }}>
                        {linha.map((carta, key) => {
                            let id = carta.cartaTO.id
                            if (!cardsEscolhidos.includes(id)) {
                                cardsEscolhidos = [id].concat(cardsEscolhidos)
                                return (
                                    <Box key={key} sx={{paddingInline: '1%'}}>
                                    <ControleQuantCarta
                                        openInPopup={openInPopup}
                                        carta={carta}
                                        cardsDeck={cardsDeck}
                                        removerCarta={removerCarta}
                                        adicionarCarta={adicionarCarta}
                                        key={key}
                                    />
                                    </Box>

                                )
                            }
                        })}
                    </Box>
                )
            })
        )
    }

    return (
        <Box >
            <Box sx={{
                overflowY: 'scroll', overflowX: 'hidden', height: '550px',
                "::-webkit-scrollbar": {
                    width: "3px",
                    height: '8px'
                },
                "::-webkit-scrollbar-track": {
                    backgroundColor: '#fff5f5'
                },
                "::-webkit-scrollbar-thumb": {
                    background: Cores.COR_PRINCIPAL,
                    borderRadius: "15px",
                    height: "1px"
                },
                "::-webkit-scrollbar-thumb:hover": {
                    background: Cores.COR_PRINCIPAL,
                    maxHeight: "1px",
                },
            }}>
                {renderizarCartas()}
            </Box>

            <Popup
                maxWidth='sm'
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title={cartaInPopup.cartaTO.nome}
            >
                <VisualizarCarta
                    carta={cartaInPopup}
                    isLoggedIn={isLoggedIn}
                    isMobile={width < Constantes.MOBILE_SCREEN_SIZE}
                    setCarta={setCartaInPopup}
                    userDetails={userDetails}
                    atualizarCarta={atualizarCarta}
                />
            </Popup>
        </Box>
    )
}