import { Box, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CartasService from "../../../api/services/CartasService";
import Controls from "../../../components/controls/Controls";
import { useCommon } from "../../../components/hooks/useCommon";
import * as Constantes from '../../../util/constantes';
import * as Cores from "../../../util/Cores";
import AreaCartasDeck from "./AreaCartasDeck";
import BuscarCartaDeck from "./BuscarCartaDeck";
import EstatisticasDeck from "./EstatisticasDeck";
import PesquisaDeckForm from "./PesquisaDeckForm";
import PoolCartasDeck from "./PoolCartasDeck";
import { cartasDesejo } from "../../../util/Utils";

export default function DeckBuilder(props) {
    const { userDetails, isLoggedIn, isMobile } = props
    const [deckList, setDeckList] = useState([])
    const [cardsDeck, setCardsDeck] = useState([])
    const [poolCards, setPoolCards] = useState([])
    const [deckSelecionado, setDeckSelecionado] = useState([])
    const [acao, setAcao] = useState(Constantes.DECK_ACAO_INICIAL)
    const { alterarLoad, notificar, Common } = useCommon()

    useEffect(() => {
        consultarDecksNome(userDetails.id)
    }, []);

    async function consultarCartasDeck(deck) {
        await CartasService.consultarDeckCodigo(deck.deckId, notificar, alterarLoad, userDetails.id).then(response => {
            setCardsDeck(response.cartasCompletasTO)
        })
    }

    function alterarAcao(acao) {
        setAcao(acao)
    }

    async function salvarDeck() {
        console.log('Salvando')
        console.log(cardsDeck)
        let deckTO = {
            cartas: cardsDeck.map((carta) => {return {id: carta.cartaTO.id, quantidade: carta.quantidade}}),
            deckId: deckSelecionado.deckId
        }
        if (deckSelecionado.deckId !== 0) {
            await CartasService.salvarDeck(deckTO, notificar, alterarLoad)
        }
    }

    async function consultarDecksNome(clienteId) {
        await CartasService.consultarDeckUsuario(clienteId, notificar, alterarLoad).then(response => {
            if (response.length === 0) {
                setDeckSelecionado({ deckId: 0, nome: Constantes.CRIAR_DECK })
            } else {
                setDeckSelecionado({ deckId: 0, nome: Constantes.ESCOLHER_DECK })
            }
            setDeckList(response)
        })
    }

    const adicionarCarta = (carta) => {
        if (cardsDeck.includes(carta)) {
            let index = cardsDeck.indexOf(carta)
            setCardsDeck(cardsDeck.map((c, i) => {
                if (index === i) {
                    return ({ ...c, quantidade: c.quantidade + 1 })
                } else {
                    return c
                }
            }))
        } else {
            setCardsDeck([...cardsDeck, { ...carta[0], quantidade: 1 }])
        }
    }

    return (
        <Box sx={{ background: 'white', padding: '1%' }}>
            <Common />
            <Box sx={{ width: '100%' }}>
                {isLoggedIn ?
                    isMobile ?
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ display: acao !== Constantes.DECK_ACAO_INICIAL && 'none' }} >
                                <PesquisaDeckForm
                                    isMobile={isMobile}
                                    userDetails={userDetails}
                                    setDeckList={setDeckList}
                                    deckList={deckList}
                                    setCardsDeck={setCardsDeck}
                                    setDeckSelecionado={setDeckSelecionado}
                                    notificar={notificar}
                                    consultarCartasDeck={consultarCartasDeck}
                                    alterarAcao={alterarAcao}
                                />
                            </Box>
                            <Box sx={{ display: acao !== Constantes.DECK_ACAO_ALTERAR ? 'none' : 'contents', paddingBottom: '5%' }}>
                                <BuscarCartaDeck
                                    userDetails={userDetails}
                                    setPoolCards={setPoolCards}
                                    poolCards={poolCards}
                                    cardsDeck={cardsDeck}
                                    setCardsDeck={setCardsDeck}
                                    adicionarCarta={adicionarCarta}
                                />
                            </Box>
                            <Box sx={{ display: acao !== Constantes.DECK_ACAO_ALTERAR ? 'none' : 'block', height: '50px', margin: '15px', marginBottom: '50px' }}>
                                <PoolCartasDeck
                                    adicionarCarta={adicionarCarta}
                                    poolCards={poolCards}
                                    cardsDeck={cardsDeck}
                                    setCardsDeck={setCardsDeck}
                                />
                            </Box>
                            <Box sx={{
                                marginTop: '15%', display: acao !== Constantes.DECK_ACAO_ALTERAR ? 'none' : 'flex',
                                justifyContent: 'space-around', width: '50%', marginLeft: '25%'
                            }}>
                                <Controls.Button
                                    text='Voltar'
                                    color='primary'
                                    onClick={() => alterarAcao(Constantes.DECK_ACAO_INICIAL)}
                                />
                                <Controls.Button
                                    text='Salvar'
                                    color='primary'
                                    onClick={() => salvarDeck()}
                                />
                            </Box>
                            <Box sx={{ display: acao !== Constantes.DECK_ACAO_ALTERAR ? 'none' : 'block' }}>
                                <AreaCartasDeck
                                    isLoggedIn={isLoggedIn}
                                    cardsDeck={cardsDeck}
                                    setCardsDeck={setCardsDeck}
                                    adicionarCarta={adicionarCarta}
                                    userDetails={userDetails}
                                    isMobile={isMobile}
                                    deckSelecionado={deckSelecionado}
                                />
                            </Box>
                            <Box sx={{ display: acao !== Constantes.DECK_ACAO_ALTERAR ? 'none' : 'block' }}>
                                <EstatisticasDeck cardsDeck={cardsDeck} isMobile={true} />
                                <Divider />
                            </Box>
                        </Box>


                        :


                        <Box sx={{ width: '100%', display: 'flex' }}>
                            <Box sx={{ width: '78%', borderRight: 'solid ' + Cores.COR_PRINCIPAL }}>
                                <Box sx={{ width: '100%', display: acao !== Constantes.DECK_ACAO_INICIAL && 'none' }}>
                                    <PesquisaDeckForm
                                        isMobile={isMobile}
                                        userDetails={userDetails}
                                        setDeckList={setDeckList}
                                        deckList={deckList}
                                        setCardsDeck={setCardsDeck}
                                        setDeckSelecionado={setDeckSelecionado}
                                        notificar={notificar}
                                        consultarCartasDeck={consultarCartasDeck}
                                        alterarAcao={alterarAcao}
                                    />
                                </Box>
                                <Box sx={{ display: acao !== Constantes.DECK_ACAO_ALTERAR ? 'none' : 'contents', width: '100%' }}>
                                    <AreaCartasDeck
                                        isLoggedIn={isLoggedIn}
                                        cardsDeck={cardsDeck}
                                        setCardsDeck={setCardsDeck}
                                        adicionarCarta={adicionarCarta}
                                        userDetails={userDetails}
                                        isMobile={isMobile}
                                        deckSelecionado={deckSelecionado}
                                    />
                                </Box>
                                <Box sx={{ marginTop: '3%', display: acao !== Constantes.DECK_ACAO_ALTERAR ? 'none' : 'flex', justifyContent: 'space-around', width: '50%', marginLeft: '25%' }}>
                                    <Controls.Button
                                        text='Voltar'
                                        color='primary'
                                        onClick={() => alterarAcao(Constantes.DECK_ACAO_INICIAL)}
                                    />
                                    <Controls.Button
                                        text='Salvar'
                                        color='primary'
                                        onClick={() => salvarDeck()}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ display: acao !== Constantes.DECK_ACAO_ALTERAR ? 'none' : 'block', width: '22%' }}>
                                <Box sx={{ paddingBottom: '5%' }}>
                                    <BuscarCartaDeck
                                        userDetails={userDetails}
                                        setPoolCards={setPoolCards}
                                        poolCards={poolCards}
                                        cardsDeck={cardsDeck}
                                        setCardsDeck={setCardsDeck}
                                        adicionarCarta={adicionarCarta}
                                    />
                                </Box>
                                <Box>
                                    <Box >
                                        <PoolCartasDeck
                                            adicionarCarta={adicionarCarta}
                                            poolCards={poolCards}
                                            cardsDeck={cardsDeck}
                                            setCardsDeck={setCardsDeck}
                                        />
                                    </Box>
                                    <Box>
                                        <EstatisticasDeck cardsDeck={cardsDeck} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    :
                    <Typography>
                        {Constantes.FACA_LOGIN}
                    </Typography>
                }
            </Box>
        </Box>
    )
}