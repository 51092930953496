import { Box, Typography } from "@mui/material";
import { ArcElement, Chart, Tooltip } from 'chart.js';
import { useEffect, useState } from "react";
import { Pie } from 'react-chartjs-2';
import { Nivel } from "./chart/nivel";
import { Raridade } from "./chart/raridade";
import { TipoPokemon } from "./chart/tipoPokemon";

export default function EstatisticasDeck(props) {
    const { cardsDeck, isMobile = false } = props
    const [dados, setDados] = useState([])
    const [dadosNivel, setDadosNivel] = useState([])
    const [dadosRaridade, setDadosRaridade] = useState([])

    useEffect(() => {
        montarDados()
        montarDadosNivel()
        montarDadosRaridade()
    }, [cardsDeck]);

    const montarDados = () => {
        let novosDados = []
        let keysEncontradas = []
        for (let key in Object.keys(TipoPokemon)) {
            let tipo = Object.values(TipoPokemon)[key]
            let cartas = cardsDeck.filter(card => card.cartaTO.natureza === tipo.id)
            if (cartas.length > 0) {
                if (!keysEncontradas.includes(key)) {
                    keysEncontradas.push(key)
                    let cartasFiltradas = cartas.map(item => {
                        return {
                            chave: tipo.nome,
                            valor: cartas.reduce((a,v) => a = a + v.quantidade, 0),
                            background: tipo.background,
                            backgroundHover: tipo.backgroundHover
                        }
                    })
                    novosDados = novosDados.concat(cartasFiltradas[0])
                }
            }

        }
        setDados(novosDados)
    }

    const montarDadosRaridade = () => {
        let novosDados = []
        let keysEncontradas = []
        for (let key in Object.keys(Raridade)) {
            let tipo = Object.values(Raridade)[key]
            let cartas = cardsDeck.filter(card => card.cartaTO.raridade === tipo.id)
            if (cartas.length > 0) {
                if (!keysEncontradas.includes(key)) {
                    keysEncontradas.push(key)
                    let cartasFiltradas = cartas.map(item => {
                        return {
                            chave: tipo.nome,
                            valor: cartas.reduce((a,v) => a = a + v.quantidade, 0),
                            background: tipo.background,
                            backgroundHover: tipo.backgroundHover
                        }
                    })
                    novosDados = novosDados.concat(cartasFiltradas[0])
                }
            }

        }
        setDadosRaridade(novosDados)
    }

    const montarDadosNivel = () => {
        let novosDados = []
        let keysEncontradas = []
        for (let key in Object.keys(Nivel)) {
            let tipo = Object.values(Nivel)[key]
            let cartas = cardsDeck.filter(card => card.cartaTO.nivel === tipo.id)
            if (cartas.length > 0) {
                if (!keysEncontradas.includes(key)) {
                    keysEncontradas.push(key)
                    let cartasFiltradas = cartas.map(item => {
                        return {
                            chave: tipo.nome,
                            valor: cartas.reduce((a,v) => a = a + v.quantidade, 0),
                            background: tipo.background,
                            backgroundHover: tipo.backgroundHover
                        }
                    })
                    novosDados = novosDados.concat(cartasFiltradas[0])
                }
            }

        }
        setDadosNivel(novosDados)
    }

    Chart.register(ArcElement, Tooltip)

    const options = {
        legend: {
            display: false,
            position: "right"
        },
        elements: {
            arc: {
                borderWidth: 0
            }
        },
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle'
                }
            }
        }
    };

    const data = {
        maintainAspectRatio: false,
        responsive: false,
        labels: dados.map(item => item.chave),
        datasets:
            [
                {
                    data: dados.map(item => item.valor),
                    backgroundColor: dados.map(item => item.background),
                    hoverBackgroundColor: dados.map(item => item.backgroundHover)
                }
            ]
    };

    const dataNivel = {
        maintainAspectRatio: false,
        responsive: false,
        labels: dadosNivel.map(item => item.chave),
        datasets:
            [
                {
                    data: dadosNivel.map(item => item.valor),
                    backgroundColor: dadosNivel.map(item => item.background),
                    hoverBackgroundColor: dadosNivel.map(item => item.backgroundHover)
                }
            ]
    };

    const dataRaridade = {
        maintainAspectRatio: false,
        responsive: false,
        labels: dadosRaridade.map(item => item.chave),
        datasets:
            [
                {
                    data: dadosRaridade.map(item => item.valor),
                    backgroundColor: dadosRaridade.map(item => item.background),
                    hoverBackgroundColor: dadosRaridade.map(item => item.backgroundHover)
                }
            ]
    };

    return (
        <Box sx={{paddingBottom: '10%', paddingTop: isMobile ? '10%' : '35%'}}>
            <Box sx={{ marginLeft: '21%', width: '60%' }}>
                <Typography> Tipo </Typography>
                <Pie
                    options={options}
                    data={data} />
            </Box>
            <Box sx={{display: 'flex'}}>
                <Box sx={{ marginLeft: '8%', width: '40%' }}>
                <Typography> Nível </Typography>
                    <Pie
                        options={options}
                        data={dataNivel} />
                </Box>
                <Box sx={{ marginLeft: '2%', width: '40%' }}>
                    <Typography> Raridade </Typography>
                    <Pie
                        options={options}
                        data={dataRaridade} />
                </Box>
            </Box>
        </Box>
    )
}