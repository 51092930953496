import { Box, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import HeaderBar from '../components/header/HeaderBar';
import backgroundNoiteImg from '../img/background-noite.jpg';
import backgroundImg from '../img/background.jpg';
import * as Cores from "../util/Cores";
import * as Utils from '../util/Utils';
import * as Constantes from '../util/constantes';
import Wrapper from "./Wrapper";

function Page(props) {
    const { corpo, isLoggedIn, userDetails, setUserDetails, setIsLoggedIn } = props
    const [carrinho, setCarrinho] = useState([])
    const [bg, setBg] = useState(backgroundImg)
    const navigate = useNavigate();

    useEffect(() => {
        let cart = secureLocalStorage.getItem('carrinho')?.valor === undefined ? [] : secureLocalStorage.getItem('carrinho')?.valor
        if (cart !== undefined && cart.data !== undefined && cart.valor !== undefined && cart.valor.length > 0) {
            let dataExpiraCarrinho = new Date(cart.data + Constantes.TIME_EXPIRAR_CARRINHO)
            if (new Date() > dataExpiraCarrinho) {
                cart = []
            }
        }
        setCarrinho(cart)
    }, []);

    useEffect(() => {
        secureLocalStorage.setItem('carrinho', { valor: carrinho, data: new Date() })
    }, [carrinho]);

    useEffect(() => {
        if (corpo === 'login') {
            if (Utils.verificarNoite()) {
                setBg(backgroundNoiteImg)
            } else {
                setBg(backgroundImg)
            }
        } else {
            setBg(backgroundImg)
        }

    }, [corpo]);

    const alterarCarrinho = (index, quant, carta, desconto = 0) => {
        return Utils.alterarCarrinho(index, quant, carta, carrinho, setCarrinho, userDetails.id, desconto)
    }

    const removerCarrinho = (index, tudo = false) => {
        if (tudo) {
            setCarrinho([])
        } else {
            setCarrinho(carrinho.filter((item, i) => i !== index))
        }
    }

    useEffect(() => {
        if ((userDetails === null || userDetails.id === 0) && corpo !== Constantes.PAGE_ALTERAR_SENHA && corpo !== Constantes.PAGE_ATIVAR_CONTA && corpo !== 'sobre'
            && corpo !== 'login' && corpo !== 'loja' && corpo !== Constantes.PAGE_CHECKOUT && corpo !== 'tutorial' && corpo !== Constantes.PAGE_CARTAS  && corpo !== 'teste') {
            navigate('/', { replace: true });
        }
    }, []);

    return (
        <Box style={{
            position: 'absolute',
            background: `url(${bg}) no-repeat center center fixed`, 
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            zIndex: '-1',
            backgroundColor: Cores.COR_PRINCIPAL,
            width: '100%',
            minHeight: '100%',
            overflow: 'hidden'
        }}>
            <HeaderBar
                corpo={corpo}
                setUserDetails={setUserDetails}
                carrinho={carrinho}
                setCarrinho={setCarrinho}
                alterarCarrinho={alterarCarrinho}
                removerCarrinho={removerCarrinho}
                isLoggedIn={isLoggedIn}
                userDetails={userDetails}
                setIsLoggedIn={setIsLoggedIn}
            />
            <Wrapper corpo={corpo}
                isLoggedIn={isLoggedIn}
                carrinho={carrinho}
                setCarrinho={setCarrinho}
                alterarCarrinho={alterarCarrinho}
                removerCarrinho={removerCarrinho}
                userDetails={userDetails}
                setUserDetails={setUserDetails}
                setIsLoggedIn={setIsLoggedIn}
            />
        </Box>
    )
}

export default Page;
