import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, IconButton, Typography } from "@mui/material";
import * as Cores from "../util/Cores";

export default function ControleQuantCarta(props) {
    const { openInPopup, carta, removerCarta, adicionarCarta } = props

    return (
        <Box sx={{ marginInline: '0.5%', position: 'relative' }}>
            <Box onClick={() => openInPopup(carta)} sx={{ cursor: 'pointer' }}>
                <img className="main-card" width='125' data-src={carta.cartaTO.imgUrl} src={carta.cartaTO.imgUrl} />
            </Box>
            <Box sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                backgroundColor: 'rgba(128, 64, 149, 0.7)',
                position: 'absolute',
                bottom: '7px',
                border: 'solid 1px ' + Cores.COR_PRINCIPAL_ESCURO,
                '& .MuiSvgIcon-root': {
                    fill: 'white',
                    fontSize: '25px'
                }
            }}>
                <Box sx={{
                    '&:hover': {
                        cursor: 'pointer',
                        backgroundColor: 'rgba(128, 64, 149, 0.9)',
                    }
                }}>
                    <IconButton onClick={() => adicionarCarta(carta)}>
                        <AddIcon />
                    </IconButton>
                </Box>
                <Box sx={{ background: Cores.COR_PRINCIPAL_ESCURO, width: '100%' }}>
                    <Typography sx={{ transform: 'translate(0px, 5px)', color: 'white' }} variant="h6">
                        {carta.quantidade}
                    </Typography>
                </Box>
                <Box sx={{
                    '&:hover': {
                        cursor: 'pointer',
                        backgroundColor: 'rgba(128, 64, 149, 0.9)',
                    }
                }}>
                    <IconButton onClick={(e) => removerCarta(e, carta)}>
                        <RemoveIcon />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    )
}