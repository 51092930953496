import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import CartasService from "../../../api/services/CartasService";
import Controls from "../../../components/controls/Controls";
import * as Cores from "../../../util/Cores";
import * as Mensagens from '../../../util/Mensagens';
import * as Constantes from '../../../util/constantes';

export default function PesquisaDeckForm(props) {
    const { userDetails, setDeckSelecionado, alterarAcao, deckList, setDeckList, notificar, consultarCartasDeck } = props
    const [deckNome, setDeckNome] = useState("")
    const [novoNome, setNovoNome] = useState("")
    const [nomeAlterar, setNomeAlterar] = useState("")
    const [erro, setErro] = useState("")

    const handleCriarNovoDeck = () => {
        if (deckList.length >= 2) {
            setErro(Mensagens.LIMITE_DECK)
        } else {
            criarNovoDeck(deckNome)
        }
    }

    const handleDeletarDeck = (e, deck) => {
        e.stopPropagation()
        e.preventDefault()
        deletarDeck(deck)
    }

    const handleAlterarNomeDeck = (e, deck) => {
        e.stopPropagation()
        e.preventDefault()
        if (novoNome !== nomeAlterar) {
            alterarNomeDeck({ nome: novoNome, deckId: deck.deckId })
        }
        setNomeAlterar('')
    }

    const handleAbrirAlterarNomeDeck = (e, deck) => {
        e.stopPropagation()
        e.preventDefault()
        if (nomeAlterar === deck) {
            setNomeAlterar("")
        } else {
            setNovoNome(deck)
            setNomeAlterar(deck)
        }
    }

    const handleSelecionarDeck = (deck) => {
        setDeckSelecionado(deck)
        consultarCartasDeck(deck)
        alterarAcao(Constantes.DECK_ACAO_ALTERAR)
    }

    async function alterarNomeDeck(deck) {
        console.log(deck)
        await CartasService.alterarNomeDeck(deck, notificar).then(() => {
            setNomeAlterar("")
            setDeckList(deckList.map((x) => {
                if (x.deckId !== deck.deckId) {
                    return x
                } else {
                    return { ...x, nome: novoNome }
                }
            }))
        })
    }
    async function deletarDeck(deck) {
        try {
            await CartasService.excluirDeck(deck).then(() => {
                setDeckList(deckList.filter((x) => x.nome !== deck))
                notificar("Deck excluído com sucesso.", "success")
            })
        } catch (e) {
            notificar(Mensagens.ERRO_SERVICO, "error")
        } finally {

        }

    }

    async function criarNovoDeck(deckNome) {
        try {
            await CartasService.criarDeck({ clienteId: userDetails.id, nome: deckNome }).then((response) => {
                if (deckNome === "") {
                    setDeckList(deckList.concat([{ nome: response.nome, deckId: response.deckId }]))
                } else {
                    setDeckList(deckList.concat([{ nome: deckNome, deckId: response.deckId }]))
                }
            })
        } catch (e) {
            notificar(Mensagens.ERRO_SERVICO, "error")
        } finally {

        }
    }

    return (
        <Box>
            <Grid item md={10} xs={12} sx={{ display: 'flex', marginTop: '2%' }}>
                <Controls.Input
                    name='deckNome'
                    label='Nome do novo Deck'
                    onChange={(e) => {setDeckNome(e.target.value)}}
                    value={deckNome}
                    error={erro}
                />
                <IconButton
                    onClick={() => handleCriarNovoDeck()}
                    sx={{
                        marginLeft: '25px',
                        '& .MuiSvgIcon-root': {
                            fill: Cores.COR_PRINCIPAL,
                        }
                    }}>
                    <AddCircleOutlineIcon fontSize="large" />
                </IconButton>

            </Grid>

            <Box sx={{ marginTop: '3%' }}>
                <Typography variant="h6" color='primary'>Seus Decks</Typography>
                <Divider />
            </Box>

            {deckList.map((deck, index) => {
                return (
                    <Box key={index} sx={{ alignItems: 'center' }}>
                        <Box onClick={() => handleSelecionarDeck(deck)} sx={{
                            display: 'flex', cursor: 'pointer', justifyContent: 'space-between', alignItems: 'center',
                            '&:hover': {
                                backgroundColor: '#f3dcf7'
                            },
                        }}>

                            <Box sx={{ padding: '1%' }}>
                                {nomeAlterar !== deck.nome ?
                                    <Box onClick={() => handleSelecionarDeck(deck)}>
                                        <Typography> {deck.nome} </Typography>
                                    </Box>
                                    :
                                    <Box onClick={(e) => {e.stopPropagation();e.preventDefault();}}
                                        sx={{
                                            justifyContent: 'space-between', 
                                        }}>
                                        <Controls.Input
                                            name='nomeDeck'
                                            label='Nome do deck'
                                            sx={{
                                                background: 'white', borderTopLeftRadius: '10px', borderTopRightRadius: '10px',
                                                "& .MuiFormLabel-root": {
                                                    marginLeft: '6px'
                                                }
                                            }}
                                            value={novoNome !== null && novoNome.substring(0, 40)}
                                            onChange={(e) => setNovoNome(e.target.value)}
                                        />

                                    </Box>}
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', paddingTop: '1%', paddingBottom: '1%', paddingRight: '1%' }}>
                                <Controls.Fab.FabPesquisarDeck
                                    handleAbrirAlterarNomeDeck={handleAbrirAlterarNomeDeck}
                                    handleAlterarNomeDeck={handleAlterarNomeDeck}
                                    nomeAlterar={nomeAlterar}
                                    deck={deck}
                                />

                                <Controls.Fab.FabExcluirDeck
                                    deck={deck}
                                    handleDeletarDeck={handleDeletarDeck}
                                />
                            </Box>

                        </Box>
                        <Divider />
                    </Box>
                )

            })}



        </Box>
    )
}