
export const CARD_PER_PAGE = 10
export const CARD_PER_ROW = 4
export const CARD_PER_ROW_LOJA = 5
export const CARD_PER_ROW_PEDIDO = 4    
export const CARD_PER_PAGE_MOBILE = 20
export const CARD_PER_ROW_MOBILE = 2
export const CARD_PER_ROW_MOBILE_LOJA = 2
export const CARD_PER_ROW_MOBILE_PEDIDO = 1

export const TIPO_LISTA_CARRINHO = 1
export const TIPO_LISTA_DESEJO = 2
export const TIPO_LISTA_COLECAO = 3
export const TIPO_LISTA_DECK = 4
export const TIPO_LISTA_TROCA = 5

export const RARIDADE_COMUM = 1
export const RARIDADE_INCOMUM = 2
export const RARIDADE_RARA = 3
export const RARIDADE_ULTRA_RARA = 4
export const RARIDADE_RARA_SECRETA = 5
export const RARIDADE_PROMO = 6
export const RARIDADE_PROMO_DIGI = 13

export const CD_ELETRICO = 8
export const CD_FOGO = 5
export const CD_AGUA = 3
export const CD_DARK = 9
export const CD_DRAGAO = 7
export const CD_FADA = 6
export const CD_LUTADOR = 1
export const CD_METAL = 10
export const CD_NORMAL = 11
export const CD_PLANTA = 2
export const CD_PSIQUICA = 4
export const CD_ITEM = 22
export const CD_APOIADOR = 23
export const CD_ESTADIO = 13
export const CD_FERRAMENTA = 21
export const CD_ENERGIA = 24

export const DECK_ACAO_INICIAL = 'inicial'
export const DECK_ACAO_ALTERAR = 'alterar'

export const CRIAR_DECK = 'Nenhum Deck'
export const ESCOLHER_DECK = 'Escolha um Deck'

export const COLECAO = 'colecao'
export const DESEJO = 'desejo'
export const TROCA = 'troca'
export const TODAS = 'todas'

export const ROLE_ADMIN = 'ROLE_ADMIN'
export const ROLE_USER = 'ROLE_USER'
export const FACA_LOGIN = 'Faça login para acessar esta aba.'
export const PAGE_ALTERAR_SENHA = 'alterarSenha'
export const PAGE_ATIVAR_CONTA = 'ativarConta'
export const PAGE_CHECKOUT = 'checkout'
export const PAGE_CARTAS = 'cartas'

export const TIME_EXPIRAR_LOGIN = 1.75 * 3600 * 1000
export const TIME_EXPIRAR_CARRINHO = 20 * 3600 * 1000

export const MOBILE_SCREEN_SIZE = 799

export const TIPO_TREINADOR = 'Treinador'
export const TIPO_ENERGIA = "Energia"

export const COR_SECUNDARIA_AZUL = "#2196f3"
//export const COR_PRINCIPAL = "#8c39bf"
export const COR_SECUNDARIA_ROSA = "#f06292"
export const COR_SECUNDARIA_ROSA_ESCURO = "#c04e74"
export const COR_SECUNDARIA_FUNDO = "#f7ebf9"
export const COR_SECUNDARIA_FUNDO_OPACITY = 'rgba(247, 235, 249, 0.8)'
export const COR_FUNDO_ERRO = "#ffd8d8"
export const COR_ERRO = '#ff4f4f'
export const COR_SECUNDARIA_ROSA_FUNDO = "#fdebf5"
export const COR_SECUNDARIA_ROSA_FUNDO_ESCURO = "#f7b7db"

export const COR_SUCESSO_FUNDO = '#c8facd'

export const COR_CONQUISTA_OURO = '#FFD700'
export const COR_CONQUISTA_PRATA = '#A9A9A9'
export const COR_CONQUISTA_BRONZE = '#CD7F32'
export const COR_CONQUISTA_NENHUM = '#DDDDDD'

export const CONQUISTA_EDICOES_COMPLETAS = 1
export const CONQUISTA_NUMERO_CARTAS = 2
export const CONQUISTA_VALOR_COLECAO = 3


export const TODAS_CARTAS_MENU = 'Cartas'
export const COLECAO_MENU = 'Coleção'
export const DESEJOS_MENU = 'Desejos'
export const TROCA_MENU = "Troca"
export const PESQUISAR_MENU = "Coleção/Listas"
export const PESQUISAR_MENU_URL = 'Pesquisar'
export const DECK_BUILDER_MENU = "Deck Builder"
export const FICHARIO_MENU = "Catalogar"
export const CONTA_MENU = 'Conta'
export const ADMIN_MENU = 'Admin'
export const ENDERECO = 'Endereços'
export const PEDIDOS = 'Pedidos'
export const ADMIN_PEDIDOS = 'Adm Pedidos'
