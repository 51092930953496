import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ConstructionIcon from '@mui/icons-material/Construction';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import InventoryIcon from '@mui/icons-material/Inventory';
import { Box } from "@mui/material";
import { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import CartasService from '../../api/services/CartasService';
import { useCommon } from '../../components/hooks/useCommon';
import * as Mensagens from '../../util/Mensagens';
import * as Utils from '../../util/Utils';
import * as Constantes from '../../util/constantes';
import CartaMenuIcone from './CartaMenuIcone';
import Cartas from "./Cartas";
import FiltroPesquisa from './FiltroPesquisa';
import DeckBuilder from './deck-builder/DeckBuilder';
import Fichario from './fichario/Fichario';
import PesquisarHash from './pesquisar-hash/PesquisarHash';

export default function CartasWrapper(props) {
    const { alterarCarrinho, removerCarrinho, isLoggedIn, userDetails } = props
    const [listaColecao, setListaColecao] = useState([])
    const [listaDesejo, setListaDesejo] = useState([])
    const [listaCartas, setListaCartas] = useState([])
    const [listaTroca, setListaTroca] = useState([])
    const [listaCartasColecao, setListaCartasColecao] = useState([])
    const [listaCartasDesejo, setListaCartasDesejo] = useState([])
    const [listaCartasTroca, setListaCartasTroca] = useState([])
    const [count, setCount] = useState(1)
    const [listaVisualizacao, setListaVisualizacao] = useState([])
    const [listaRaridade, setListaRaridade] = useState()
    const [listaEdicao, setListaEdicao] = useState()
    const [listaJogo, setListaJogo] = useState()
    const [listaNatureza, setListaNatureza] = useState()
    const [showPage, setShowPage] = useState(false)
    const [page, setPage] = useState(1)
    const [hashDesejo, setHashDesejo] = useState("")
    const [hashColecao, setHashColecao] = useState("")
    const [hashTroca, setHashTroca] = useState("")
    const [listaPesquisaHash, setListaPesquisaHash] = useState([])
    const [nomeLista, setNomeLista] = useState('')
    const { height, width } = Utils.useWindowDimensions();
    const [corpo, setCorpo] = useState(Constantes.TODAS_CARTAS_MENU)
    const [params] = useSearchParams();
    const [nome, setNome] = useState('')
    const [isFichario, setIsFichario] = useState(false)
    const [opcoes, setOpcoes] = useState([])
    const [tipoListaId, setTipoListaId] = useState('')
    const [pesquisou, setPesquisou] = useState(false)
    const [valuesFiltro, setValuesFiltro] = useState({})
    const [countItens, setCountItens] = useState(0)
    const [valuesFichario, setValuesFichario] = useState('')
    const [sort, setSort] = useState(1)

    const isMobile = width < Constantes.MOBILE_SCREEN_SIZE
    const { Common, notificar, alterarLoad, alterarMensagemLoad } = useCommon()

    useEffect(() => {
        let tipo = params.get('tipo')
        console.log(tipo)
        if (tipo === Constantes.PESQUISAR_MENU_URL) {
            setCorpo(Constantes.PESQUISAR_MENU)
        }
        consultarCombos()
    }, []);

    async function consultarCombos(jogo = 1) {
        await CartasService.consultarCombos(jogo, alterarMensagemLoad, alterarLoad, notificar).then((response) => {
            if (response !== undefined) {
                setListaRaridade(response.listaRaridade)
                setListaEdicao(response.listaEdicao)
                setListaNatureza(response.listaNatureza)
                setListaJogo(response.listaJogo)
            }
            setShowPage(true)
        })
    }

    function selecionarCorpo(valor) {
        setCorpo(valor)
    }

    const verificarColecao = (listaCartas, listaColecao, carta) => {
        if (listaCartas !== undefined) {
            let result = listaColecao.filter(x => { return (Number(x) === carta.cartaTO.id) })
            if (result.length > 0) {
                return true
            }
        }
        return false
    }

    const verificarDesejo = (listaCartas, listaDesejo, carta) => {
        if (listaCartas !== undefined) {
            let result = listaDesejo.filter(x => { return (Number(x) === carta.cartaTO.id) })
            if (result.length > 0) {
                return true
            }
        }
        return false
    }

    const verificarTroca = (listaCartas, listaTroca, carta) => {
        if (listaCartas !== undefined) {
            let result = listaTroca.filter(x => { return (Number(x) === carta.cartaTO.id) })
            if (result.length > 0) {
                return true
            }
        }
        return false
    }

    const atualizarListaAcaoIcone = (is, lista, setLista, listaCartas, setListaCartas, carta) => {
        if (is) {
            setLista([...lista, carta.cartaTO.id.toString()])
            let listaInicio = []
            let listaFim = []
            listaCartas.forEach(item => {
                if (item.cartaTO.numero < carta.cartaTO.numero) {
                    listaInicio.push(item)
                } else {
                    listaFim.push(item)
                }
            })
            setListaCartas([...listaInicio, carta, ...listaFim])
        } else {
            setLista(lista.filter(x => x !== carta.cartaTO.id.toString()))
            setListaCartas(listaCartas.filter(x => x.cartaTO.id !== carta.cartaTO.id))
        }
    }

    const atualizarCarta = (carta, is, tipo) => {
        if (tipo === Constantes.TIPO_LISTA_COLECAO) {
            atualizarListaAcaoIcone(is, listaColecao, setListaColecao, listaCartasColecao, setListaCartasColecao, carta)

        } if (tipo === Constantes.TIPO_LISTA_DESEJO) {
            atualizarListaAcaoIcone(is, listaDesejo, setListaDesejo, listaCartasDesejo, setListaCartasDesejo, carta)

        } if (tipo === Constantes.TIPO_LISTA_TROCA) {
            atualizarListaAcaoIcone(is, listaTroca, setListaTroca, listaCartasTroca, setListaCartasTroca, carta)
        }
    }

    function atualizarLista(response) {
        let colecao = response.cartasCompletasTO.filter(x => response.listaColecao.includes(x.cartaTO.id))
        setListaCartasColecao(colecao)
        setHashColecao(response.hashColecao)

        let desejo = response.cartasCompletasTO.filter(x => response.listaDesejo.includes(x.cartaTO.id))
        setListaCartasDesejo(desejo)
        setHashDesejo(response.hashDesejo)

        let troca = response.cartasCompletasTO.filter(x => response.listaTroca.includes(x.cartaTO.id))
        setListaCartasTroca(troca)
        setHashTroca(response.hashTroca)

        setListaCartas(response.cartasCompletasTO)
        setListaColecao(response.listaColecao)
        setListaDesejo(response.listaDesejo)
        setListaTroca(response.listaTroca)

        let grupos = []
        let tamanhoLinha = width < Constantes.MOBILE_SCREEN_SIZE ? Constantes.CARD_PER_ROW_MOBILE : Constantes.CARD_PER_ROW

        if (corpo === Constantes.DESEJOS_MENU) {
            grupos = Utils.dividirArrayEmGrupos(desejo, tamanhoLinha)
        } else if (corpo === Constantes.TROCA_MENU) {
            grupos = Utils.dividirArrayEmGrupos(troca, tamanhoLinha)
        } else if (corpo === Constantes.COLECAO_MENU) {
            grupos = Utils.dividirArrayEmGrupos(colecao, tamanhoLinha)
        } else {
            grupos = Utils.dividirArrayEmGrupos(response.cartasCompletasTO, tamanhoLinha)
        }

        let aux = grupos.map(x => x.map(y => {
            return {
                ...y, ehColecao: verificarColecao(listaCartas, response.listaColecao, y), ehDesejo: verificarDesejo(listaCartas, response.listaDesejo, y),
                ehTroca: verificarTroca(listaCartas, response.listaTroca, y)
            }
        }
        ))
        setListaVisualizacao(aux)
    }

    async function consultar(filtro) {
        filtro.clienteId = userDetails.id.toString()
        alterarMensagemLoad(Mensagens.CONSULTANDO_CARTAS)
        await CartasService.consultarCartasPorFiltro(filtro, notificar).then(response => {
            setIsFichario(false)
            setValuesFiltro(filtro)
            setCountItens(response.numeroItens)
            setCount(response.numeroPaginas)
            atualizarLista(response)
        })
    }

    async function consultarSort(sort){
        setPage(1)
        if (isFichario) {
            await consultarMultiplosNomes(valuesFichario, 0, sort)
        } else {
            await consultar({ ...valuesFiltro, sort: sort, pagina: 0 })
        }
    }

    async function paginacao(value) {
        setPage(value)
        if (isFichario) {
            await consultarMultiplosNomes(valuesFichario, value - 1)
        } else {
            await consultar({ ...valuesFiltro, pagina: value - 1 })
        }

    }


    async function consultarMultiplosNomes(nomes, pagina, sort = null) {
        setValuesFichario(nomes)
        alterarMensagemLoad(Mensagens.CONSULTANDO_CARTAS)
        let request = { nomes: nomes, clienteId: userDetails.id, pagina: pagina, tamanhoPagina: Constantes.CARD_PER_PAGE_MOBILE, sort: sort }
        await CartasService.consultarMultiplosNomes(request, notificar).then(response => {
            setIsFichario(true)
            setCount(response.numeroPaginas)
            atualizarLista(response)
            setCountItens(response.numeroItens)
        })
    }

    function montarRetorno(lista, tipo, logIn) {
        let hash = ""
        if (tipo === Constantes.COLECAO) {
            hash = hashColecao
        } else if (tipo === Constantes.DESEJO) {
            hash = hashDesejo
        } else if (tipo === Constantes.TROCA) {
            hash = hashTroca
        }
        return (
            <Box>
                <Cartas
                    isMobile={isMobile}
                    countItens={countItens}
                    page={page}
                    paginacao={paginacao}
                    alterarCarrinho={alterarCarrinho}
                    removerCarrinho={removerCarrinho}
                    verificarColecao={verificarColecao}
                    verificarDesejo={verificarDesejo}
                    verificarTroca={verificarTroca}
                    listaColecao={listaColecao}
                    listaDesejo={listaDesejo}
                    listaTroca={listaTroca}
                    listaCartas={lista}
                    count={count}
                    setCount={setCount}
                    setListaVisualizacao={setListaVisualizacao}
                    listaVisualizacao={listaVisualizacao}
                    atualizarCarta={atualizarCarta}
                    tipo={tipo}
                    hash={hash}
                    isLoggedIn={logIn}
                    userDetails={userDetails}
                    consultarSort={consultarSort}
                    sort={sort}
                    setSort={setSort}
                />
            </Box>
        )
    }

    return (
        <Box sx={{ overflowX: 'hidden' }}>
            <Common />
            <Box sx={{
                background: 'white',
                backdropFilter: 'blur(25px)',
                justifyContent: isMobile ? 'left' : 'center', 
                display: 'flex'
            }}>
                <Box sx={{ display: 'flex' }}>
                    <CartaMenuIcone
                        nome={Constantes.TODAS_CARTAS_MENU}
                        icone={<InventoryIcon />}
                        selecionarCorpo={selecionarCorpo}
                        corpo={corpo}
                        isMobile={isMobile}
                    />
                     <CartaMenuIcone
                        nome={Constantes.FICHARIO_MENU}
                        icone={<CameraAltIcon />}
                        selecionarCorpo={selecionarCorpo}
                        corpo={corpo}
                        isMobile={isMobile}
                    />
                      <CartaMenuIcone
                        nome={Constantes.PESQUISAR_MENU}
                        icone={<EmojiPeopleIcon />}
                        selecionarCorpo={selecionarCorpo}
                        corpo={corpo}
                        isMobile={isMobile}
                    />
                    <CartaMenuIcone
                        nome={Constantes.DECK_BUILDER_MENU}
                        icone={<ConstructionIcon />}
                        selecionarCorpo={selecionarCorpo}
                        corpo={corpo}
                        isMobile={isMobile}
                    />
                </Box>
            </Box>

            {![Constantes.PESQUISAR_MENU, Constantes.DECK_BUILDER_MENU, Constantes.FICHARIO_MENU].includes(corpo) &&
                <FiltroPesquisa
                    alterarLoad={alterarLoad}
                    setPage={setPage}
                    isMobile={isMobile}
                    listaVisualizacao={listaVisualizacao}
                    width={width}
                    listaRaridade={listaRaridade}
                    listaEdicao={listaEdicao}
                    listaNatureza={listaNatureza}
                    listaJogo={listaJogo}
                    atualizarLista={atualizarLista}
                    showPage={showPage}
                    consultar={consultar}
                    userDetails={userDetails}
                    isLoggedIn={isLoggedIn}
                    consultarCombos={consultarCombos}
                    page={page}
                    setSort={setSort}
                />}

            {corpo === Constantes.DECK_BUILDER_MENU &&
                <DeckBuilder
                    userDetails={userDetails}
                    isLoggedIn={isLoggedIn}
                    isMobile={isMobile}
                />
            }

            {corpo !== Constantes.DECK_BUILDER_MENU && <Box sx={{
                backdropFilter: 'blur(8px)', background: Constantes.COR_SECUNDARIA_FUNDO_OPACITY, height: height * 0.80,
                overflowY: 'scroll', borderRadius: '0px', ...Utils.barra(),
            }}>
                <Box sx={{ overflowX: 'scroll', display: showPage ? 'contents' : 'none', ...Utils.barra(), }}>
                    {corpo === Constantes.TODAS_CARTAS_MENU &&
                        montarRetorno(listaCartas, Constantes.TODAS, isLoggedIn)
                    }
                    {corpo === Constantes.FICHARIO_MENU &&
                        <Box>
                            {
                                userDetails.id === 0 ?
                                    <Box>
                                        É necessário realizar login.
                                    </Box>
                                    :
                                    <Box>
                                        <Fichario
                                            setPage={setPage}
                                            isMobile={isMobile}
                                            consultarMultiplosNomes={consultarMultiplosNomes}
                                            listaJogo={listaJogo}
                                            alterarLoad={alterarLoad}
                                            notificar={notificar}
                                            userDetails={userDetails}
                                        />
                                        {montarRetorno(listaCartas, Constantes.TODAS, isLoggedIn)}
                                    </Box>
                            }
                        </Box>
                    }
                    {corpo === Constantes.PESQUISAR_MENU &&
                        <PesquisarHash
                            alterarCarrinho={alterarCarrinho}
                            isMobile={isMobile}
                            alterarLoad={alterarLoad}
                            notificar={notificar}
                            listaVisualizacao={listaPesquisaHash}
                            setListaVisualizacao={setListaPesquisaHash}
                            nomeLista={nomeLista}
                            setNomeLista={setNomeLista}
                            isLoggedIn={isLoggedIn}
                            userDetails={userDetails}
                            nome={nome}
                            setNome={setNome}
                            opcoes={opcoes}
                            setOpcoes={setOpcoes}
                            tipoListaId={tipoListaId}
                            setTipoListaId={setTipoListaId}
                            pesquisou={pesquisou}
                            setPesquisou={setPesquisou}
                        />
                    }

                </Box>
            </Box>
            }
        </Box>)
}