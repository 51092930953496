import { Autocomplete, Box, CircularProgress, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import CartasService from "../../api/services/CartasService";

export default function AutocompleteCarta(props) {
    const [options, setOptions] = useState([])
    const [exibirLoad, setExibirLoad] = useState()
    const [listaCartas, setListaCartas] = useState()
    const { setPoolCards, poolCards, cardsDeck, setCardsDeck, userDetails, adicionarCarta } = props

    const handleChangeValue = (e, valor) => {
        if (valor.length > 3 && !exibirLoad) {
            consultar(valor)
        }
    }

    const getImagem = (id) => {
        return listaCartas.filter((x) => x.cartaTO.id === id)[0].cartaTO.imgUrl
    }

    const handleSelecionaItem = (e, carta) => {
        let cartaTO = listaCartas.filter((x) => carta.id === x.cartaTO.id)
        adicionarCarta(cartaTO)
        setPoolCards(cartaTO.concat(poolCards))
    }

    async function consultar(nome) {
        await CartasService.consultarCartaPorNome(nome, userDetails.id, null, setExibirLoad).then(response => {
            setListaCartas(response)
            setOptions(
                response.map((item) => {
                    return { id: item.cartaTO.id, label: item.cartaTO.numero + ' ' + item.cartaTO.nome + " - " + item.edicaoDescricao }
                })
            )
        })
    }

    return (
        <Autocomplete
            disablePortal
            id="buscarCarta"
            options={options}
            sx={{
                width: '85%',
            }}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label="Carta"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position='start'>
                                <CircularProgress
                                    size={25}
                                    disableShrink={true}
                                    variant={exibirLoad ? "indeterminate" : 'determinate'}
                                    value={0}
                                />
                            </InputAdornment>
                        )
                    }}
                />}

            renderOption={(props, option) => (
                <Box key={option.id} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                        loading="lazy"
                        width="40"
                        src={getImagem(option.id)}
                        alt=""
                    />
                    {option.label}
                </Box>
            )}
            onChange={(e, valor) => handleSelecionaItem(e, valor)}
            onInputChange={(e, valor) => { handleChangeValue(e, valor) }}
            noOptionsText={'Nenhum resultado'}
        />
    )
}