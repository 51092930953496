import { Box, CircularProgress, Grid, Pagination, Stack, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import Carta from "../../components/Carta";
import * as Utils from "../../util/Utils";
import * as Constantes from "../../util/constantes";
import Controls from "../../components/controls/Controls";

export default function Cartas(props) {
    const { setCount, listaVisualizacao, setListaVisualizacao, count, listaCartas, listaDesejo, listaColecao, listaTroca, alterarCarrinho,
        sort, setSort, verificarColecao, verificarDesejo, verificarTroca, atualizarCarta, tipo, paginacao, page, hash, isLoggedIn, userDetails, countItens, isMobile, consultarSort } = props
    const { width } = Utils.useWindowDimensions();
    const [load, setLoad] = useState(false)

    const onChangeSort = async (e) => {
        setLoad(true)
        setSort(e.target.value)
        await consultarSort(e.target.value).finally(() => {
            setLoad(false)
        })
    }

    useEffect(() => {
        let grupos
        if (isMobile) {
            grupos = Utils.dividirArrayEmGrupos(listaCartas, Constantes.CARD_PER_ROW_MOBILE)
        } else {
            grupos = Utils.dividirArrayEmGrupos(listaCartas, Constantes.CARD_PER_ROW)
        }
        let aux = grupos.map(x => x.map(y => {
            return {
                ...y, ehColecao: verificarColecao(listaCartas, listaColecao, y), ehDesejo: verificarDesejo(listaCartas, listaDesejo, y),
                ehTroca: verificarTroca(listaCartas, listaTroca, y)
            }
        }
        ))
        setListaVisualizacao(aux)
        if (isMobile) {
            setCount(Math.ceil(grupos.length / Constantes.CARD_PER_PAGE_MOBILE))
        } else {
            setCount(Math.ceil(grupos.length / Constantes.CARD_PER_PAGE))
        }

    }, [])

    const handleChangePagination = async (event, value) => {
        setLoad(true)
        await paginacao(value).finally(() => {
            setLoad(false)
        })
    };

    return (
        <Box>
            
            <Box sx={{
                marginBottom: '1%',
                display: isLoggedIn ? 'block' : 'none',
                '& .MuiPagination-ul': {
                    justifyContent: 'center'
                }
            }}>
                {tipo !== 'todas' ?
                    <Typography color='primary' style={{ fontWeight: 700, marginTop: '1%', marginBottom: '1%', borderRadius: '50px', textAlign: 'center', fontSize: '20px', }}>
                        Lista {hash}
                    </Typography>
                    :
                    null}
            </Box>
            <Box sx={{
                '& .MuiPagination-ul': {
                    justifyContent: 'center'
                }
            }}>
                <Grid container sx={{ paddingBottom: isMobile ? '1%' : '0.4%',display: listaCartas.length > 0 ? 'flex' : 'none', alignItems: 'center', background: 'white', width: '100%' }}>
                    <Grid item md={4} xs={4}>
                        <Box sx={{ width: isMobile ? '80%' : '60%', paddingLeft: '2%' }}>
                            <Controls.SortCartas
                                value={sort}
                                onChange={onChangeSort}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={4}>
                        <Typography color='primary' sx={{ justifyContent: 'left' }}>
                            <b>Resultado: {countItens}</b>
                        </Typography>
                    </Grid>
                </Grid>

                <Pagination sx={{ marginTop: '1%' }} count={count} color="secondary" page={page} onChange={handleChangePagination} />
            </Box>
            <Box sx={{ display: isLoggedIn || (!isLoggedIn && tipo === 'todas') ? 'none' : 'contents' }}>
                {Constantes.FACA_LOGIN}
            </Box>
            {load && <CircularProgress />}
            <Box>
                {listaVisualizacao.map((lista, i) => {
                    return (
                        <Stack direction='row' key={i} sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }} spacing={isMobile ? 4 : 2}>
                            {lista.map((item, index) => {
                                return (<Carta
                                    alterarCarrinho={alterarCarrinho}
                                    atualizarCarta={atualizarCarta}
                                    cartaInfo={item}
                                    pagina='loja'
                                    key={'loja' + index}
                                    isLoggedIn={isLoggedIn}
                                    userDetails={userDetails}
                                    width={width}
                                />)
                            })}
                        </Stack>
                    )
                })}
            </Box>

            {listaVisualizacao.length > 0 &&
                <Box sx={{
                    paddingBottom: '1%',
                    '& .MuiPagination-ul': {
                        justifyContent: 'center'
                    }
                }}>
                    <Pagination count={count} color="secondary" page={page} onChange={handleChangePagination} />
                </Box>}

        </Box>
    )
}