import { Box } from "@mui/material";
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import { useEffect, useState } from 'react';
import { useCommon } from '../../components/hooks/useCommon';
import * as Utils from '../../util/Utils';
import * as Constantes from '../../util/constantes';
import MarkerComWindow from "./MarkerComWindow";
import * as dados from './dados'
import * as dadosVenda from './dadosVenda'

const locations = dados.locations.concat(dadosVenda.locations)

export default function TesteWrapper(props) {
    const { userDetails, setUserDetails } = props
    const { notificar, alterarLoad, alterarMensagemLoad, Common } = useCommon()
    const { width } = Utils.useWindowDimensions();
    const isMobile = width < Constantes.MOBILE_SCREEN_SIZE
    console.log(locations.length)

    useEffect(() => {
        console.log(haversine_distance({ lat: -3.8500837829873986, lng: -38.46188654232841}, { lat: -3.8569704887780434, lng: -38.446345728836334}))
    }, []);

    function haversine_distance(mk1, mk2) {
        var R = 6371.0710; // Radius of the Earth in miles
        var rlat1 = mk1.lat * (Math.PI/180); // Convert degrees to radians
        var rlat2 = mk2.lat * (Math.PI/180); // Convert degrees to radians
        var difflat = rlat2-rlat1; // Radian difference (latitudes)
        var difflon = (mk2.lng-mk1.lng) * (Math.PI/180); // Radian difference (longitudes)
  
        var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
        return d;
      }

      const PoiMarkers = (pois) => {

        return (
            <>
                {pois.map((poi) => (
                    <MarkerComWindow
                        poi={poi}
                    />
                ))}
            </>
        );
    };

    return (
        <Box sx={{ height: '800px', width: '100%' }}>
            <Common />
            <APIProvider apiKey={'AIzaSyBgqjErywYT_C7YU2XD_33UqhNXQkcbKfo'} onLoad={() => console.log('Maps API has loaded.')}>
                <Map
                    defaultZoom={13}
                    defaultCenter={{ lat: -3.8510313329603183, lng: -38.462464052819016 }}
                    mapId='521583b8d3f65fc3'
                    /*onCameraChanged={(ev) =>
                        console.log('')
                        console.log('camera changed:', ev.detail.center, 'zoom:', ev.detail.zoom)
                    }*/
                        >
                    {PoiMarkers(locations)}
                </Map>

            </APIProvider>
        </Box>
    )
}