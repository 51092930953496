import {
    deleteCarta, getConsultarEdicaoPorJogo, postConsultarCartaDeCliente, postConsultarCartaPorMultiplosNome,
    getConsultarCartaPorId,
    getConsultarCartaPorNome,
    getConsultarCartaPorNomeSimples,
    getConsultarCartasPorNomeEdicao,
    getConsultarDeckCodigo,
    getConsultarDecksUsuario,
    getConsultarLista,
    getListaHashOpcoes,
    getListarCombos,
    postAdicionarCartaLista,
    postAlterarNomeDeck,
    postConsultarCartasPorFiltro,
    postCriarDeck, postExcluirDeck,
    postListaPorHash,
    postRemoverCartaLista,
    postSalvarCarta,
    postSalvarDeck,
    postSalvarEdicao, postSalvarLista,
    getListaJogos,
    postConsultarDashboardSimplesCliente, getConsultarDashboardEdicao,
    getConsultarEdicaoDashboard,
    getConsultarDashboardConquistas

} from '../CartaApi';

import * as Mensagens from '../../util/Mensagens';

async function salvarCarta(carta) {
    await postSalvarCarta(carta).then(response => {
        return response.data
    })
}

async function adicionarCartaLista(cartaCliente) {
    return await postAdicionarCartaLista(cartaCliente).then(response => {
        return response.data
    })
}

async function removerCartaLista(cartaCliente) {
    await postRemoverCartaLista(cartaCliente).then(response => {
        return response.data
    })
}

async function salvarLista(lista) {
    await postSalvarLista(lista).then(response => {
        return response.data
    })
}

async function salvarDeck(deckTO, notificar, alterarLoad) {
    alterarLoad(true)
    let resp
    try {
        await postSalvarDeck(deckTO).then(response => {
            resp = response.data
            notificar("Deck salvo com sucesso.", "success")
        })
    } catch (e) {
        notificar(Mensagens.ERRO_SERVICO, "error")
    } finally {
        alterarLoad(false)
    }
    return resp
}

async function excluirDeck(request) {
    let resp
    await postExcluirDeck(request).then(response => {
        resp = response.data
    })
    return resp
}

async function alterarNomeDeck(deck, notificar) {
    let resp
    try {
        await postAlterarNomeDeck(deck).then(response => {
            resp = response.data
            notificar("Nome alterado com sucesso.", "success")
        })
    } catch (e) {
        notificar(Mensagens.ERRO_SERVICO, "error")
    }
    return resp
}

async function criarDeck(request) {
    let resp
    await postCriarDeck(request).then(response => {
        resp = response.data
    })
    return resp
}

async function salvarEdicao(edicao) {
    await postSalvarEdicao(edicao).then(response => {
        return response.data
    })
}

async function consultarLista(clienteId, tipoListaId) {
    let resp
    await getConsultarLista(clienteId, tipoListaId).then(response => {
        resp = response.data
    })
    return resp
}

async function consultarDeckUsuario(clienteId, notificar, alterarLoad) {
    alterarLoad(true)
    let resp
    try {
        await getConsultarDecksUsuario(clienteId).then(response => {
            resp = response.data
        })
    } catch (e) {
        notificar(Mensagens.ERRO_SERVICO, "error")
    } finally {
        alterarLoad(false)
    }
    return resp
}

async function consultarDeckCodigo(deckId, notificar, alterarLoad, clienteId) {
    alterarLoad(true)
    try {
        return await getConsultarDeckCodigo(deckId, clienteId).then(r => {
            return r.data
        })
    } catch (e) {
        notificar(Mensagens.ERRO_SERVICO, "error")
    } finally {
        alterarLoad(false)
    }
}
async function consultarCartasPorNomeEdicao(edicao, nome) {
    let resp
    await getConsultarCartasPorNomeEdicao(edicao, nome).then(response => {
        resp = response.data
    })
    return resp
}

async function consultarCartaPorNome(nome, clienteId, setNotify, setExibirLoad) {
    setExibirLoad(true)
    let resp
    try {
        await getConsultarCartaPorNome(nome, clienteId).then(response => {
            resp = response.data
        })
    } catch (e) {
        if (setNotify !== null) {
            setNotify({
                isOpen: true,
                message: Mensagens.ERRO_SERVICO,
                type: "error",
            })
        }
    } finally {
        setExibirLoad(false)
    }

    return resp
}

async function consultarCartaPorNomeSimples(nome, clienteId, alterarLoad) {
    alterarLoad(true)
    try {
        return await getConsultarCartaPorNomeSimples(nome, clienteId).then(response => {
            return response.data
        })
    } catch (e) {
        console.log(e)
    } finally {
        alterarLoad(false)
    }
}

async function consultarCartasPorFiltro(filtro, notificar, alterarLoad) {
    let resp
    try {
        await postConsultarCartasPorFiltro(filtro).then(response => {
            resp = response.data
        })
    } catch (e) {
        notificar(Mensagens.ERRO_SERVICO, "error")
    }

    return resp
}

async function consultarCartaPorId(id) {
    let resp
    await getConsultarCartaPorId(id).then(response => {
        resp = response.data
    })
    return resp
}

async function consultarCombos(jogo, alterarMensagemLoad, alterarLoad, notificar) {
    try {
        alterarMensagemLoad(Mensagens.CARREGANDO_PAGINA)
        alterarLoad(true)
        return await getListarCombos(jogo).then((response) => {
            return converterCombos(response.data)
        })
    } catch (e) {
        notificar(Mensagens.ERRO_SERVICO, 'error')
    } finally {
        alterarLoad(false)
    }
}

async function consultarEdicaoPorJogo(jogoId) {
    return await getConsultarEdicaoPorJogo(jogoId).then((response) => {
        return response.data.map((item) => {
            return ({ id: item.edicao_id, value: item.nome })
        })
    })
}

function converterCombos(response) {
    let raridades = response.raridade.map(item => {
        return { id: item.raridade_id, value: item.nome }
    })
    let edicoes = response.edicao.map(item => {
        return { id: item.edicao_id, value: item.nome }
    })
    let naturezas = response.natureza.map(item => {
        return { id: item.id, value: item.nome }
    })
    let idiomas = response.idioma.map(item => {
        return { id: item.id, value: item.nome }
    })
    let niveis = response.nivel.map(item => {
        return { id: item.id, value: item.nome }
    })
    let jogos = response.jogo.map(item => {
        return { id: item.id, value: item.nome }
    })
    return (
        {
            listaRaridade: [{ id: 0, value: 'Selecione' }].concat(raridades),
            listaEdicao: [{ id: 0, value: 'Selecione' }].concat(edicoes),
            listaNatureza: [{ id: 0, value: 'Selecione' }].concat(naturezas),
            listaNivel: [{ id: 0, value: 'Selecione' }].concat(niveis),
            listaIdioma: [{ id: 0, value: 'Selecione' }].concat(idiomas),
            listaJogo: [{ id: 0, value: 'Selecione' }].concat(jogos)
        }
    )
}

async function consultarHash(request) {
    return await postListaPorHash(request).then((response) => {
        return response.data
    })
}

async function consultarMultiplosNomes(request, notificar, alterarLoad) {
    try {
        return await postConsultarCartaPorMultiplosNome(request).then((response) => {
            return response.data
        })
    } catch (e) {
        notificar(Mensagens.ERRO_SERVICO, 'error')
    }
}

async function consultarCartaDeCliente(request) {
    try {
        return await postConsultarCartaDeCliente(request).then((response) => {
            return response.data
        })
    } catch (e) {
        console.log(e)
    }
}

async function consultarJogos(alterarLoad) {
    return await getListaJogos().then((response) => {
        return response.data.map((item) => {
            return ({ id: item.id, value: item.nome })
        })
    })

}

async function consultarHashOpcoes(hash, tipo, tipoListaId, clienteConsultadoId, notificar) {
    try {
        return await getListaHashOpcoes(hash, tipo, tipoListaId, clienteConsultadoId).then((response) => {
            if (response.length === 0) {
                notificar("Nenhum resultado encontrado.", "error")
            }
            return response.data
        })
    } catch (e) {
        notificar(Mensagens.ERRO_SERVICO, "error")
    }
}

async function deletarCarta(cartaId) {
    let resp
    await deleteCarta(cartaId).then((response) => {
        resp = response.data
    })
    return resp
}


async function consultarDashboardSimplesCliente(alterarLoad, notificar, request) {
    alterarLoad(true)
    try {
        return await postConsultarDashboardSimplesCliente(request).then((response) => {
            if (response.data.erro) {
                notificar(response.data.mensagem, "error")
            }
            return response.data
        })
    } catch (e) {
        console.log(e)
        notificar("Erro ao consultar coleção.", "error")
    } finally {
        alterarLoad(false)
    }

}

async function consultarDashboardEdicao(alterarLoad, id, clienteId) {
    alterarLoad(true)
    try {
        return await getConsultarDashboardEdicao(id, clienteId).then((response) => {
            return response.data
        })
    } catch {

    } finally {
        alterarLoad(false)
    }
}

async function consultarEdicaoDashboard(request, alterarLoad) {
    try {
        alterarLoad(true)
        return await getConsultarEdicaoDashboard(request).then((response) => {
            if (response != null) {
                return response.data;
            }
        })
    } catch (e) {
        console.log(e)
    } finally {
        alterarLoad(false)
    }
}

async function consultarDashboardConquistas(request) {
    try {

        return await getConsultarDashboardConquistas(request).then((response) => {
            if (response != null) {
                return response.data;
            }
        })
    } catch (e) {
        console.log(e)
    } finally {
    }
}

const CartasService = {
    consultarEdicaoDashboard,
    consultarCartaDeCliente,
    consultarDashboardEdicao,
    alterarNomeDeck,
    salvarDeck,
    consultarCartaPorNome,
    excluirDeck,
    criarDeck,
    consultarDeckCodigo,
    consultarDeckUsuario,
    consultarCombos,
    salvarCarta,
    consultarCartaPorId,
    salvarEdicao,
    salvarLista,
    consultarLista,
    adicionarCartaLista,
    removerCartaLista,
    consultarCartasPorNomeEdicao,
    consultarCartasPorFiltro,
    deletarCarta,
    consultarHashOpcoes,
    consultarCartaPorNomeSimples,
    consultarDashboardSimplesCliente,
    consultarJogos,
    consultarEdicaoPorJogo,
    consultarHash,
    consultarDashboardConquistas,
    consultarMultiplosNomes
}


export default CartasService
