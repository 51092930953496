import { useEffect, useState } from 'react';
import Controls from '../Controls';

export default function SortCartas(props) {

    const { value, error = null, onChange, sx, ...other } = props;
    const [listaOpcoes, setListaOpcoes] = useState([])

    useEffect(() => {
        setListaOpcoes([
            {id: 1, value: 'Número'},
            {id: 2, value: 'Nome'},
            {id: 3, value: 'Menor preço'},
            {id: 4, value: 'Maior preço'},
        ])
    }, [])

    return (
        <Controls.Select
            name='ordenar'
            label='Ordenar'
            value={value}
            onChange={onChange}
            options={listaOpcoes}
            error={error}
            sx={sx}
            {...other}
        />
    )
}

