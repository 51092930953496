export const locations =
    [
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-79m2-venda-RS250000-id-2746422938/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Rua Lucimar Câmara do Amaral, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/44a329e3e30b1248b5e7d0d81e7733a5/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-79m-em-aquiraz-aquiraz.webp",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 79m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:79,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9085271,
              lng:-38.3759721
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS250000-id-2759459046/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/51d6ee662987a0d3131d15d11dea5dea/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-80m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 3 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-92m2-venda-RS299000-id-2761673427/",
           valorVenda:"299000.00",
           valorOriginal:"299000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/9f540be0a4355eadace4e7a4b50f5e38/foto-1-de-casa-com-2-quartos-a-venda-92m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 2 Quartos à Venda, 92m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:92,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-91m2-venda-RS350000-id-2726155248/",
           valorVenda:"350000.00",
           valorOriginal:"350000.00",
           endereco:"Rua João de Castro, 705 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/ef899416e2d265e427bc2bf33b404522/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-91m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 3 Quartos à Venda, 91m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:91,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.918079,
              lng:-38.3834312
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-77m2-venda-RS270000-id-2710848653/",
           valorVenda:"270000.00",
           valorOriginal:"270000.00",
           endereco:"Rua Tiburcio Targino, 1550 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 77m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:77,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.911794699999999,
              lng:-38.3837324
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-78m2-venda-RS140000-id-2655284197/",
           valorVenda:"140000.00",
           valorOriginal:"140000.00",
           endereco:"Rua Otacílio Bernardo de Castro, 375 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 78m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:78,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.919841899999999,
              lng:-38.43431940000001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-99m2-venda-RS259000-id-2722031234/",
           valorVenda:"259000.00",
           valorOriginal:"259000.00",
           endereco:"Rua Desembargador Alberto Barros, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 99m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:99,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966895,
              lng:-38.3739789
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-95m2-venda-RS250000-id-2748488093/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 95m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:95,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-35m2-venda-RS297497-id-2728977813/",
           valorVenda:"297497.00",
           valorOriginal:"297497.00",
           endereco:"Rua 1, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 35m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:35,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8715167,
              lng:-38.4096236
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-53m2-venda-RS150000-id-2687784019/",
           valorVenda:"150000.00",
           valorOriginal:"150000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 53m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:53,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-76m2-venda-RS270000-id-2712404802/",
           valorVenda:"270000.00",
           valorOriginal:"270000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 76m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:76,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS250000-id-2751893594/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 183 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9133513,
              lng:-38.3722675
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-70m2-venda-RS195000-id-2719975056/",
           valorVenda:"195000.00",
           valorOriginal:"195000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 70m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:70,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS230000-id-2719856267/",
           valorVenda:"230000.00",
           valorOriginal:"230000.00",
           endereco:"Rua Almir Filho, 123 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.918087299999999,
              lng:-38.377588
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS330000-id-2730728039/",
           valorVenda:"330000.00",
           valorOriginal:"330000.00",
           endereco:"Rua Albatroz, 21 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/fit-in/142x80/named.images.sp/3f437041b0acbbef4c07a31761a0ce4d/{description}.webp",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8478579,
              lng:-38.3970528
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-100m2-venda-RS500000-id-2760886368/",
           valorVenda:"500000.00",
           valorOriginal:"500000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 100m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:100,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-130m2-venda-RS220000-id-2585148686/",
           valorVenda:"NaN",
           valorOriginal:"NaN",
           endereco:"Rua Waldir Medeiros da Silva, 138 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 130m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:130,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9187711,
              lng:-38.3864872
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-91m2-venda-RS259000-id-2711657922/",
           valorVenda:"259000.00",
           valorOriginal:"259000.00",
           endereco:"CE-040 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 91m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:91,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9275105,
              lng:-38.3883115
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-81m2-venda-RS220000-id-2722026794/",
           valorVenda:"220000.00",
           valorOriginal:"220000.00",
           endereco:"Rua Desembargador Alberto Barros, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 81m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:81,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966895,
              lng:-38.3739789
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-86m2-venda-RS300000-id-2715202506/",
           valorVenda:"300000.00",
           valorOriginal:"300000.00",
           endereco:"Avenida Manoel Feliciano de Lima, 1 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 86m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:86,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9608129,
              lng:-38.4738436
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-78m2-venda-RS238000-id-2738670213/",
           valorVenda:"238000.00",
           valorOriginal:"238000.00",
           endereco:"Avenida Dedezinho Sampaio, 22525 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 78m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:78,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8986257,
              lng:-38.3749608
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-65m2-venda-RS170000-id-2711658908/",
           valorVenda:"170000.00",
           valorOriginal:"170000.00",
           endereco:"CE-040 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 65m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:65,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9275105,
              lng:-38.3883115
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-60m2-venda-RS325000-id-2727630456/",
           valorVenda:"325000.00",
           valorOriginal:"325000.00",
           endereco:"Rua Búzios, 541 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 60m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:60,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8323747,
              lng:-38.4087715
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS341066-id-2715893399/",
           valorVenda:"341066.00",
           valorOriginal:"341066.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS250000-id-2748984905/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 557 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151931,
              lng:-38.369682
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-74m2-venda-RS235000-id-2564764803/",
           valorVenda:"235000.00",
           valorOriginal:"235000.00",
           endereco:"Rua IV - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 74m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:74,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9369807,
              lng:-38.3858966
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-86m2-venda-RS250000-id-2749490899/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Rua Manuel Evangelista da Silva, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 86m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:86,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9243966,
              lng:-38.3688293
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/cobertura-4-quartos-aquiraz-bairros-aquiraz-com-garagem-160m2-venda-RS1630000-id-2757194123/",
           valorVenda:"1630000.00",
           valorOriginal:"1630000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 4 Quartos à Venda, 160m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:160,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS299000-id-2754405893/",
           valorVenda:"299000.00",
           valorOriginal:"299000.00",
           endereco:"Rua João de Castro, 100 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9126921,
              lng:-38.383822
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1700000-id-2706528700/",
           valorVenda:"1700000.00",
           valorOriginal:"1700000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1490000-id-2706509392/",
           valorVenda:"1490000.00",
           valorOriginal:"1490000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-97m2-venda-RS515000-id-2635463960/",
           valorVenda:"515000.00",
           valorOriginal:"515000.00",
           endereco:"CE-040, 500 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 97m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:97,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966353,
              lng:-38.4183427
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-85m2-venda-RS250000-id-2754178495/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 85m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:85,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-4-quartos-aquiraz-bairros-aquiraz-com-garagem-350m2-venda-RS650000-id-2561041900/",
           valorVenda:"650000.00",
           valorOriginal:"650000.00",
           endereco:"Avenida Manoel Feliciano de Lima, 4027 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 4 Quartos à Venda, 350m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:350,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9450346,
              lng:-38.4653993
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS250000-id-2751033520/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS300000-id-2749751613/",
           valorVenda:"300000.00",
           valorOriginal:"300000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-121m2-venda-RS1050000-id-2716035294/",
           valorVenda:"1050000.00",
           valorOriginal:"1050000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/44abebd1ac93f58486e101cc0b50321f/foto-1-de-apartamento-com-3-quartos-a-venda-121m-em-aquiraz-aquiraz.webp",
           titulo:"Apartamento com 3 Quartos à Venda, 121m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:121,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-4-quartos-aquiraz-bairros-aquiraz-com-garagem-126m2-venda-RS1000000-id-2647749461/",
           valorVenda:"1000000.00",
           valorOriginal:"1000000.00",
           endereco:"Rua Agua Viva, 445 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/0f3a52a1282d728a0b8f69e54ea7d85b/foto-1-de-apartamento-com-4-quartos-a-venda-126m-em-aquiraz-aquiraz.webp",
           titulo:"Apartamento com 4 Quartos à Venda, 126m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:126,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.845669,
              lng:-38.3929047
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS299000-id-2761934119/",
           valorVenda:"299000.00",
           valorOriginal:"299000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/cf7dad30f549e59016cefc3ee18dbd33/foto-1-de-casa-com-2-quartos-a-venda-90m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 2 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-126m2-venda-RS1050000-id-2758419495/",
           valorVenda:"1050000.00",
           valorOriginal:"1050000.00",
           endereco:"Rua Doutor Arimatéia Monte, 999 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/1827d864dd68e88842cdbd18206ba945/foto-1-de-apartamento-com-3-quartos-a-venda-126m-em-aquiraz-aquiraz.webp",
           titulo:"Apartamento com 3 Quartos à Venda, 126m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:126,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.887004,
              lng:-38.3598088
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/sobrado-3-quartos-aquiraz-bairros-aquiraz-com-garagem-162m2-venda-RS450000-id-2683534032/",
           valorVenda:"NaN",
           valorOriginal:"NaN",
           endereco:"Rua Lucila Pereira Studart, 200 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 162m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:162,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9332059,
              lng:-38.3121201
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-95m2-venda-RS590000-id-2723121463/",
           valorVenda:"590000.00",
           valorOriginal:"590000.00",
           endereco:"Rua Ecologista Chico Mendes - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 95m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:95,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8911448,
              lng:-38.4269629
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-aquiraz-bairros-aquiraz-com-garagem-92m2-venda-RS360000-id-2715199987/",
           valorVenda:"360000.00",
           valorOriginal:"360000.00",
           endereco:"Rua Tiburcio Targino - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 4 Quartos à Venda, 92m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:92,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.907514,
              lng:-38.3863218
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-88m2-venda-RS870000-id-2665739293/",
           valorVenda:"870000.00",
           valorOriginal:"870000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 88m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:88,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-aquiraz-bairros-aquiraz-com-garagem-150m2-venda-RS600000-id-2705634229/",
           valorVenda:"600000.00",
           valorOriginal:"600000.00",
           endereco:"Estrada do Fio, 00 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 4 Quartos à Venda, 150m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:150,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.911794,
              lng:-38.3836131
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-92m2-venda-RS349000-id-2738820249/",
           valorVenda:"349000.00",
           valorOriginal:"349000.00",
           endereco:"Avenida Dedezinho Sampaio, 25252 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 92m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:92,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8986257,
              lng:-38.3749608
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-89m2-venda-RS280000-id-2738668193/",
           valorVenda:"280000.00",
           valorOriginal:"280000.00",
           endereco:"Avenida Dedezinho Sampaio, 22525 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 89m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:89,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8986257,
              lng:-38.3749608
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-95m2-venda-RS590000-id-2722916933/",
           valorVenda:"590000.00",
           valorOriginal:"590000.00",
           endereco:"Rua Ecologista Chico Mendes, 449 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 95m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:95,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8921619,
              lng:-38.4272592
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS1000000-id-2686865257/",
           valorVenda:"1000000.00",
           valorOriginal:"1000000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-114m2-venda-RS485000-id-2733605330/",
           valorVenda:"485000.00",
           valorOriginal:"485000.00",
           endereco:"Rua João de Castro, 20 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 114m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:114,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9119887,
              lng:-38.3837901
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-67m2-venda-RS285000-id-2731435814/",
           valorVenda:"285000.00",
           valorOriginal:"285000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 67m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:67,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-103m2-venda-RS1600000-id-2685236276/",
           valorVenda:"1600000.00",
           valorOriginal:"1600000.00",
           endereco:"Avenida Oceano Indico, 255 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 103m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:103,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8588365,
              lng:-38.385924
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-137m2-venda-RS250000-id-2521504256/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 137m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:137,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-65m2-venda-RS650000-id-2713109104/",
           valorVenda:"650000.00",
           valorOriginal:"650000.00",
           endereco:"Rua Doutor Arimatéia Monte, 999 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 65m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:65,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.887004,
              lng:-38.3598088
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-59m2-venda-RS349910-id-2727987251/",
           valorVenda:"349910.00",
           valorOriginal:"349910.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 59m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:59,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-126m2-venda-RS1300000-id-2758419055/",
           valorVenda:"1300000.00",
           valorOriginal:"1300000.00",
           endereco:"Rua Doutor Arimatéia Monte, 999 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 126m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:126,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.887004,
              lng:-38.3598088
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS220000-id-2703500895/",
           valorVenda:"220000.00",
           valorOriginal:"220000.00",
           endereco:"Rua Rociclea, 2 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9099408,
              lng:-38.378766
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS300000-id-2715200059/",
           valorVenda:"300000.00",
           valorOriginal:"300000.00",
           endereco:"Vila do Machuca, 99999 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9335157,
              lng:-38.3895838
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-42m2-venda-RS190000-id-2698469145/",
           valorVenda:"190000.00",
           valorOriginal:"190000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 42m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:42,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-118m2-venda-RS1550000-id-2750849609/",
           valorVenda:"1550000.00",
           valorOriginal:"1550000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 118m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:118,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-88m2-venda-RS900000-id-2664559802/",
           valorVenda:"900000.00",
           valorOriginal:"900000.00",
           endereco:"Rua 1, 258 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 88m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:88,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8715167,
              lng:-38.4096236
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-59m2-venda-RS513810-id-2728976447/",
           valorVenda:"513810.00",
           valorOriginal:"513810.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 59m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:59,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-aquiraz-bairros-aquiraz-com-garagem-350m2-venda-RS650000-id-2559372454/",
           valorVenda:"650000.00",
           valorOriginal:"650000.00",
           endereco:"Avenida Manoel Feliciano de Lima - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 4 Quartos à Venda, 350m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:350,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9042,
              lng:-38.38662
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/cobertura-4-quartos-aquiraz-bairros-aquiraz-com-garagem-203m2-venda-RS2250000-id-2689994219/",
           valorVenda:"2250000.00",
           valorOriginal:"2250000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 4 Quartos à Venda, 203m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:203,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1400000-id-2686862919/",
           valorVenda:"1400000.00",
           valorOriginal:"1400000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1400000-id-2715127755/",
           valorVenda:"1400000.00",
           valorOriginal:"1400000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-68m2-venda-RS550000-id-2521503241/",
           valorVenda:"550000.00",
           valorOriginal:"550000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 68m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:68,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-53m2-venda-RS460000-id-2747055562/",
           valorVenda:"460000.00",
           valorOriginal:"460000.00",
           endereco:"Rua 1 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 53m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:53,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8715167,
              lng:-38.4096236
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-150m2-venda-RS1646399-id-2593124124/",
           valorVenda:"1646399.00",
           valorOriginal:"1646399.00",
           endereco:"Avenida Beira Mar, 3710 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 150m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:150,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.885406,
              lng:-38.361876
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-75m2-venda-RS875000-id-2593123038/",
           valorVenda:"875000.00",
           valorOriginal:"875000.00",
           endereco:"Avenida Beira Mar, 3710 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 75m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:75,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.885406,
              lng:-38.361876
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-75m2-venda-RS703427-id-2737124246/",
           valorVenda:"703427.00",
           valorOriginal:"703427.00",
           endereco:"Avenida Beira Mar - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 75m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:75,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9022961,
              lng:-38.3454471
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS1000000-id-2715126513/",
           valorVenda:"1000000.00",
           valorOriginal:"1000000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-7-quartos-aquiraz-bairros-aquiraz-400m2-venda-RS1150000-id-2721832855/",
           valorVenda:"1150000.00",
           valorOriginal:"1150000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/f622921e1eba3ee839b3a8a7b082db7b/foto-1-de-casa-com-7-quartos-a-venda-400m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 7 Quartos à Venda, 400m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:400,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-87m2-venda-RS880000-id-2699658287/",
           valorVenda:"880000.00",
           valorOriginal:"880000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/9b5e9e76122008348831d61027a83bfe/foto-1-de-apartamento-com-3-quartos-a-venda-87m-em-aquiraz-aquiraz.webp",
           titulo:"Apartamento com 3 Quartos à Venda, 87m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:87,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-5-quartos-aquiraz-bairros-aquiraz-com-garagem-201m2-venda-RS1300000-id-2754897193/",
           valorVenda:"1300000.00",
           valorOriginal:"1300000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/57c22d4f289a4b04e296f707ac2c69d6/foto-1-de-casa-com-5-quartos-a-venda-201m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 5 Quartos à Venda, 201m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:201,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-114m2-venda-RS550000-id-2731088324/",
           valorVenda:"550000.00",
           valorOriginal:"550000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/eb0952618d6cbc4118da6232fdd39f12/foto-1-de-apartamento-com-3-quartos-a-venda-114m-em-aquiraz-aquiraz.webp",
           titulo:"Apartamento com 3 Quartos à Venda, 114m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:114,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1600000-id-2723577240/",
           valorVenda:"1600000.00",
           valorOriginal:"1600000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-75m2-venda-RS850083-id-2752488108/",
           valorVenda:"850083.00",
           valorOriginal:"850083.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 75m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:75,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-4-quartos-aquiraz-bairros-aquiraz-132m2-venda-RS465000-id-2757986018/",
           valorVenda:"465000.00",
           valorOriginal:"465000.00",
           endereco:"Rua Tomás de Aquino - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 4 Quartos à Venda, 132m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:132,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9071947,
              lng:-38.3484858
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-89m2-venda-RS900000-id-2713798802/",
           valorVenda:"900000.00",
           valorOriginal:"900000.00",
           endereco:"Rua Doutor Arimatéia Monte, 999 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 89m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:89,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.887004,
              lng:-38.3598088
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-4-quartos-aquiraz-bairros-aquiraz-132m2-venda-RS465000-id-2757998017/",
           valorVenda:"465000.00",
           valorOriginal:"465000.00",
           endereco:"Rua Tomás de Aquino - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 4 Quartos à Venda, 132m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:132,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9071947,
              lng:-38.3484858
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-114m2-venda-RS390000-id-2752512139/",
           valorVenda:"390000.00",
           valorOriginal:"390000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 114m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:114,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-4-quartos-aquiraz-bairros-aquiraz-com-garagem-244m2-venda-RS600000-id-2699890384/",
           valorVenda:"600000.00",
           valorOriginal:"600000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 4 Quartos à Venda, 244m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:244,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-100m2-venda-RS404386-id-2689686581/",
           valorVenda:"404386.00",
           valorOriginal:"404386.00",
           endereco:"CE-040 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 100m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:100,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9275105,
              lng:-38.3883115
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/cobertura-4-quartos-aquiraz-bairros-aquiraz-com-garagem-196m2-venda-RS950000-id-2635389575/",
           valorVenda:"NaN",
           valorOriginal:"NaN",
           endereco:"Avenida Mar Mediterrâneo, 1 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 4 Quartos à Venda, 196m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:196,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8381342,
              lng:-38.4002798
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-89m2-venda-RS260000-id-2660088967/",
           valorVenda:"NaN",
           valorOriginal:"NaN",
           endereco:"Rua Francisco Moura de Abreu - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 89m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:89,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8985233,
              lng:-38.3835985
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS604799-id-2715891529/",
           valorVenda:"604799.00",
           valorOriginal:"604799.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1350000-id-2754140815/",
           valorVenda:"1350000.00",
           valorOriginal:"1350000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-75m2-venda-RS985214-id-2593124418/",
           valorVenda:"985214.00",
           valorOriginal:"985214.00",
           endereco:"Avenida Beira Mar, 3710 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 75m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:75,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.885406,
              lng:-38.361876
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-148m2-venda-RS2899000-id-2688964565/",
           valorVenda:"2899000.00",
           valorOriginal:"2899000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 148m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:148,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-aquiraz-bairros-aquiraz-com-garagem-178m2-venda-RS2470000-id-2741106609/",
           valorVenda:"2470000.00",
           valorOriginal:"2470000.00",
           endereco:"Avenida Litorânea, 1 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 4 Quartos à Venda, 178m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:178,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8808214,
              lng:-38.3652519
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1250000-id-2733467800/",
           valorVenda:"1250000.00",
           valorOriginal:"1250000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-107m2-venda-RS1100000-id-2692742263/",
           valorVenda:"1100000.00",
           valorOriginal:"1100000.00",
           endereco:"Rua dos Golfinhos, 2071 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 107m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:107,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.843762,
              lng:-38.4027197
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-5-quartos-aquiraz-bairros-aquiraz-com-garagem-241m2-venda-RS1500000-id-2742589785/",
           valorVenda:"1500000.00",
           valorOriginal:"1500000.00",
           endereco:"Rua João de Castro, 1344 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 5 Quartos à Venda, 241m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:241,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9163537,
              lng:-38.3834687
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-330m2-venda-RS630000-id-2715204296/",
           valorVenda:"630000.00",
           valorOriginal:"630000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 330m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:330,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-81m2-venda-RS1140000-id-2739855527/",
           valorVenda:"1140000.00",
           valorOriginal:"1140000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 81m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:81,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-4-quartos-aquiraz-bairros-aquiraz-com-garagem-140m2-venda-RS1050000-id-2746685198/",
           valorVenda:"1050000.00",
           valorOriginal:"1050000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 4 Quartos à Venda, 140m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:140,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-7-quartos-aquiraz-bairros-aquiraz-400m2-venda-RS1150000-id-2721651544/",
           valorVenda:"1150000.00",
           valorOriginal:"1150000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 7 Quartos à Venda, 400m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:400,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-70m2-venda-RS750000-id-2745581056/",
           valorVenda:"750000.00",
           valorOriginal:"750000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 70m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:70,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-81m2-venda-RS990000-id-2721596355/",
           valorVenda:"990000.00",
           valorOriginal:"990000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 81m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:81,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-aquiraz-bairros-aquiraz-com-garagem-238m2-venda-RS1400000-id-2741121654/",
           valorVenda:"1400000.00",
           valorOriginal:"1400000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 4 Quartos à Venda, 238m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:238,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/cobertura-4-quartos-aquiraz-bairros-aquiraz-com-garagem-170m2-venda-RS1890000-id-2587570709/",
           valorVenda:"1890000.00",
           valorOriginal:"1890000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 4 Quartos à Venda, 170m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:170,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-107m2-venda-RS1100000-id-2550788697/",
           valorVenda:"1100000.00",
           valorOriginal:"1100000.00",
           endereco:"Avenida dos Golfinhos, 2335 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 107m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:107,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8468905,
              lng:-38.3885533
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-82m2-venda-RS1100000-id-2647459612/",
           valorVenda:"1100000.00",
           valorOriginal:"1100000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 82m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:82,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-126m2-venda-RS1980000-id-2557592434/",
           valorVenda:"1980000.00",
           valorOriginal:"1980000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 126m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:126,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1190000-id-2663110861/",
           valorVenda:"1190000.00",
           valorOriginal:"1190000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-82m2-venda-RS1200000-id-2568499321/",
           valorVenda:"1200000.00",
           valorOriginal:"1200000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 82m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:82,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-5-quartos-aquiraz-bairros-aquiraz-com-garagem-437m2-venda-RS1150000-id-2680880507/",
           valorVenda:"1150000.00",
           valorOriginal:"1150000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 5 Quartos à Venda, 437m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:437,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1600000-id-2723384006/",
           valorVenda:"1600000.00",
           valorOriginal:"1600000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/2d064737b42e72c435c68cb0a767614e/foto-1-de-apartamento-com-3-quartos-a-venda-101m-em-aquiraz-aquiraz.webp",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-4-quartos-aquiraz-bairros-aquiraz-com-garagem-163m2-venda-RS1600000-id-2585023789/",
           valorVenda:"1600000.00",
           valorOriginal:"1600000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/468e8fd34fd90eca8e10481bb0bf17bf/foto-1-de-apartamento-com-4-quartos-a-venda-163m-em-aquiraz-aquiraz.webp",
           titulo:"Apartamento com 4 Quartos à Venda, 163m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:163,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-123m2-venda-RS1342615-id-2728977713/",
           valorVenda:"1342615.00",
           valorOriginal:"1342615.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/d91dd2995e5c7fc936cb35215b60b4e0/foto-1-de-apartamento-com-3-quartos-a-venda-123m-em-aquiraz-aquiraz.webp",
           titulo:"Apartamento com 3 Quartos à Venda, 123m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:123,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1290000-id-2751077549/",
           valorVenda:"1290000.00",
           valorOriginal:"1290000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/e347d828ca64b5b4d0dfc8a95e8fc9e4/foto-1-de-apartamento-com-3-quartos-a-venda-101m-em-aquiraz-aquiraz.webp",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1490000-id-2720326533/",
           valorVenda:"1490000.00",
           valorOriginal:"1490000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-62m2-venda-RS732355-id-2737123357/",
           valorVenda:"732355.00",
           valorOriginal:"732355.00",
           endereco:"Avenida Litorânea - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 62m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:62,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8808214,
              lng:-38.3652519
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-59m2-venda-RS550000-id-2749744339/",
           valorVenda:"550000.00",
           valorOriginal:"550000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 59m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:59,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-5-quartos-aquiraz-bairros-aquiraz-com-garagem-370m2-venda-RS1700000-id-2661920889/",
           valorVenda:"1700000.00",
           valorOriginal:"1700000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 5 Quartos à Venda, 370m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:370,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-81m2-venda-RS990000-id-2721425852/",
           valorVenda:"990000.00",
           valorOriginal:"990000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 81m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:81,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-231m2-venda-RS1300000-id-2751537519/",
           valorVenda:"1300000.00",
           valorOriginal:"1300000.00",
           endereco:"CE-040, 2 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 231m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:231,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9136018,
              lng:-38.3899315
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-4-quartos-aquiraz-bairros-aquiraz-com-garagem-400m2-venda-RS529000-id-2730247182/",
           valorVenda:"529000.00",
           valorOriginal:"529000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 4 Quartos à Venda, 400m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:400,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-6-quartos-aquiraz-bairros-aquiraz-com-garagem-330m2-venda-RS2450000-id-2745232770/",
           valorVenda:"2450000.00",
           valorOriginal:"2450000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 6 Quartos à Venda, 330m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:330,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-116m2-venda-RS1378000-id-2645743981/",
           valorVenda:"1378000.00",
           valorOriginal:"1378000.00",
           endereco:"Avenida Oceano Indico - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 116m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:116,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8577723,
              lng:-38.3865316
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-6-quartos-aquiraz-bairros-aquiraz-com-garagem-442m2-venda-RS2990000-id-2609676820/",
           valorVenda:"2990000.00",
           valorOriginal:"2990000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 6 Quartos à Venda, 442m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:442,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS600000-id-2715204100/",
           valorVenda:"600000.00",
           valorOriginal:"600000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-114m2-venda-RS485000-id-2709108746/",
           valorVenda:"485000.00",
           valorOriginal:"485000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 114m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:114,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-81m2-venda-RS950000-id-2753931296/",
           valorVenda:"950000.00",
           valorOriginal:"950000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 81m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:81,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1290000-id-2749633929/",
           valorVenda:"1290000.00",
           valorOriginal:"1290000.00",
           endereco:"CE-040 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9275105,
              lng:-38.3883115
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-4-quartos-aquiraz-bairros-aquiraz-com-garagem-892m2-venda-RS650000-id-2713192122/",
           valorVenda:"650000.00",
           valorOriginal:"650000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 4 Quartos à Venda, 892m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:892,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-4-quartos-aquiraz-bairros-aquiraz-com-garagem-157m2-venda-RS1580000-id-2695902174/",
           valorVenda:"1580000.00",
           valorOriginal:"1580000.00",
           endereco:"Avenida dos Golfinhos - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 4 Quartos à Venda, 157m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:157,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8447614,
              lng:-38.3913142
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-168m2-venda-RS924000-id-2624405051/",
           valorVenda:"924000.00",
           valorOriginal:"924000.00",
           endereco:"CE-040, 100 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 168m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:168,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9129728,
              lng:-38.3935705
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-75m2-venda-RS835930-id-2746533861/",
           valorVenda:"835930.00",
           valorOriginal:"835930.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 75m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:75,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-111m2-venda-RS1490000-id-2710125700/",
           valorVenda:"1490000.00",
           valorOriginal:"1490000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 111m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:111,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-700m2-venda-RS5700000-id-2647944920/",
           valorVenda:"5700000.00",
           valorOriginal:"5700000.00",
           endereco:"Rua Joao Anastacio de Castro - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 700m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:700,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9262437,
              lng:-38.4332887
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-178m2-venda-RS847728-id-2746390731/",
           valorVenda:"847728.00",
           valorOriginal:"847728.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 178m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:178,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-113m2-venda-RS2170000-id-2734801684/",
           valorVenda:"2170000.00",
           valorOriginal:"2170000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 113m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:113,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-143m2-venda-RS849900-id-2695902281/",
           valorVenda:"849900.00",
           valorOriginal:"849900.00",
           endereco:"Rua Joao Anastacio de Castro - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 143m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:143,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9262437,
              lng:-38.4332887
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-99m2-venda-RS950000-id-2745047769/",
           valorVenda:"950000.00",
           valorOriginal:"950000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 99m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:99,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-104m2-venda-RS2200000-id-2749253410/",
           valorVenda:"2200000.00",
           valorOriginal:"2200000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 104m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:104,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-81m2-venda-RS950000-id-2753796912/",
           valorVenda:"950000.00",
           valorOriginal:"950000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 81m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:81,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-528m2-venda-RS1200000-id-2729653715/",
           valorVenda:"1200000.00",
           valorOriginal:"1200000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 528m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:528,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-4-quartos-aquiraz-bairros-aquiraz-com-garagem-300m2-venda-RS2200000-id-2551581123/",
           valorVenda:"2200000.00",
           valorOriginal:"2200000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 4 Quartos à Venda, 300m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:300,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-99m2-venda-RS810000-id-2754532203/",
           valorVenda:"810000.00",
           valorOriginal:"810000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 99m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:99,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-115m2-venda-RS1650000-id-2741569484/",
           valorVenda:"1650000.00",
           valorOriginal:"1650000.00",
           endereco:"Avenida Oceano Indico - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 115m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:115,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8577723,
              lng:-38.3865316
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-193m2-venda-RS1070000-id-2695901982/",
           valorVenda:"1070000.00",
           valorOriginal:"1070000.00",
           endereco:"Rua Joao Anastacio de Castro - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 193m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:193,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9262437,
              lng:-38.4332887
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-4-quartos-aquiraz-bairros-aquiraz-com-garagem-243m2-venda-RS500000-id-2510763559/",
           valorVenda:"500000.00",
           valorOriginal:"500000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 4 Quartos à Venda, 243m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:243,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-4-quartos-aquiraz-bairros-aquiraz-com-garagem-163m2-venda-RS1790000-id-2624405148/",
           valorVenda:"1790000.00",
           valorOriginal:"1790000.00",
           endereco:"CE-025 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/bd7ee22cb16bf5d91e2ad211d4bd25bc/foto-1-de-apartamento-com-4-quartos-a-venda-163m-em-aquiraz-aquiraz.webp",
           titulo:"Apartamento com 4 Quartos à Venda, 163m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:163,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9211788,
              lng:-38.3877158
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-aquiraz-bairros-aquiraz-com-garagem-178m2-venda-RS2698208-id-2750760250/",
           valorVenda:"2698208.00",
           valorOriginal:"2698208.00",
           endereco:"Avenida Litorânea - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/3835b343a7bc15bd54939a94bca2ead4/foto-1-de-casa-de-condominio-com-4-quartos-a-venda-178m-em-aquiraz-aquiraz.webp",
           titulo:"Casa de condomínio com 4 Quartos à Venda, 178m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:178,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8808214,
              lng:-38.3652519
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1500000-id-2761327130/",
           valorVenda:"1500000.00",
           valorOriginal:"1500000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/866c36e4b81e8a1a2caf0a32c25e3275/foto-1-de-apartamento-com-3-quartos-a-venda-101m-em-aquiraz-aquiraz.webp",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-9-quartos-aquiraz-bairros-aquiraz-com-garagem-966m2-venda-RS2500000-id-2731602559/",
           valorVenda:"2500000.00",
           valorOriginal:"2500000.00",
           endereco:"Avenida Beira Mar, 1294 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/656a9342b10f39f4d1a0fcec93829056/foto-1-de-casa-com-9-quartos-a-venda-966m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 9 Quartos à Venda, 966m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:966,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8933507,
              lng:-38.3546836
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-4-quartos-aquiraz-bairros-aquiraz-com-garagem-1609m2-venda-RS2300000-id-2531972984/",
           valorVenda:"NaN",
           valorOriginal:"NaN",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 4 Quartos à Venda, 1609m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:1609,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-103m2-venda-RS1500000-id-2637535271/",
           valorVenda:"1500000.00",
           valorOriginal:"1500000.00",
           endereco:"Estrada Aquiraz-Prainha - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 103m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:103,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8999163,
              lng:-38.3660991
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-5-quartos-aquiraz-bairros-aquiraz-com-garagem-203m2-venda-RS2000000-id-2745320733/",
           valorVenda:"2000000.00",
           valorOriginal:"2000000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 5 Quartos à Venda, 203m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:203,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-81m2-venda-RS1200000-id-2734000353/",
           valorVenda:"1200000.00",
           valorOriginal:"1200000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 81m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:81,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/cobertura-4-quartos-aquiraz-bairros-aquiraz-com-garagem-160m2-venda-RS1200000-id-2759206424/",
           valorVenda:"1200000.00",
           valorOriginal:"1200000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 4 Quartos à Venda, 160m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:160,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-aquiraz-bairros-aquiraz-com-garagem-497m2-venda-RS13900000-id-2655085897/",
           valorVenda:"13900000.00",
           valorOriginal:"13900000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 4 Quartos à Venda, 497m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:497,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1600000-id-2761171530/",
           valorVenda:"1600000.00",
           valorOriginal:"1600000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-6-quartos-aquiraz-bairros-aquiraz-com-garagem-442m2-venda-RS2290000-id-2567322858/",
           valorVenda:"2290000.00",
           valorOriginal:"2290000.00",
           endereco:"Rua Monsenhor Catão, 2 - Aquiraz, Fortaleza - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 6 Quartos à Venda, 442m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:442,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.7426697,
              lng:-38.4918443
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-81m2-venda-RS950000-id-2753925893/",
           valorVenda:"950000.00",
           valorOriginal:"950000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 81m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:81,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-102m2-venda-RS1450000-id-2732099617/",
           valorVenda:"1450000.00",
           valorOriginal:"1450000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 102m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:102,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-81m2-venda-RS1100000-id-2761155156/",
           valorVenda:"1100000.00",
           valorOriginal:"1100000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 81m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:81,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-8-quartos-aquiraz-bairros-aquiraz-com-garagem-240m2-venda-RS1800000-id-2715200987/",
           valorVenda:"1800000.00",
           valorOriginal:"1800000.00",
           endereco:"Avenida Beira Mar - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 8 Quartos à Venda, 240m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:240,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9022961,
              lng:-38.3454471
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-5-quartos-aquiraz-bairros-aquiraz-com-garagem-1100m2-venda-RS5000000-id-2704705064/",
           valorVenda:"5000000.00",
           valorOriginal:"5000000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 5 Quartos à Venda, 1100m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:1100,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-102m2-venda-RS1399000-id-2751125049/",
           valorVenda:"1399000.00",
           valorOriginal:"1399000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 102m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:102,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-75m2-venda-RS724700-id-2751473729/",
           valorVenda:"724700.00",
           valorOriginal:"724700.00",
           endereco:"Avenida Beira Mar, 3614 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 75m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:75,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.886109,
              lng:-38.361355
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-10-quartos-aquiraz-bairros-aquiraz-com-garagem-1250m2-venda-RS1895000-id-2744100558/",
           valorVenda:"1895000.00",
           valorOriginal:"1895000.00",
           endereco:"Rua Doctor Mario Gabel, 102 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 10 Quartos à Venda, 1250m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:1250,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9001807,
              lng:-38.3502361
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-400m2-venda-RS1900000-id-2668552026/",
           valorVenda:"1900000.00",
           valorOriginal:"1900000.00",
           endereco:"Rua zé Manel, 26 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 400m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:400,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.970011099999999,
              lng:-38.4038962
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-4-quartos-aquiraz-bairros-aquiraz-com-garagem-431m2-venda-RS3200000-id-2611798940/",
           valorVenda:"3200000.00",
           valorOriginal:"3200000.00",
           endereco:"Rua Eriberto de Souza, 1107 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 4 Quartos à Venda, 431m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:431,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.928568,
              lng:-38.3658607
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-118m2-venda-RS2450000-id-2763213126/",
           valorVenda:"2450000.00",
           valorOriginal:"2450000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 118m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:118,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-79m2-venda-RS250000-id-2746422938/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Rua Lucimar Câmara do Amaral, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/44a329e3e30b1248b5e7d0d81e7733a5/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-79m-em-aquiraz-aquiraz.webp",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 79m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:79,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9085271,
              lng:-38.3759721
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS250000-id-2759459046/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/51d6ee662987a0d3131d15d11dea5dea/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-80m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 3 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-92m2-venda-RS299000-id-2761673427/",
           valorVenda:"299000.00",
           valorOriginal:"299000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/9f540be0a4355eadace4e7a4b50f5e38/foto-1-de-casa-com-2-quartos-a-venda-92m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 2 Quartos à Venda, 92m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:92,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-91m2-venda-RS350000-id-2726155248/",
           valorVenda:"350000.00",
           valorOriginal:"350000.00",
           endereco:"Rua João de Castro, 705 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/ef899416e2d265e427bc2bf33b404522/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-91m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 3 Quartos à Venda, 91m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:91,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.918079,
              lng:-38.3834312
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-77m2-venda-RS270000-id-2710848653/",
           valorVenda:"270000.00",
           valorOriginal:"270000.00",
           endereco:"Rua Tiburcio Targino, 1550 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 77m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:77,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.911794699999999,
              lng:-38.3837324
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-78m2-venda-RS140000-id-2655284197/",
           valorVenda:"140000.00",
           valorOriginal:"140000.00",
           endereco:"Rua Otacílio Bernardo de Castro, 375 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 78m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:78,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.919841899999999,
              lng:-38.43431940000001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-99m2-venda-RS259000-id-2722031234/",
           valorVenda:"259000.00",
           valorOriginal:"259000.00",
           endereco:"Rua Desembargador Alberto Barros, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 99m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:99,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966895,
              lng:-38.3739789
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-95m2-venda-RS250000-id-2748488093/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 95m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:95,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-35m2-venda-RS297497-id-2728977813/",
           valorVenda:"297497.00",
           valorOriginal:"297497.00",
           endereco:"Rua 1, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 35m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:35,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8715167,
              lng:-38.4096236
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-53m2-venda-RS150000-id-2687784019/",
           valorVenda:"150000.00",
           valorOriginal:"150000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 53m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:53,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-76m2-venda-RS270000-id-2712404802/",
           valorVenda:"270000.00",
           valorOriginal:"270000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 76m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:76,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS250000-id-2751893594/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 183 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9133513,
              lng:-38.3722675
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-70m2-venda-RS195000-id-2719975056/",
           valorVenda:"195000.00",
           valorOriginal:"195000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 70m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:70,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS230000-id-2719856267/",
           valorVenda:"230000.00",
           valorOriginal:"230000.00",
           endereco:"Rua Almir Filho, 123 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.918087299999999,
              lng:-38.377588
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS330000-id-2730728039/",
           valorVenda:"330000.00",
           valorOriginal:"330000.00",
           endereco:"Rua Albatroz, 21 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/fit-in/142x80/named.images.sp/3f437041b0acbbef4c07a31761a0ce4d/{description}.webp",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8478579,
              lng:-38.3970528
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-100m2-venda-RS500000-id-2760886368/",
           valorVenda:"500000.00",
           valorOriginal:"500000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 100m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:100,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-130m2-venda-RS220000-id-2585148686/",
           valorVenda:"NaN",
           valorOriginal:"NaN",
           endereco:"Rua Waldir Medeiros da Silva, 138 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 130m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:130,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9187711,
              lng:-38.3864872
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-91m2-venda-RS259000-id-2711657922/",
           valorVenda:"259000.00",
           valorOriginal:"259000.00",
           endereco:"CE-040 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 91m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:91,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9275105,
              lng:-38.3883115
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-81m2-venda-RS220000-id-2722026794/",
           valorVenda:"220000.00",
           valorOriginal:"220000.00",
           endereco:"Rua Desembargador Alberto Barros, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 81m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:81,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966895,
              lng:-38.3739789
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-86m2-venda-RS300000-id-2715202506/",
           valorVenda:"300000.00",
           valorOriginal:"300000.00",
           endereco:"Avenida Manoel Feliciano de Lima, 1 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 86m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:86,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9608129,
              lng:-38.4738436
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-78m2-venda-RS238000-id-2738670213/",
           valorVenda:"238000.00",
           valorOriginal:"238000.00",
           endereco:"Avenida Dedezinho Sampaio, 22525 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 78m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:78,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8986257,
              lng:-38.3749608
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-65m2-venda-RS170000-id-2711658908/",
           valorVenda:"170000.00",
           valorOriginal:"170000.00",
           endereco:"CE-040 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 65m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:65,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9275105,
              lng:-38.3883115
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-60m2-venda-RS325000-id-2727630456/",
           valorVenda:"325000.00",
           valorOriginal:"325000.00",
           endereco:"Rua Búzios, 541 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 60m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:60,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8323747,
              lng:-38.4087715
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS341066-id-2715893399/",
           valorVenda:"341066.00",
           valorOriginal:"341066.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS250000-id-2748984905/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 557 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151931,
              lng:-38.369682
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-74m2-venda-RS235000-id-2564764803/",
           valorVenda:"235000.00",
           valorOriginal:"235000.00",
           endereco:"Rua IV - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 74m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:74,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9369807,
              lng:-38.3858966
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-86m2-venda-RS250000-id-2749490899/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Rua Manuel Evangelista da Silva, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 86m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:86,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9243966,
              lng:-38.3688293
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/cobertura-4-quartos-aquiraz-bairros-aquiraz-com-garagem-160m2-venda-RS1630000-id-2757194123/",
           valorVenda:"1630000.00",
           valorOriginal:"1630000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 4 Quartos à Venda, 160m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:160,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS299000-id-2754405893/",
           valorVenda:"299000.00",
           valorOriginal:"299000.00",
           endereco:"Rua João de Castro, 100 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9126921,
              lng:-38.383822
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1700000-id-2706528700/",
           valorVenda:"1700000.00",
           valorOriginal:"1700000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1490000-id-2706509392/",
           valorVenda:"1490000.00",
           valorOriginal:"1490000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-97m2-venda-RS515000-id-2635463960/",
           valorVenda:"515000.00",
           valorOriginal:"515000.00",
           endereco:"CE-040, 500 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 97m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:97,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966353,
              lng:-38.4183427
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-85m2-venda-RS250000-id-2754178495/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 85m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:85,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-4-quartos-aquiraz-bairros-aquiraz-com-garagem-350m2-venda-RS650000-id-2561041900/",
           valorVenda:"650000.00",
           valorOriginal:"650000.00",
           endereco:"Avenida Manoel Feliciano de Lima, 4027 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 4 Quartos à Venda, 350m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:350,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9450346,
              lng:-38.4653993
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS250000-id-2751033520/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS300000-id-2749751613/",
           valorVenda:"300000.00",
           valorOriginal:"300000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-79m2-venda-RS250000-id-2746422938/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Rua Lucimar Câmara do Amaral, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/44a329e3e30b1248b5e7d0d81e7733a5/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-79m-em-aquiraz-aquiraz.webp",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 79m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:79,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9085271,
              lng:-38.3759721
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS250000-id-2759459046/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/51d6ee662987a0d3131d15d11dea5dea/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-80m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 3 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-92m2-venda-RS299000-id-2761673427/",
           valorVenda:"299000.00",
           valorOriginal:"299000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/9f540be0a4355eadace4e7a4b50f5e38/foto-1-de-casa-com-2-quartos-a-venda-92m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 2 Quartos à Venda, 92m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:92,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-91m2-venda-RS350000-id-2726155248/",
           valorVenda:"350000.00",
           valorOriginal:"350000.00",
           endereco:"Rua João de Castro, 705 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/ef899416e2d265e427bc2bf33b404522/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-91m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 3 Quartos à Venda, 91m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:91,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.918079,
              lng:-38.3834312
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-77m2-venda-RS270000-id-2710848653/",
           valorVenda:"270000.00",
           valorOriginal:"270000.00",
           endereco:"Rua Tiburcio Targino, 1550 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 77m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:77,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.911794699999999,
              lng:-38.3837324
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-78m2-venda-RS140000-id-2655284197/",
           valorVenda:"140000.00",
           valorOriginal:"140000.00",
           endereco:"Rua Otacílio Bernardo de Castro, 375 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 78m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:78,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.919841899999999,
              lng:-38.43431940000001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-99m2-venda-RS259000-id-2722031234/",
           valorVenda:"259000.00",
           valorOriginal:"259000.00",
           endereco:"Rua Desembargador Alberto Barros, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 99m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:99,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966895,
              lng:-38.3739789
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-95m2-venda-RS250000-id-2748488093/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 95m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:95,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-35m2-venda-RS297497-id-2728977813/",
           valorVenda:"297497.00",
           valorOriginal:"297497.00",
           endereco:"Rua 1, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 35m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:35,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8715167,
              lng:-38.4096236
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-53m2-venda-RS150000-id-2687784019/",
           valorVenda:"150000.00",
           valorOriginal:"150000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 53m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:53,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-76m2-venda-RS270000-id-2712404802/",
           valorVenda:"270000.00",
           valorOriginal:"270000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 76m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:76,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS250000-id-2751893594/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 183 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9133513,
              lng:-38.3722675
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-70m2-venda-RS195000-id-2719975056/",
           valorVenda:"195000.00",
           valorOriginal:"195000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 70m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:70,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS230000-id-2719856267/",
           valorVenda:"230000.00",
           valorOriginal:"230000.00",
           endereco:"Rua Almir Filho, 123 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.918087299999999,
              lng:-38.377588
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS330000-id-2730728039/",
           valorVenda:"330000.00",
           valorOriginal:"330000.00",
           endereco:"Rua Albatroz, 21 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/fit-in/142x80/named.images.sp/3f437041b0acbbef4c07a31761a0ce4d/{description}.webp",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8478579,
              lng:-38.3970528
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-100m2-venda-RS500000-id-2760886368/",
           valorVenda:"500000.00",
           valorOriginal:"500000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 100m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:100,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-130m2-venda-RS220000-id-2585148686/",
           valorVenda:"NaN",
           valorOriginal:"NaN",
           endereco:"Rua Waldir Medeiros da Silva, 138 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 130m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:130,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9187711,
              lng:-38.3864872
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-91m2-venda-RS259000-id-2711657922/",
           valorVenda:"259000.00",
           valorOriginal:"259000.00",
           endereco:"CE-040 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 91m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:91,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9275105,
              lng:-38.3883115
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-81m2-venda-RS220000-id-2722026794/",
           valorVenda:"220000.00",
           valorOriginal:"220000.00",
           endereco:"Rua Desembargador Alberto Barros, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 81m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:81,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966895,
              lng:-38.3739789
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-86m2-venda-RS300000-id-2715202506/",
           valorVenda:"300000.00",
           valorOriginal:"300000.00",
           endereco:"Avenida Manoel Feliciano de Lima, 1 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 86m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:86,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9608129,
              lng:-38.4738436
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-78m2-venda-RS238000-id-2738670213/",
           valorVenda:"238000.00",
           valorOriginal:"238000.00",
           endereco:"Avenida Dedezinho Sampaio, 22525 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 78m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:78,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8986257,
              lng:-38.3749608
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-65m2-venda-RS170000-id-2711658908/",
           valorVenda:"170000.00",
           valorOriginal:"170000.00",
           endereco:"CE-040 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 65m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:65,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9275105,
              lng:-38.3883115
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-60m2-venda-RS325000-id-2727630456/",
           valorVenda:"325000.00",
           valorOriginal:"325000.00",
           endereco:"Rua Búzios, 541 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 60m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:60,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8323747,
              lng:-38.4087715
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS341066-id-2715893399/",
           valorVenda:"341066.00",
           valorOriginal:"341066.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS250000-id-2748984905/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 557 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151931,
              lng:-38.369682
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-74m2-venda-RS235000-id-2564764803/",
           valorVenda:"235000.00",
           valorOriginal:"235000.00",
           endereco:"Rua IV - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 74m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:74,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9369807,
              lng:-38.3858966
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-86m2-venda-RS250000-id-2749490899/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Rua Manuel Evangelista da Silva, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 86m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:86,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9243966,
              lng:-38.3688293
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/cobertura-4-quartos-aquiraz-bairros-aquiraz-com-garagem-160m2-venda-RS1630000-id-2757194123/",
           valorVenda:"1630000.00",
           valorOriginal:"1630000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 4 Quartos à Venda, 160m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:160,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS299000-id-2754405893/",
           valorVenda:"299000.00",
           valorOriginal:"299000.00",
           endereco:"Rua João de Castro, 100 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9126921,
              lng:-38.383822
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1700000-id-2706528700/",
           valorVenda:"1700000.00",
           valorOriginal:"1700000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1490000-id-2706509392/",
           valorVenda:"1490000.00",
           valorOriginal:"1490000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-97m2-venda-RS515000-id-2635463960/",
           valorVenda:"515000.00",
           valorOriginal:"515000.00",
           endereco:"CE-040, 500 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 97m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:97,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966353,
              lng:-38.4183427
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-85m2-venda-RS250000-id-2754178495/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 85m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:85,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-4-quartos-aquiraz-bairros-aquiraz-com-garagem-350m2-venda-RS650000-id-2561041900/",
           valorVenda:"650000.00",
           valorOriginal:"650000.00",
           endereco:"Avenida Manoel Feliciano de Lima, 4027 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 4 Quartos à Venda, 350m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:350,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9450346,
              lng:-38.4653993
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS250000-id-2751033520/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS300000-id-2749751613/",
           valorVenda:"300000.00",
           valorOriginal:"300000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-79m2-venda-RS250000-id-2746422938/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Rua Lucimar Câmara do Amaral, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/44a329e3e30b1248b5e7d0d81e7733a5/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-79m-em-aquiraz-aquiraz.webp",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 79m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:79,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9085271,
              lng:-38.3759721
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS250000-id-2759459046/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/51d6ee662987a0d3131d15d11dea5dea/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-80m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 3 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-92m2-venda-RS299000-id-2761673427/",
           valorVenda:"299000.00",
           valorOriginal:"299000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/9f540be0a4355eadace4e7a4b50f5e38/foto-1-de-casa-com-2-quartos-a-venda-92m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 2 Quartos à Venda, 92m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:92,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-91m2-venda-RS350000-id-2726155248/",
           valorVenda:"350000.00",
           valorOriginal:"350000.00",
           endereco:"Rua João de Castro, 705 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/ef899416e2d265e427bc2bf33b404522/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-91m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 3 Quartos à Venda, 91m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:91,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.918079,
              lng:-38.3834312
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-77m2-venda-RS270000-id-2710848653/",
           valorVenda:"270000.00",
           valorOriginal:"270000.00",
           endereco:"Rua Tiburcio Targino, 1550 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 77m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:77,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.911794699999999,
              lng:-38.3837324
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-78m2-venda-RS140000-id-2655284197/",
           valorVenda:"140000.00",
           valorOriginal:"140000.00",
           endereco:"Rua Otacílio Bernardo de Castro, 375 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 78m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:78,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.919841899999999,
              lng:-38.43431940000001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-99m2-venda-RS259000-id-2722031234/",
           valorVenda:"259000.00",
           valorOriginal:"259000.00",
           endereco:"Rua Desembargador Alberto Barros, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 99m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:99,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966895,
              lng:-38.3739789
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-95m2-venda-RS250000-id-2748488093/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 95m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:95,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-35m2-venda-RS297497-id-2728977813/",
           valorVenda:"297497.00",
           valorOriginal:"297497.00",
           endereco:"Rua 1, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 35m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:35,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8715167,
              lng:-38.4096236
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-53m2-venda-RS150000-id-2687784019/",
           valorVenda:"150000.00",
           valorOriginal:"150000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 53m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:53,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-76m2-venda-RS270000-id-2712404802/",
           valorVenda:"270000.00",
           valorOriginal:"270000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 76m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:76,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS250000-id-2751893594/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 183 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9133513,
              lng:-38.3722675
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-70m2-venda-RS195000-id-2719975056/",
           valorVenda:"195000.00",
           valorOriginal:"195000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 70m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:70,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS230000-id-2719856267/",
           valorVenda:"230000.00",
           valorOriginal:"230000.00",
           endereco:"Rua Almir Filho, 123 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.918087299999999,
              lng:-38.377588
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS330000-id-2730728039/",
           valorVenda:"330000.00",
           valorOriginal:"330000.00",
           endereco:"Rua Albatroz, 21 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/fit-in/142x80/named.images.sp/3f437041b0acbbef4c07a31761a0ce4d/{description}.webp",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8478579,
              lng:-38.3970528
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-100m2-venda-RS500000-id-2760886368/",
           valorVenda:"500000.00",
           valorOriginal:"500000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 100m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:100,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-130m2-venda-RS220000-id-2585148686/",
           valorVenda:"NaN",
           valorOriginal:"NaN",
           endereco:"Rua Waldir Medeiros da Silva, 138 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 130m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:130,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9187711,
              lng:-38.3864872
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-91m2-venda-RS259000-id-2711657922/",
           valorVenda:"259000.00",
           valorOriginal:"259000.00",
           endereco:"CE-040 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 91m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:91,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9275105,
              lng:-38.3883115
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-81m2-venda-RS220000-id-2722026794/",
           valorVenda:"220000.00",
           valorOriginal:"220000.00",
           endereco:"Rua Desembargador Alberto Barros, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 81m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:81,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966895,
              lng:-38.3739789
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-86m2-venda-RS300000-id-2715202506/",
           valorVenda:"300000.00",
           valorOriginal:"300000.00",
           endereco:"Avenida Manoel Feliciano de Lima, 1 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 86m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:86,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9608129,
              lng:-38.4738436
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-78m2-venda-RS238000-id-2738670213/",
           valorVenda:"238000.00",
           valorOriginal:"238000.00",
           endereco:"Avenida Dedezinho Sampaio, 22525 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 78m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:78,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8986257,
              lng:-38.3749608
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-65m2-venda-RS170000-id-2711658908/",
           valorVenda:"170000.00",
           valorOriginal:"170000.00",
           endereco:"CE-040 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 65m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:65,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9275105,
              lng:-38.3883115
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-60m2-venda-RS325000-id-2727630456/",
           valorVenda:"325000.00",
           valorOriginal:"325000.00",
           endereco:"Rua Búzios, 541 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 60m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:60,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8323747,
              lng:-38.4087715
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS341066-id-2715893399/",
           valorVenda:"341066.00",
           valorOriginal:"341066.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS250000-id-2748984905/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 557 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151931,
              lng:-38.369682
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-74m2-venda-RS235000-id-2564764803/",
           valorVenda:"235000.00",
           valorOriginal:"235000.00",
           endereco:"Rua IV - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 74m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:74,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9369807,
              lng:-38.3858966
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-86m2-venda-RS250000-id-2749490899/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Rua Manuel Evangelista da Silva, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 86m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:86,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9243966,
              lng:-38.3688293
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/cobertura-4-quartos-aquiraz-bairros-aquiraz-com-garagem-160m2-venda-RS1630000-id-2757194123/",
           valorVenda:"1630000.00",
           valorOriginal:"1630000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 4 Quartos à Venda, 160m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:160,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS299000-id-2754405893/",
           valorVenda:"299000.00",
           valorOriginal:"299000.00",
           endereco:"Rua João de Castro, 100 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9126921,
              lng:-38.383822
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1700000-id-2706528700/",
           valorVenda:"1700000.00",
           valorOriginal:"1700000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1490000-id-2706509392/",
           valorVenda:"1490000.00",
           valorOriginal:"1490000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-97m2-venda-RS515000-id-2635463960/",
           valorVenda:"515000.00",
           valorOriginal:"515000.00",
           endereco:"CE-040, 500 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 97m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:97,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966353,
              lng:-38.4183427
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-85m2-venda-RS250000-id-2754178495/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 85m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:85,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-4-quartos-aquiraz-bairros-aquiraz-com-garagem-350m2-venda-RS650000-id-2561041900/",
           valorVenda:"650000.00",
           valorOriginal:"650000.00",
           endereco:"Avenida Manoel Feliciano de Lima, 4027 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 4 Quartos à Venda, 350m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:350,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9450346,
              lng:-38.4653993
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS250000-id-2751033520/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS300000-id-2749751613/",
           valorVenda:"300000.00",
           valorOriginal:"300000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-79m2-venda-RS250000-id-2746422938/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Rua Lucimar Câmara do Amaral, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/44a329e3e30b1248b5e7d0d81e7733a5/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-79m-em-aquiraz-aquiraz.webp",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 79m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:79,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9085271,
              lng:-38.3759721
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS250000-id-2759459046/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/51d6ee662987a0d3131d15d11dea5dea/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-80m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 3 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-92m2-venda-RS299000-id-2761673427/",
           valorVenda:"299000.00",
           valorOriginal:"299000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/9f540be0a4355eadace4e7a4b50f5e38/foto-1-de-casa-com-2-quartos-a-venda-92m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 2 Quartos à Venda, 92m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:92,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-91m2-venda-RS350000-id-2726155248/",
           valorVenda:"350000.00",
           valorOriginal:"350000.00",
           endereco:"Rua João de Castro, 705 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/ef899416e2d265e427bc2bf33b404522/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-91m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 3 Quartos à Venda, 91m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:91,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.918079,
              lng:-38.3834312
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-77m2-venda-RS270000-id-2710848653/",
           valorVenda:"270000.00",
           valorOriginal:"270000.00",
           endereco:"Rua Tiburcio Targino, 1550 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 77m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:77,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.911794699999999,
              lng:-38.3837324
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-78m2-venda-RS140000-id-2655284197/",
           valorVenda:"140000.00",
           valorOriginal:"140000.00",
           endereco:"Rua Otacílio Bernardo de Castro, 375 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 78m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:78,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.919841899999999,
              lng:-38.43431940000001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-99m2-venda-RS259000-id-2722031234/",
           valorVenda:"259000.00",
           valorOriginal:"259000.00",
           endereco:"Rua Desembargador Alberto Barros, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 99m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:99,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966895,
              lng:-38.3739789
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-95m2-venda-RS250000-id-2748488093/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 95m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:95,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-35m2-venda-RS297497-id-2728977813/",
           valorVenda:"297497.00",
           valorOriginal:"297497.00",
           endereco:"Rua 1, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 35m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:35,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8715167,
              lng:-38.4096236
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-53m2-venda-RS150000-id-2687784019/",
           valorVenda:"150000.00",
           valorOriginal:"150000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 53m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:53,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-76m2-venda-RS270000-id-2712404802/",
           valorVenda:"270000.00",
           valorOriginal:"270000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 76m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:76,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS250000-id-2751893594/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 183 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9133513,
              lng:-38.3722675
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-70m2-venda-RS195000-id-2719975056/",
           valorVenda:"195000.00",
           valorOriginal:"195000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 70m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:70,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS230000-id-2719856267/",
           valorVenda:"230000.00",
           valorOriginal:"230000.00",
           endereco:"Rua Almir Filho, 123 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.918087299999999,
              lng:-38.377588
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS330000-id-2730728039/",
           valorVenda:"330000.00",
           valorOriginal:"330000.00",
           endereco:"Rua Albatroz, 21 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/fit-in/142x80/named.images.sp/3f437041b0acbbef4c07a31761a0ce4d/{description}.webp",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8478579,
              lng:-38.3970528
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-100m2-venda-RS500000-id-2760886368/",
           valorVenda:"500000.00",
           valorOriginal:"500000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 100m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:100,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-130m2-venda-RS220000-id-2585148686/",
           valorVenda:"NaN",
           valorOriginal:"NaN",
           endereco:"Rua Waldir Medeiros da Silva, 138 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 130m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:130,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9187711,
              lng:-38.3864872
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-91m2-venda-RS259000-id-2711657922/",
           valorVenda:"259000.00",
           valorOriginal:"259000.00",
           endereco:"CE-040 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 91m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:91,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9275105,
              lng:-38.3883115
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-81m2-venda-RS220000-id-2722026794/",
           valorVenda:"220000.00",
           valorOriginal:"220000.00",
           endereco:"Rua Desembargador Alberto Barros, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 81m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:81,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966895,
              lng:-38.3739789
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-86m2-venda-RS300000-id-2715202506/",
           valorVenda:"300000.00",
           valorOriginal:"300000.00",
           endereco:"Avenida Manoel Feliciano de Lima, 1 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 86m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:86,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9608129,
              lng:-38.4738436
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-78m2-venda-RS238000-id-2738670213/",
           valorVenda:"238000.00",
           valorOriginal:"238000.00",
           endereco:"Avenida Dedezinho Sampaio, 22525 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 78m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:78,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8986257,
              lng:-38.3749608
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-65m2-venda-RS170000-id-2711658908/",
           valorVenda:"170000.00",
           valorOriginal:"170000.00",
           endereco:"CE-040 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 65m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:65,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9275105,
              lng:-38.3883115
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-60m2-venda-RS325000-id-2727630456/",
           valorVenda:"325000.00",
           valorOriginal:"325000.00",
           endereco:"Rua Búzios, 541 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 60m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:60,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8323747,
              lng:-38.4087715
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS341066-id-2715893399/",
           valorVenda:"341066.00",
           valorOriginal:"341066.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS250000-id-2748984905/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 557 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151931,
              lng:-38.369682
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-74m2-venda-RS235000-id-2564764803/",
           valorVenda:"235000.00",
           valorOriginal:"235000.00",
           endereco:"Rua IV - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 74m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:74,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9369807,
              lng:-38.3858966
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-86m2-venda-RS250000-id-2749490899/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Rua Manuel Evangelista da Silva, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 86m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:86,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9243966,
              lng:-38.3688293
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/cobertura-4-quartos-aquiraz-bairros-aquiraz-com-garagem-160m2-venda-RS1630000-id-2757194123/",
           valorVenda:"1630000.00",
           valorOriginal:"1630000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 4 Quartos à Venda, 160m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:160,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS299000-id-2754405893/",
           valorVenda:"299000.00",
           valorOriginal:"299000.00",
           endereco:"Rua João de Castro, 100 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9126921,
              lng:-38.383822
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1700000-id-2706528700/",
           valorVenda:"1700000.00",
           valorOriginal:"1700000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1490000-id-2706509392/",
           valorVenda:"1490000.00",
           valorOriginal:"1490000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-97m2-venda-RS515000-id-2635463960/",
           valorVenda:"515000.00",
           valorOriginal:"515000.00",
           endereco:"CE-040, 500 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 97m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:97,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966353,
              lng:-38.4183427
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-85m2-venda-RS250000-id-2754178495/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 85m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:85,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-4-quartos-aquiraz-bairros-aquiraz-com-garagem-350m2-venda-RS650000-id-2561041900/",
           valorVenda:"650000.00",
           valorOriginal:"650000.00",
           endereco:"Avenida Manoel Feliciano de Lima, 4027 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 4 Quartos à Venda, 350m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:350,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9450346,
              lng:-38.4653993
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS250000-id-2751033520/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS300000-id-2749751613/",
           valorVenda:"300000.00",
           valorOriginal:"300000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-79m2-venda-RS250000-id-2746422938/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Rua Lucimar Câmara do Amaral, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/44a329e3e30b1248b5e7d0d81e7733a5/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-79m-em-aquiraz-aquiraz.webp",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 79m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:79,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9085271,
              lng:-38.3759721
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS250000-id-2759459046/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/51d6ee662987a0d3131d15d11dea5dea/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-80m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 3 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-92m2-venda-RS299000-id-2761673427/",
           valorVenda:"299000.00",
           valorOriginal:"299000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/9f540be0a4355eadace4e7a4b50f5e38/foto-1-de-casa-com-2-quartos-a-venda-92m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 2 Quartos à Venda, 92m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:92,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-91m2-venda-RS350000-id-2726155248/",
           valorVenda:"350000.00",
           valorOriginal:"350000.00",
           endereco:"Rua João de Castro, 705 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/ef899416e2d265e427bc2bf33b404522/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-91m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 3 Quartos à Venda, 91m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:91,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.918079,
              lng:-38.3834312
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-77m2-venda-RS270000-id-2710848653/",
           valorVenda:"270000.00",
           valorOriginal:"270000.00",
           endereco:"Rua Tiburcio Targino, 1550 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 77m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:77,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.911794699999999,
              lng:-38.3837324
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-78m2-venda-RS140000-id-2655284197/",
           valorVenda:"140000.00",
           valorOriginal:"140000.00",
           endereco:"Rua Otacílio Bernardo de Castro, 375 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 78m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:78,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.919841899999999,
              lng:-38.43431940000001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-99m2-venda-RS259000-id-2722031234/",
           valorVenda:"259000.00",
           valorOriginal:"259000.00",
           endereco:"Rua Desembargador Alberto Barros, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 99m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:99,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966895,
              lng:-38.3739789
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-95m2-venda-RS250000-id-2748488093/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 95m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:95,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-35m2-venda-RS297497-id-2728977813/",
           valorVenda:"297497.00",
           valorOriginal:"297497.00",
           endereco:"Rua 1, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 35m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:35,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8715167,
              lng:-38.4096236
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-53m2-venda-RS150000-id-2687784019/",
           valorVenda:"150000.00",
           valorOriginal:"150000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 53m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:53,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-76m2-venda-RS270000-id-2712404802/",
           valorVenda:"270000.00",
           valorOriginal:"270000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 76m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:76,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS250000-id-2751893594/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 183 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9133513,
              lng:-38.3722675
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-70m2-venda-RS195000-id-2719975056/",
           valorVenda:"195000.00",
           valorOriginal:"195000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 70m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:70,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS230000-id-2719856267/",
           valorVenda:"230000.00",
           valorOriginal:"230000.00",
           endereco:"Rua Almir Filho, 123 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.918087299999999,
              lng:-38.377588
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS330000-id-2730728039/",
           valorVenda:"330000.00",
           valorOriginal:"330000.00",
           endereco:"Rua Albatroz, 21 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/fit-in/142x80/named.images.sp/3f437041b0acbbef4c07a31761a0ce4d/{description}.webp",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8478579,
              lng:-38.3970528
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-100m2-venda-RS500000-id-2760886368/",
           valorVenda:"500000.00",
           valorOriginal:"500000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 100m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:100,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-130m2-venda-RS220000-id-2585148686/",
           valorVenda:"NaN",
           valorOriginal:"NaN",
           endereco:"Rua Waldir Medeiros da Silva, 138 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 130m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:130,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9187711,
              lng:-38.3864872
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-91m2-venda-RS259000-id-2711657922/",
           valorVenda:"259000.00",
           valorOriginal:"259000.00",
           endereco:"CE-040 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 91m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:91,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9275105,
              lng:-38.3883115
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-81m2-venda-RS220000-id-2722026794/",
           valorVenda:"220000.00",
           valorOriginal:"220000.00",
           endereco:"Rua Desembargador Alberto Barros, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 81m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:81,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966895,
              lng:-38.3739789
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-86m2-venda-RS300000-id-2715202506/",
           valorVenda:"300000.00",
           valorOriginal:"300000.00",
           endereco:"Avenida Manoel Feliciano de Lima, 1 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 86m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:86,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9608129,
              lng:-38.4738436
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-78m2-venda-RS238000-id-2738670213/",
           valorVenda:"238000.00",
           valorOriginal:"238000.00",
           endereco:"Avenida Dedezinho Sampaio, 22525 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 78m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:78,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8986257,
              lng:-38.3749608
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-65m2-venda-RS170000-id-2711658908/",
           valorVenda:"170000.00",
           valorOriginal:"170000.00",
           endereco:"CE-040 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 65m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:65,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9275105,
              lng:-38.3883115
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-60m2-venda-RS325000-id-2727630456/",
           valorVenda:"325000.00",
           valorOriginal:"325000.00",
           endereco:"Rua Búzios, 541 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 60m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:60,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8323747,
              lng:-38.4087715
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS341066-id-2715893399/",
           valorVenda:"341066.00",
           valorOriginal:"341066.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS250000-id-2748984905/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 557 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151931,
              lng:-38.369682
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-74m2-venda-RS235000-id-2564764803/",
           valorVenda:"235000.00",
           valorOriginal:"235000.00",
           endereco:"Rua IV - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 74m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:74,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9369807,
              lng:-38.3858966
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-86m2-venda-RS250000-id-2749490899/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Rua Manuel Evangelista da Silva, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 86m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:86,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9243966,
              lng:-38.3688293
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/cobertura-4-quartos-aquiraz-bairros-aquiraz-com-garagem-160m2-venda-RS1630000-id-2757194123/",
           valorVenda:"1630000.00",
           valorOriginal:"1630000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 4 Quartos à Venda, 160m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:160,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS299000-id-2754405893/",
           valorVenda:"299000.00",
           valorOriginal:"299000.00",
           endereco:"Rua João de Castro, 100 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9126921,
              lng:-38.383822
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1700000-id-2706528700/",
           valorVenda:"1700000.00",
           valorOriginal:"1700000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1490000-id-2706509392/",
           valorVenda:"1490000.00",
           valorOriginal:"1490000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-97m2-venda-RS515000-id-2635463960/",
           valorVenda:"515000.00",
           valorOriginal:"515000.00",
           endereco:"CE-040, 500 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 97m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:97,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966353,
              lng:-38.4183427
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-85m2-venda-RS250000-id-2754178495/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 85m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:85,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-4-quartos-aquiraz-bairros-aquiraz-com-garagem-350m2-venda-RS650000-id-2561041900/",
           valorVenda:"650000.00",
           valorOriginal:"650000.00",
           endereco:"Avenida Manoel Feliciano de Lima, 4027 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 4 Quartos à Venda, 350m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:350,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9450346,
              lng:-38.4653993
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS250000-id-2751033520/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS300000-id-2749751613/",
           valorVenda:"300000.00",
           valorOriginal:"300000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-79m2-venda-RS250000-id-2746422938/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Rua Lucimar Câmara do Amaral, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/44a329e3e30b1248b5e7d0d81e7733a5/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-79m-em-aquiraz-aquiraz.webp",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 79m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:79,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9085271,
              lng:-38.3759721
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS250000-id-2759459046/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/51d6ee662987a0d3131d15d11dea5dea/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-80m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 3 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-92m2-venda-RS299000-id-2761673427/",
           valorVenda:"299000.00",
           valorOriginal:"299000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/9f540be0a4355eadace4e7a4b50f5e38/foto-1-de-casa-com-2-quartos-a-venda-92m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 2 Quartos à Venda, 92m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:92,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-91m2-venda-RS350000-id-2726155248/",
           valorVenda:"350000.00",
           valorOriginal:"350000.00",
           endereco:"Rua João de Castro, 705 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/ef899416e2d265e427bc2bf33b404522/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-91m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 3 Quartos à Venda, 91m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:91,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.918079,
              lng:-38.3834312
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-77m2-venda-RS270000-id-2710848653/",
           valorVenda:"270000.00",
           valorOriginal:"270000.00",
           endereco:"Rua Tiburcio Targino, 1550 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 77m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:77,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.911794699999999,
              lng:-38.3837324
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-78m2-venda-RS140000-id-2655284197/",
           valorVenda:"140000.00",
           valorOriginal:"140000.00",
           endereco:"Rua Otacílio Bernardo de Castro, 375 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 78m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:78,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.919841899999999,
              lng:-38.43431940000001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-99m2-venda-RS259000-id-2722031234/",
           valorVenda:"259000.00",
           valorOriginal:"259000.00",
           endereco:"Rua Desembargador Alberto Barros, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 99m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:99,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966895,
              lng:-38.3739789
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-95m2-venda-RS250000-id-2748488093/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 95m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:95,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-35m2-venda-RS297497-id-2728977813/",
           valorVenda:"297497.00",
           valorOriginal:"297497.00",
           endereco:"Rua 1, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 35m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:35,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8715167,
              lng:-38.4096236
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-53m2-venda-RS150000-id-2687784019/",
           valorVenda:"150000.00",
           valorOriginal:"150000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 53m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:53,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-76m2-venda-RS270000-id-2712404802/",
           valorVenda:"270000.00",
           valorOriginal:"270000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 76m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:76,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS250000-id-2751893594/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 183 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9133513,
              lng:-38.3722675
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-70m2-venda-RS195000-id-2719975056/",
           valorVenda:"195000.00",
           valorOriginal:"195000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 70m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:70,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS230000-id-2719856267/",
           valorVenda:"230000.00",
           valorOriginal:"230000.00",
           endereco:"Rua Almir Filho, 123 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.918087299999999,
              lng:-38.377588
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS330000-id-2730728039/",
           valorVenda:"330000.00",
           valorOriginal:"330000.00",
           endereco:"Rua Albatroz, 21 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/fit-in/142x80/named.images.sp/3f437041b0acbbef4c07a31761a0ce4d/{description}.webp",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8478579,
              lng:-38.3970528
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-100m2-venda-RS500000-id-2760886368/",
           valorVenda:"500000.00",
           valorOriginal:"500000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 100m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:100,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-130m2-venda-RS220000-id-2585148686/",
           valorVenda:"NaN",
           valorOriginal:"NaN",
           endereco:"Rua Waldir Medeiros da Silva, 138 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 130m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:130,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9187711,
              lng:-38.3864872
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-91m2-venda-RS259000-id-2711657922/",
           valorVenda:"259000.00",
           valorOriginal:"259000.00",
           endereco:"CE-040 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 91m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:91,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9275105,
              lng:-38.3883115
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-81m2-venda-RS220000-id-2722026794/",
           valorVenda:"220000.00",
           valorOriginal:"220000.00",
           endereco:"Rua Desembargador Alberto Barros, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 81m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:81,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966895,
              lng:-38.3739789
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-86m2-venda-RS300000-id-2715202506/",
           valorVenda:"300000.00",
           valorOriginal:"300000.00",
           endereco:"Avenida Manoel Feliciano de Lima, 1 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 86m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:86,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9608129,
              lng:-38.4738436
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-78m2-venda-RS238000-id-2738670213/",
           valorVenda:"238000.00",
           valorOriginal:"238000.00",
           endereco:"Avenida Dedezinho Sampaio, 22525 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 78m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:78,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8986257,
              lng:-38.3749608
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-65m2-venda-RS170000-id-2711658908/",
           valorVenda:"170000.00",
           valorOriginal:"170000.00",
           endereco:"CE-040 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 65m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:65,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9275105,
              lng:-38.3883115
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-60m2-venda-RS325000-id-2727630456/",
           valorVenda:"325000.00",
           valorOriginal:"325000.00",
           endereco:"Rua Búzios, 541 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 60m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:60,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8323747,
              lng:-38.4087715
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS341066-id-2715893399/",
           valorVenda:"341066.00",
           valorOriginal:"341066.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS250000-id-2748984905/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 557 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151931,
              lng:-38.369682
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-74m2-venda-RS235000-id-2564764803/",
           valorVenda:"235000.00",
           valorOriginal:"235000.00",
           endereco:"Rua IV - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 74m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:74,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9369807,
              lng:-38.3858966
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-86m2-venda-RS250000-id-2749490899/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Rua Manuel Evangelista da Silva, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 86m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:86,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9243966,
              lng:-38.3688293
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/cobertura-4-quartos-aquiraz-bairros-aquiraz-com-garagem-160m2-venda-RS1630000-id-2757194123/",
           valorVenda:"1630000.00",
           valorOriginal:"1630000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 4 Quartos à Venda, 160m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:160,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS299000-id-2754405893/",
           valorVenda:"299000.00",
           valorOriginal:"299000.00",
           endereco:"Rua João de Castro, 100 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9126921,
              lng:-38.383822
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1700000-id-2706528700/",
           valorVenda:"1700000.00",
           valorOriginal:"1700000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1490000-id-2706509392/",
           valorVenda:"1490000.00",
           valorOriginal:"1490000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-97m2-venda-RS515000-id-2635463960/",
           valorVenda:"515000.00",
           valorOriginal:"515000.00",
           endereco:"CE-040, 500 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 97m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:97,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966353,
              lng:-38.4183427
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-85m2-venda-RS250000-id-2754178495/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 85m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:85,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-4-quartos-aquiraz-bairros-aquiraz-com-garagem-350m2-venda-RS650000-id-2561041900/",
           valorVenda:"650000.00",
           valorOriginal:"650000.00",
           endereco:"Avenida Manoel Feliciano de Lima, 4027 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 4 Quartos à Venda, 350m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:350,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9450346,
              lng:-38.4653993
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS250000-id-2751033520/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS300000-id-2749751613/",
           valorVenda:"300000.00",
           valorOriginal:"300000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-79m2-venda-RS250000-id-2746422938/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Rua Lucimar Câmara do Amaral, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/44a329e3e30b1248b5e7d0d81e7733a5/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-79m-em-aquiraz-aquiraz.webp",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 79m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:79,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9085271,
              lng:-38.3759721
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS250000-id-2759459046/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/51d6ee662987a0d3131d15d11dea5dea/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-80m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 3 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-92m2-venda-RS299000-id-2761673427/",
           valorVenda:"299000.00",
           valorOriginal:"299000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/9f540be0a4355eadace4e7a4b50f5e38/foto-1-de-casa-com-2-quartos-a-venda-92m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 2 Quartos à Venda, 92m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:92,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-91m2-venda-RS350000-id-2726155248/",
           valorVenda:"350000.00",
           valorOriginal:"350000.00",
           endereco:"Rua João de Castro, 705 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/ef899416e2d265e427bc2bf33b404522/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-91m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 3 Quartos à Venda, 91m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:91,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.918079,
              lng:-38.3834312
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-77m2-venda-RS270000-id-2710848653/",
           valorVenda:"270000.00",
           valorOriginal:"270000.00",
           endereco:"Rua Tiburcio Targino, 1550 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 77m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:77,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.911794699999999,
              lng:-38.3837324
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-78m2-venda-RS140000-id-2655284197/",
           valorVenda:"140000.00",
           valorOriginal:"140000.00",
           endereco:"Rua Otacílio Bernardo de Castro, 375 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 78m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:78,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.919841899999999,
              lng:-38.43431940000001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-99m2-venda-RS259000-id-2722031234/",
           valorVenda:"259000.00",
           valorOriginal:"259000.00",
           endereco:"Rua Desembargador Alberto Barros, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 99m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:99,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966895,
              lng:-38.3739789
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-95m2-venda-RS250000-id-2748488093/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 95m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:95,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-35m2-venda-RS297497-id-2728977813/",
           valorVenda:"297497.00",
           valorOriginal:"297497.00",
           endereco:"Rua 1, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 35m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:35,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8715167,
              lng:-38.4096236
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-53m2-venda-RS150000-id-2687784019/",
           valorVenda:"150000.00",
           valorOriginal:"150000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 53m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:53,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-76m2-venda-RS270000-id-2712404802/",
           valorVenda:"270000.00",
           valorOriginal:"270000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 76m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:76,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS250000-id-2751893594/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 183 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9133513,
              lng:-38.3722675
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-70m2-venda-RS195000-id-2719975056/",
           valorVenda:"195000.00",
           valorOriginal:"195000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 70m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:70,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS230000-id-2719856267/",
           valorVenda:"230000.00",
           valorOriginal:"230000.00",
           endereco:"Rua Almir Filho, 123 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.918087299999999,
              lng:-38.377588
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS330000-id-2730728039/",
           valorVenda:"330000.00",
           valorOriginal:"330000.00",
           endereco:"Rua Albatroz, 21 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/fit-in/142x80/named.images.sp/3f437041b0acbbef4c07a31761a0ce4d/{description}.webp",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8478579,
              lng:-38.3970528
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-100m2-venda-RS500000-id-2760886368/",
           valorVenda:"500000.00",
           valorOriginal:"500000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 100m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:100,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-130m2-venda-RS220000-id-2585148686/",
           valorVenda:"NaN",
           valorOriginal:"NaN",
           endereco:"Rua Waldir Medeiros da Silva, 138 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 130m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:130,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9187711,
              lng:-38.3864872
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-91m2-venda-RS259000-id-2711657922/",
           valorVenda:"259000.00",
           valorOriginal:"259000.00",
           endereco:"CE-040 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 91m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:91,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9275105,
              lng:-38.3883115
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-81m2-venda-RS220000-id-2722026794/",
           valorVenda:"220000.00",
           valorOriginal:"220000.00",
           endereco:"Rua Desembargador Alberto Barros, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 81m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:81,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966895,
              lng:-38.3739789
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-86m2-venda-RS300000-id-2715202506/",
           valorVenda:"300000.00",
           valorOriginal:"300000.00",
           endereco:"Avenida Manoel Feliciano de Lima, 1 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 86m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:86,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9608129,
              lng:-38.4738436
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-78m2-venda-RS238000-id-2738670213/",
           valorVenda:"238000.00",
           valorOriginal:"238000.00",
           endereco:"Avenida Dedezinho Sampaio, 22525 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 78m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:78,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8986257,
              lng:-38.3749608
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-65m2-venda-RS170000-id-2711658908/",
           valorVenda:"170000.00",
           valorOriginal:"170000.00",
           endereco:"CE-040 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 65m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:65,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9275105,
              lng:-38.3883115
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-60m2-venda-RS325000-id-2727630456/",
           valorVenda:"325000.00",
           valorOriginal:"325000.00",
           endereco:"Rua Búzios, 541 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 60m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:60,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8323747,
              lng:-38.4087715
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS341066-id-2715893399/",
           valorVenda:"341066.00",
           valorOriginal:"341066.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS250000-id-2748984905/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 557 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151931,
              lng:-38.369682
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-74m2-venda-RS235000-id-2564764803/",
           valorVenda:"235000.00",
           valorOriginal:"235000.00",
           endereco:"Rua IV - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 74m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:74,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9369807,
              lng:-38.3858966
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-86m2-venda-RS250000-id-2749490899/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Rua Manuel Evangelista da Silva, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 86m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:86,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9243966,
              lng:-38.3688293
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/cobertura-4-quartos-aquiraz-bairros-aquiraz-com-garagem-160m2-venda-RS1630000-id-2757194123/",
           valorVenda:"1630000.00",
           valorOriginal:"1630000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 4 Quartos à Venda, 160m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:160,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS299000-id-2754405893/",
           valorVenda:"299000.00",
           valorOriginal:"299000.00",
           endereco:"Rua João de Castro, 100 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9126921,
              lng:-38.383822
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1700000-id-2706528700/",
           valorVenda:"1700000.00",
           valorOriginal:"1700000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1490000-id-2706509392/",
           valorVenda:"1490000.00",
           valorOriginal:"1490000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-97m2-venda-RS515000-id-2635463960/",
           valorVenda:"515000.00",
           valorOriginal:"515000.00",
           endereco:"CE-040, 500 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 97m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:97,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966353,
              lng:-38.4183427
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-85m2-venda-RS250000-id-2754178495/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 85m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:85,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-4-quartos-aquiraz-bairros-aquiraz-com-garagem-350m2-venda-RS650000-id-2561041900/",
           valorVenda:"650000.00",
           valorOriginal:"650000.00",
           endereco:"Avenida Manoel Feliciano de Lima, 4027 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 4 Quartos à Venda, 350m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:350,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9450346,
              lng:-38.4653993
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS250000-id-2751033520/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS300000-id-2749751613/",
           valorVenda:"300000.00",
           valorOriginal:"300000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-79m2-venda-RS250000-id-2746422938/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Rua Lucimar Câmara do Amaral, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/44a329e3e30b1248b5e7d0d81e7733a5/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-79m-em-aquiraz-aquiraz.webp",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 79m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:79,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9085271,
              lng:-38.3759721
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS250000-id-2759459046/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/51d6ee662987a0d3131d15d11dea5dea/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-80m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 3 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-92m2-venda-RS299000-id-2761673427/",
           valorVenda:"299000.00",
           valorOriginal:"299000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/9f540be0a4355eadace4e7a4b50f5e38/foto-1-de-casa-com-2-quartos-a-venda-92m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 2 Quartos à Venda, 92m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:92,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-91m2-venda-RS350000-id-2726155248/",
           valorVenda:"350000.00",
           valorOriginal:"350000.00",
           endereco:"Rua João de Castro, 705 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/ef899416e2d265e427bc2bf33b404522/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-91m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 3 Quartos à Venda, 91m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:91,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.918079,
              lng:-38.3834312
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-77m2-venda-RS270000-id-2710848653/",
           valorVenda:"270000.00",
           valorOriginal:"270000.00",
           endereco:"Rua Tiburcio Targino, 1550 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 77m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:77,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.911794699999999,
              lng:-38.3837324
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-78m2-venda-RS140000-id-2655284197/",
           valorVenda:"140000.00",
           valorOriginal:"140000.00",
           endereco:"Rua Otacílio Bernardo de Castro, 375 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 78m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:78,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.919841899999999,
              lng:-38.43431940000001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-99m2-venda-RS259000-id-2722031234/",
           valorVenda:"259000.00",
           valorOriginal:"259000.00",
           endereco:"Rua Desembargador Alberto Barros, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 99m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:99,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966895,
              lng:-38.3739789
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-95m2-venda-RS250000-id-2748488093/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 95m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:95,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-35m2-venda-RS297497-id-2728977813/",
           valorVenda:"297497.00",
           valorOriginal:"297497.00",
           endereco:"Rua 1, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 35m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:35,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8715167,
              lng:-38.4096236
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-53m2-venda-RS150000-id-2687784019/",
           valorVenda:"150000.00",
           valorOriginal:"150000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 53m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:53,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-76m2-venda-RS270000-id-2712404802/",
           valorVenda:"270000.00",
           valorOriginal:"270000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 76m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:76,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS250000-id-2751893594/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 183 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9133513,
              lng:-38.3722675
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-70m2-venda-RS195000-id-2719975056/",
           valorVenda:"195000.00",
           valorOriginal:"195000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 70m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:70,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS230000-id-2719856267/",
           valorVenda:"230000.00",
           valorOriginal:"230000.00",
           endereco:"Rua Almir Filho, 123 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.918087299999999,
              lng:-38.377588
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS330000-id-2730728039/",
           valorVenda:"330000.00",
           valorOriginal:"330000.00",
           endereco:"Rua Albatroz, 21 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/fit-in/142x80/named.images.sp/3f437041b0acbbef4c07a31761a0ce4d/{description}.webp",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8478579,
              lng:-38.3970528
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-100m2-venda-RS500000-id-2760886368/",
           valorVenda:"500000.00",
           valorOriginal:"500000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 100m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:100,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-130m2-venda-RS220000-id-2585148686/",
           valorVenda:"NaN",
           valorOriginal:"NaN",
           endereco:"Rua Waldir Medeiros da Silva, 138 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 130m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:130,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9187711,
              lng:-38.3864872
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-91m2-venda-RS259000-id-2711657922/",
           valorVenda:"259000.00",
           valorOriginal:"259000.00",
           endereco:"CE-040 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 91m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:91,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9275105,
              lng:-38.3883115
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-81m2-venda-RS220000-id-2722026794/",
           valorVenda:"220000.00",
           valorOriginal:"220000.00",
           endereco:"Rua Desembargador Alberto Barros, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 81m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:81,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966895,
              lng:-38.3739789
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-86m2-venda-RS300000-id-2715202506/",
           valorVenda:"300000.00",
           valorOriginal:"300000.00",
           endereco:"Avenida Manoel Feliciano de Lima, 1 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 86m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:86,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9608129,
              lng:-38.4738436
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-78m2-venda-RS238000-id-2738670213/",
           valorVenda:"238000.00",
           valorOriginal:"238000.00",
           endereco:"Avenida Dedezinho Sampaio, 22525 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 78m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:78,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8986257,
              lng:-38.3749608
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-65m2-venda-RS170000-id-2711658908/",
           valorVenda:"170000.00",
           valorOriginal:"170000.00",
           endereco:"CE-040 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 65m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:65,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9275105,
              lng:-38.3883115
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-60m2-venda-RS325000-id-2727630456/",
           valorVenda:"325000.00",
           valorOriginal:"325000.00",
           endereco:"Rua Búzios, 541 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 60m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:60,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8323747,
              lng:-38.4087715
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS341066-id-2715893399/",
           valorVenda:"341066.00",
           valorOriginal:"341066.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS250000-id-2748984905/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 557 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151931,
              lng:-38.369682
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-74m2-venda-RS235000-id-2564764803/",
           valorVenda:"235000.00",
           valorOriginal:"235000.00",
           endereco:"Rua IV - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 74m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:74,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9369807,
              lng:-38.3858966
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-86m2-venda-RS250000-id-2749490899/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Rua Manuel Evangelista da Silva, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 86m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:86,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9243966,
              lng:-38.3688293
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/cobertura-4-quartos-aquiraz-bairros-aquiraz-com-garagem-160m2-venda-RS1630000-id-2757194123/",
           valorVenda:"1630000.00",
           valorOriginal:"1630000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 4 Quartos à Venda, 160m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:160,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS299000-id-2754405893/",
           valorVenda:"299000.00",
           valorOriginal:"299000.00",
           endereco:"Rua João de Castro, 100 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9126921,
              lng:-38.383822
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1700000-id-2706528700/",
           valorVenda:"1700000.00",
           valorOriginal:"1700000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1490000-id-2706509392/",
           valorVenda:"1490000.00",
           valorOriginal:"1490000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-97m2-venda-RS515000-id-2635463960/",
           valorVenda:"515000.00",
           valorOriginal:"515000.00",
           endereco:"CE-040, 500 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 97m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:97,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966353,
              lng:-38.4183427
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-85m2-venda-RS250000-id-2754178495/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 85m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:85,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-4-quartos-aquiraz-bairros-aquiraz-com-garagem-350m2-venda-RS650000-id-2561041900/",
           valorVenda:"650000.00",
           valorOriginal:"650000.00",
           endereco:"Avenida Manoel Feliciano de Lima, 4027 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 4 Quartos à Venda, 350m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:350,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9450346,
              lng:-38.4653993
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS250000-id-2751033520/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS300000-id-2749751613/",
           valorVenda:"300000.00",
           valorOriginal:"300000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-79m2-venda-RS250000-id-2746422938/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Rua Lucimar Câmara do Amaral, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/44a329e3e30b1248b5e7d0d81e7733a5/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-79m-em-aquiraz-aquiraz.webp",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 79m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:79,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9085271,
              lng:-38.3759721
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS250000-id-2759459046/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/51d6ee662987a0d3131d15d11dea5dea/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-80m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 3 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-92m2-venda-RS299000-id-2761673427/",
           valorVenda:"299000.00",
           valorOriginal:"299000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/9f540be0a4355eadace4e7a4b50f5e38/foto-1-de-casa-com-2-quartos-a-venda-92m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 2 Quartos à Venda, 92m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:92,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-91m2-venda-RS350000-id-2726155248/",
           valorVenda:"350000.00",
           valorOriginal:"350000.00",
           endereco:"Rua João de Castro, 705 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/ef899416e2d265e427bc2bf33b404522/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-91m-em-aquiraz-aquiraz.webp",
           titulo:"Casa com 3 Quartos à Venda, 91m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:91,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.918079,
              lng:-38.3834312
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-77m2-venda-RS270000-id-2710848653/",
           valorVenda:"270000.00",
           valorOriginal:"270000.00",
           endereco:"Rua Tiburcio Targino, 1550 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 77m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:77,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.911794699999999,
              lng:-38.3837324
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-78m2-venda-RS140000-id-2655284197/",
           valorVenda:"140000.00",
           valorOriginal:"140000.00",
           endereco:"Rua Otacílio Bernardo de Castro, 375 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 78m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:78,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.919841899999999,
              lng:-38.43431940000001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-99m2-venda-RS259000-id-2722031234/",
           valorVenda:"259000.00",
           valorOriginal:"259000.00",
           endereco:"Rua Desembargador Alberto Barros, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 99m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:99,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966895,
              lng:-38.3739789
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-95m2-venda-RS250000-id-2748488093/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 95m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:95,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-35m2-venda-RS297497-id-2728977813/",
           valorVenda:"297497.00",
           valorOriginal:"297497.00",
           endereco:"Rua 1, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 35m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:35,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8715167,
              lng:-38.4096236
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-53m2-venda-RS150000-id-2687784019/",
           valorVenda:"150000.00",
           valorOriginal:"150000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 53m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:53,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-76m2-venda-RS270000-id-2712404802/",
           valorVenda:"270000.00",
           valorOriginal:"270000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 76m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:76,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS250000-id-2751893594/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 183 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9133513,
              lng:-38.3722675
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-70m2-venda-RS195000-id-2719975056/",
           valorVenda:"195000.00",
           valorOriginal:"195000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 70m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:70,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-80m2-venda-RS230000-id-2719856267/",
           valorVenda:"230000.00",
           valorOriginal:"230000.00",
           endereco:"Rua Almir Filho, 123 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 80m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:80,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.918087299999999,
              lng:-38.377588
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS330000-id-2730728039/",
           valorVenda:"330000.00",
           valorOriginal:"330000.00",
           endereco:"Rua Albatroz, 21 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/resizedimgs.vivareal.com/fit-in/142x80/named.images.sp/3f437041b0acbbef4c07a31761a0ce4d/{description}.webp",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8478579,
              lng:-38.3970528
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-100m2-venda-RS500000-id-2760886368/",
           valorVenda:"500000.00",
           valorOriginal:"500000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 100m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:100,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-130m2-venda-RS220000-id-2585148686/",
           valorVenda:"NaN",
           valorOriginal:"NaN",
           endereco:"Rua Waldir Medeiros da Silva, 138 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 130m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:130,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9187711,
              lng:-38.3864872
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-91m2-venda-RS259000-id-2711657922/",
           valorVenda:"259000.00",
           valorOriginal:"259000.00",
           endereco:"CE-040 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 91m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:91,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9275105,
              lng:-38.3883115
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-81m2-venda-RS220000-id-2722026794/",
           valorVenda:"220000.00",
           valorOriginal:"220000.00",
           endereco:"Rua Desembargador Alberto Barros, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 81m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:81,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966895,
              lng:-38.3739789
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-86m2-venda-RS300000-id-2715202506/",
           valorVenda:"300000.00",
           valorOriginal:"300000.00",
           endereco:"Avenida Manoel Feliciano de Lima, 1 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 3 Quartos à Venda, 86m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:86,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9608129,
              lng:-38.4738436
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-78m2-venda-RS238000-id-2738670213/",
           valorVenda:"238000.00",
           valorOriginal:"238000.00",
           endereco:"Avenida Dedezinho Sampaio, 22525 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 78m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:78,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8986257,
              lng:-38.3749608
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-aquiraz-bairros-aquiraz-com-garagem-65m2-venda-RS170000-id-2711658908/",
           valorVenda:"170000.00",
           valorOriginal:"170000.00",
           endereco:"CE-040 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa de condomínio com 2 Quartos à Venda, 65m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:65,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9275105,
              lng:-38.3883115
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aquiraz-bairros-aquiraz-com-garagem-60m2-venda-RS325000-id-2727630456/",
           valorVenda:"325000.00",
           valorOriginal:"325000.00",
           endereco:"Rua Búzios, 541 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 2 Quartos à Venda, 60m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:60,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8323747,
              lng:-38.4087715
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS341066-id-2715893399/",
           valorVenda:"341066.00",
           valorOriginal:"341066.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS250000-id-2748984905/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 557 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151931,
              lng:-38.369682
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-74m2-venda-RS235000-id-2564764803/",
           valorVenda:"235000.00",
           valorOriginal:"235000.00",
           endereco:"Rua IV - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 74m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:74,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9369807,
              lng:-38.3858966
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aquiraz-bairros-aquiraz-com-garagem-86m2-venda-RS250000-id-2749490899/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Rua Manuel Evangelista da Silva, S/N - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 2 Quartos à Venda, 86m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:86,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9243966,
              lng:-38.3688293
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/cobertura-4-quartos-aquiraz-bairros-aquiraz-com-garagem-160m2-venda-RS1630000-id-2757194123/",
           valorVenda:"1630000.00",
           valorOriginal:"1630000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 4 Quartos à Venda, 160m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:160,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS299000-id-2754405893/",
           valorVenda:"299000.00",
           valorOriginal:"299000.00",
           endereco:"Rua João de Castro, 100 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9126921,
              lng:-38.383822
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1700000-id-2706528700/",
           valorVenda:"1700000.00",
           valorOriginal:"1700000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aquiraz-bairros-aquiraz-com-garagem-101m2-venda-RS1490000-id-2706509392/",
           valorVenda:"1490000.00",
           valorOriginal:"1490000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com 3 Quartos à Venda, 101m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:101,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-97m2-venda-RS515000-id-2635463960/",
           valorVenda:"515000.00",
           valorOriginal:"515000.00",
           endereco:"CE-040, 500 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 97m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:97,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.8966353,
              lng:-38.4183427
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-85m2-venda-RS250000-id-2754178495/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 85m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:85,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-4-quartos-aquiraz-bairros-aquiraz-com-garagem-350m2-venda-RS650000-id-2561041900/",
           valorVenda:"650000.00",
           valorOriginal:"650000.00",
           endereco:"Avenida Manoel Feliciano de Lima, 4027 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 4 Quartos à Venda, 350m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:350,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9450346,
              lng:-38.4653993
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-aquiraz-bairros-aquiraz-com-garagem-90m2-venda-RS250000-id-2751033520/",
           valorVenda:"250000.00",
           valorOriginal:"250000.00",
           endereco:"Estrada do Picão, 555 - Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Casa com 3 Quartos à Venda, 90m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:1,
           descricaoTipoImovel:"Casa",
           area:90,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.9151907,
              lng:-38.3697001
           }
        },
        {
           url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-aquiraz-bairros-aquiraz-com-garagem-36m2-venda-RS300000-id-2749751613/",
           valorVenda:"300000.00",
           valorOriginal:"300000.00",
           endereco:"Aquiraz, Aquiraz - CE",
           imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
           titulo:"Apartamento com  Quarto à Venda, 36m²",
           estado:"CE",
           cidade:"Aquiraz",
           tipoNegocio:2,
           descricaoTipoNegocio:"Venda",
           tipoImovel:2,
           descricaoTipoImovel:"Apartamento",
           area:36,
           areaTerreno:0,
           vendido:0,
           location:{
              lat:-3.906275899999999,
              lng:-38.3873843
           }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-50m2-venda-RS95000-id-2762475925/",
            valorVenda: "95000.00",
            valorOriginal: "95000.00",
            endereco: "Avenida Brasília, 200 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/92176e57898319c8ad6ae9296bc033ad/foto-1-de-apartamento-com-2-quartos-a-venda-50m-em-eusebio-eusebio.webp",
            titulo: "Apartamento com 2 Quartos à Venda, 50m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "50",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8971197,
                lng: -38.4528641
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-50m2-venda-RS95000-id-2762482814/",
            valorVenda: "95000.00",
            valorOriginal: "95000.00",
            endereco: "Avenida Brasília, 200 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/e6faf65a3c7ed8bef0afe1d1fba6764f/foto-1-de-apartamento-com-2-quartos-a-venda-50m-em-eusebio-eusebio.webp",
            titulo: "Apartamento com 2 Quartos à Venda, 50m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "50",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8971197,
                lng: -38.4528641
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS70000-id-2762469251/",
            valorVenda: "70000.00",
            valorOriginal: "70000.00",
            endereco: "Avenida Brasília, 200 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/5be817fdd4cc07822910c895945b1a49/foto-1-de-apartamento-com-2-quartos-a-venda-44m-em-eusebio-eusebio.webp",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8971197,
                lng: -38.4528641
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-com-garagem-65m2-venda-RS374600-id-2588522722/",
            valorVenda: "374600.00",
            valorOriginal: "374600.00",
            endereco: "Rua Industrial Amílcar Araújo, 255 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/fit-in/142x80/named.images.sp/84727e3f5497d74f9ca9690c3c40c185/{description}.webp",
            titulo: "Apartamento com 3 Quartos à Venda, 65m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "65",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8815994,
                lng: -38.46295509999999
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-50m2-venda-RS195000-id-2753488098/",
            valorVenda: "195000.00",
            valorOriginal: "195000.00",
            endereco: "Avenida Brasília, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 50m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "50",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8975813,
                lng: -38.45296769999999
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-51m2-venda-RS230000-id-2625413467/",
            valorVenda: "230000.00",
            valorOriginal: "230000.00",
            endereco: "Rua Acilon Gonçalves Pinto, 705 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 51m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "51",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8754191,
                lng: -38.4567842
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-50m2-venda-RS95000-id-2761179917/",
            valorVenda: "95000.00",
            valorOriginal: "95000.00",
            endereco: "Avenida Brasília, 279 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 50m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "50",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8969089,
                lng: -38.4523047
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-58m2-venda-RS331700-id-2710596462/",
            valorVenda: "331700.00",
            valorOriginal: "331700.00",
            endereco: "Rua Industrial Amílcar Araújo, 8 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 58m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "58",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8810028,
                lng: -38.4604083
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-50m2-venda-RS95000-id-2761179226/",
            valorVenda: "95000.00",
            valorOriginal: "95000.00",
            endereco: "Avenida Brasília, 279 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 50m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "50",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8969089,
                lng: -38.4523047
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-com-garagem-73m2-venda-RS93000-id-2758809127/",
            valorVenda: "93000.00",
            valorOriginal: "93000.00",
            endereco: "Rua São Cristóvão, 141 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 3 Quartos à Venda, 73m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "73",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.888957099999999,
                lng: -38.4464638
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS220000-id-2760188242/",
            valorVenda: "220000.00",
            valorOriginal: "220000.00",
            endereco: "Rua Santa Cecília, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890084,
                lng: -38.4520954
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-64m2-venda-RS95000-id-2739158631/",
            valorVenda: "95000.00",
            valorOriginal: "95000.00",
            endereco: "Rua Guaramirim, 805 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 64m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "64",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8953236,
                lng: -38.43747
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-49m2-venda-RS55000-id-2758032788/",
            valorVenda: "55000.00",
            valorOriginal: "55000.00",
            endereco: "Rua Santa Cecília, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 49m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "49",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890084,
                lng: -38.4520954
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-49m2-venda-RS320000-id-2624643529/",
            valorVenda: "320000.00",
            valorOriginal: "320000.00",
            endereco: "Rua Doutor Raimundo Guimarães - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 49m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "49",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8764931,
                lng: -38.4628368
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-eusebio-bairros-eusebio-com-garagem-68m2-venda-RS360000-id-2664181596/",
            valorVenda: "360000.00",
            valorOriginal: "360000.00",
            endereco: "Rua Luiz Pio Campina, 500 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 2 Quartos à Venda, 68m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "68",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8726227,
                lng: -38.4692525
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-70m2-venda-RS368291-id-2631172772/",
            valorVenda: "368291.00",
            valorOriginal: "368291.00",
            endereco: "Rua Industrial Amílcar Araújo, 900 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 70m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "70",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8825706,
                lng: -38.4688907
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-90m2-venda-RS359900-id-2684227343/",
            valorVenda: "359900.00",
            valorOriginal: "359900.00",
            endereco: "Rua Santa Maria, 300 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 90m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "90",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8473406,
                lng: -38.4538775
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-com-garagem-73m2-venda-RS399500-id-2631171973/",
            valorVenda: "399500.00",
            valorOriginal: "399500.00",
            endereco: "Rua Industrial Amílcar Araújo, 900 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 3 Quartos à Venda, 73m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "73",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8825706,
                lng: -38.4688907
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS59000-id-2750411201/",
            valorVenda: "59000.00",
            valorOriginal: "59000.00",
            endereco: "Rua Santa Cecília, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890084,
                lng: -38.4520954
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-2-quartos-eusebio-bairros-eusebio-com-garagem-83m2-venda-RS299000-id-2760278534/",
            valorVenda: "299000.00",
            valorOriginal: "299000.00",
            endereco: "Avenida Eusébio de Queiroz, 120 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 2 Quartos à Venda, 83m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "83",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8797677,
                lng: -38.4590877
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-50m2-venda-RS313000-id-2750287095/",
            valorVenda: "313000.00",
            valorOriginal: "313000.00",
            endereco: "Rua Doutor Raimundo Guimarães, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 50m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "50",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8782602,
                lng: -38.4715705
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-58m2-venda-RS210000-id-2583861599/",
            valorVenda: "NaN",
            valorOriginal: "NaN",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 58m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "58",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-93m2-venda-RS419900-id-2711048342/",
            valorVenda: "419900.00",
            valorOriginal: "419900.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 93m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "93",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS313000-id-2759977715/",
            valorVenda: "313000.00",
            valorOriginal: "313000.00",
            endereco: "Rua Doutor Raimundo Guimarães, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8782602,
                lng: -38.4715705
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-41m2-venda-RS208000-id-2752006941/",
            valorVenda: "208000.00",
            valorOriginal: "208000.00",
            endereco: "Rua Alameda Verde, 280 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 41m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "41",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8556747,
                lng: -38.47014660000001
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-86m2-venda-RS285000-id-2699657786/",
            valorVenda: "285000.00",
            valorOriginal: "285000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 86m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "86",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-50m2-venda-RS79990-id-2755544028/",
            valorVenda: "79990.00",
            valorOriginal: "79990.00",
            endereco: "Avenida Brasília, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 50m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "50",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8975813,
                lng: -38.45296769999999
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-49m2-venda-RS195000-id-2746789905/",
            valorVenda: "195000.00",
            valorOriginal: "195000.00",
            endereco: "Avenida Brasília, 278 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 49m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "49",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8974365,
                lng: -38.4520174
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS95000-id-2761437430/",
            valorVenda: "95000.00",
            valorOriginal: "95000.00",
            endereco: "Avenida Brasília, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8975813,
                lng: -38.45296769999999
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-42m2-venda-RS235000-id-2590106537/",
            valorVenda: "235000.00",
            valorOriginal: "235000.00",
            endereco: "Rua Olívia Campína da Silva, 193 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 42m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "42",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8684713,
                lng: -38.4702838
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-2-quartos-eusebio-bairros-eusebio-com-garagem-83m2-venda-RS299000-id-2760277847/",
            valorVenda: "299000.00",
            valorOriginal: "299000.00",
            endereco: "Avenida Eusébio de Queiroz, 120 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 2 Quartos à Venda, 83m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "83",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8797677,
                lng: -38.4590877
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS45000-id-2759372674/",
            valorVenda: "45000.00",
            valorOriginal: "45000.00",
            endereco: "Rua Santa Cecília, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890084,
                lng: -38.4520954
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-com-garagem-61m2-venda-RS317000-id-2711804973/",
            valorVenda: "317000.00",
            valorOriginal: "317000.00",
            endereco: "Avenida Maria Teixeira Joca - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 3 Quartos à Venda, 61m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "61",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8864247,
                lng: -38.4651071
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-49m2-venda-RS217000-id-2746971013/",
            valorVenda: "217000.00",
            valorOriginal: "217000.00",
            endereco: "Avenida Brasília, 279 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 49m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "49",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8969089,
                lng: -38.4523047
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS69000-id-2759379147/",
            valorVenda: "69000.00",
            valorOriginal: "69000.00",
            endereco: "Alameda Verde, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8557298,
                lng: -38.4716511
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-41m2-venda-RS215000-id-2717850234/",
            valorVenda: "215000.00",
            valorOriginal: "215000.00",
            endereco: "Alameda Verde, 280 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 41m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "41",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8556747,
                lng: -38.47014660000001
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-113m2-venda-RS450000-id-2627250027/",
            valorVenda: "450000.00",
            valorOriginal: "450000.00",
            endereco: "Rua Embaúba, 143 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/f1eb42b1b87faba093f6922b563615aa/foto-1-de-casa-com-3-quartos-a-venda-113m-em-eusebio-eusebio.webp",
            titulo: "Casa com 3 Quartos à Venda, 113m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "113",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8960336,
                lng: -38.455618
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-com-garagem-61m2-venda-RS335000-id-2587934003/",
            valorVenda: "335000.00",
            valorOriginal: "335000.00",
            endereco: "Rua Maria Teixeira Joca, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/12ec5536afe15f548ac75d667a537e2a/foto-1-de-apartamento-com-3-quartos-a-venda-61m-em-eusebio-eusebio.webp",
            titulo: "Apartamento com 3 Quartos à Venda, 61m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "61",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8849856,
                lng: -38.4589492
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-com-garagem-61m2-venda-RS320000-id-2746393861/",
            valorVenda: "320000.00",
            valorOriginal: "320000.00",
            endereco: "Rua Maria Teixeira Joca, 286 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/02771b2c7b8e6f86d9bad4244c2ca142/foto-1-de-apartamento-com-3-quartos-a-venda-61m-em-eusebio-eusebio.webp",
            titulo: "Apartamento com 3 Quartos à Venda, 61m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "61",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8852359,
                lng: -38.4605734
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-com-garagem-76m2-venda-RS310000-id-2730181117/",
            valorVenda: "310000.00",
            valorOriginal: "310000.00",
            endereco: "Rua Jacinta Pereira da Silva, 2 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/e513c6c51c7c61be0a600031420b3343/foto-1-de-apartamento-com-3-quartos-a-venda-76m-em-eusebio-eusebio.webp",
            titulo: "Apartamento com 3 Quartos à Venda, 76m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "76",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8738825,
                lng: -38.4489855
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-49m2-venda-RS198000-id-2746232515/",
            valorVenda: "198000.00",
            valorOriginal: "198000.00",
            endereco: "Avenida Brasília, 400 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 49m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "49",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8969282,
                lng: -38.4511311
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-86m2-venda-RS300000-id-2716793208/",
            valorVenda: "300000.00",
            valorOriginal: "300000.00",
            endereco: "Avenida Coronel Cícero Sá, 4500 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 86m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "86",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9275702,
                lng: -38.45727
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-2-quartos-eusebio-bairros-eusebio-com-garagem-83m2-venda-RS290000-id-2649163886/",
            valorVenda: "290000.00",
            valorOriginal: "290000.00",
            endereco: "Rua João Mozart, 1315 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 2 Quartos à Venda, 83m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "83",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9181475,
                lng: -38.4513705
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-180m2-venda-RS319999-id-2710359111/",
            valorVenda: "319999.00",
            valorOriginal: "319999.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 180m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "180",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-86m2-venda-RS285000-id-2700368289/",
            valorVenda: "285000.00",
            valorOriginal: "285000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 86m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "86",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-105m2-venda-RS440000-id-2751889197/",
            valorVenda: "440000.00",
            valorOriginal: "440000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 105m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "105",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-42m2-venda-RS210000-id-2651116659/",
            valorVenda: "210000.00",
            valorOriginal: "210000.00",
            endereco: "CE-040 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 42m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "42",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8612579,
                lng: -38.4703107
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/cobertura-3-quartos-eusebio-bairros-eusebio-com-garagem-148m2-venda-RS550000-id-2631465353/",
            valorVenda: "NaN",
            valorOriginal: "NaN",
            endereco: "Rua Bahia, 26 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 3 Quartos à Venda, 148m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "148",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8974518,
                lng: -38.4513404
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS80000-id-2693707677/",
            valorVenda: "80000.00",
            valorOriginal: "80000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS69000-id-2757586521/",
            valorVenda: "69000.00",
            valorOriginal: "69000.00",
            endereco: "Alameda Verde, 200 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8557264,
                lng: -38.4703361
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-45m2-venda-RS240000-id-2697587186/",
            valorVenda: "240000.00",
            valorOriginal: "240000.00",
            endereco: "Rua Maria Teixeira Joca - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 45m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "45",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8864247,
                lng: -38.4651071
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-41m2-venda-RS230000-id-2681540394/",
            valorVenda: "230000.00",
            valorOriginal: "230000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 41m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "41",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-2-quartos-eusebio-bairros-eusebio-com-garagem-70m2-venda-RS299000-id-2567323570/",
            valorVenda: "299000.00",
            valorOriginal: "299000.00",
            endereco: "Rua Bela Fonte, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 2 Quartos à Venda, 70m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "70",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8812731,
                lng: -38.4202019
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-50m2-venda-RS95000-id-2763488245/",
            valorVenda: "95000.00",
            valorOriginal: "95000.00",
            endereco: "Avenida Brasília, 200 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 50m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "50",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8971197,
                lng: -38.4528641
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-50m2-venda-RS70000-id-2762994241/",
            valorVenda: "70000.00",
            valorOriginal: "70000.00",
            endereco: "Avenida Brasília, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 50m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "50",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8975813,
                lng: -38.45296769999999
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS100000-id-2716188143/",
            valorVenda: "100000.00",
            valorOriginal: "100000.00",
            endereco: "Rua Alameda Verde, 56 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8558611,
                lng: -38.47128259999999
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS55000-id-2759030914/",
            valorVenda: "55000.00",
            valorOriginal: "55000.00",
            endereco: "Rua Santa Cecília, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890084,
                lng: -38.4520954
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-108m2-venda-RS450000-id-2705675238/",
            valorVenda: "450000.00",
            valorOriginal: "450000.00",
            endereco: "Rua Alisson Silva dos Santos, 264 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 108m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "108",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8813897,
                lng: -38.4743716
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS69000-id-2759244814/",
            valorVenda: "69000.00",
            valorOriginal: "69000.00",
            endereco: "Alameda Verde, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8557298,
                lng: -38.4716511
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-97m2-venda-RS404387-id-2537400048/",
            valorVenda: "404387.00",
            valorOriginal: "404387.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 97m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "97",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-123m2-venda-RS450000-id-2608829174/",
            valorVenda: "450000.00",
            valorOriginal: "450000.00",
            endereco: "Avenida Eusébio de Queiroz, 20 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 123m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "123",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8797677,
                lng: -38.4590877
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-99m2-venda-RS414781-id-2683630814/",
            valorVenda: "414781.00",
            valorOriginal: "414781.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 99m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "99",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-52m2-venda-RS339000-id-2625417170/",
            valorVenda: "339000.00",
            valorOriginal: "339000.00",
            endereco: "Rua Doutor Raimundo Guimarães - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 52m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "52",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8764931,
                lng: -38.4628368
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS200000-id-2642977871/",
            valorVenda: "200000.00",
            valorOriginal: "200000.00",
            endereco: "Rua Alameda Verde, 2 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8558611,
                lng: -38.47128259999999
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-81m2-venda-RS249000-id-2761327424/",
            valorVenda: "249000.00",
            valorOriginal: "249000.00",
            endereco: "Estrada do Fio, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 81m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "81",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.87252,
                lng: -38.431038
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-127m2-venda-RS506000-id-2755062612/",
            valorVenda: "506000.00",
            valorOriginal: "506000.00",
            endereco: "Rua Portal do Sol, 300 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 127m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "127",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8966292,
                lng: -38.450554
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS45000-id-2759669413/",
            valorVenda: "45000.00",
            valorOriginal: "45000.00",
            endereco: "Rua Santa Cecília, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890084,
                lng: -38.4520954
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS69990-id-2759703419/",
            valorVenda: "69990.00",
            valorOriginal: "69990.00",
            endereco: "Alameda Verde, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8557298,
                lng: -38.4716511
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-49m2-venda-RS318000-id-2692959905/",
            valorVenda: "318000.00",
            valorOriginal: "318000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 49m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "49",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-41m2-venda-RS270000-id-2719822840/",
            valorVenda: "270000.00",
            valorOriginal: "270000.00",
            endereco: "Alameda Verde, 280 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 41m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "41",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8556747,
                lng: -38.47014660000001
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-41m2-venda-RS230000-id-2681538608/",
            valorVenda: "230000.00",
            valorOriginal: "230000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 41m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "41",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-41m2-venda-RS185000-id-2747046506/",
            valorVenda: "185000.00",
            valorOriginal: "185000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 41m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "41",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-110m2-venda-RS520000-id-2691795123/",
            valorVenda: "520000.00",
            valorOriginal: "520000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/7207ea669106c133c82c0f70229c3a43/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-110m-em-eusebio-eusebio.webp",
            titulo: "Casa com 3 Quartos à Venda, 110m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "110",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-137m2-venda-RS753000-id-2567324853/",
            valorVenda: "753000.00",
            valorOriginal: "753000.00",
            endereco: "Rua Zildenia, 156 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/fda706251144d3602e33c571e9a5e9d8/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-137m-em-eusebio-eusebio.webp",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 137m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "137",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8692528,
                lng: -38.4660139
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-80m2-venda-RS339000-id-2624185356/",
            valorVenda: "339000.00",
            valorOriginal: "339000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/cc8d2e50fcf515f94244c840bccc1107/foto-1-de-casa-com-3-quartos-a-venda-80m-em-eusebio-eusebio.webp",
            titulo: "Casa com 3 Quartos à Venda, 80m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "80",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-42m2-venda-RS220000-id-2752645049/",
            valorVenda: "220000.00",
            valorOriginal: "220000.00",
            endereco: "Alameda Verde, 1000 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/b332c3c00729b9af5d6181d1c165468e/foto-1-de-apartamento-com-2-quartos-a-venda-42m-em-eusebio-eusebio.webp",
            titulo: "Apartamento com 2 Quartos à Venda, 42m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "42",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8558813,
                lng: -38.4718773
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-42m2-venda-RS185000-id-2761662117/",
            valorVenda: "185000.00",
            valorOriginal: "185000.00",
            endereco: "Rua Alameda Verde, 4 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 42m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "42",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8558611,
                lng: -38.47128259999999
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-97m2-venda-RS440000-id-2649229706/",
            valorVenda: "440000.00",
            valorOriginal: "440000.00",
            endereco: "Avenida dos Pardais, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 97m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "97",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8508199,
                lng: -38.4628345
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-158m2-venda-RS1028053-id-2675641597/",
            valorVenda: "1028053.00",
            valorOriginal: "1028053.00",
            endereco: "Avenida Eusébio de Queiroz, 5684 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 158m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "158",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.890218,
                lng: -38.441825
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS190000-id-2681470515/",
            valorVenda: "190000.00",
            valorOriginal: "190000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-93m2-venda-RS387900-id-2603949282/",
            valorVenda: "387900.00",
            valorOriginal: "387900.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 93m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "93",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS69990-id-2757156320/",
            valorVenda: "69990.00",
            valorOriginal: "69990.00",
            endereco: "Alameda Verde, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8557298,
                lng: -38.4716511
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-93m2-venda-RS359000-id-2715186246/",
            valorVenda: "359000.00",
            valorOriginal: "359000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 93m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "93",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-93m2-venda-RS439900-id-2624471280/",
            valorVenda: "439900.00",
            valorOriginal: "439900.00",
            endereco: "Rua dos Colibris, 159 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 93m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "93",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8491344,
                lng: -38.4634947
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-149m2-venda-RS570000-id-2560876131/",
            valorVenda: "570000.00",
            valorOriginal: "570000.00",
            endereco: "Rua Industrial Amílcar Araújo - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 149m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "149",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8821168,
                lng: -38.4658877
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-41m2-venda-RS208000-id-2752006295/",
            valorVenda: "208000.00",
            valorOriginal: "208000.00",
            endereco: "CE-040 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 41m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "41",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8612579,
                lng: -38.4703107
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-105m2-venda-RS550000-id-2641056294/",
            valorVenda: "550000.00",
            valorOriginal: "550000.00",
            endereco: "Rua Luiz Pio Campina, 115 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 105m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "105",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.873585299999999,
                lng: -38.4657345
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-86m2-venda-RS290000-id-2567322110/",
            valorVenda: "290000.00",
            valorOriginal: "290000.00",
            endereco: "Avenida Coronel Cícero de Sá, 4500 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 86m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "86",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9275702,
                lng: -38.45727
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-135m2-venda-RS200000-id-2716688210/",
            valorVenda: "200000.00",
            valorOriginal: "200000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 135m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "135",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS194000-id-2760198821/",
            valorVenda: "194000.00",
            valorOriginal: "194000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-51m2-venda-RS247116-id-2534011191/",
            valorVenda: "247116.00",
            valorOriginal: "247116.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 51m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "51",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-92m2-venda-RS369000-id-2579997295/",
            valorVenda: "369000.00",
            valorOriginal: "369000.00",
            endereco: "Rua das Juritis, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 92m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "92",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8502392,
                lng: -38.4619081
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-45m2-venda-RS325000-id-2741758271/",
            valorVenda: "325000.00",
            valorOriginal: "325000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 45m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "45",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-93m2-venda-RS349000-id-2698644504/",
            valorVenda: "349000.00",
            valorOriginal: "349000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 93m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "93",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-41m2-venda-RS100000-id-2715616604/",
            valorVenda: "100000.00",
            valorOriginal: "100000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 41m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "41",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-151m2-venda-RS995000-id-2738774605/",
            valorVenda: "995000.00",
            valorOriginal: "995000.00",
            endereco: "Rua Maria Teixeira Joca, 722 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 151m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "151",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8863331,
                lng: -38.4650013
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-90m2-venda-RS340000-id-2755027708/",
            valorVenda: "340000.00",
            valorOriginal: "340000.00",
            endereco: "Avenida Coronel Cícero Sá, 10000 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 90m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "90",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9309099,
                lng: -38.46016270000001
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-93m2-venda-RS335000-id-2644857079/",
            valorVenda: "335000.00",
            valorOriginal: "335000.00",
            endereco: "Rua Beira Rio, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 93m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "93",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9096729,
                lng: -38.4515608
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-150m2-venda-RS300000-id-2573758523/",
            valorVenda: "300000.00",
            valorOriginal: "300000.00",
            endereco: "Rua Assis Mangaba, 57 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 150m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "150",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.880563,
                lng: -38.418743
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-128m2-venda-RS480000-id-2657615469/",
            valorVenda: "480000.00",
            valorOriginal: "480000.00",
            endereco: "Rua Cristal, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 128m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "128",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9090338,
                lng: -38.4548131
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS69000-id-2757024118/",
            valorVenda: "69000.00",
            valorOriginal: "69000.00",
            endereco: "Alameda Verde, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8557298,
                lng: -38.4716511
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-105m2-venda-RS420000-id-2730785422/",
            valorVenda: "NaN",
            valorOriginal: "NaN",
            endereco: "Rua Francisco Raimundo Monteiro Cavalcante, 200 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 105m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "105",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8534329,
                lng: -38.4471654
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-49m2-venda-RS364900-id-2624642025/",
            valorVenda: "364900.00",
            valorOriginal: "364900.00",
            endereco: "Rua Doutor Raimundo Guimarães - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 49m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "49",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8764931,
                lng: -38.4628368
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS69900-id-2760834128/",
            valorVenda: "69900.00",
            valorOriginal: "69900.00",
            endereco: "Alameda Verde, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8557298,
                lng: -38.4716511
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-144m2-venda-RS700000-id-2663977803/",
            valorVenda: "700000.00",
            valorOriginal: "700000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 144m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "144",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-120m2-venda-RS590000-id-2691778602/",
            valorVenda: "590000.00",
            valorOriginal: "590000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 120m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "120",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-123m2-venda-RS445000-id-2673647383/",
            valorVenda: "NaN",
            valorOriginal: "NaN",
            endereco: "Rua Aurino Paulino dos Santos, 94 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 123m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "123",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8597555,
                lng: -38.4639821
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-com-garagem-107m2-venda-RS614846-id-2736030051/",
            valorVenda: "614846.00",
            valorOriginal: "614846.00",
            endereco: "Avenida Brasília - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 3 Quartos à Venda, 107m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "107",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8965566,
                lng: -38.4496913
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-101m2-venda-RS579000-id-2648595654/",
            valorVenda: "579000.00",
            valorOriginal: "579000.00",
            endereco: "Rua José Amora Sá, 385 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/3d1f12451bca0a5d5937d1de852b5d6f/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-101m-em-eusebio-eusebio.webp",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 101m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "101",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8846346,
                lng: -38.4616777
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-118m2-venda-RS530000-id-2664977921/",
            valorVenda: "530000.00",
            valorOriginal: "530000.00",
            endereco: "Rua Fernando de Souza Guedelha, 4655 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/b324175498aa9f8366938de8ec56ca1d/foto-1-de-casa-de-condominio-com-4-quartos-a-venda-118m-em-eusebio-eusebio.webp",
            titulo: "Casa com 4 Quartos à Venda, 118m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "118",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.870088,
                lng: -38.433652
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS45000-id-2755528000/",
            valorVenda: "45000.00",
            valorOriginal: "45000.00",
            endereco: "Rua Santa Cecília, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/5cdee60ee541afe9a69b837f155d0b7f/foto-1-de-apartamento-com-2-quartos-a-venda-44m-em-eusebio-eusebio.webp",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890084,
                lng: -38.4520954
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-com-garagem-67m2-venda-RS245000-id-2753071697/",
            valorVenda: "245000.00",
            valorOriginal: "245000.00",
            endereco: "Rua Blumenau, 5557 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/8e995629b1499e0b4b205298b3bdad00/foto-1-de-apartamento-com-3-quartos-a-venda-67m-em-eusebio-eusebio.webp",
            titulo: "Apartamento com 3 Quartos à Venda, 67m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "67",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8968414,
                lng: -38.4452595
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-178m2-venda-RS599000-id-2589765589/",
            valorVenda: "599000.00",
            valorOriginal: "599000.00",
            endereco: "Rua Nossa Senhora de Nazaré, 790 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 178m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "178",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8796436,
                lng: -38.4689022
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-110m2-venda-RS489000-id-2602952291/",
            valorVenda: "489000.00",
            valorOriginal: "489000.00",
            endereco: "Avenida Brasília, 51 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 110m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "110",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8965566,
                lng: -38.4496913
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-120m2-venda-RS590000-id-2715190441/",
            valorVenda: "590000.00",
            valorOriginal: "590000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 120m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "120",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS275000-id-2710957339/",
            valorVenda: "275000.00",
            valorOriginal: "275000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-41m2-venda-RS230000-id-2690172467/",
            valorVenda: "230000.00",
            valorOriginal: "230000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 41m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "41",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-2-quartos-eusebio-bairros-eusebio-com-garagem-71m2-venda-RS320000-id-2734546552/",
            valorVenda: "320000.00",
            valorOriginal: "320000.00",
            endereco: "Rua Nossa Senhora de Fátima - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 2 Quartos à Venda, 71m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "71",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8632654,
                lng: -38.4478925
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-50m2-venda-RS69900-id-2762982726/",
            valorVenda: "69900.00",
            valorOriginal: "69900.00",
            endereco: "Rua Santa Cecília, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 50m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "50",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890084,
                lng: -38.4520954
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-100m2-venda-RS450000-id-2711047353/",
            valorVenda: "450000.00",
            valorOriginal: "450000.00",
            endereco: "Rua Alisson Silva dos Santos, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 100m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "100",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8812454,
                lng: -38.472091
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-133m2-venda-RS575000-id-2761011338/",
            valorVenda: "575000.00",
            valorOriginal: "575000.00",
            endereco: "Rua Antônia Sá e Silva, 788 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 133m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "133",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8755243,
                lng: -38.4708009
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-49m2-venda-RS346000-id-2718777602/",
            valorVenda: "346000.00",
            valorOriginal: "346000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 49m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "49",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-95m2-venda-RS450000-id-2745170702/",
            valorVenda: "450000.00",
            valorOriginal: "450000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 95m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "95",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-128m2-venda-RS460000-id-2710849155/",
            valorVenda: "460000.00",
            valorOriginal: "460000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 128m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "128",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-2-quartos-eusebio-bairros-eusebio-com-garagem-130m2-venda-RS900000-id-2608889694/",
            valorVenda: "900000.00",
            valorOriginal: "900000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 2 Quartos à Venda, 130m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "130",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-com-garagem-133m2-venda-RS520000-id-2716213227/",
            valorVenda: "520000.00",
            valorOriginal: "520000.00",
            endereco: "Rua Fernando de Souza Guedelha, 170 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 3 Quartos à Venda, 133m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "133",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8693515,
                lng: -38.4335744
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-41m2-venda-RS208000-id-2756586946/",
            valorVenda: "208000.00",
            valorOriginal: "208000.00",
            endereco: "Alameda Verde, 2 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 41m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "41",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8558611,
                lng: -38.47128259999999
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-215m2-venda-RS1700000-id-2761396345/",
            valorVenda: "1700000.00",
            valorOriginal: "1700000.00",
            endereco: "Rua Guaramirim, 399 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 215m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "215",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.895529,
                lng: -38.441027
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-193m2-venda-RS970000-id-2657457415/",
            valorVenda: "970000.00",
            valorOriginal: "970000.00",
            endereco: "Avenida Eusébio de Queiroz - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 193m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "193",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8797677,
                lng: -38.4590877
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-180m2-venda-RS1100000-id-2736824660/",
            valorVenda: "1100000.00",
            valorOriginal: "1100000.00",
            endereco: "Rua Santa Rita de Cassia, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 180m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "180",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9045656,
                lng: -38.4604235
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-42m2-venda-RS270000-id-2743714620/",
            valorVenda: "270000.00",
            valorOriginal: "270000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 42m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "42",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-com-garagem-90m2-venda-RS790000-id-2680527494/",
            valorVenda: "790000.00",
            valorOriginal: "790000.00",
            endereco: "Rua Judite Martins, 300 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 3 Quartos à Venda, 90m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "90",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9219019,
                lng: -38.453213
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-com-garagem-70m2-venda-RS382000-id-2632561719/",
            valorVenda: "382000.00",
            valorOriginal: "382000.00",
            endereco: "Rua Industrial Amílcar Araújo, 900 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 3 Quartos à Venda, 70m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "70",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8825706,
                lng: -38.4688907
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-94m2-venda-RS655000-id-2608852661/",
            valorVenda: "655000.00",
            valorOriginal: "655000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 94m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "94",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-2-quartos-eusebio-bairros-eusebio-com-garagem-130m2-venda-RS900000-id-2594549654/",
            valorVenda: "900000.00",
            valorOriginal: "900000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 2 Quartos à Venda, 130m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "130",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-144m2-venda-RS700000-id-2721636864/",
            valorVenda: "700000.00",
            valorOriginal: "700000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 144m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "144",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-120m2-venda-RS590000-id-2697765325/",
            valorVenda: "590000.00",
            valorOriginal: "590000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 120m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "120",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-105m2-venda-RS540000-id-2693173057/",
            valorVenda: "540000.00",
            valorOriginal: "540000.00",
            endereco: "Rua Blumenau, 365 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 105m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "105",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8935304,
                lng: -38.4454031
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-44m2-venda-RS105000-id-2709514622/",
            valorVenda: "105000.00",
            valorOriginal: "105000.00",
            endereco: "Alameda Verde, 280 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8556747,
                lng: -38.47014660000001
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-105m2-venda-RS584000-id-2580038789/",
            valorVenda: "584000.00",
            valorOriginal: "584000.00",
            endereco: "Avenida Ayrton Senna, 22 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 105m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "105",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8892049,
                lng: -38.4550192
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-90m2-venda-RS350000-id-2758542136/",
            valorVenda: "350000.00",
            valorOriginal: "350000.00",
            endereco: "Rua Eduardo Casé, 1000 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 90m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "90",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.857115,
                lng: -38.4463345
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-149m2-venda-RS570000-id-2561051802/",
            valorVenda: "570000.00",
            valorOriginal: "570000.00",
            endereco: "Rua Industrial Amílcar Araújo, 836 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 149m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "149",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8825971,
                lng: -38.4684785
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-132m2-venda-RS520000-id-2582076359/",
            valorVenda: "520000.00",
            valorOriginal: "520000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 132m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "132",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS55000-id-2759374956/",
            valorVenda: "55000.00",
            valorOriginal: "55000.00",
            endereco: "Rua Santa Cecília, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890084,
                lng: -38.4520954
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-210m2-venda-RS1474429-id-2756589722/",
            valorVenda: "1474429.00",
            valorOriginal: "1474429.00",
            endereco: "Avenida Eusébio de Queiroz, 5684 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/2baed485b3b43118ad8eb13e759390b3/foto-1-de-casa-de-condominio-com-4-quartos-a-venda-210m-em-eusebio-eusebio.webp",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 210m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "210",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.890218,
                lng: -38.441825
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-2-quartos-eusebio-bairros-eusebio-com-garagem-71m2-venda-RS295000-id-2702330486/",
            valorVenda: "295000.00",
            valorOriginal: "295000.00",
            endereco: "Rua Nossa Senhora de Fátima, 42 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/0b5fac6fabde016cfa5b7894c482c4e7/foto-1-de-casa-com-2-quartos-a-venda-71m-em-eusebio-eusebio.webp",
            titulo: "Casa com 2 Quartos à Venda, 71m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "71",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8635909,
                lng: -38.4475781
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-41m2-venda-RS180000-id-2696303477/",
            valorVenda: "180000.00",
            valorOriginal: "180000.00",
            endereco: "CE-040 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/12ad71979cff82e57e73edd42786ef48/foto-1-de-apartamento-com-2-quartos-a-venda-41m-em-eusebio-eusebio.webp",
            titulo: "Apartamento com 2 Quartos à Venda, 41m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "41",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8612579,
                lng: -38.4703107
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-com-garagem-90m2-venda-RS790000-id-2715422315/",
            valorVenda: "790000.00",
            valorOriginal: "790000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/52acf955760b2b8e02896a77697de461/foto-1-de-apartamento-com-3-quartos-a-venda-90m-em-eusebio-eusebio.webp",
            titulo: "Apartamento com 3 Quartos à Venda, 90m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "90",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-134m2-venda-RS599900-id-2710850601/",
            valorVenda: "599900.00",
            valorOriginal: "599900.00",
            endereco: "Avenida Eusébio de Queiroz, 01 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 134m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "134",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8902629,
                lng: -38.4474496
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-105m2-venda-RS584000-id-2679938395/",
            valorVenda: "584000.00",
            valorOriginal: "584000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 105m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "105",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-215m2-venda-RS940000-id-2635613790/",
            valorVenda: "940000.00",
            valorOriginal: "940000.00",
            endereco: "Avenida José Moraes de Almeida, 844 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 215m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "215",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.860596199999999,
                lng: -38.4646596
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-com-garagem-90m2-venda-RS1219347-id-2713558730/",
            valorVenda: "1219347.00",
            valorOriginal: "1219347.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 3 Quartos à Venda, 90m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "90",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-com-garagem-58m2-venda-RS372300-id-2713558721/",
            valorVenda: "372300.00",
            valorOriginal: "372300.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 3 Quartos à Venda, 58m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "58",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-105m2-venda-RS450000-id-2558550121/",
            valorVenda: "450000.00",
            valorOriginal: "450000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 105m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "105",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-40m2-venda-RS100000-id-2719535241/",
            valorVenda: "100000.00",
            valorOriginal: "100000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 40m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "40",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-178m2-venda-RS550000-id-2561028973/",
            valorVenda: "550000.00",
            valorOriginal: "550000.00",
            endereco: "Rua Acilon Gonçalves Pinto, 500 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 178m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "178",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8752366,
                lng: -38.4591154
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-105m2-venda-RS585000-id-2624461678/",
            valorVenda: "585000.00",
            valorOriginal: "585000.00",
            endereco: "Rua Blumenau, 365 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 105m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "105",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8935304,
                lng: -38.4454031
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-41m2-venda-RS230000-id-2690168553/",
            valorVenda: "230000.00",
            valorOriginal: "230000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 41m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "41",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-93m2-venda-RS420000-id-2686496524/",
            valorVenda: "420000.00",
            valorOriginal: "420000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 93m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "93",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-93m2-venda-RS420000-id-2686496117/",
            valorVenda: "420000.00",
            valorOriginal: "420000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 93m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "93",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-112m2-venda-RS449000-id-2758576509/",
            valorVenda: "449000.00",
            valorOriginal: "449000.00",
            endereco: "Estrada do Fio, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 112m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "112",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.87252,
                lng: -38.431038
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-170m2-venda-RS1290000-id-2703249237/",
            valorVenda: "1290000.00",
            valorOriginal: "1290000.00",
            endereco: "Rua Francelino Tavares de Abreu, 10 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 170m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "170",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8733076,
                lng: -38.4820363
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-206m2-venda-RS1055000-id-2754393412/",
            valorVenda: "1055000.00",
            valorOriginal: "1055000.00",
            endereco: "Rua Vicente Leitão, 233 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 206m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "206",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.881973899999999,
                lng: -38.4575752
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-183m2-venda-RS1284953-id-2756587861/",
            valorVenda: "1284953.00",
            valorOriginal: "1284953.00",
            endereco: "Avenida Eusébio de Queiroz, 5684 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 183m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "183",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.890218,
                lng: -38.441825
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-93m2-venda-RS414900-id-2580037523/",
            valorVenda: "414900.00",
            valorOriginal: "414900.00",
            endereco: "Rua dos Colibris, 9 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 93m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "93",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8502649,
                lng: -38.464204
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS69990-id-2757024723/",
            valorVenda: "69990.00",
            valorOriginal: "69990.00",
            endereco: "Alameda Verde, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8557298,
                lng: -38.4716511
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-190m2-venda-RS1333437-id-2756589816/",
            valorVenda: "1333437.00",
            valorOriginal: "1333437.00",
            endereco: "Avenida Eusébio de Queiroz, 5684 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 190m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "190",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.890218,
                lng: -38.441825
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-215m2-venda-RS1399000-id-2756587860/",
            valorVenda: "1399000.00",
            valorOriginal: "1399000.00",
            endereco: "Avenida Eusébio de Queiroz, 5684 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 215m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "215",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.890218,
                lng: -38.441825
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-41m2-venda-RS230000-id-2690164958/",
            valorVenda: "230000.00",
            valorOriginal: "230000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 41m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "41",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-90m2-venda-RS380000-id-2750761246/",
            valorVenda: "380000.00",
            valorOriginal: "380000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 90m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "90",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-151m2-venda-RS965000-id-2634258626/",
            valorVenda: "965000.00",
            valorOriginal: "965000.00",
            endereco: "Rua Maria Teixeira Joca, 722 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 151m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "151",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8863331,
                lng: -38.4650013
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-93m2-venda-RS420000-id-2686277532/",
            valorVenda: "420000.00",
            valorOriginal: "420000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 93m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "93",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-150m2-venda-RS800000-id-2625756360/",
            valorVenda: "800000.00",
            valorOriginal: "800000.00",
            endereco: "Rua Vicente Leitão, 233 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 150m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "150",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.881973899999999,
                lng: -38.4575752
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-105m2-venda-RS730000-id-2722485776/",
            valorVenda: "730000.00",
            valorOriginal: "730000.00",
            endereco: "Avenida Santa Cecília, 2700 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/fit-in/142x80/named.images.sp/95a55153f3df558a52315648e8ce995e/{description}.webp",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 105m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "105",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8699996,
                lng: -38.4466975
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-com-garagem-107m2-venda-RS512703-id-2700924206/",
            valorVenda: "512703.00",
            valorOriginal: "512703.00",
            endereco: "Avenida Brasília, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 3 Quartos à Venda, 107m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "107",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8965566,
                lng: -38.4496913
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-130m2-venda-RS650000-id-2758587135/",
            valorVenda: "650000.00",
            valorOriginal: "650000.00",
            endereco: "Rua Raimunda Soares Silva, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 130m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "130",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8981724,
                lng: -38.4411675
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-140m2-venda-RS500000-id-2634725159/",
            valorVenda: "500000.00",
            valorOriginal: "500000.00",
            endereco: "Rua Santa Rita de Cassia, 95 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 140m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "140",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9043858,
                lng: -38.45610629999999
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-101m2-venda-RS385000-id-2640888572/",
            valorVenda: "385000.00",
            valorOriginal: "385000.00",
            endereco: "Rua 12, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 101m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "101",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8298183,
                lng: -38.4330148
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-244m2-venda-RS1399000-id-2756589818/",
            valorVenda: "1399000.00",
            valorOriginal: "1399000.00",
            endereco: "Avenida Eusébio de Queiroz, 5684 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 244m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "244",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.890218,
                lng: -38.441825
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-215m2-venda-RS650000-id-2572659296/",
            valorVenda: "650000.00",
            valorOriginal: "650000.00",
            endereco: "Rua Padre Cícero, 12 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 215m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "215",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8932212,
                lng: -38.4417821
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-94m2-venda-RS419900-id-2568644213/",
            valorVenda: "419900.00",
            valorOriginal: "419900.00",
            endereco: "Rua das Andorinhas, 82 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/60cbba9672d8648b588264a288e73f3c/foto-1-de-casa-com-3-quartos-a-venda-94m-em-eusebio-eusebio.webp",
            titulo: "Casa com 3 Quartos à Venda, 94m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "94",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8499667,
                lng: -38.4636407
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-140m2-venda-RS620000-id-2707533853/",
            valorVenda: "620000.00",
            valorOriginal: "620000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/69f9942445ddcb2cf0bd4a46b7c59dde/foto-1-de-casa-com-4-quartos-a-venda-140m-em-eusebio-eusebio.webp",
            titulo: "Casa com 4 Quartos à Venda, 140m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "140",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-118m2-venda-RS759000-id-2629244986/",
            valorVenda: "759000.00",
            valorOriginal: "759000.00",
            endereco: "Avenida Francisco Ferreira da Silva, 544 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/09ae0f3e87f7e6bc2f8271e8ac0d469f/foto-1-de-casa-de-condominio-com-4-quartos-a-venda-118m-em-eusebio-eusebio.webp",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 118m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "118",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8590652,
                lng: -38.4404967
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-228m2-venda-RS780000-id-2609117600/",
            valorVenda: "780000.00",
            valorOriginal: "780000.00",
            endereco: "Avenida Litorânea, 17 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/1764136ed8d80487e90bde44ce77dcfa/foto-1-de-casa-com-4-quartos-a-venda-228m-em-eusebio-eusebio.webp",
            titulo: "Casa com 4 Quartos à Venda, 228m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "228",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8236144,
                lng: -38.4203955
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-250m2-venda-RS1490000-id-2713779301/",
            valorVenda: "1490000.00",
            valorOriginal: "1490000.00",
            endereco: "Rua Zildenia, 310 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 250m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "250",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8769185,
                lng: -38.4606349
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-168m2-venda-RS700000-id-2542217204/",
            valorVenda: "700000.00",
            valorOriginal: "700000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 168m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "168",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-130m2-venda-RS599000-id-2758620335/",
            valorVenda: "599000.00",
            valorOriginal: "599000.00",
            endereco: "Avenida Francisco Ferreira da Silva, 544 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 130m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "130",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8590652,
                lng: -38.4404967
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-133m2-venda-RS611000-id-2737093654/",
            valorVenda: "611000.00",
            valorOriginal: "611000.00",
            endereco: "Rua José Amora Sá, 300 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 133m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "133",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8847407,
                lng: -38.4637108
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-2-quartos-eusebio-bairros-eusebio-com-garagem-66m2-venda-RS300000-id-2746392841/",
            valorVenda: "300000.00",
            valorOriginal: "300000.00",
            endereco: "Rua Manoel Felipe da Silva, 700 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 2 Quartos à Venda, 66m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "66",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8621089,
                lng: -38.4257142
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS70000-id-2758035545/",
            valorVenda: "70000.00",
            valorOriginal: "70000.00",
            endereco: "Alameda Verde, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8557298,
                lng: -38.4716511
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-139m2-venda-RS625000-id-2642821410/",
            valorVenda: "625000.00",
            valorOriginal: "625000.00",
            endereco: "Estrada do Fio, 2000 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 139m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "139",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8509237,
                lng: -38.46000920000001
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-207m2-venda-RS924679-id-2630312257/",
            valorVenda: "924679.00",
            valorOriginal: "924679.00",
            endereco: "Rua Antônia Sá e Silva, 911 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 207m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "207",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8747877,
                lng: -38.4732484
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-173m2-venda-RS1040000-id-2757663746/",
            valorVenda: "1040000.00",
            valorOriginal: "1040000.00",
            endereco: "Rua Sorocaba, 701 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 173m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "173",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.815395,
                lng: -38.4366605
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-75m2-venda-RS279000-id-2747815040/",
            valorVenda: "279000.00",
            valorOriginal: "279000.00",
            endereco: "Rua Manoel Felipe da Silva, 651 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 75m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "75",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8624008,
                lng: -38.4259706
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-144m2-venda-RS499000-id-2755070210/",
            valorVenda: "499000.00",
            valorOriginal: "499000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 144m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "144",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-93m2-venda-RS420000-id-2591232326/",
            valorVenda: "420000.00",
            valorOriginal: "420000.00",
            endereco: "Rua Mirian de Abreu, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 93m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "93",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.900958,
                lng: -38.4532682
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-104m2-venda-RS460000-id-2548538255/",
            valorVenda: "460000.00",
            valorOriginal: "460000.00",
            endereco: "Rua Embaúba, 159 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 104m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "104",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8962004,
                lng: -38.455521
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-90m2-venda-RS379000-id-2649175489/",
            valorVenda: "379000.00",
            valorOriginal: "379000.00",
            endereco: "Rua Santa Maria, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 90m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "90",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8494447,
                lng: -38.4479313
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-103m2-venda-RS544100-id-2652982447/",
            valorVenda: "544100.00",
            valorOriginal: "544100.00",
            endereco: "Rua Santa Cecília, 2700 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 103m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "103",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8699996,
                lng: -38.4466975
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-149m2-venda-RS550000-id-2530898110/",
            valorVenda: "NaN",
            valorOriginal: "NaN",
            endereco: "Avenida Eusébio de Queiroz, 2752 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 149m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "149",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8776348,
                lng: -38.4624879
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-230m2-venda-RS1350000-id-2708950347/",
            valorVenda: "1350000.00",
            valorOriginal: "1350000.00",
            endereco: "Rua Santa Rita de Cassia, 7999 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 230m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "230",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9048186,
                lng: -38.456315
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-com-garagem-90m2-venda-RS789999-id-2710498031/",
            valorVenda: "789999.00",
            valorOriginal: "789999.00",
            endereco: "Rua Tamatanduba, 80 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 3 Quartos à Venda, 90m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "90",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8734629,
                lng: -38.46521000000001
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-191m2-venda-RS1180000-id-2756252052/",
            valorVenda: "1180000.00",
            valorOriginal: "1180000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 191m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "191",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-130m2-venda-RS499000-id-2750017895/",
            valorVenda: "499000.00",
            valorOriginal: "499000.00",
            endereco: "Rua Menino Jesus, 352 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 130m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "130",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9077155,
                lng: -38.4517308
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-105m2-venda-RS440000-id-2754971366/",
            valorVenda: "440000.00",
            valorOriginal: "440000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 105m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "105",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-78m2-venda-RS562629-id-2641170706/",
            valorVenda: "562629.00",
            valorOriginal: "562629.00",
            endereco: "Avenida Bernardo Manuel - Eusebio, Fortaleza - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 78m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "78",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8105975,
                lng: -38.5550968
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-144m2-venda-RS759900-id-2627582659/",
            valorVenda: "759900.00",
            valorOriginal: "759900.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 144m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "144",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-41m2-venda-RS230000-id-2690165757/",
            valorVenda: "230000.00",
            valorOriginal: "230000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 41m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "41",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-40m2-venda-RS100000-id-2719531887/",
            valorVenda: "100000.00",
            valorOriginal: "100000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 40m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "40",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS55000-id-2758166629/",
            valorVenda: "55000.00",
            valorOriginal: "55000.00",
            endereco: "Rua Santa Cecília, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890084,
                lng: -38.4520954
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-90m2-venda-RS799000-id-2576927435/",
            valorVenda: "799000.00",
            valorOriginal: "799000.00",
            endereco: "Rua Ezequiel Campinas, 737 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 3 Quartos à Venda, 90m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "90",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.870262499999999,
                lng: -38.4721252
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS69990-id-2759957175/",
            valorVenda: "69990.00",
            valorOriginal: "69990.00",
            endereco: "Alameda Verde, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8557298,
                lng: -38.4716511
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS69900-id-2760249359/",
            valorVenda: "69900.00",
            valorOriginal: "69900.00",
            endereco: "Alameda Verde, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8557298,
                lng: -38.4716511
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-170m2-venda-RS848000-id-2506035116/",
            valorVenda: "848000.00",
            valorOriginal: "848000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 170m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "170",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-112m2-venda-RS449900-id-2657614912/",
            valorVenda: "449900.00",
            valorOriginal: "449900.00",
            endereco: "Rua dos Curiós, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 112m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "112",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8555747,
                lng: -38.4545837
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-41m2-venda-RS230000-id-2721641365/",
            valorVenda: "230000.00",
            valorOriginal: "230000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 41m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "41",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-180m2-venda-RS1100000-id-2719568063/",
            valorVenda: "1100000.00",
            valorOriginal: "1100000.00",
            endereco: "Rua Santa Rita de Cassia, 67 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/6681187602ad3f2443581b155016191b/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-180m-em-eusebio-eusebio.webp",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 180m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "180",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9048186,
                lng: -38.456315
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-132m2-venda-RS736278-id-2580524111/",
            valorVenda: "736278.00",
            valorOriginal: "736278.00",
            endereco: "Rua Pires Façanha, 555 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/dd569bb21793832eeaa7af36cadd5c44/foto-1-de-casa-com-4-quartos-a-venda-132m-em-eusebio-eusebio.webp",
            titulo: "Casa com 4 Quartos à Venda, 132m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "132",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.886298199999999,
                lng: -38.43521339999999
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-102m2-venda-RS450000-id-2715201738/",
            valorVenda: "450000.00",
            valorOriginal: "450000.00",
            endereco: "Rua Pires Façanha, 9 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/72736a8263a8920df3da16656d8e3da8/foto-1-de-casa-com-3-quartos-a-venda-102m-em-eusebio-eusebio.webp",
            titulo: "Casa com 3 Quartos à Venda, 102m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "102",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8892125,
                lng: -38.4371948
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-com-garagem-70m2-venda-RS338500-id-2625254518/",
            valorVenda: "338500.00",
            valorOriginal: "338500.00",
            endereco: "Rua Industrial Amílcar Araújo, 3 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/904d2acca4bd843e1e1b7ec7b024909d/foto-1-de-apartamento-com-3-quartos-a-venda-70m-em-eusebio-eusebio.webp",
            titulo: "Apartamento com 3 Quartos à Venda, 70m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "70",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8809893,
                lng: -38.460369
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-206m2-venda-RS1350000-id-2568511208/",
            valorVenda: "1350000.00",
            valorOriginal: "1350000.00",
            endereco: "Rua Vicente Leitão, 233 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 206m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "206",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.881973899999999,
                lng: -38.4575752
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-112m2-venda-RS579000-id-2755073395/",
            valorVenda: "579000.00",
            valorOriginal: "579000.00",
            endereco: "Rua Liberdade, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 112m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "112",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9303863,
                lng: -38.5042672
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-105m2-venda-RS440000-id-2754506357/",
            valorVenda: "440000.00",
            valorOriginal: "440000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 105m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "105",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-134m2-venda-RS629900-id-2711599103/",
            valorVenda: "629900.00",
            valorOriginal: "629900.00",
            endereco: "Rua Santa Cecília, 7 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 134m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "134",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9065108,
                lng: -38.453186
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-4-quartos-eusebio-bairros-eusebio-com-garagem-350m2-venda-RS2700000-id-2581062337/",
            valorVenda: "2700000.00",
            valorOriginal: "2700000.00",
            endereco: "CE-040, 700 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 4 Quartos à Venda, 350m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "350",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.861821,
                lng: -38.4734493
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-150m2-venda-RS599000-id-2758561253/",
            valorVenda: "599000.00",
            valorOriginal: "599000.00",
            endereco: "Rua Pacajus, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 150m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "150",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8988878,
                lng: -38.4581558
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-91m2-venda-RS399000-id-2652394503/",
            valorVenda: "399000.00",
            valorOriginal: "399000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 91m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "91",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-2-quartos-eusebio-bairros-eusebio-com-garagem-68m2-venda-RS290000-id-2652394981/",
            valorVenda: "290000.00",
            valorOriginal: "290000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 2 Quartos à Venda, 68m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "68",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-191m2-venda-RS819900-id-2532244670/",
            valorVenda: "NaN",
            valorOriginal: "NaN",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 191m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "191",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-120m2-venda-RS590000-id-2715425809/",
            valorVenda: "590000.00",
            valorOriginal: "590000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 120m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "120",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-122m2-venda-RS489000-id-2763483424/",
            valorVenda: "489000.00",
            valorOriginal: "489000.00",
            endereco: "Avenida José Morais de Almeida, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 122m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "122",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.861284,
                lng: -38.46217439999999
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-243m2-venda-RS1490000-id-2643142761/",
            valorVenda: "1490000.00",
            valorOriginal: "1490000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 243m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "243",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-101m2-venda-RS420000-id-2752515799/",
            valorVenda: "NaN",
            valorOriginal: "NaN",
            endereco: "Rua Manoel Gomes, 80 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 101m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "101",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8713063,
                lng: -38.4632243
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-5-quartos-eusebio-bairros-eusebio-com-garagem-383m2-venda-RS3000000-id-2658327978/",
            valorVenda: "3000000.00",
            valorOriginal: "3000000.00",
            endereco: "Rua Alemanha - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 5 Quartos à Venda, 383m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "383",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8843569,
                lng: -38.4419407
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-42m2-venda-RS270000-id-2742025201/",
            valorVenda: "270000.00",
            valorOriginal: "270000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 42m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "42",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-133m2-venda-RS520000-id-2717933849/",
            valorVenda: "520000.00",
            valorOriginal: "520000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 133m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "133",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-118m2-venda-RS639148-id-2645061557/",
            valorVenda: "639148.00",
            valorOriginal: "639148.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 118m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "118",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-164m2-venda-RS320000-id-2521503470/",
            valorVenda: "320000.00",
            valorOriginal: "320000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 164m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "164",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-100m2-venda-RS450000-id-2698509174/",
            valorVenda: "450000.00",
            valorOriginal: "450000.00",
            endereco: "Gravatá - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 100m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "100",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8970662,
                lng: -38.4567148
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-com-garagem-84m2-venda-RS501748-id-2705366197/",
            valorVenda: "501748.00",
            valorOriginal: "501748.00",
            endereco: "Rua Nossa Senhora de Nazaré - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 3 Quartos à Venda, 84m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "84",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8790918,
                lng: -38.4661132
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-122m2-venda-RS445000-id-2642996856/",
            valorVenda: "NaN",
            valorOriginal: "NaN",
            endereco: "Rua Aurino Paulino dos Santos, 84 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 122m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "122",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8598052,
                lng: -38.4638319
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-120m2-venda-RS492000-id-2580011795/",
            valorVenda: "492000.00",
            valorOriginal: "492000.00",
            endereco: "Avenida Eduardo Sá, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 120m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "120",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8905588,
                lng: -38.4539361
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-151m2-venda-RS650000-id-2710849156/",
            valorVenda: "650000.00",
            valorOriginal: "650000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 151m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "151",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-41m2-venda-RS230000-id-2715284219/",
            valorVenda: "230000.00",
            valorOriginal: "230000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 41m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "41",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-132m2-venda-RS804661-id-2679830235/",
            valorVenda: "804661.00",
            valorOriginal: "804661.00",
            endereco: "Rua Carlos Albuquerque De Lima, 209 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 132m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "132",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8788166,
                lng: -38.4449597
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-130m2-venda-RS850000-id-2695061810/",
            valorVenda: "850000.00",
            valorOriginal: "850000.00",
            endereco: "Rua Santa Cecília, 300 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 130m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "130",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8872058,
                lng: -38.4512583
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-136m2-venda-RS550000-id-2755071600/",
            valorVenda: "550000.00",
            valorOriginal: "550000.00",
            endereco: "Avenida Santa Cecília, 1000 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 136m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "136",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8806894,
                lng: -38.4504198
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-178m2-venda-RS550000-id-2558567969/",
            valorVenda: "550000.00",
            valorOriginal: "550000.00",
            endereco: "Rua Acilon Gonçalves Pinto, 500 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 178m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "178",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8752366,
                lng: -38.4591154
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-93m2-venda-RS420000-id-2686495518/",
            valorVenda: "420000.00",
            valorOriginal: "420000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 93m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "93",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-193m2-venda-RS990000-id-2738211925/",
            valorVenda: "990000.00",
            valorOriginal: "990000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 193m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "193",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-240m2-venda-RS1140000-id-2684182368/",
            valorVenda: "NaN",
            valorOriginal: "NaN",
            endereco: "Avenida José Amora Sá, 250 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 240m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "240",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8899038,
                lng: -38.4614791
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-154m2-venda-RS810000-id-2755069004/",
            valorVenda: "810000.00",
            valorOriginal: "810000.00",
            endereco: "Rua Nossa Senhora de Nazaré, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 154m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "154",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8783185,
                lng: -38.4629319
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-129m2-venda-RS499000-id-2751783396/",
            valorVenda: "499000.00",
            valorOriginal: "499000.00",
            endereco: "CE-040, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/e281c84c93eba88aec9f7a8ec2b8383c/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-129m-em-eusebio-eusebio.webp",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 129m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "129",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8818335,
                lng: -38.4497506
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-91m2-venda-RS389000-id-2745163001/",
            valorVenda: "389000.00",
            valorOriginal: "389000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/8f8157307ccb41a63c90e22155e8a558/foto-1-de-casa-com-3-quartos-a-venda-91m-em-eusebio-eusebio.webp",
            titulo: "Casa com 3 Quartos à Venda, 91m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "91",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-147m2-venda-RS496400-id-2634695421/",
            valorVenda: "496400.00",
            valorOriginal: "496400.00",
            endereco: "Rua José de Paula, 300 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/20912c68c367bf35a0846eef1231127c/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-147m-em-eusebio-eusebio.webp",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 147m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "147",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8992807,
                lng: -38.4569582
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-103m2-venda-RS551100-id-2731869423/",
            valorVenda: "551100.00",
            valorOriginal: "551100.00",
            endereco: "Avenida Santa Cecília, 2700 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/c9084213a8d41a4d05cd98826a95b50e/foto-1-de-casa-de-condominio-com-4-quartos-a-venda-103m-em-eusebio-eusebio.webp",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 103m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "103",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8699996,
                lng: -38.4466975
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-93m2-venda-RS419900-id-2634300705/",
            valorVenda: "419900.00",
            valorOriginal: "419900.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 93m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "93",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-72m2-venda-RS395000-id-2580040395/",
            valorVenda: "395000.00",
            valorOriginal: "395000.00",
            endereco: "Rua José Amora Sá, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 72m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "72",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8836961,
                lng: -38.4589299
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-86m2-venda-RS298000-id-2731556834/",
            valorVenda: "298000.00",
            valorOriginal: "298000.00",
            endereco: "Avenida Coronel Cícero Sá - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 86m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "86",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9126758,
                lng: -38.4515116
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-93m2-venda-RS399900-id-2677954410/",
            valorVenda: "399900.00",
            valorOriginal: "399900.00",
            endereco: "Rua dos Colibris, 106 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 93m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "93",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.849718999999999,
                lng: -38.46375
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-com-garagem-90m2-venda-RS810000-id-2680527495/",
            valorVenda: "810000.00",
            valorOriginal: "810000.00",
            endereco: "Rua Judite Martins, 300 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 3 Quartos à Venda, 90m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "90",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9219019,
                lng: -38.453213
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-139m2-venda-RS595000-id-2580040499/",
            valorVenda: "595000.00",
            valorOriginal: "595000.00",
            endereco: "CE-040, 1087 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 139m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "139",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8863054,
                lng: -38.4396588
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-154m2-venda-RS830000-id-2713206601/",
            valorVenda: "830000.00",
            valorOriginal: "830000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 154m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "154",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-93m2-venda-RS420000-id-2686495418/",
            valorVenda: "420000.00",
            valorOriginal: "420000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 93m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "93",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-180m2-venda-RS800000-id-2725778159/",
            valorVenda: "NaN",
            valorOriginal: "NaN",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 180m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "180",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-90m2-venda-RS335000-id-2758539931/",
            valorVenda: "335000.00",
            valorOriginal: "335000.00",
            endereco: "Avenida Coronel Cícero Sá, 10000 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 90m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "90",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9309099,
                lng: -38.46016270000001
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-108m2-venda-RS499000-id-2651371064/",
            valorVenda: "499000.00",
            valorOriginal: "499000.00",
            endereco: "Rua Nossa Senhora da Conceição, 51 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 108m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "108",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8638882,
                lng: -38.4477658
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-98m2-venda-RS549000-id-2645743982/",
            valorVenda: "549000.00",
            valorOriginal: "549000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 98m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "98",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-266m2-venda-RS1850000-id-2734644194/",
            valorVenda: "1850000.00",
            valorOriginal: "1850000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 266m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "266",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-151m2-venda-RS995000-id-2727367588/",
            valorVenda: "995000.00",
            valorOriginal: "995000.00",
            endereco: "Rua Maria Teixeira Joca, 722 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 151m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "151",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8863331,
                lng: -38.4650013
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-300m2-venda-RS1200000-id-2693928810/",
            valorVenda: "1200000.00",
            valorOriginal: "1200000.00",
            endereco: "Avenida Maria Teixeira Joca, 526 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 300m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "300",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.885694399999999,
                lng: -38.4623136
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-220m2-venda-RS1790000-id-2761926531/",
            valorVenda: "1790000.00",
            valorOriginal: "1790000.00",
            endereco: "Avenida Atlântica, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 220m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "220",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8908905,
                lng: -38.4860426
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-110m2-venda-RS480000-id-2681129538/",
            valorVenda: "480000.00",
            valorOriginal: "480000.00",
            endereco: "Rua Otoní Sá, 30 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 110m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "110",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.84099,
                lng: -38.4282941
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-110m2-venda-RS549900-id-2736324182/",
            valorVenda: "549900.00",
            valorOriginal: "549900.00",
            endereco: "Avenida Eusébio de Queiroz, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 110m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "110",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8902629,
                lng: -38.4474496
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-151m2-venda-RS949000-id-2568647453/",
            valorVenda: "949000.00",
            valorOriginal: "949000.00",
            endereco: "Rua Maria Teixeira Joca - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 151m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "151",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8864247,
                lng: -38.4651071
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-227m2-venda-RS1690000-id-2756332526/",
            valorVenda: "1690000.00",
            valorOriginal: "1690000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 227m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "227",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-183m2-venda-RS1100000-id-2711681399/",
            valorVenda: "NaN",
            valorOriginal: "NaN",
            endereco: "Avenida Eusébio de Queiroz - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 183m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "183",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8797677,
                lng: -38.4590877
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-103m2-venda-RS345000-id-2593308781/",
            valorVenda: "NaN",
            valorOriginal: "NaN",
            endereco: "Rua Valter Bezerra de Sá, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 103m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "103",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8693309,
                lng: -38.4351588
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-183m2-venda-RS1100000-id-2738389725/",
            valorVenda: "NaN",
            valorOriginal: "NaN",
            endereco: "Avenida Eusébio de Queiroz - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 183m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "183",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8797677,
                lng: -38.4590877
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-137m2-venda-RS440000-id-2698726204/",
            valorVenda: "440000.00",
            valorOriginal: "440000.00",
            endereco: "Rua Nossa Senhora Aparecida, 600 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 137m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "137",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9098005,
                lng: -38.455979
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-143m2-venda-RS689000-id-2567317686/",
            valorVenda: "689000.00",
            valorOriginal: "689000.00",
            endereco: "Rua Antônia Sá e Silva, 548 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 143m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "143",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.875269299999999,
                lng: -38.4692033
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-144m2-venda-RS990000-id-2716687717/",
            valorVenda: "990000.00",
            valorOriginal: "990000.00",
            endereco: "Avenida José Amora de Sá, 2695 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 144m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "144",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.890828399999999,
                lng: -38.4648571
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-363m2-venda-RS1990000-id-2658325788/",
            valorVenda: "1990000.00",
            valorOriginal: "1990000.00",
            endereco: "Alameda da Sereia - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 363m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "363",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8885822,
                lng: -38.483574
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-93m2-venda-RS380000-id-2640239600/",
            valorVenda: "380000.00",
            valorOriginal: "380000.00",
            endereco: "Rua Emílio Sá, 8 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 93m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "93",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9099353,
                lng: -38.4514364
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-151m2-venda-RS629000-id-2647342177/",
            valorVenda: "629000.00",
            valorOriginal: "629000.00",
            endereco: "Rua Ana Felício - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 151m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "151",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8852788,
                lng: -38.4500429
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-5-quartos-eusebio-bairros-eusebio-com-garagem-195m2-venda-RS1300000-id-2673462254/",
            valorVenda: "1300000.00",
            valorOriginal: "1300000.00",
            endereco: "Rua Mário Perdigão Bastos, 131 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 5 Quartos à Venda, 195m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "195",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8892113,
                lng: -38.4492913
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-42m2-venda-RS200000-id-2760140131/",
            valorVenda: "200000.00",
            valorOriginal: "200000.00",
            endereco: "Rua Gentil Monte Silva - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos para Venda/Aluguel 42m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "42",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8683922,
                lng: -38.4710024
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-191m2-venda-RS1180000-id-2742818779/",
            valorVenda: "1180000.00",
            valorOriginal: "1180000.00",
            endereco: "Rua Santa Rita de Cassia, 3333 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 191m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "191",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9048186,
                lng: -38.456315
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-132m2-venda-RS736278-id-2580498204/",
            valorVenda: "736278.00",
            valorOriginal: "736278.00",
            endereco: "Rua Carlos Albuquerque De Lima - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/7ad39367e4de327946fc2abfd94115ec/foto-1-de-casa-com-4-quartos-a-venda-132m-em-eusebio-eusebio.webp",
            titulo: "Casa com 4 Quartos à Venda, 132m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "132",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8786238,
                lng: -38.4460851
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-103m2-venda-RS420000-id-2745356232/",
            valorVenda: "420000.00",
            valorOriginal: "420000.00",
            endereco: "Rua Milton Gonçalves de Oliveira, 268 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/2d03bf514408b5a4ec67563e4d488f1d/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-103m-em-eusebio-eusebio.webp",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 103m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "103",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9055818,
                lng: -38.4485608
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-2-quartos-eusebio-bairros-eusebio-com-garagem-2400m2-venda-RS650000-id-2755055699/",
            valorVenda: "650000.00",
            valorOriginal: "650000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/a3dd5054c741cb192e5356e5ae015105/foto-1-de-casa-com-2-quartos-a-venda-2400m-em-eusebio-eusebio.webp",
            titulo: "Casa com 2 Quartos à Venda, 2400m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "2400",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-102m2-venda-RS578000-id-2761470363/",
            valorVenda: "578000.00",
            valorOriginal: "578000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/0ad9959195b17fd7151e495beed7c3a5/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-102m-em-eusebio-eusebio.webp",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 102m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "102",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-119m2-venda-RS645240-id-2646989154/",
            valorVenda: "645240.00",
            valorOriginal: "645240.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 119m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "119",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-144m2-venda-RS700000-id-2624398407/",
            valorVenda: "700000.00",
            valorOriginal: "700000.00",
            endereco: "Rua Antônia Sá e Silva - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 144m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "144",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8755699,
                lng: -38.4714078
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-278m2-venda-RS2250000-id-2687389542/",
            valorVenda: "2250000.00",
            valorOriginal: "2250000.00",
            endereco: "CE-040, 700 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 278m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "278",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.861821,
                lng: -38.4734493
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-93m2-venda-RS420000-id-2686276727/",
            valorVenda: "420000.00",
            valorOriginal: "420000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 93m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "93",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-105m2-venda-RS584000-id-2647911481/",
            valorVenda: "584000.00",
            valorOriginal: "584000.00",
            endereco: "Rua Blumenau, 365 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 105m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "105",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8935304,
                lng: -38.4454031
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-210m2-venda-RS1150000-id-2571163276/",
            valorVenda: "1150000.00",
            valorOriginal: "1150000.00",
            endereco: "Rua Nossa Senhora de Nazaré, 175 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 210m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "210",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8785723,
                lng: -38.4634514
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-158m2-venda-RS828000-id-2558548807/",
            valorVenda: "NaN",
            valorOriginal: "NaN",
            endereco: "Avenida Eusébio de Queiroz, 5684 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 158m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "158",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.890218,
                lng: -38.441825
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-133m2-venda-RS549000-id-2652395674/",
            valorVenda: "549000.00",
            valorOriginal: "549000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 133m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "133",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-123m2-venda-RS530000-id-2697580801/",
            valorVenda: "530000.00",
            valorOriginal: "530000.00",
            endereco: "Rua Coronel Jucá - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 123m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "123",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8574373,
                lng: -38.4676041
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-102m2-venda-RS579000-id-2761789719/",
            valorVenda: "579000.00",
            valorOriginal: "579000.00",
            endereco: "Rua José Amora Sá, 385 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 102m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "102",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8846346,
                lng: -38.4616777
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-90m2-venda-RS519000-id-2758574744/",
            valorVenda: "519000.00",
            valorOriginal: "519000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 90m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "90",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-218m2-venda-RS1563017-id-2718321256/",
            valorVenda: "1563017.00",
            valorOriginal: "1563017.00",
            endereco: "Avenida Eusébio de Queiroz, 5684 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 218m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "218",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.890218,
                lng: -38.441825
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-144m2-venda-RS700000-id-2700359291/",
            valorVenda: "700000.00",
            valorOriginal: "700000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 144m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "144",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-120m2-venda-RS727000-id-2646618377/",
            valorVenda: "727000.00",
            valorOriginal: "727000.00",
            endereco: "Rua Antônia Sá e Silva, 756 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 120m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "120",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8758312,
                lng: -38.4706382
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-120m2-venda-RS590000-id-2692570186/",
            valorVenda: "590000.00",
            valorOriginal: "590000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 120m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "120",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-190m2-venda-RS1346771-id-2718323334/",
            valorVenda: "1346771.00",
            valorOriginal: "1346771.00",
            endereco: "Avenida Eusébio de Queiroz, 5684 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 190m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "190",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.890218,
                lng: -38.441825
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-133m2-venda-RS550000-id-2624402184/",
            valorVenda: "550000.00",
            valorOriginal: "550000.00",
            endereco: "Rua Industrial Amílcar Araújo, 856 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 133m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "133",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8828104,
                lng: -38.46853
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-130m2-venda-RS599000-id-2755044761/",
            valorVenda: "599000.00",
            valorOriginal: "599000.00",
            endereco: "Rua Pacajus, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 130m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "130",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8988878,
                lng: -38.4581558
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-127m2-venda-RS540000-id-2731870616/",
            valorVenda: "540000.00",
            valorOriginal: "540000.00",
            endereco: "Rua Luz do Sol, 487 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 127m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "127",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8964361,
                lng: -38.450808
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-109m2-venda-RS430000-id-2755053594/",
            valorVenda: "430000.00",
            valorOriginal: "430000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 109m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "109",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-com-garagem-84m2-venda-RS586514-id-2735915318/",
            valorVenda: "586514.00",
            valorOriginal: "586514.00",
            endereco: "Rua Nossa Senhora de Nazaré, 730 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 3 Quartos à Venda, 84m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "84",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8792,
                lng: -38.4684406
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-290m2-venda-RS2100000-id-2658076861/",
            valorVenda: "NaN",
            valorOriginal: "NaN",
            endereco: "CE-040 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 290m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "290",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8612579,
                lng: -38.4703107
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-128m2-venda-RS650000-id-2640007503/",
            valorVenda: "650000.00",
            valorOriginal: "650000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 128m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "128",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-112m2-venda-RS449000-id-2639396594/",
            valorVenda: "449000.00",
            valorOriginal: "449000.00",
            endereco: "Rua dos Curiós, 10 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 112m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "112",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8581636,
                lng: -38.4575166
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-100m2-venda-RS599900-id-2534567477/",
            valorVenda: "599900.00",
            valorOriginal: "599900.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 100m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "100",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-98m2-venda-RS525000-id-2568644428/",
            valorVenda: "525000.00",
            valorOriginal: "525000.00",
            endereco: "Estrada do Fio, 705 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 98m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "98",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8492741,
                lng: -38.4619668
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-305m2-venda-RS2570000-id-2741916074/",
            valorVenda: "2570000.00",
            valorOriginal: "2570000.00",
            endereco: "Avenida Eusébio de Queiroz, 4130 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 305m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "305",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8882052,
                lng: -38.4564817
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-215m2-venda-RS1523978-id-2718323134/",
            valorVenda: "1523978.00",
            valorOriginal: "1523978.00",
            endereco: "Avenida Eusébio de Queiroz, 5684 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 215m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "215",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.890218,
                lng: -38.441825
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-5-quartos-eusebio-bairros-eusebio-com-garagem-262m2-venda-RS1790000-id-2724254983/",
            valorVenda: "1790000.00",
            valorOriginal: "1790000.00",
            endereco: "Alameda da Sereia, 10 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 5 Quartos à Venda, 262m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "262",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8885822,
                lng: -38.483574
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-337m2-venda-RS1180000-id-2747810259/",
            valorVenda: "1180000.00",
            valorOriginal: "1180000.00",
            endereco: "Rua Santa Rita de Cássia, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 337m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "337",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9045656,
                lng: -38.4604235
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-86m2-venda-RS399000-id-2745162800/",
            valorVenda: "399000.00",
            valorOriginal: "399000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 86m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "86",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-189m2-venda-RS1350000-id-2679660730/",
            valorVenda: "1350000.00",
            valorOriginal: "1350000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 189m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "189",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-187m2-venda-RS850000-id-2681982827/",
            valorVenda: "850000.00",
            valorOriginal: "850000.00",
            endereco: "Rua Ipê, 12 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/6e573a06cfe1c85b9ee68a9b4c658f05/foto-1-de-casa-com-3-quartos-a-venda-187m-em-eusebio-eusebio.webp",
            titulo: "Casa com 3 Quartos à Venda, 187m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "187",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8975549,
                lng: -38.4561414
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-257m2-venda-RS1050000-id-2761323848/",
            valorVenda: "NaN",
            valorOriginal: "NaN",
            endereco: "Rua Nossa Senhora de Nazaré, 360 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/faec59ee285c3bf47613f90620d838c5/foto-1-de-casa-com-4-quartos-a-venda-257m-em-eusebio-eusebio.webp",
            titulo: "Casa com 4 Quartos à Venda, 257m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "257",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8787935,
                lng: -38.4654728
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-112m2-venda-RS469000-id-2669828696/",
            valorVenda: "469000.00",
            valorOriginal: "469000.00",
            endereco: "Rua Nossa Senhora de Nazaré, 2222 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/9b6725272c2a61b389adeca6258d060e/foto-1-de-casa-com-4-quartos-a-venda-112m-em-eusebio-eusebio.webp",
            titulo: "Casa com 4 Quartos à Venda, 112m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "112",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8790918,
                lng: -38.4661132
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-98m2-venda-RS525000-id-2745187301/",
            valorVenda: "525000.00",
            valorOriginal: "525000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/cbc3a8f4452e260a74372c06e3a7fae0/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-98m-em-eusebio-eusebio.webp",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 98m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "98",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-161m2-venda-RS799000-id-2736128861/",
            valorVenda: "799000.00",
            valorOriginal: "799000.00",
            endereco: "Rua Vicente Leitão, 3 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 161m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "161",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8832694,
                lng: -38.4511065
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-230m2-venda-RS1750000-id-2626192858/",
            valorVenda: "1750000.00",
            valorOriginal: "1750000.00",
            endereco: "Rua Mário Perdigão Bastos, 131 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 230m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "230",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8892113,
                lng: -38.4492913
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS55000-id-2759696914/",
            valorVenda: "55000.00",
            valorOriginal: "55000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS275000-id-2715444719/",
            valorVenda: "275000.00",
            valorOriginal: "275000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-168m2-venda-RS924000-id-2568644601/",
            valorVenda: "924000.00",
            valorOriginal: "924000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 168m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "168",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS203000-id-2760189042/",
            valorVenda: "203000.00",
            valorOriginal: "203000.00",
            endereco: "Estrada do Fio, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.87252,
                lng: -38.431038
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-310m2-venda-RS2990000-id-2654682037/",
            valorVenda: "2990000.00",
            valorOriginal: "2990000.00",
            endereco: "CE-040, 5810 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 310m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "310",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8880329,
                lng: -38.4374996
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-120m2-venda-RS430000-id-2725158186/",
            valorVenda: "430000.00",
            valorOriginal: "430000.00",
            endereco: "Rua Caramuru, 521 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 120m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "120",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8935213,
                lng: -38.4391392
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-183m2-venda-RS1297802-id-2718320281/",
            valorVenda: "1297802.00",
            valorOriginal: "1297802.00",
            endereco: "Avenida Eusébio de Queiroz, 5684 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 183m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "183",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.890218,
                lng: -38.441825
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-93m2-venda-RS395000-id-2676219009/",
            valorVenda: "395000.00",
            valorOriginal: "395000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 93m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "93",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-144m2-venda-RS700000-id-2624397211/",
            valorVenda: "700000.00",
            valorOriginal: "700000.00",
            endereco: "Rua Antônia Sá e Silva - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 144m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "144",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8755699,
                lng: -38.4714078
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-100m2-venda-RS560000-id-2754691369/",
            valorVenda: "560000.00",
            valorOriginal: "560000.00",
            endereco: "Avenida Santa Cecília, 1940 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 100m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "100",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8730859,
                lng: -38.44846450000001
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-180m2-venda-RS950000-id-2743552567/",
            valorVenda: "950000.00",
            valorOriginal: "950000.00",
            endereco: "Avenida Atlântica, sn - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 180m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "180",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8908905,
                lng: -38.4860426
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-510m2-venda-RS2200000-id-2690163658/",
            valorVenda: "2200000.00",
            valorOriginal: "2200000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 510m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "510",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-144m2-venda-RS760000-id-2754141617/",
            valorVenda: "760000.00",
            valorOriginal: "760000.00",
            endereco: "Travessa Nova Esperança, 1185 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 144m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "144",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9298144,
                lng: -38.5040909
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-191m2-venda-RS1353860-id-2718323233/",
            valorVenda: "1353860.00",
            valorOriginal: "1353860.00",
            endereco: "Avenida Eusébio de Queiroz, 5684 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 191m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "191",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.890218,
                lng: -38.441825
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-180m2-venda-RS885000-id-2580039151/",
            valorVenda: "885000.00",
            valorOriginal: "885000.00",
            endereco: "Avenida Eusébio de Queiroz, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 180m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "180",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8902629,
                lng: -38.4474496
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-256m2-venda-RS1400000-id-2686098398/",
            valorVenda: "1400000.00",
            valorOriginal: "1400000.00",
            endereco: "Rua Nossa Senhora de Nazaré, 360 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 256m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "256",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8787935,
                lng: -38.4654728
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS45000-id-2752996005/",
            valorVenda: "45000.00",
            valorOriginal: "45000.00",
            endereco: "Rua Santa Cecília, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890084,
                lng: -38.4520954
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-151m2-venda-RS920000-id-2549863778/",
            valorVenda: "920000.00",
            valorOriginal: "920000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 151m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "151",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-151m2-venda-RS1200000-id-2721318358/",
            valorVenda: "1200000.00",
            valorOriginal: "1200000.00",
            endereco: "Rua Maria Teixeira Joca - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 151m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "151",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8864247,
                lng: -38.4651071
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-350m2-venda-RS3000000-id-2691042355/",
            valorVenda: "3000000.00",
            valorOriginal: "3000000.00",
            endereco: "Avenida José Morais de Almeida, 777 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 350m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "350",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8591901,
                lng: -38.4593973
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-510m2-venda-RS2200000-id-2690158831/",
            valorVenda: "2200000.00",
            valorOriginal: "2200000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 510m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "510",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-137m2-venda-RS999000-id-2691936658/",
            valorVenda: "999000.00",
            valorOriginal: "999000.00",
            endereco: "Rua Zildênia, 156 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 137m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "137",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8692528,
                lng: -38.4660139
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-103m2-venda-RS420000-id-2736880214/",
            valorVenda: "420000.00",
            valorOriginal: "420000.00",
            endereco: "Rua Milton Gonçalves de Oliveira, 86 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 103m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "103",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9043704,
                lng: -38.4483235
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-93m2-venda-RS369000-id-2751733709/",
            valorVenda: "369000.00",
            valorOriginal: "369000.00",
            endereco: "Rua Judith Martins - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 93m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "93",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9202511,
                lng: -38.4516423
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-196m2-venda-RS999900-id-2740998278/",
            valorVenda: "999900.00",
            valorOriginal: "999900.00",
            endereco: "Rua Nossa Senhora de Nazaré, 605 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 196m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "196",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.879413,
                lng: -38.466623
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-144m2-venda-RS749000-id-2719974745/",
            valorVenda: "749000.00",
            valorOriginal: "749000.00",
            endereco: "Avenida Francisco Ferreira da Silva - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 144m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "144",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8511873,
                lng: -38.4357101
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-151m2-venda-RS995000-id-2551116102/",
            valorVenda: "995000.00",
            valorOriginal: "995000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 151m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "151",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-206m2-venda-RS1100000-id-2555952000/",
            valorVenda: "1100000.00",
            valorOriginal: "1100000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 206m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "206",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-90m2-venda-RS330000-id-2763298357/",
            valorVenda: "330000.00",
            valorOriginal: "330000.00",
            endereco: "Avenida Coronel Cícero Sá, 10000 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 90m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "90",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9309099,
                lng: -38.46016270000001
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-com-garagem-84m2-venda-RS586514-id-2730003709/",
            valorVenda: "586514.00",
            valorOriginal: "586514.00",
            endereco: "Rua Nossa Senhora de Nazaré, 730 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 3 Quartos à Venda, 84m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "84",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8792,
                lng: -38.4684406
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-260m2-venda-RS1090000-id-2715511505/",
            valorVenda: "1090000.00",
            valorOriginal: "1090000.00",
            endereco: "Avenida Eusébio de Queiroz - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/501d3d1ae5180e2a9d76fe964229f806/foto-1-de-casa-com-4-quartos-a-venda-260m-em-eusebio-eusebio.webp",
            titulo: "Casa com 4 Quartos à Venda, 260m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "260",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8797677,
                lng: -38.4590877
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-372m2-venda-RS3300000-id-2671905312/",
            valorVenda: "3300000.00",
            valorOriginal: "3300000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/75e90c59990d696e2370e8007908d81f/foto-1-de-casa-com-4-quartos-a-venda-372m-em-eusebio-eusebio.webp",
            titulo: "Casa com 4 Quartos à Venda, 372m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "372",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-151m2-venda-RS965000-id-2611799231/",
            valorVenda: "965000.00",
            valorOriginal: "965000.00",
            endereco: "Rua Maria Teixeira Joca, 722 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/2eeb61974989a77b861a0d92eea768fc/foto-1-de-casa-de-condominio-com-4-quartos-a-venda-151m-em-eusebio-eusebio.webp",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 151m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "151",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8863331,
                lng: -38.4650013
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-300m2-venda-RS1100000-id-2715128200/",
            valorVenda: "1100000.00",
            valorOriginal: "1100000.00",
            endereco: "Rua Maria Teixeira Joca - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/3e4f7aad9fb61d795bf6ad79cb3f1bc5/foto-1-de-casa-com-3-quartos-a-venda-300m-em-eusebio-eusebio.webp",
            titulo: "Casa com 3 Quartos à Venda, 300m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "300",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8864247,
                lng: -38.4651071
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-5-quartos-eusebio-bairros-eusebio-com-garagem-208m2-venda-RS999900-id-2750345473/",
            valorVenda: "999900.00",
            valorOriginal: "999900.00",
            endereco: "Rua Nossa Senhora de Nazaré, 605 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 5 Quartos à Venda, 208m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "208",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.879413,
                lng: -38.466623
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-132m2-venda-RS739900-id-2682537198/",
            valorVenda: "739900.00",
            valorOriginal: "739900.00",
            endereco: "Rua Carlos Albuquerque De Lima, 7 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 132m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "132",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8778006,
                lng: -38.4495422
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-180m2-venda-RS825000-id-2761785063/",
            valorVenda: "825000.00",
            valorOriginal: "825000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 180m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "180",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-180m2-venda-RS895000-id-2738198843/",
            valorVenda: "895000.00",
            valorOriginal: "895000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 180m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "180",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-230m2-venda-RS1050000-id-2715126291/",
            valorVenda: "1050000.00",
            valorOriginal: "1050000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 230m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "230",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-200m2-venda-RS1999000-id-2666183681/",
            valorVenda: "1999000.00",
            valorOriginal: "1999000.00",
            endereco: "Avenida 2, 3 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 200m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "200",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-140m2-venda-RS619000-id-2745162897/",
            valorVenda: "619000.00",
            valorOriginal: "619000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 140m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "140",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-250m2-venda-RS1700000-id-2624406815/",
            valorVenda: "1700000.00",
            valorOriginal: "1700000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 250m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "250",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-300m2-venda-RS2700000-id-2583571774/",
            valorVenda: "2700000.00",
            valorOriginal: "2700000.00",
            endereco: "CE-040, 705 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 300m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "300",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.880787499999999,
                lng: -38.4527737
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-510m2-venda-RS2200000-id-2690163860/",
            valorVenda: "2200000.00",
            valorOriginal: "2200000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 510m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "510",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-165m2-venda-RS825000-id-2567324024/",
            valorVenda: "825000.00",
            valorOriginal: "825000.00",
            endereco: "Rua Nossa Senhora de Nazaré, 385 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 165m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "165",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8790317,
                lng: -38.4651527
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-230m2-venda-RS1100000-id-2548502387/",
            valorVenda: "NaN",
            valorOriginal: "NaN",
            endereco: "Rua Santa Cecília, 1433 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 230m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "230",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8777014,
                lng: -38.4500628
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-342m2-venda-RS2500000-id-2731016143/",
            valorVenda: "2500000.00",
            valorOriginal: "2500000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 342m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "342",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-180m2-venda-RS885000-id-2568644205/",
            valorVenda: "885000.00",
            valorOriginal: "885000.00",
            endereco: "Rua Nossa Senhora de Nazaré, 385 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 180m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "180",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8790317,
                lng: -38.4651527
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-192m2-venda-RS999990-id-2751936320/",
            valorVenda: "999990.00",
            valorOriginal: "999990.00",
            endereco: "Rua Nossa Senhora de Nazaré, 605 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 192m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "192",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.879413,
                lng: -38.466623
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-313m2-venda-RS2800000-id-2717823365/",
            valorVenda: "2800000.00",
            valorOriginal: "2800000.00",
            endereco: "Avenida Eusébio de Queiroz, 4130 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 313m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "313",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8882052,
                lng: -38.4564817
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-com-garagem-90m2-venda-RS851000-id-2758656838/",
            valorVenda: "851000.00",
            valorOriginal: "851000.00",
            endereco: "Rua Judite Martins, 300 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 3 Quartos à Venda, 90m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "90",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9219019,
                lng: -38.453213
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-210m2-venda-RS1270000-id-2738198845/",
            valorVenda: "1270000.00",
            valorOriginal: "1270000.00",
            endereco: "Avenida Litorânea - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 210m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "210",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8177098,
                lng: -38.4226087
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-179m2-venda-RS599000-id-2578721177/",
            valorVenda: "599000.00",
            valorOriginal: "599000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 179m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "179",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-150m2-venda-RS795000-id-2746788416/",
            valorVenda: "795000.00",
            valorOriginal: "795000.00",
            endereco: "Rua Urucunema, 12 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 150m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "150",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.902539,
                lng: -38.45247140000001
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-132m2-venda-RS670000-id-2757356720/",
            valorVenda: "670000.00",
            valorOriginal: "670000.00",
            endereco: "Rua Raimunda Soares Silva, 200 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 132m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "132",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8981724,
                lng: -38.4411675
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-192m2-venda-RS845000-id-2737136730/",
            valorVenda: "845000.00",
            valorOriginal: "845000.00",
            endereco: "Rua Industrial Amílcar Araújo, 55 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 192m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "192",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8811197,
                lng: -38.4608927
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-148m2-venda-RS765000-id-2584406760/",
            valorVenda: "765000.00",
            valorOriginal: "765000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 148m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "148",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-316m2-venda-RS2900000-id-2621652629/",
            valorVenda: "2900000.00",
            valorOriginal: "2900000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 316m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "316",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS56000-id-2763542929/",
            valorVenda: "56000.00",
            valorOriginal: "56000.00",
            endereco: "Alameda Verde, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8557298,
                lng: -38.4716511
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-280m2-venda-RS1890000-id-2737094117/",
            valorVenda: "1890000.00",
            valorOriginal: "1890000.00",
            endereco: "Alameda das Américas, 777 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 280m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "280",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8585516,
                lng: -38.4726883
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-240m2-venda-RS800000-id-2733099247/",
            valorVenda: "800000.00",
            valorOriginal: "800000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 240m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "240",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-158m2-venda-RS899900-id-2741652885/",
            valorVenda: "NaN",
            valorOriginal: "NaN",
            endereco: "Rua José Amora Sá, 742 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 158m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "158",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8849148,
                lng: -38.46555499999999
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-236m2-venda-RS1490000-id-2558025807/",
            valorVenda: "1490000.00",
            valorOriginal: "1490000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 236m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "236",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-175m2-venda-RS529000-id-2534567476/",
            valorVenda: "NaN",
            valorOriginal: "NaN",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 175m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "175",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-165m2-venda-RS779000-id-2741586062/",
            valorVenda: "779000.00",
            valorOriginal: "779000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 165m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "165",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-120m2-venda-RS590000-id-2690040022/",
            valorVenda: "590000.00",
            valorOriginal: "590000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 120m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "120",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-100m2-venda-RS605000-id-2739749332/",
            valorVenda: "605000.00",
            valorOriginal: "605000.00",
            endereco: "Alameda Olho d'Água, 445 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/d37b11b267f502f30f824077e021d501/foto-1-de-casa-com-3-quartos-a-venda-100m-em-eusebio-eusebio.webp",
            titulo: "Casa com 3 Quartos à Venda, 100m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "100",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8692836,
                lng: -38.4312154
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-140m2-venda-RS550000-id-2596915008/",
            valorVenda: "550000.00",
            valorOriginal: "550000.00",
            endereco: "Travessa Doutor Raimundo Gomes Guimaraes - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/728a5223665c9d80f77a2027d72b3ec6/foto-1-de-casa-com-4-quartos-a-venda-140m-em-eusebio-eusebio.webp",
            titulo: "Casa com 4 Quartos à Venda, 140m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "140",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.877374,
                lng: -38.4721014
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-128m2-venda-RS480000-id-2640888479/",
            valorVenda: "480000.00",
            valorOriginal: "480000.00",
            endereco: "Rua Cristal, 419 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/84df31e9ac8fa6b76dd3cc0c235127f3/foto-1-de-casa-com-3-quartos-a-venda-128m-em-eusebio-eusebio.webp",
            titulo: "Casa com 3 Quartos à Venda, 128m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "128",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9082117,
                lng: -38.4511321
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-183m2-venda-RS1100000-id-2570654969/",
            valorVenda: "1100000.00",
            valorOriginal: "1100000.00",
            endereco: "Avenida Eusébio de Queiroz, 5684 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/908ea6f34d0e31429d9ae667c00e2d9c/foto-1-de-casa-de-condominio-com-4-quartos-a-venda-183m-em-eusebio-eusebio.webp",
            titulo: "Casa com 4 Quartos à Venda, 183m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "183",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.890218,
                lng: -38.441825
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-157m2-venda-RS700000-id-2564578233/",
            valorVenda: "NaN",
            valorOriginal: "NaN",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 157m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "157",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-160m2-venda-RS899000-id-2756589817/",
            valorVenda: "899000.00",
            valorOriginal: "899000.00",
            endereco: "Avenida Eusébio de Queiroz, 5684 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 160m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "160",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.890218,
                lng: -38.441825
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-111m2-venda-RS480000-id-2647989095/",
            valorVenda: "480000.00",
            valorOriginal: "480000.00",
            endereco: "Rua Miguel Ximenes Melo, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 111m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "111",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8981764,
                lng: -38.4398096
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-105m2-venda-RS590000-id-2753390166/",
            valorVenda: "590000.00",
            valorOriginal: "590000.00",
            endereco: "Rua Blumenau, 365 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 105m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "105",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8935304,
                lng: -38.4454031
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-226m2-venda-RS1190000-id-2577596024/",
            valorVenda: "1190000.00",
            valorOriginal: "1190000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 226m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "226",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-118m2-venda-RS499000-id-2646367727/",
            valorVenda: "499000.00",
            valorOriginal: "499000.00",
            endereco: "Rua Fernando de Souza Guedelha, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 118m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "118",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8703436,
                lng: -38.4339647
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-510m2-venda-RS2200000-id-2690164358/",
            valorVenda: "2200000.00",
            valorOriginal: "2200000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 510m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "510",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-com-garagem-120m2-venda-RS1031000-id-2758657428/",
            valorVenda: "1031000.00",
            valorOriginal: "1031000.00",
            endereco: "Rua Judite Martins, 300 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 3 Quartos à Venda, 120m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "120",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9219019,
                lng: -38.453213
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-154m2-venda-RS765000-id-2601054301/",
            valorVenda: "765000.00",
            valorOriginal: "765000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 154m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "154",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS430000-id-2763324427/",
            valorVenda: "430000.00",
            valorOriginal: "430000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-132m2-venda-RS811133-id-2717934341/",
            valorVenda: "811133.00",
            valorOriginal: "811133.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 132m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "132",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-278m2-venda-RS2100000-id-2743516997/",
            valorVenda: "2100000.00",
            valorOriginal: "2100000.00",
            endereco: "Avenida Eusébio de Queiroz, 700 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 278m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "278",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8620349,
                lng: -38.4707976
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-305m2-venda-RS2570000-id-2719508346/",
            valorVenda: "2570000.00",
            valorOriginal: "2570000.00",
            endereco: "Avenida Eusébio de Queiroz, 4130 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 305m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "305",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8882052,
                lng: -38.4564817
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-127m2-venda-RS506000-id-2608635609/",
            valorVenda: "506000.00",
            valorOriginal: "506000.00",
            endereco: "Avenida Brasília, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 127m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "127",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8965566,
                lng: -38.4496913
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-128m2-venda-RS810985-id-2727905188/",
            valorVenda: "810985.00",
            valorOriginal: "810985.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 128m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "128",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-131m2-venda-RS590000-id-2752003551/",
            valorVenda: "590000.00",
            valorOriginal: "590000.00",
            endereco: "Rua Pires Façanha - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 131m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "131",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8875517,
                lng: -38.436113
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-168m2-venda-RS1090000-id-2760204118/",
            valorVenda: "1090000.00",
            valorOriginal: "1090000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 168m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "168",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-510m2-venda-RS2000000-id-2690163563/",
            valorVenda: "2000000.00",
            valorOriginal: "2000000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 510m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "510",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-227m2-venda-RS1180000-id-2744606227/",
            valorVenda: "1180000.00",
            valorOriginal: "1180000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 227m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "227",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-44m2-venda-RS69900-id-2763539890/",
            valorVenda: "69900.00",
            valorOriginal: "69900.00",
            endereco: "Alameda Verde, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 2 Quartos à Venda, 44m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "44",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8557298,
                lng: -38.4716511
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-110m2-venda-RS540000-id-2634056100/",
            valorVenda: "540000.00",
            valorOriginal: "540000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 110m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "110",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-5-quartos-eusebio-bairros-eusebio-com-garagem-189m2-venda-RS1349000-id-2720204335/",
            valorVenda: "1349000.00",
            valorOriginal: "1349000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 5 Quartos à Venda, 189m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "189",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-158m2-venda-RS950000-id-2651116238/",
            valorVenda: "950000.00",
            valorOriginal: "950000.00",
            endereco: "Avenida Eusébio de Queiroz, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 158m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "158",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8902629,
                lng: -38.4474496
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-151m2-venda-RS995000-id-2737135940/",
            valorVenda: "995000.00",
            valorOriginal: "995000.00",
            endereco: "Rua Maria Teixeira Joca, 63 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 151m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "151",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8851925,
                lng: -38.4585277
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-450m2-venda-RS1890000-id-2715509636/",
            valorVenda: "1890000.00",
            valorOriginal: "1890000.00",
            endereco: "Rua Mário Perdigão Bastos - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 450m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "450",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8887399,
                lng: -38.4493055
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-300m2-venda-RS2980000-id-2599509446/",
            valorVenda: "2980000.00",
            valorOriginal: "2980000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 300m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "300",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-183m2-venda-RS1100000-id-2570658616/",
            valorVenda: "1100000.00",
            valorOriginal: "1100000.00",
            endereco: "Avenida Eusébio de Queiroz, 5684 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 183m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "183",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.890218,
                lng: -38.441825
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-244m2-venda-RS1399000-id-2724954437/",
            valorVenda: "1399000.00",
            valorOriginal: "1399000.00",
            endereco: "Avenida Eusébio de Queiroz, 5684 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 244m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "244",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.890218,
                lng: -38.441825
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-510m2-venda-RS2200000-id-2691347861/",
            valorVenda: "2200000.00",
            valorOriginal: "2200000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 510m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "510",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-93m2-venda-RS349990-id-2640021572/",
            valorVenda: "349990.00",
            valorOriginal: "349990.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 93m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "93",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-134m2-venda-RS599900-id-2708938640/",
            valorVenda: "599900.00",
            valorOriginal: "599900.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 134m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "134",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-165m2-venda-RS798000-id-2738196741/",
            valorVenda: "798000.00",
            valorOriginal: "798000.00",
            endereco: "Rua Zildenia - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 165m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "165",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8735222,
                lng: -38.4632004
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-285m2-venda-RS2490000-id-2642561903/",
            valorVenda: "2490000.00",
            valorOriginal: "2490000.00",
            endereco: "Alameda das Américas, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/d450116a39af18edcc1770ca6d3dc13e/foto-1-de-casa-de-condominio-com-4-quartos-a-venda-285m-em-eusebio-eusebio.webp",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 285m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "285",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8585516,
                lng: -38.4726883
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-196m2-venda-RS999900-id-2744903418/",
            valorVenda: "999900.00",
            valorOriginal: "999900.00",
            endereco: "Rua Nossa Senhora de Nazaré, 605 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/4a96ccf35d926c3dde2d441d4f6efb4a/foto-1-de-casa-de-condominio-com-4-quartos-a-venda-196m-em-eusebio-eusebio.webp",
            titulo: "Casa com 4 Quartos à Venda, 196m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "196",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.879413,
                lng: -38.466623
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-133m2-venda-RS740000-id-2546939657/",
            valorVenda: "740000.00",
            valorOriginal: "740000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/d4448453cc5af16c5c77c9416cde63ff/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-133m-em-eusebio-eusebio.webp",
            titulo: "Casa com 3 Quartos à Venda, 133m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "133",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-4-quartos-eusebio-bairros-eusebio-com-garagem-130m2-venda-RS620000-id-2763325827/",
            valorVenda: "620000.00",
            valorOriginal: "620000.00",
            endereco: "Rua Santa Cecília, 100 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/93818d35666468686e6e566400b63de9/foto-1-de-apartamento-com-4-quartos-a-venda-130m-em-eusebio-eusebio.webp",
            titulo: "Apartamento com 4 Quartos à Venda, 130m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "130",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890084,
                lng: -38.4520954
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-320m2-venda-RS2500000-id-2681210108/",
            valorVenda: "2500000.00",
            valorOriginal: "2500000.00",
            endereco: "Avenida Atlântica, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 320m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "320",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8908905,
                lng: -38.4860426
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-4-quartos-eusebio-bairros-eusebio-com-garagem-500m2-venda-RS3300000-id-2641396801/",
            valorVenda: "3300000.00",
            valorOriginal: "3300000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 4 Quartos à Venda, 500m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "500",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-5-quartos-eusebio-bairros-eusebio-com-garagem-420m2-venda-RS3500000-id-2635743970/",
            valorVenda: "3500000.00",
            valorOriginal: "3500000.00",
            endereco: "Avenida Alphaville, 2 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 5 Quartos à Venda, 420m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "420",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8218017,
                lng: -38.4231822
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-300m2-venda-RS2500000-id-2583571519/",
            valorVenda: "2500000.00",
            valorOriginal: "2500000.00",
            endereco: "CE-040, 700 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 300m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "300",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.861821,
                lng: -38.4734493
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-338m2-venda-RS1990000-id-2731017421/",
            valorVenda: "1990000.00",
            valorOriginal: "1990000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 338m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "338",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-250m2-venda-RS1700000-id-2741976762/",
            valorVenda: "1700000.00",
            valorOriginal: "1700000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 250m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "250",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-244m2-venda-RS1360000-id-2703164177/",
            valorVenda: "1360000.00",
            valorOriginal: "1360000.00",
            endereco: "CE-040 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 244m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "244",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8612579,
                lng: -38.4703107
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-315m2-venda-RS3500000-id-2759592722/",
            valorVenda: "3500000.00",
            valorOriginal: "3500000.00",
            endereco: "Alameda das Américas, 10 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 315m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "315",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8585516,
                lng: -38.4726883
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-5-quartos-eusebio-bairros-eusebio-com-garagem-850m2-venda-RS9900000-id-2696382503/",
            valorVenda: "9900000.00",
            valorOriginal: "9900000.00",
            endereco: "Avenida Litorânea, 193 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 5 Quartos à Venda, 850m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "850",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8239081,
                lng: -38.42090839999999
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-90m2-venda-RS340000-id-2763305015/",
            valorVenda: "340000.00",
            valorOriginal: "340000.00",
            endereco: "Avenida Coronel Cícero Sá, 1500 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 90m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "90",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9026121,
                lng: -38.4525113
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-127m2-venda-RS540000-id-2736110567/",
            valorVenda: "540000.00",
            valorOriginal: "540000.00",
            endereco: "Avenida Brasília, 487 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 127m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "127",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.896547,
                lng: -38.45069580000001
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-474m2-venda-RS1720000-id-2523390189/",
            valorVenda: "1720000.00",
            valorOriginal: "1720000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 474m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "474",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-220m2-venda-RS1950000-id-2655751425/",
            valorVenda: "1950000.00",
            valorOriginal: "1950000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 220m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "220",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-223m2-venda-RS1550000-id-2755390204/",
            valorVenda: "1550000.00",
            valorOriginal: "1550000.00",
            endereco: "Rua Zildenia - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 223m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "223",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8735222,
                lng: -38.4632004
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-210m2-venda-RS1300000-id-2738198844/",
            valorVenda: "1300000.00",
            valorOriginal: "1300000.00",
            endereco: "Avenida Litorânea - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 210m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "210",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8177098,
                lng: -38.4226087
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-315m2-venda-RS1990000-id-2653343915/",
            valorVenda: "NaN",
            valorOriginal: "NaN",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 315m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "315",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-376m2-venda-RS2280000-id-2673229191/",
            valorVenda: "2280000.00",
            valorOriginal: "2280000.00",
            endereco: "Avenida Atlântica, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/fit-in/142x80/named.images.sp/1bb96862cd0bce3e347deef851509767/{description}.webp",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 376m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "376",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8908905,
                lng: -38.4860426
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-529m2-venda-RS1960000-id-2560606712/",
            valorVenda: "1960000.00",
            valorOriginal: "1960000.00",
            endereco: "CE-040, 700 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 529m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "529",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.861821,
                lng: -38.4734493
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-177m2-venda-RS847728-id-2750761130/",
            valorVenda: "847728.00",
            valorOriginal: "847728.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 177m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "177",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-330m2-venda-RS1700000-id-2598554056/",
            valorVenda: "1700000.00",
            valorOriginal: "1700000.00",
            endereco: "Rua Mário Perdigão Bastos, 131 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 330m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "330",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8892113,
                lng: -38.4492913
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-450m2-venda-RS1890000-id-2568639981/",
            valorVenda: "1890000.00",
            valorOriginal: "1890000.00",
            endereco: "Rua Mário Perdigão Bastos, 131 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 450m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "450",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8892113,
                lng: -38.4492913
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-158m2-venda-RS899900-id-2737135126/",
            valorVenda: "899900.00",
            valorOriginal: "899900.00",
            endereco: "Rua José Amora Sá, 133 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 158m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "158",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.883896,
                lng: -38.4599823
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-280m2-venda-RS1890000-id-2555787114/",
            valorVenda: "1890000.00",
            valorOriginal: "1890000.00",
            endereco: "Avenida Eusébio de Queiroz - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 280m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "280",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8797677,
                lng: -38.4590877
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-149m2-venda-RS569180-id-2719974863/",
            valorVenda: "569180.00",
            valorOriginal: "569180.00",
            endereco: "Rua Industrial Amílcar Araújo - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 149m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "149",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8821168,
                lng: -38.4658877
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-290m2-venda-RS2400000-id-2715200182/",
            valorVenda: "2400000.00",
            valorOriginal: "2400000.00",
            endereco: "CE-040, 50 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 290m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "290",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8937843,
                lng: -38.4321649
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-152m2-venda-RS950000-id-2644345664/",
            valorVenda: "950000.00",
            valorOriginal: "950000.00",
            endereco: "Rua Mirian de Abreu, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 152m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "152",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.900958,
                lng: -38.4532682
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-143m2-venda-RS810985-id-2737135941/",
            valorVenda: "810985.00",
            valorOriginal: "810985.00",
            endereco: "Rua Manoel Gomes, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 143m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "143",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8719545,
                lng: -38.4642857
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-140m2-venda-RS599900-id-2728916717/",
            valorVenda: "599900.00",
            valorOriginal: "599900.00",
            endereco: "Rua Djanira Pinheiro Ellery, 302 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 140m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "140",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.874527199999999,
                lng: -38.4503362
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-3-quartos-eusebio-bairros-eusebio-90m2-venda-RS850000-id-2717833543/",
            valorVenda: "850000.00",
            valorOriginal: "850000.00",
            endereco: "Rua Judite Martins - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 3 Quartos à Venda, 90m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "90",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.9202511,
                lng: -38.4516423
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-510m2-venda-RS2200000-id-2682307736/",
            valorVenda: "2200000.00",
            valorOriginal: "2200000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 510m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "510",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-5-quartos-eusebio-bairros-eusebio-com-garagem-499m2-venda-RS4300000-id-2687005646/",
            valorVenda: "4300000.00",
            valorOriginal: "4300000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Apartamento com 5 Quartos à Venda, 499m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "499",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-270m2-venda-RS1200000-id-2561045858/",
            valorVenda: "1200000.00",
            valorOriginal: "1200000.00",
            endereco: "Avenida Pacifico, 253 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 270m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "270",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8801955,
                lng: -38.4856715
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-6-quartos-eusebio-bairros-eusebio-com-garagem-248m2-venda-RS1200000-id-2564251375/",
            valorVenda: "1200000.00",
            valorOriginal: "1200000.00",
            endereco: "Avenida Eusébio de Queiroz, 3778 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/ae7186cc28b02ec76931adc8a955fd73/foto-1-de-casa-com-6-quartos-a-venda-248m-em-eusebio-eusebio.webp",
            titulo: "Casa com 6 Quartos à Venda, 248m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "248",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8856842,
                lng: -38.4577151
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-eusebio-com-garagem-41m2-venda-RS70000-id-2719825846/",
            valorVenda: "70000.00",
            valorOriginal: "70000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/00c4d63737a7a39b3d02789bb76298b1/foto-1-de-apartamento-com-2-quartos-a-venda-41m-em-eusebio-eusebio.webp",
            titulo: "Apartamento com 2 Quartos à Venda, 41m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 2,
            descricaoTipoImovel: "Apartamento",
            area: "41",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-320m2-venda-RS1890000-id-2707914617/",
            valorVenda: "1890000.00",
            valorOriginal: "1890000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/7cb63c59340451669bb54d4a669fc828/foto-1-de-casa-com-4-quartos-a-venda-320m-em-eusebio-eusebio.webp",
            titulo: "Casa com 4 Quartos à Venda, 320m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "320",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-162m2-venda-RS850000-id-2745071701/",
            valorVenda: "850000.00",
            valorOriginal: "850000.00",
            endereco: "Rua Vicente Leitão, 233 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/532ddc7febd391602f28b3684a0bd339/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-162m-em-eusebio-eusebio.webp",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 162m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "162",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.881973899999999,
                lng: -38.4575752
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-520m2-venda-RS1890000-id-2582880168/",
            valorVenda: "1890000.00",
            valorOriginal: "1890000.00",
            endereco: "Rua Mário Perdigão Bastos - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 520m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "520",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8887399,
                lng: -38.4493055
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-253m2-venda-RS1650000-id-2719968024/",
            valorVenda: "1650000.00",
            valorOriginal: "1650000.00",
            endereco: "Rodovia 4º Anel Viário - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 253m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "253",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8734013,
                lng: -38.4900666
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-5-quartos-eusebio-bairros-eusebio-com-garagem-300m2-venda-RS1700000-id-2562053853/",
            valorVenda: "1700000.00",
            valorOriginal: "1700000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 5 Quartos à Venda, 300m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "300",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-5-quartos-eusebio-bairros-eusebio-com-garagem-312m2-venda-RS1750000-id-2710355393/",
            valorVenda: "1750000.00",
            valorOriginal: "1750000.00",
            endereco: "Avenida Eusébio de Queiroz - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 5 Quartos à Venda/Aluguel 312m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "312",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8797677,
                lng: -38.4590877
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-5-quartos-eusebio-bairros-eusebio-com-garagem-330m2-venda-RS800000-id-2715201359/",
            valorVenda: "800000.00",
            valorOriginal: "800000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 5 Quartos à Venda, 330m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "330",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-450m2-venda-RS1890000-id-2558548793/",
            valorVenda: "1890000.00",
            valorOriginal: "1890000.00",
            endereco: "Rua Mário Perdigão Bastos, 131 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 450m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "450",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8892113,
                lng: -38.4492913
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-348m2-venda-RS1500000-id-2594346793/",
            valorVenda: "1500000.00",
            valorOriginal: "1500000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 348m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "348",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-330m2-venda-RS1450000-id-2580037531/",
            valorVenda: "NaN",
            valorOriginal: "NaN",
            endereco: "Rua Santa Cecília, 1 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 330m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "330",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8691239,
                lng: -38.4460454
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-154m2-venda-RS810000-id-2751489108/",
            valorVenda: "810000.00",
            valorOriginal: "810000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 154m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "154",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-230m2-venda-RS1050000-id-2714803663/",
            valorVenda: "1050000.00",
            valorOriginal: "1050000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 230m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "230",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-180m2-venda-RS1100000-id-2698494398/",
            valorVenda: "1100000.00",
            valorOriginal: "1100000.00",
            endereco: "Avenida Bernardo Manuel - Eusebio, Fortaleza - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 180m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "180",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8105975,
                lng: -38.5550968
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-5-quartos-eusebio-bairros-eusebio-com-garagem-300m2-venda-RS1700000-id-2562052552/",
            valorVenda: "1700000.00",
            valorOriginal: "1700000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 5 Quartos à Venda, 300m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "300",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-372m2-venda-RS3300000-id-2738387471/",
            valorVenda: "3300000.00",
            valorOriginal: "3300000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 372m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "372",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-237m2-venda-RS1280000-id-2745158351/",
            valorVenda: "1280000.00",
            valorOriginal: "1280000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 237m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "237",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-257m2-venda-RS2090000-id-2659933152/",
            valorVenda: "2090000.00",
            valorOriginal: "2090000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 257m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "257",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-5-quartos-eusebio-bairros-eusebio-com-garagem-380m2-venda-RS4800000-id-2748209198/",
            valorVenda: "4800000.00",
            valorOriginal: "4800000.00",
            endereco: "CE-040, 5810 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 5 Quartos à Venda, 380m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "380",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8880329,
                lng: -38.4374996
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-5-quartos-eusebio-bairros-eusebio-com-garagem-584m2-venda-RS10000000-id-2754340902/",
            valorVenda: "10000000.00",
            valorOriginal: "10000000.00",
            endereco: "Avenida Eusébio de Queiroz, 4130 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 5 Quartos à Venda, 584m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "584",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8882052,
                lng: -38.4564817
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-322m2-venda-RS2850000-id-2624591240/",
            valorVenda: "2850000.00",
            valorOriginal: "2850000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 322m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "322",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-3-quartos-eusebio-bairros-eusebio-com-garagem-220m2-venda-RS980000-id-2608853272/",
            valorVenda: "980000.00",
            valorOriginal: "980000.00",
            endereco: "Avenida José Moraes de Almeida, 2049 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 220m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "220",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8619782,
                lng: -38.461431
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-5-quartos-eusebio-bairros-eusebio-com-garagem-396m2-venda-RS1850000-id-2558550122/",
            valorVenda: "1850000.00",
            valorOriginal: "1850000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 5 Quartos à Venda, 396m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "396",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-135m2-venda-RS580000-id-2763010076/",
            valorVenda: "580000.00",
            valorOriginal: "580000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 3 Quartos à Venda, 135m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "135",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-5-quartos-eusebio-bairros-eusebio-600m2-venda-RS4890000-id-2574851576/",
            valorVenda: "NaN",
            valorOriginal: "NaN",
            endereco: "Avenida Eusébio de Queiroz, 4065 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 5 Quartos à Venda, 600m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "600",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8876475,
                lng: -38.4561397
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-5-quartos-eusebio-bairros-eusebio-com-garagem-737m2-venda-RS11000000-id-2726393791/",
            valorVenda: "11000000.00",
            valorOriginal: "11000000.00",
            endereco: "Avenida Eusébio de Queiroz, 3865 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 5 Quartos à Venda, 737m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "737",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8879668,
                lng: -38.4559041
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-6-quartos-eusebio-bairros-eusebio-com-garagem-650m2-venda-RS6500000-id-2561602120/",
            valorVenda: "6500000.00",
            valorOriginal: "6500000.00",
            endereco: "Avenida Litorânea, 2041 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 6 Quartos à Venda, 650m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "650",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8116741,
                lng: -38.4240149
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-350m2-venda-RS2000000-id-2675940101/",
            valorVenda: "2000000.00",
            valorOriginal: "2000000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 350m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "350",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-326m2-venda-RS2750000-id-2624592022/",
            valorVenda: "2750000.00",
            valorOriginal: "2750000.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 326m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "326",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-5-quartos-eusebio-bairros-eusebio-com-garagem-341m2-venda-RS2600000-id-2762943135/",
            valorVenda: "2600000.00",
            valorOriginal: "2600000.00",
            endereco: "Avenida Eusébio de Queiroz, 4130 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 5 Quartos à Venda, 341m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "341",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8882052,
                lng: -38.4564817
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-5-quartos-eusebio-bairros-eusebio-com-garagem-350m2-venda-RS2400000-id-2651861059/",
            valorVenda: "2400000.00",
            valorOriginal: "2400000.00",
            endereco: "Avenida Eusébio de Queiroz, 4130 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 5 Quartos à Venda, 350m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "350",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8882052,
                lng: -38.4564817
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-200m2-venda-RS980000-id-2760886624/",
            valorVenda: "980000.00",
            valorOriginal: "980000.00",
            endereco: "Rua Mirian de Abreu, 230 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 200m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "200",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.901245,
                lng: -38.4549775
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-4-quartos-eusebio-bairros-eusebio-com-garagem-144m2-venda-RS759900-id-2763343120/",
            valorVenda: "759900.00",
            valorOriginal: "759900.00",
            endereco: "Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 4 Quartos à Venda, 144m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "144",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.8890009,
                lng: -38.4546539
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-4-quartos-eusebio-bairros-eusebio-com-garagem-213m2-venda-RS1200000-id-2611799227/",
            valorVenda: "1200000.00",
            valorOriginal: "1200000.00",
            endereco: "Rua Guaramirim, 399 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa de condomínio com 4 Quartos à Venda, 213m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "213",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.895529,
                lng: -38.441027
            }
        },
        {
            url: "https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-eusebio-bairros-eusebio-com-garagem-400m2-venda-RS5800000-id-2558791361/",
            valorVenda: "5800000.00",
            valorOriginal: "5800000.00",
            endereco: "CE-040, 700 - Eusebio, Eusébio - CE",
            imagemUrl: "https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
            titulo: "Casa com 3 Quartos à Venda, 400m²",
            estado: "CE",
            cidade: "Eusebio",
            tipoNegocio: 2,
            descricaoTipoNegocio: "Venda",
            tipoImovel: 1,
            descricaoTipoImovel: "Casa",
            area: "400",
            areaTerreno: 0,
            vendido: 0,
            location: {
                lat: -3.861821,
                lng: -38.4734493
            }
        },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-siqueira-bairros-fortaleza-com-garagem-95m2-venda-RS80000-id-2722873386/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Safira, 101 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/a134beae81db9f2647f8f214a45df5f4/foto-1-de-casa-com-3-quartos-a-venda-95m-em-siqueira-fortaleza.webp",
               titulo:"Casa com 3 Quartos à Venda, 95m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:95,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8083031,
                  lng:-38.6238552
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS80000-id-2714434793/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua A Lot Messejana, 100 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/51f2f3d2c8e38d563ff03fe576c643f7/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-messejana-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8427322,
                  lng:-38.4919074
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS80000-id-2714984501/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua A Lot Messejana, 100 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/e3b2b2e53e7ab643bd1a164506a569e7/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-messejana-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8427322,
                  lng:-38.4919074
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS80000-id-2714981918/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua A Lot Messejana, 100 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/eec0306cae355e276549d47b90731567/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-messejana-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8427322,
                  lng:-38.4919074
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS80000-id-2714984502/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua A Lot Messejana, 100 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8427322,
                  lng:-38.4919074
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS80000-id-2738358879/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua Holanda, 574 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.805614899999999,
                  lng:-38.5586338
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS80000-id-2714434300/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua A Lot Messejana, 100 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8427322,
                  lng:-38.4919074
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS80000-id-2714434696/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua A Lot Messejana, 100 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8427322,
                  lng:-38.4919074
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-45m2-venda-RS80000-id-2763387915/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua B, 191 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7952255,
                  lng:-38.5625803
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-49m2-venda-RS80000-id-2756678450/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua C, 76 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8034507,
                  lng:-38.5497475
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-benfica-bairros-fortaleza-com-garagem-44m2-venda-RS80000-id-2751606620/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua Júlio César, 178 - Benfica, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.746565,
                  lng:-38.5394965
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-42m2-venda-RS80000-id-2753279505/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua 6, 55 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7949112,
                  lng:-38.5411836
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-sao-bento-bairros-fortaleza-com-garagem-85m2-venda-RS80000-id-2740770601/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua Paisagistica, 90 - São Bento, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 85m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:85,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.857711,
                  lng:-38.4774153
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-45m2-venda-RS80000-id-2761664333/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua Noruega, 191 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7901062,
                  lng:-38.5649544
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-48m2-venda-RS80000-id-2745747402/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua C, 105 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8151226,
                  lng:-38.5542233
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS80000-id-2763379616/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua B, 191 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7952255,
                  lng:-38.5625803
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS80000-id-2763386027/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua B, 191 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7952255,
                  lng:-38.5625803
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-48m2-venda-RS80000-id-2747369909/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua C, 105 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8151226,
                  lng:-38.5542233
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS80000-id-2738362339/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua Holanda, 574 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.805614899999999,
                  lng:-38.5586338
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS80000-id-2714984403/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua A Lot Messejana, 100 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8427322,
                  lng:-38.4919074
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS80000-id-2763387221/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua B, 191 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7952255,
                  lng:-38.5625803
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS80000-id-2738359873/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua Holanda, 574 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.805614899999999,
                  lng:-38.5586338
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-novo-mondubim-bairros-fortaleza-com-garagem-50m2-venda-RS80000-id-2734549040/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Alfredo Mamede, 777 - Novo Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8114637,
                  lng:-38.5840935
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-49m2-venda-RS80000-id-2756564258/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua C, 10 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7969263,
                  lng:-38.5482174
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-49m2-venda-RS80000-id-2757709520/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua C, 141 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8151251,
                  lng:-38.554225
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-43m2-venda-RS80000-id-2748463440/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua Monte Líbano, 944 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 43m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:43,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8286633,
                  lng:-38.565248
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-49m2-venda-RS80000-id-2759796146/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua C, 179 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8152957,
                  lng:-38.5543622
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jacarecanga-bairros-fortaleza-com-garagem-10m2-venda-RS80000-id-2762778132/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua Odorico de Morais, 300 - Jacarecanga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 10m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:10,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7162528,
                  lng:-38.5493289
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-49m2-venda-RS80000-id-2752681129/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua C, 279 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8052237,
                  lng:-38.5500446
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aracape-bairros-fortaleza-com-garagem-51m2-venda-RS80000-id-2760025328/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Doutor Procópio, 1360 - ARACAPE, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 51m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:51,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8255616,
                  lng:-38.5898325
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-48m2-venda-RS80000-id-2747694301/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua C, 105 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8151226,
                  lng:-38.5542233
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-novo-mondubim-bairros-fortaleza-60m2-venda-RS80000-id-2726679228/",
               valorVenda:"80000.00",
               valorOriginal:"80000.00",
               endereco:"Rua Alfredo Mamede, 777 - Novo Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8114637,
                  lng:-38.5840935
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-canindezinho-bairros-fortaleza-com-garagem-100m2-venda-RS81172-id-2758183428/",
               valorVenda:"81172.00",
               valorOriginal:"81172.00",
               endereco:"Rua Brilhante, N. 331  LT 58 59 QD 22 - Canindezinho, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 100m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:100,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8083315,
                  lng:-38.6262823
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-canindezinho-bairros-fortaleza-com-garagem-100m2-venda-RS81172-id-2759767026/",
               valorVenda:"81172.00",
               valorOriginal:"81172.00",
               endereco:"Rua Brilhante, N. 331  LT 58 59 QD 22 - Canindezinho, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 100m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:100,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8083315,
                  lng:-38.6262823
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-bom-jardim-bairros-fortaleza-com-garagem-264m2-venda-RS81964-id-2759409122/",
               valorVenda:"81964.00",
               valorOriginal:"81964.00",
               endereco:"Rua João Victor, N. 2474  PT LT 16 - Bom Jardim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 264m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:264,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7936436,
                  lng:-38.6274924
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-bom-jardim-bairros-fortaleza-com-garagem-264m2-venda-RS81964-id-2759767029/",
               valorVenda:"81964.00",
               valorOriginal:"81964.00",
               endereco:"Rua João Victor, N. 2474  PT LT 16 - Bom Jardim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 264m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:264,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7936436,
                  lng:-38.6274924
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/kitnet-1-quartos-benfica-bairros-fortaleza-22m2-venda-RS82000-id-2646994760/",
               valorVenda:"82000.00",
               valorOriginal:"82000.00",
               endereco:"Rua Marechal Deodoro, 460 - Benfica, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/7b24608a734d4efdc78825fa03c1ab7e/foto-1-de-kitnet-com-1-quarto-a-venda-22m-em-benfica-fortaleza.webp",
               titulo:"Apartamento com  Quarto à Venda, 22m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:22,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.739575099999999,
                  lng:-38.5353788
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS82000-id-2745283400/",
               valorVenda:"82000.00",
               valorOriginal:"82000.00",
               endereco:"Avenida Frei Cirilo, 953 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/9e71bca1deab2637ad1a0cd24739fb6e/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-messejana-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.813233,
                  lng:-38.4992473
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-44m2-venda-RS83721-id-2760108016/",
               valorVenda:"83721.00",
               valorOriginal:"83721.00",
               endereco:"Rua Alencar Oliveira, N. 470 APTO. 302 BL 08 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/84f499690546c7b693421cb58718c0b9/foto-1-de-apartamento-com-2-quartos-a-venda-44m-em-passare-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8146422,
                  lng:-38.5200259
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-45m2-venda-RS84000-id-2745445620/",
               valorVenda:"84000.00",
               valorOriginal:"84000.00",
               endereco:"Rua C - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/27e2919893c21d3f0797b184bba80cd1/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-parque-dois-irmaos-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8162732,
                  lng:-38.554676
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-granja-lisboa-bairros-fortaleza-com-garagem-132m2-venda-RS84440-id-2744485629/",
               valorVenda:"84440.00",
               valorOriginal:"84440.00",
               endereco:"Rua Medelin, N. 3487 - Granja Lisboa, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 132m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:132,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7914865,
                  lng:-38.6299547
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-granja-lisboa-bairros-fortaleza-com-garagem-132m2-venda-RS84440-id-2759769018/",
               valorVenda:"84440.00",
               valorOriginal:"84440.00",
               endereco:"Rua Medelin, N. 3487 - Granja Lisboa, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 132m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:132,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7914865,
                  lng:-38.6299547
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-paupina-bairros-fortaleza-com-garagem-48m2-venda-RS85000-id-2762507230/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua A, 30 - Paupina, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8535035,
                  lng:-38.4921087
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-44m2-venda-RS85000-id-2762480016/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua A Lot Messejana, 100 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8427322,
                  lng:-38.4919074
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-itaperi-bairros-fortaleza-com-garagem-10m2-venda-RS85000-id-2759167016/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua Fagundes Varela, 100 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 10m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:10,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7807891,
                  lng:-38.5607012
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-paupina-bairros-fortaleza-com-garagem-48m2-venda-RS85000-id-2762242919/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua A, 30 - Paupina, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8535035,
                  lng:-38.4921087
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-ancuri-bairros-fortaleza-com-garagem-90m2-venda-RS85000-id-2755256099/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rodovia Quarto Anel Viário, 357 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8074048,
                  lng:-38.6382159
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2747142603/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua B, 568 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.811791,
                  lng:-38.5808401
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS85000-id-2761989618/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua B, 100 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7956815,
                  lng:-38.5616847
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-48m2-venda-RS85000-id-2745487603/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 105 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8151226,
                  lng:-38.5542233
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-44m2-venda-RS85000-id-2743373373/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 91 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8110589,
                  lng:-38.5504159
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-60m2-venda-RS85000-id-2753256793/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 100 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.795019299999999,
                  lng:-38.5473158
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-ancuri-bairros-fortaleza-com-garagem-98m2-venda-RS85000-id-2745738410/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rodovia Quarto Anel Viário, 239 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 98m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:98,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8058141,
                  lng:-38.6387328
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2759057714/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua Mário Araújo, 123 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8163239,
                  lng:-38.5316373
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2748952200/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 289 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8052679,
                  lng:-38.5501327
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-10m2-venda-RS85000-id-2757052722/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 105 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 10m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:10,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8151226,
                  lng:-38.5542233
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-44m2-venda-RS85000-id-2719479070/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 132 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8037286,
                  lng:-38.5497787
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2736697420/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua B, 980 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8148903,
                  lng:-38.5365463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-ancuri-bairros-fortaleza-com-garagem-90m2-venda-RS85000-id-2746699701/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rodovia Quarto Anel Viário, 230 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8060659,
                  lng:-38.6393847
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2754035508/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 91 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8110589,
                  lng:-38.5504159
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2754035495/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 91 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7949872,
                  lng:-38.5473963
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-pedras-bairros-fortaleza-com-garagem-90m2-venda-RS85000-id-2747705299/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua Quartzo, 130 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8882467,
                  lng:-38.5199306
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2744448107/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua B, 980 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8148903,
                  lng:-38.5365463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2758808418/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 105 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8151226,
                  lng:-38.5542233
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2742542353/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 91 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8110589,
                  lng:-38.5504159
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2758039921/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 105 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8151226,
                  lng:-38.5542233
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-pedras-bairros-fortaleza-com-garagem-90m2-venda-RS85000-id-2748446636/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua Quartzo, 30 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.888871,
                  lng:-38.5192621
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2743850587/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua Monte Líbano, 980 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8287175,
                  lng:-38.5651061
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2745568802/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 167 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8151268,
                  lng:-38.5542261
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-5-quartos-sao-bento-bairros-fortaleza-com-garagem-150m2-venda-RS85000-id-2750817247/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua K, 120 - São Bento, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 5 Quartos à Venda, 150m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:150,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8089107,
                  lng:-38.6202381
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-80m2-venda-RS85000-id-2744584874/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8215107,
                  lng:-38.580734
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-44m2-venda-RS85000-id-2762238722/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 91 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8110589,
                  lng:-38.5504159
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-60m2-venda-RS85000-id-2753256793/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 100 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/c03589ce2c05d91effc9a85125c34c80/foto-1-de-apartamento-com-2-quartos-a-venda-60m-em-maraponga-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.795019299999999,
                  lng:-38.5473158
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-sao-bento-bairros-fortaleza-com-garagem-80m2-venda-RS85000-id-2683837315/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua I, 64 - São Bento, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/5096cd7a7f6db26f9f81c794263e4d6c/foto-1-de-casa-com-3-quartos-a-venda-80m-em-sao-bento-fortaleza.webp",
               titulo:"Casa com 3 Quartos à Venda, 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8572385,
                  lng:-38.4914925
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-48m2-venda-RS85000-id-2745487603/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 105 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/94c1fd09a1cb7a7289c089e620d0f1ba/foto-1-de-apartamento-com-2-quartos-a-venda-48m-em-parque-dois-irmaos-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8151226,
                  lng:-38.5542233
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2745790421/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 256 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/d0b85bbdf67c46f37cba4cd309bfa454/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-parque-dois-irmaos-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8048871,
                  lng:-38.55027450000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-ancuri-bairros-fortaleza-com-garagem-90m2-venda-RS85000-id-2746163000/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rodovia Quarto Anel Viário, 210 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8057737,
                  lng:-38.6395415
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2732520322/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 31 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8151176,
                  lng:-38.5542198
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-pedras-bairros-fortaleza-com-garagem-150m2-venda-RS85000-id-2735324690/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 1 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 150m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:150,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8884323,
                  lng:-38.5449349
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2747142603/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua B, 568 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.811791,
                  lng:-38.5808401
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-ancuri-bairros-fortaleza-com-garagem-90m2-venda-RS85000-id-2746699701/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rodovia Quarto Anel Viário, 230 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8060659,
                  lng:-38.6393847
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2736697420/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua B, 980 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8148903,
                  lng:-38.5365463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-44m2-venda-RS85000-id-2762238722/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 91 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8110589,
                  lng:-38.5504159
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2754031476/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 91 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8110589,
                  lng:-38.5504159
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-44m2-venda-RS85000-id-2758789927/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 86 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8144773,
                  lng:-38.55380299999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-ancuri-bairros-fortaleza-com-garagem-80m2-venda-RS85000-id-2757483521/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rodovia Quarto Anel Viário, 391 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8076564,
                  lng:-38.6380779
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-44m2-venda-RS85000-id-2726447036/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 105 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8151226,
                  lng:-38.5542233
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-50m2-venda-RS85000-id-2758892837/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 2 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7969503,
                  lng:-38.5480721
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-ancuri-bairros-fortaleza-com-garagem-89m2-venda-RS85000-id-2749986995/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rodovia Quarto Anel Viário, 46 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 89m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:89,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8533986,
                  lng:-38.5591373
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-paupina-bairros-fortaleza-com-garagem-48m2-venda-RS85000-id-2762929082/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua A, 30 - Paupina, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8535035,
                  lng:-38.4921087
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-mondubim-bairros-fortaleza-com-garagem-120m2-venda-RS85000-id-2754190340/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Vila Humberto, 19 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 120m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:120,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8099817,
                  lng:-38.5699784
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2744734070/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua Monte Líbano, 923 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8281634,
                  lng:-38.564442
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-46m2-venda-RS85000-id-2732519454/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 980 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.820293399999999,
                  lng:-38.5560484
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2747149799/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 167 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8151268,
                  lng:-38.5542261
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2742042980/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 989 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.820519,
                  lng:-38.5558199
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2751598683/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 178 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8153233,
                  lng:-38.5543859
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2754512502/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 105 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8151226,
                  lng:-38.5542233
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-42m2-venda-RS85000-id-2734347719/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 10 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7969263,
                  lng:-38.5482174
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-80m2-venda-RS85000-id-2760234238/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua 122, 100 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.800507,
                  lng:-38.544685
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-48m2-venda-RS85000-id-2745491296/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 105 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8151226,
                  lng:-38.5542233
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2757875727/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 105 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8151226,
                  lng:-38.5542233
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-ancuri-bairros-fortaleza-com-garagem-90m2-venda-RS85000-id-2752950501/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rodovia Quarto Anel Viário, 115 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8050769,
                  lng:-38.6395484
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-48m2-venda-RS85000-id-2752682816/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 179 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8152957,
                  lng:-38.5543622
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2761673109/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 105 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8151226,
                  lng:-38.5542233
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-44m2-venda-RS85000-id-2719484313/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 133 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8045054,
                  lng:-38.5499512
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2745605895/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 105 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8151226,
                  lng:-38.5542233
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-rachel-de-queiroz-bairros-fortaleza-com-garagem-46m2-venda-RS85000-id-2757707614/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua B, 165 - Rachel de Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7953379,
                  lng:-38.5622624
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2744711524/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua B, 980 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8148903,
                  lng:-38.5365463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-45m2-venda-RS85000-id-2754512502/",
               valorVenda:"85000.00",
               valorOriginal:"85000.00",
               endereco:"Rua C, 105 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/8965a009809555d9dd92fe9c8cca61fb/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-parque-dois-irmaos-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8151226,
                  lng:-38.5542233
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-41m2-venda-RS85571-id-2685482039/",
               valorVenda:"85571.00",
               valorOriginal:"85571.00",
               endereco:"Avenida Dionísio Leonel Alencar - Pedras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/087759e9c88c10dda4c9ac689ed080c5/foto-1-de-apartamento-com-2-quartos-a-venda-41m-em-pedras-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 41m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:41,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8708699,
                  lng:-38.5265602
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-granja-lisboa-bairros-fortaleza-com-garagem-83m2-venda-RS85901-id-2754474132/",
               valorVenda:"85901.00",
               valorOriginal:"85901.00",
               endereco:"Rua Medelim, 3487 - Granja Lisboa, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/c02a99df2f935c5f0a6264f6fc4db1ca/foto-1-de-casa-com-3-quartos-a-venda-83m-em-granja-lisboa-fortaleza.webp",
               titulo:"Casa com 3 Quartos à Venda, 83m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:83,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7914865,
                  lng:-38.6299547
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-granja-lisboa-bairros-fortaleza-com-garagem-83m2-venda-RS85901-id-2759143928/",
               valorVenda:"85901.00",
               valorOriginal:"85901.00",
               endereco:"Rua Medelim, 3487 - Granja Lisboa, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/a30480f0a102b54fd1aa5195df11ceea/foto-1-de-casa-com-3-quartos-a-venda-83m-em-granja-lisboa-fortaleza.webp",
               titulo:"Casa com 3 Quartos à Venda, 83m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:83,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7914865,
                  lng:-38.6299547
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-41m2-venda-RS85943-id-2750588793/",
               valorVenda:"85943.00",
               valorOriginal:"85943.00",
               endereco:"Avenida Dionísio Leonel Alencar, N. 1596 APTO. 203 BL D - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 41m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:41,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8487397,
                  lng:-38.5040628
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-4-quartos-edson-queiroz-bairros-fortaleza-com-garagem-98m2-venda-RS86000-id-2558522796/",
               valorVenda:"86000.00",
               valorOriginal:"86000.00",
               endereco:"Rua Ramires Maranhão do Vale, 101 - Edson Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 4 Quartos à Venda, 98m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:98,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7749094,
                  lng:-38.4759338
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-siqueira-bairros-fortaleza-132m2-venda-RS86070-id-2750942896/",
               valorVenda:"86070.00",
               valorOriginal:"86070.00",
               endereco:"Rua Orlando Dias, N. 230  BAIRRO GRANJA LISBOA - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 132m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:132,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.79827,
                  lng:-38.6205893
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-siqueira-bairros-fortaleza-132m2-venda-RS86070-id-2759765296/",
               valorVenda:"86070.00",
               valorOriginal:"86070.00",
               endereco:"Rua Orlando Dias, N. 230  BAIRRO GRANJA LISBOA - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 132m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:132,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.79827,
                  lng:-38.6205893
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-granja-lisboa-bairros-fortaleza-com-garagem-140m2-venda-RS87000-id-2744670831/",
               valorVenda:"87000.00",
               valorOriginal:"87000.00",
               endereco:"Rua C - Granja Lisboa, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 140m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:140,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8154747,
                  lng:-38.5150516
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-siqueira-bairros-fortaleza-com-garagem-95m2-venda-RS87000-id-2749338094/",
               valorVenda:"87000.00",
               valorOriginal:"87000.00",
               endereco:"Rua Nova, 78 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 95m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:95,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.803242,
                  lng:-38.6301354
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-siqueira-bairros-fortaleza-com-garagem-90m2-venda-RS87000-id-2757792625/",
               valorVenda:"87000.00",
               valorOriginal:"87000.00",
               endereco:"Rua Nova, 100 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.803242,
                  lng:-38.6301354
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-siqueira-bairros-fortaleza-com-garagem-90m2-venda-RS87000-id-2745327508/",
               valorVenda:"87000.00",
               valorOriginal:"87000.00",
               endereco:"Rua Nova, 76 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.803242,
                  lng:-38.6301354
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-siqueira-bairros-fortaleza-com-garagem-90m2-venda-RS87000-id-2757873764/",
               valorVenda:"87000.00",
               valorOriginal:"87000.00",
               endereco:"Rua Nova, 114 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.803242,
                  lng:-38.6301354
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-siqueira-bairros-fortaleza-com-garagem-98m2-venda-RS87000-id-2744461602/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Estrada do Jatobá, 3431 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 98m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:98,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8017297,
                  lng:-38.6274431
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-siqueira-bairros-fortaleza-com-garagem-92m2-venda-RS87000-id-2744438228/",
               valorVenda:"87000.00",
               valorOriginal:"87000.00",
               endereco:"Rua Local 07 do Loteamento Tatu Mundé, 90 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 92m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:92,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8008618,
                  lng:-38.6135321
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-siqueira-bairros-fortaleza-com-garagem-90m2-venda-RS87000-id-2761916050/",
               valorVenda:"87000.00",
               valorOriginal:"87000.00",
               endereco:"Rua Nova, 89 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.803242,
                  lng:-38.6301354
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-siqueira-bairros-fortaleza-com-garagem-90m2-venda-RS87000-id-2758800418/",
               valorVenda:"87000.00",
               valorOriginal:"87000.00",
               endereco:"Rua Nova, 120 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.803242,
                  lng:-38.6301354
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-siqueira-bairros-fortaleza-com-garagem-140m2-venda-RS87000-id-2744269158/",
               valorVenda:"87000.00",
               valorOriginal:"87000.00",
               endereco:"Estrada do Jatobá - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 140m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:140,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7990329,
                  lng:-38.6270596
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-siqueira-bairros-fortaleza-com-garagem-90m2-venda-RS87000-id-2756261523/",
               valorVenda:"87000.00",
               valorOriginal:"87000.00",
               endereco:"Rua Nova, 122 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.803242,
                  lng:-38.6301354
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-42m2-venda-RS87000-id-2736122522/",
               valorVenda:"87000.00",
               valorOriginal:"87000.00",
               endereco:"Rua C, 76 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8034507,
                  lng:-38.5497475
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-siqueira-bairros-fortaleza-com-garagem-90m2-venda-RS87000-id-2744762228/",
               valorVenda:"87000.00",
               valorOriginal:"87000.00",
               endereco:"Rua Nova, 76 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.803242,
                  lng:-38.6301354
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-65m2-venda-RS87900-id-2746133015/",
               valorVenda:"87900.00",
               valorOriginal:"87900.00",
               endereco:"Rua 05H do Loteamento Cidade Jardim - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 65m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:65,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8289449,
                  lng:-38.5389966
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-85m2-venda-RS87900-id-2744720778/",
               valorVenda:"87900.00",
               valorOriginal:"87900.00",
               endereco:"Rua 05H do Loteamento Cidade Jardim, 836 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 85m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:85,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8289449,
                  lng:-38.5389966
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-77m2-venda-RS87900-id-2745608604/",
               valorVenda:"87900.00",
               valorOriginal:"87900.00",
               endereco:"Rua 05H do Loteamento Cidade Jardim, 1267 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 77m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:77,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8289449,
                  lng:-38.5389966
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-45m2-venda-RS88000-id-2741941468/",
               valorVenda:"88000.00",
               valorOriginal:"88000.00",
               endereco:"Rua B, 980 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8148903,
                  lng:-38.5365463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-granja-lisboa-bairros-fortaleza-com-garagem-90m2-venda-RS88000-id-2761986413/",
               valorVenda:"88000.00",
               valorOriginal:"88000.00",
               endereco:"Rua José Carlos Bezerra, 90 - Granja Lisboa, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7935397,
                  lng:-38.6234586
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-44m2-venda-RS88000-id-2734825620/",
               valorVenda:"88000.00",
               valorOriginal:"88000.00",
               endereco:"Rua B, 980 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8148903,
                  lng:-38.5365463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-49m2-venda-RS88000-id-2751816310/",
               valorVenda:"88000.00",
               valorOriginal:"88000.00",
               endereco:"Rua C, 168 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8152957,
                  lng:-38.5543622
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-44m2-venda-RS88999-id-2708657474/",
               valorVenda:"88999.00",
               valorOriginal:"88999.00",
               endereco:"Rua C, 88 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8144972,
                  lng:-38.5538181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-45m2-venda-RS89000-id-2755920432/",
               valorVenda:"89000.00",
               valorOriginal:"89000.00",
               endereco:"Rua C, 120 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8036292,
                  lng:-38.5497307
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-1-quartos-joao-xxiii-bairros-fortaleza-com-garagem-86m2-venda-RS89000-id-2673938275/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Audízio Pinheiro, 2031 - João XXIII, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com  Quarto à Venda, 86m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:86,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7685099,
                  lng:-38.592852
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-56m2-venda-RS89739-id-2754462403/",
               valorVenda:"89739.00",
               valorOriginal:"89739.00",
               endereco:"Avenida I, N. 1661 APTO. 102 . - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8389435,
                  lng:-38.5546209
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-granja-lisboa-bairros-fortaleza-137m2-venda-RS89739-id-2762688731/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Manuel Bezerra dos Santos, N. 101  . - Granja Lisboa, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 137m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:137,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.786817699999999,
                  lng:-38.6113375
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-56m2-venda-RS89739-id-2759768121/",
               valorVenda:"89739.00",
               valorOriginal:"89739.00",
               endereco:"Avenida I, N. 1661 APTO. 102 . - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8389435,
                  lng:-38.5546209
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-50m2-venda-RS90000-id-2663207974/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua Monte Líbano - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8262274,
                  lng:-38.5631478
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-46m2-venda-RS90000-id-2763222538/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua Coronel Manuel Albano, 51 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8076124,
                  lng:-38.5666835
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-50m2-venda-RS90000-id-2754751707/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua 03H, 100 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/a6ae2ddf889d5c600bfb43f395d93562/foto-1-de-apartamento-com-2-quartos-a-venda-50m-em-prefeito-jose-walter-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8286685,
                  lng:-38.53745230000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jangurussu-bairros-fortaleza-45m2-venda-RS90000-id-2719856549/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua Florêncio Fontenele, 380 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/8a96508904d51792899712b6b29d163f/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-jangurussu-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.833748999999999,
                  lng:-38.5084662
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-60m2-venda-RS90000-id-2756196842/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua 03H, 198 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/31fc32292f10d2ccd68ca1254dd47e85/foto-1-de-apartamento-com-2-quartos-a-venda-60m-em-prefeito-jose-walter-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8308574,
                  lng:-38.5358866
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-itaperi-bairros-fortaleza-com-garagem-90m2-venda-RS90000-id-2762705824/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua Cambará, 67 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/ba8b289bfb805cbfcf1a38fb1bcfedfa/foto-1-de-casa-com-2-quartos-a-venda-90m-em-itaperi-fortaleza.webp",
               titulo:"Casa com 2 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7809613,
                  lng:-38.5630468
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-44m2-venda-RS90000-id-2711537300/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua C, 59 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8031894,
                  lng:-38.549461
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-44m2-venda-RS90000-id-2718332737/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua C, 105 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8151226,
                  lng:-38.5542233
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-44m2-venda-RS90000-id-2726433188/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua C, 105 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8151226,
                  lng:-38.5542233
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-48m2-venda-RS90000-id-2752439001/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua B, 178 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.803922599999999,
                  lng:-38.55469250000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-45m2-venda-RS90000-id-2741365406/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua B, 44 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8030656,
                  lng:-38.5500415
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-60m2-venda-RS90000-id-2755035414/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua 03H, 100 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8286685,
                  lng:-38.53745230000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-granja-lisboa-bairros-fortaleza-com-garagem-90m2-venda-RS90000-id-2762706642/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua José Carlos Bezerra, 90 - Granja Lisboa, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7935397,
                  lng:-38.6234586
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-60m2-venda-RS90000-id-2756213433/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua 03H, 197 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8308327,
                  lng:-38.5360298
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-granja-lisboa-bairros-fortaleza-com-garagem-85m2-venda-RS90000-id-2763056221/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua José Carlos Bezerra, 90 - Granja Lisboa, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 85m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:85,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7935397,
                  lng:-38.6234586
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-44m2-venda-RS90000-id-2714840600/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua C, 08 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.796968999999999,
                  lng:-38.5480789
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-itaperi-bairros-fortaleza-com-garagem-90m2-venda-RS90000-id-2763047071/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua Cambará, 90 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7809899,
                  lng:-38.563474
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-46m2-venda-RS90000-id-2756405926/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua 03H, 197 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8308327,
                  lng:-38.5360298
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-44m2-venda-RS90000-id-2715470439/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua C, 639 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8178174,
                  lng:-38.5549944
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-benfica-bairros-fortaleza-21m2-venda-RS90000-id-2762918327/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua Marechal Deodoro, 460 - Benfica, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com  Quarto à Venda, 21m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:21,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.739575099999999,
                  lng:-38.5353788
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-benfica-bairros-fortaleza-21m2-venda-RS90000-id-2762919120/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua Marechal Deodoro, 460 - Benfica, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com  Quarto à Venda, 21m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:21,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.739575099999999,
                  lng:-38.5353788
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-bonsucesso-bairros-fortaleza-com-garagem-56m2-venda-RS90000-id-2758219081/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua Bias Mendes, 1301 - Bonsucesso, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7766704,
                  lng:-38.5910547
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-48m2-venda-RS90000-id-2630012785/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua Alfredo Mamede, 777 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8114454,
                  lng:-38.5837415
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-benfica-bairros-fortaleza-21m2-venda-RS90000-id-2762918628/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua Marechal Deodoro, 460 - Benfica, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com  Quarto à Venda, 21m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:21,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.739575099999999,
                  lng:-38.5353788
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-53m2-venda-RS90000-id-2756533836/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Alfredo Mamede, 555 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 53m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:53,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8104703,
                  lng:-38.5817258
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-46m2-venda-RS90000-id-2763228530/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua Coronel Manuel Albano, 90 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8073329,
                  lng:-38.5670361
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-granja-lisboa-bairros-fortaleza-com-garagem-85m2-venda-RS90000-id-2762791616/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua José Carlos Bezerra, 90 - Granja Lisboa, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 85m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:85,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7935397,
                  lng:-38.6234586
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-itaperi-bairros-fortaleza-com-garagem-90m2-venda-RS90000-id-2762945752/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua Cambará, 300 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7838368,
                  lng:-38.5643064
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-44m2-venda-RS90000-id-2714811806/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua C, 2 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7969503,
                  lng:-38.5480721
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-44m2-venda-RS90000-id-2716003296/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua C, 65 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8032526,
                  lng:-38.5493713
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-novo-mondubim-bairros-fortaleza-com-garagem-48m2-venda-RS90000-id-2552619377/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua 101, 255 - Novo Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8093111,
                  lng:-38.5906327
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-45m2-venda-RS90000-id-2746935593/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua C, 367 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8060898,
                  lng:-38.5503851
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-85m2-venda-RS90000-id-2744453263/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua 05H do Loteamento Cidade Jardim, 980 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 85m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:85,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8289449,
                  lng:-38.5389966
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-52m2-venda-RS90000-id-2754994299/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua 03H, 179 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8298324,
                  lng:-38.537725
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-45m2-venda-RS90000-id-2744842837/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua C, 356 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8161644,
                  lng:-38.55477399999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-granja-lisboa-bairros-fortaleza-com-garagem-90m2-venda-RS90000-id-2761915625/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua José Carlos Bezerra, 2024 - Granja Lisboa, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7925969,
                  lng:-38.6303544
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-46m2-venda-RS90000-id-2763636036/",
               valorVenda:"90000.00",
               valorOriginal:"90000.00",
               endereco:"Rua Coronel Manuel Albano, 467 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8068559,
                  lng:-38.5694554
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-siqueira-bairros-fortaleza-360m2-venda-RS93629-id-2750588808/",
               valorVenda:"93629.00",
               valorOriginal:"93629.00",
               endereco:"Rua Miracel, N. 1450  LT 13  QD 80 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 360m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:360,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8049323,
                  lng:-38.6304163
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-45m2-venda-RS94000-id-2745606298/",
               valorVenda:"94000.00",
               valorOriginal:"94000.00",
               endereco:"Rua B, 267 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/dfb7ac8d360d066a616db18bd763e3e6/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-dende-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8038567,
                  lng:-38.5551518
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-1-quartos-aquiraz-bairros-fortaleza-150m2-venda-RS94000-id-2656744082/",
               valorVenda:"94000.00",
               valorOriginal:"94000.00",
               endereco:"CE-040 - Aquiraz, Aquiraz - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/9e0a7d036804d05bfc53727a918dc76c/foto-1-de-casa-de-condominio-com-1-quarto-a-venda-150m-em-aquiraz-fortaleza.webp",
               titulo:"Casa de condomínio com  Quarto à Venda, 150m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:150,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.9275105,
                  lng:-38.3883115
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-1-quartos-siqueira-bairros-fortaleza-com-garagem-150m2-venda-RS94815-id-2760104242/",
               valorVenda:"94815.00",
               valorOriginal:"94815.00",
               endereco:"Rua Quartzo Rosa, N. 207  LT 02 QD 12 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/c96b2e72ed52fae16ae973dd06d46f40/foto-1-de-casa-com-1-quarto-a-venda-150m-em-siqueira-fortaleza.webp",
               titulo:"Casa com  Quarto à Venda, 150m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:150,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8055467,
                  lng:-38.623802
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-46m2-venda-RS94900-id-2746131027/",
               valorVenda:"94900.00",
               valorOriginal:"94900.00",
               endereco:"Rua B, 87 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/64af4cdd4143bfb74b106b7122f87d33/foto-1-de-apartamento-com-2-quartos-a-venda-46m-em-dende-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7960768,
                  lng:-38.5617783
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS95000-id-2737304819/",
               valorVenda:"95000.00",
               valorOriginal:"95000.00",
               endereco:"Rua B, 295 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7950918,
                  lng:-38.5626782
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-maraponga-bairros-fortaleza-com-garagem-90m2-venda-RS95000-id-2759025759/",
               valorVenda:"95000.00",
               valorOriginal:"95000.00",
               endereco:"Rua 122, 100 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8799333,
                  lng:-38.60518880000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-43m2-venda-RS95000-id-2736239065/",
               valorVenda:"95000.00",
               valorOriginal:"95000.00",
               endereco:"Rua C - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 43m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:43,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8162732,
                  lng:-38.554676
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-parangaba-bairros-fortaleza-com-garagem-80m2-venda-RS95000-id-2760449717/",
               valorVenda:"95000.00",
               valorOriginal:"95000.00",
               endereco:"Rua 122, 100 - Parangaba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8799333,
                  lng:-38.60518880000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS95000-id-2734575230/",
               valorVenda:"95000.00",
               valorOriginal:"95000.00",
               endereco:"Rua Nunes Feijó, 1100 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8469009,
                  lng:-38.50433260000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-45m2-venda-RS95000-id-2738184318/",
               valorVenda:"95000.00",
               valorOriginal:"95000.00",
               endereco:"Rua C, 980 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.820293399999999,
                  lng:-38.5560484
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-44m2-venda-RS95000-id-2761974758/",
               valorVenda:"95000.00",
               valorOriginal:"95000.00",
               endereco:"Rua A Lot Messejana, 100 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8427322,
                  lng:-38.4919074
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-novo-mondubim-bairros-fortaleza-com-garagem-55m2-venda-RS95000-id-2719577548/",
               valorVenda:"95000.00",
               valorOriginal:"95000.00",
               endereco:"Rua Alfredo Mamede, 777 - Novo Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8114637,
                  lng:-38.5840935
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-50m2-venda-RS95000-id-2754839232/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua 03H, 200 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8308893,
                  lng:-38.5357756
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-granja-lisboa-bairros-fortaleza-com-garagem-90m2-venda-RS95000-id-2754450089/",
               valorVenda:"95000.00",
               valorOriginal:"95000.00",
               endereco:"Rua B, 345 - Granja Lisboa, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8093914,
                  lng:-38.6213146
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS95000-id-2746715930/",
               valorVenda:"95000.00",
               valorOriginal:"95000.00",
               endereco:"Rua Júlio Alcides, 246 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7886896,
                  lng:-38.564011
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-carlito-pamplona-bairros-fortaleza-70m2-venda-RS95000-id-2684407621/",
               valorVenda:"95000.00",
               valorOriginal:"95000.00",
               endereco:"Avenida Francisco Sá, 3861 - Carlito Pamplona, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7167776,
                  lng:-38.5596323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-paupina-bairros-fortaleza-com-garagem-47m2-venda-RS95000-id-2761984417/",
               valorVenda:"95000.00",
               valorOriginal:"95000.00",
               endereco:"Rua A, 30 - Paupina, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8535035,
                  lng:-38.4921087
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-45m2-venda-RS95000-id-2759050817/",
               valorVenda:"95000.00",
               valorOriginal:"95000.00",
               endereco:"Rua 03H, 123 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.828789,
                  lng:-38.5374807
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS95000-id-2734576015/",
               valorVenda:"95000.00",
               valorOriginal:"95000.00",
               endereco:"Rua Nunes Feijó, 1100 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8469009,
                  lng:-38.50433260000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS95000-id-2737302518/",
               valorVenda:"95000.00",
               valorOriginal:"95000.00",
               endereco:"Rua B, 51 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.796436,
                  lng:-38.5618282
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-paupina-bairros-fortaleza-com-garagem-48m2-venda-RS95000-id-2762227732/",
               valorVenda:"95000.00",
               valorOriginal:"95000.00",
               endereco:"Rua A, 30 - Paupina, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8535035,
                  lng:-38.4921087
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-novo-mondubim-bairros-fortaleza-47m2-venda-RS95000-id-2710339599/",
               valorVenda:"95000.00",
               valorOriginal:"95000.00",
               endereco:"Rua 101, 255 - Novo Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8093111,
                  lng:-38.5906327
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-itaperi-bairros-fortaleza-com-garagem-90m2-venda-RS95000-id-2759372532/",
               valorVenda:"95000.00",
               valorOriginal:"95000.00",
               endereco:"Avenida A, 100 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.816340099999999,
                  lng:-38.5591316
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-80m2-venda-RS95000-id-2759676416/",
               valorVenda:"95000.00",
               valorOriginal:"95000.00",
               endereco:"Rua 109, 100 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8009746,
                  lng:-38.5466298
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-jacarecanga-bairros-fortaleza-35m2-venda-RS95000-id-2755092215/",
               valorVenda:"95000.00",
               valorOriginal:"95000.00",
               endereco:"Rua Júlio Pinto - Jacarecanga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com  Quarto à Venda, 35m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:35,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7262326,
                  lng:-38.5444269
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-paupina-bairros-fortaleza-com-garagem-48m2-venda-RS95000-id-2762232719/",
               valorVenda:"95000.00",
               valorOriginal:"95000.00",
               endereco:"Rua A, 30 - Paupina, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8535035,
                  lng:-38.4921087
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS95000-id-2746718804/",
               valorVenda:"95000.00",
               valorOriginal:"95000.00",
               endereco:"Rua Júlio Alcides, 267 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7882415,
                  lng:-38.564098
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-santa-maria-bairros-fortaleza-com-garagem-42m2-venda-RS95000-id-2673758281/",
               valorVenda:"95000.00",
               valorOriginal:"95000.00",
               endereco:"Estrada do Ancuri - Parque Santa Maria, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8402897,
                  lng:-38.4969419
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-granja-lisboa-bairros-fortaleza-com-garagem-90m2-venda-RS95000-id-2755038428/",
               valorVenda:"95000.00",
               valorOriginal:"95000.00",
               endereco:"Rua B, 87 - Granja Lisboa, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8084329,
                  lng:-38.6038839
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-mondubim-bairros-fortaleza-100m2-venda-RS95200-id-2758587362/",
               valorVenda:"95200.00",
               valorOriginal:"95200.00",
               endereco:"Rua C - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 100m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:100,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8215107,
                  lng:-38.580734
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cajazeiras-bairros-fortaleza-56m2-venda-RS96000-id-2761405413/",
               valorVenda:"96000.00",
               valorOriginal:"96000.00",
               endereco:"Avenida Deputado Paulino Rocha, 1001 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8062282,
                  lng:-38.5081022
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-46m2-venda-RS96000-id-2756679135/",
               valorVenda:"96000.00",
               valorOriginal:"96000.00",
               endereco:"Rua Júlio Alcides, 18 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7880078,
                  lng:-38.5665561
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-siqueira-bairros-fortaleza-com-garagem-150m2-venda-RS96211-id-2759694231/",
               valorVenda:"96211.00",
               valorOriginal:"96211.00",
               endereco:"Rua Esmeralda, N. 76  UNIDADE A - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 150m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:150,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8059566,
                  lng:-38.6241216
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-siqueira-bairros-fortaleza-com-garagem-150m2-venda-RS96211-id-2759768009/",
               valorVenda:"96211.00",
               valorOriginal:"96211.00",
               endereco:"Rua Esmeralda, N. 76  UNIDADE A - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 150m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:150,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8059566,
                  lng:-38.6241216
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-paupina-bairros-fortaleza-47m2-venda-RS96592-id-2758184120/",
               valorVenda:"96592.00",
               valorOriginal:"96592.00",
               endereco:"Rua Central do Loteamento Parque Messejana, N. 100 APTO. 202 QD 02 BL 06 - Paupina, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com  Quarto à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8462847,
                  lng:-38.4865106
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jacarecanga-bairros-fortaleza-com-garagem-44m2-venda-RS97900-id-2748802503/",
               valorVenda:"97900.00",
               valorOriginal:"97900.00",
               endereco:"Rua Odorico de Morais, 250 - Jacarecanga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7160072,
                  lng:-38.5485818
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jacarecanga-bairros-fortaleza-com-garagem-44m2-venda-RS97900-id-2748802503/",
               valorVenda:"97900.00",
               valorOriginal:"97900.00",
               endereco:"Rua Odorico de Morais, 250 - Jacarecanga, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/24aca335d6bb639454b70eef5e778942/foto-1-de-apartamento-com-2-quartos-a-venda-44m-em-jacarecanga-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7160072,
                  lng:-38.5485818
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS98000-id-2746966698/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 100 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/7ab2106d8cabf1ce206ff6f855368430/foto-1-de-apartamento-com-2-quartos-a-venda-44m-em-maraponga-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.78826,
                  lng:-38.5656845
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-46m2-venda-RS98000-id-2757618086/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 19 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/caef21acb71e1725b953ee86f15870fa/foto-1-de-apartamento-com-2-quartos-a-venda-46m-em-maraponga-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7878062,
                  lng:-38.5664872
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS98000-id-2759229131/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 100 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/6f5f2cbb3acafa99c8e64b00d98be2c4/foto-1-de-apartamento-com-2-quartos-a-venda-44m-em-maraponga-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.78826,
                  lng:-38.5656845
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-edson-queiroz-bairros-fortaleza-com-garagem-48m2-venda-RS98000-id-2708528552/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Cidade Ecológica, 555 - Edson Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7737129,
                  lng:-38.4647685
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS98000-id-2746968568/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 320 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7889666,
                  lng:-38.5626867
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-46m2-venda-RS98000-id-2747232819/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 320 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7889666,
                  lng:-38.5626867
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS98000-id-2756822040/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 320 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7889666,
                  lng:-38.5626867
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-novo-mondubim-bairros-fortaleza-45m2-venda-RS98000-id-2742506268/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua 101, 255 - Novo Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8093111,
                  lng:-38.5906327
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS98000-id-2747143840/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Noruega - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7906466,
                  lng:-38.5651212
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS98000-id-2758233718/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 320 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7889666,
                  lng:-38.5626867
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS98000-id-2746959245/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 100 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.78826,
                  lng:-38.5656845
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS98000-id-2762508415/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 320 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7889666,
                  lng:-38.5626867
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS98000-id-2759198222/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 320 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7889666,
                  lng:-38.5626867
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS98000-id-2759969128/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 320 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7889666,
                  lng:-38.5626867
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS98000-id-2758033687/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 320 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7889666,
                  lng:-38.5626867
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS98000-id-2759844415/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 320 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7889666,
                  lng:-38.5626867
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS98000-id-2762685317/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 320 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7889666,
                  lng:-38.5626867
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-46m2-venda-RS98000-id-2747229561/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 320 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7889666,
                  lng:-38.5626867
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS98000-id-2762793832/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 320 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7889666,
                  lng:-38.5626867
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS98000-id-2757034535/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 320 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7889666,
                  lng:-38.5626867
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS98000-id-2755947876/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 100 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.78826,
                  lng:-38.5656845
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS98000-id-2746938498/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 320 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7889666,
                  lng:-38.5626867
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-46m2-venda-RS98000-id-2747156922/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 320 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7889666,
                  lng:-38.5626867
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS98000-id-2759843235/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 320 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7889666,
                  lng:-38.5626867
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS98000-id-2746964504/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 100 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.78826,
                  lng:-38.5656845
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS98000-id-2761694618/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 320 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7889666,
                  lng:-38.5626867
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS98000-id-2762932619/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 320 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7889666,
                  lng:-38.5626867
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS98000-id-2757867653/",
               valorVenda:"98000.00",
               valorOriginal:"98000.00",
               endereco:"Rua Júlio Alcides, 320 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7889666,
                  lng:-38.5626867
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-siqueira-bairros-fortaleza-com-garagem-120m2-venda-RS98760-id-2750942895/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Miracel, N. 1335 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 120m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:120,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.805511699999999,
                  lng:-38.628991
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-siqueira-bairros-fortaleza-70m2-venda-RS98760-id-2759762921/",
               valorVenda:"98760.00",
               valorOriginal:"98760.00",
               endereco:"Rua Miracel, 1335 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.805511699999999,
                  lng:-38.628991
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-siqueira-bairros-fortaleza-com-garagem-120m2-venda-RS98760-id-2759767058/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Miracel, N. 1335 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 120m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:120,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.805511699999999,
                  lng:-38.628991
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-paupina-bairros-fortaleza-51m2-venda-RS98963-id-2760108113/",
               valorVenda:"98963.00",
               valorOriginal:"98963.00",
               endereco:"Rua Central do Loteamento Parque Messejana, N. 100 APTO. 3 BL 01 - Paupina, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com  Quarto à Venda, 51m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:51,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8462847,
                  lng:-38.4865106
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-presidente-vargas-bairros-fortaleza-com-garagem-43m2-venda-RS99000-id-2699938683/",
               valorVenda:"99000.00",
               valorOriginal:"99000.00",
               endereco:"Rua Herculano Pena, 100 - Parque Presidente Vargas, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 43m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:43,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8274964,
                  lng:-38.5937239
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-conjunto-esperanca-bairros-fortaleza-com-garagem-42m2-venda-RS99000-id-2624424516/",
               valorVenda:"99000.00",
               valorOriginal:"99000.00",
               endereco:"Vila Torquato, 255 - Conjunto Esperança, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8091683,
                  lng:-38.5912517
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-bonsucesso-bairros-fortaleza-com-garagem-51m2-venda-RS99000-id-2567324032/",
               valorVenda:"99000.00",
               valorOriginal:"99000.00",
               endereco:"Rua Bias Mendes, 1301 - Bonsucesso, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 51m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:51,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7766704,
                  lng:-38.5910547
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-joaquim-tavora-bairros-fortaleza-60m2-venda-RS99000-id-2509867587/",
               valorVenda:"99000.00",
               valorOriginal:"99000.00",
               endereco:"Rua Fiscal Vieira, 3508 - Joaquim Tavora, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/8cf224942600219d5be2b8420e8670d4/foto-1-de-apartamento-com-2-quartos-a-venda-60m-em-joaquim-tavora-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7521427,
                  lng:-38.5162921
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-siqueira-bairros-fortaleza-com-garagem-50m2-venda-RS99000-id-2750294207/",
               valorVenda:"99000.00",
               valorOriginal:"99000.00",
               endereco:"Rua Monsenhor Agostinho - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/9b813d857af5e401d8bbd8456844e09a/foto-1-de-apartamento-com-2-quartos-a-venda-50m-em-siqueira-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7968036,
                  lng:-38.5908284
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-granja-lisboa-bairros-fortaleza-com-garagem-10m2-venda-RS99000-id-2753030694/",
               valorVenda:"99000.00",
               valorOriginal:"99000.00",
               endereco:"Rua Frank Sinatra, 34 - Granja Lisboa, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/26fa82c3a0489a869e7c2dd1e550f70e/foto-1-de-casa-com-3-quartos-a-venda-10m-em-granja-lisboa-fortaleza.webp",
               titulo:"Casa com 3 Quartos à Venda, 10m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:10,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7978151,
                  lng:-38.6186422
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-siqueira-bairros-fortaleza-com-garagem-70m2-venda-RS99201-id-2749487847/",
               valorVenda:"99201.00",
               valorOriginal:"99201.00",
               endereco:"Rua Miracel, 1335 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/58b95876eb0b2fae13f8f62e51204692/foto-1-de-apartamento-com-2-quartos-a-venda-70m-em-siqueira-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.805511699999999,
                  lng:-38.628991
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-siqueira-bairros-fortaleza-180m2-venda-RS99311-id-2744486227/",
               valorVenda:"99311.00",
               valorOriginal:"99311.00",
               endereco:"Rua Miracel, N. 1472  L - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 180m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:180,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8049727,
                  lng:-38.6302149
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-siqueira-bairros-fortaleza-180m2-venda-RS99311-id-2759768028/",
               valorVenda:"99311.00",
               valorOriginal:"99311.00",
               endereco:"Rua Miracel, N. 1472  L - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 180m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:180,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8049727,
                  lng:-38.6302149
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-vila-peri-bairros-fortaleza-com-garagem-50m2-venda-RS99900-id-2736333238/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Monsenhor Agostinho, 803 - Vila Peri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.789308399999999,
                  lng:-38.5844441
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-passare-bairros-fortaleza-com-garagem-40m2-venda-RS100000-id-2733896542/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua Coronel Chico Alves, 21 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com  Quarto à Venda, 40m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:40,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8070918,
                  lng:-38.52605519999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-iracema-bairros-fortaleza-com-garagem-58m2-venda-RS100000-id-2751721509/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua 3 - Parque Iracema, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos para Venda/Aluguel 58m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:58,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.802647,
                  lng:-38.4983268
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-farias-brito-bairros-fortaleza-52m2-venda-RS100000-id-2621175964/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua Princesa Isabel, 1408 - Farias Brito, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7332087,
                  lng:-38.5375558
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-conjunto-esperanca-bairros-fortaleza-com-garagem-56m2-venda-RS100000-id-2555168341/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua 105, 355 - Conjunto Esperança, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8095797,
                  lng:-38.5853486
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-conjunto-esperanca-bairros-fortaleza-com-garagem-47m2-venda-RS100000-id-2558621889/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua 101 - Conjunto Esperança, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8105478,
                  lng:-38.5886361
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-57m2-venda-RS100000-id-2522644090/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua Pedro Aguiar, 333 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 57m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:57,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7931269,
                  lng:-38.5444012
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/sobrado-3-quartos-parque-sao-jose-bairros-fortaleza-com-garagem-90m2-venda-RS100000-id-2689048982/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua Cônego de Castro, 4250 - Parque Sao Jose, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8028969,
                  lng:-38.5902308
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS100000-id-2729586216/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua Nunes Feijó, 1100 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8469009,
                  lng:-38.50433260000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jangurussu-bairros-fortaleza-com-garagem-45m2-venda-RS100000-id-2727040016/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua Nunes Feijó, 1100 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8469009,
                  lng:-38.50433260000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS100000-id-2735927626/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua B, 51 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.796436,
                  lng:-38.5618282
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-passare-bairros-fortaleza-com-garagem-40m2-venda-RS100000-id-2733897614/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua Coronel Chico Alves, 21 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com  Quarto à Venda, 40m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:40,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8070918,
                  lng:-38.52605519999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-bom-sucesso-bairros-fortaleza-com-garagem-45m2-venda-RS100000-id-2724852958/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua Bias Mendes, 1301 - Bom Sucesso, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7766704,
                  lng:-38.5910547
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-50m2-venda-RS100000-id-2652994455/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua Justino Soares, 1780 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8421468,
                  lng:-38.5108787
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-41m2-venda-RS100000-id-2685704733/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 41m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:41,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8118712,
                  lng:-38.5746698
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-barra-do-ceara-bairros-fortaleza-com-garagem-44m2-venda-RS100000-id-2761433726/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua Odorico de Morais, 100 - Barra do Ceará, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7155794,
                  lng:-38.5474549
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cajazeiras-bairros-fortaleza-com-garagem-57m2-venda-RS100000-id-2751936325/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua Francisca Nogueira Ramos, 156 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 57m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:57,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8066406,
                  lng:-38.5040511
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-ii-bairros-fortaleza-com-garagem-49m2-venda-RS100000-id-2729372656/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua Coronel Francisco Montenegro, 64 - Praia do Futuro II, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7553358,
                  lng:-38.4471047
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-43m2-venda-RS100000-id-2624474429/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Avenida I, 855 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 43m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:43,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8417237,
                  lng:-38.555731
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-presidente-vargas-bairros-fortaleza-com-garagem-43m2-venda-RS100000-id-2752009002/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua Herculano Pena, 100 - Parque Presidente Vargas, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 43m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:43,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8274964,
                  lng:-38.5937239
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-vila-velha-bairros-fortaleza-com-garagem-60m2-venda-RS100000-id-2746697698/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Avenida Major Assis, 2820 Altos - Vila Velha, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7192147,
                  lng:-38.60367300000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-45m2-venda-RS100000-id-2737520119/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua 6, 55 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7949112,
                  lng:-38.5411836
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS100000-id-2738358322/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua Nunes Feijó, 1100 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8469009,
                  lng:-38.50433260000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS100000-id-2735926926/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua B, 51 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.796436,
                  lng:-38.5618282
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS100000-id-2735927922/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua B, 51 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.796436,
                  lng:-38.5618282
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS100000-id-2737520538/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua Pedro Wilson, 55 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7916174,
                  lng:-38.544923
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-45m2-venda-RS100000-id-2737519921/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua 6, 55 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7949112,
                  lng:-38.5411836
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-45m2-venda-RS100000-id-2737520513/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua 6, 55 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7949112,
                  lng:-38.5411836
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-45m2-venda-RS100000-id-2737521922/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua Pedro Wilson, 55 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7916174,
                  lng:-38.544923
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-benfica-bairros-fortaleza-21m2-venda-RS100000-id-2713079916/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua Marechal Deodoro, 460 - Benfica, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com  Quarto à Venda, 21m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:21,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.739575099999999,
                  lng:-38.5353788
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS100000-id-2735926926/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua B, 51 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/121acbb0210202ccbe04b9502c79664f/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-maraponga-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.796436,
                  lng:-38.5618282
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-45m2-venda-RS100000-id-2737519921/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua 6, 55 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/df4865ab02b698c33dd9f8f0672e19d4/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-itaperi-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7949112,
                  lng:-38.5411836
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-45m2-venda-RS100000-id-2737520513/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua 6, 55 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/136856bce6890a02825c4d7c214cf5b7/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-itaperi-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7949112,
                  lng:-38.5411836
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-manuel-satiro-bairros-fortaleza-44m2-venda-RS100000-id-2719083646/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua Creuza Roque, 559 - Manuel Sátiro, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/ad17cd469f28b57748a824b44943763d/foto-1-de-apartamento-com-2-quartos-a-venda-44m-em-manuel-satiro-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.801276,
                  lng:-38.5800207
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS100000-id-2734268121/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Avenida da Saudade, 3225 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8152399,
                  lng:-38.5352715
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cidade-2000-bairros-fortaleza-com-garagem-73m2-venda-RS100000-id-2751719227/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Avenida das Adenanteras - Cidade 2000, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos para Venda/Aluguel 73m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:73,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.754108,
                  lng:-38.471573
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-joao-xxiii-bairros-fortaleza-52m2-venda-RS100000-id-2579809594/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua Chuí, 440 - João XXIII, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7674091,
                  lng:-38.5913591
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-presidente-vargas-bairros-fortaleza-com-garagem-43m2-venda-RS100000-id-2756861414/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Rua Herculano Pena, 100 - Parque Presidente Vargas, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 43m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:43,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8274964,
                  lng:-38.5937239
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-carlito-pamplona-bairros-fortaleza-com-garagem-44m2-venda-RS100000-id-2759684926/",
               valorVenda:"100000.00",
               valorOriginal:"100000.00",
               endereco:"Galeria Carlitus Center, 15 - Carlito Pamplona, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7178756,
                  lng:-38.5560028
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-parque-santa-maria-bairros-fortaleza-com-garagem-135m2-venda-RS100741-id-2759767236/",
               valorVenda:"100741.00",
               valorOriginal:"100741.00",
               endereco:"Rua Pedestre 13, N. 106  0 - Parque Santa Maria, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 135m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:135,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8548712,
                  lng:-38.5044934
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-parque-santa-maria-bairros-fortaleza-com-garagem-135m2-venda-RS100741-id-2744481078/",
               valorVenda:"100741.00",
               valorOriginal:"100741.00",
               endereco:"Rua Pedestre 13, N. 106  0 - Parque Santa Maria, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 135m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:135,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8548712,
                  lng:-38.5044934
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-curio-bairros-fortaleza-com-garagem-59m2-venda-RS101904-id-2756728233/",
               valorVenda:"101904.00",
               valorOriginal:"101904.00",
               endereco:"Avenida Odilon Guimarães, 2249 - Curió, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 59m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:59,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.81991,
                  lng:-38.4754
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-siqueira-bairros-fortaleza-com-garagem-150m2-venda-RS101904-id-2750943000/",
               valorVenda:"101904.00",
               valorOriginal:"101904.00",
               endereco:"Rua Safira, N. 115  A - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 150m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:150,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8081542,
                  lng:-38.6237423
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-curio-bairros-fortaleza-com-garagem-59m2-venda-RS101904-id-2759143140/",
               valorVenda:"101904.00",
               valorOriginal:"101904.00",
               endereco:"Avenida Odilon Guimarães, 2249 - Curió, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 59m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:59,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.81991,
                  lng:-38.4754
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-curio-bairros-fortaleza-com-garagem-59m2-venda-RS101904-id-2758496906/",
               valorVenda:"101904.00",
               valorOriginal:"101904.00",
               endereco:"Avenida Odilon Guimarães, 2249 - Curió, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 59m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:59,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.81991,
                  lng:-38.4754
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-siqueira-bairros-fortaleza-com-garagem-150m2-venda-RS101904-id-2759765542/",
               valorVenda:"101904.00",
               valorOriginal:"101904.00",
               endereco:"Rua Safira, N. 115  A - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 150m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:150,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8081542,
                  lng:-38.6237423
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-boa-vista-bairros-fortaleza-com-garagem-69m2-venda-RS104992-id-2759768214/",
               valorVenda:"104992.00",
               valorOriginal:"104992.00",
               endereco:"Rua Francisco Vilela, N. 357 APTO. 101 TIPO A - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 69m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:69,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7981934,
                  lng:-38.5159241
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-boa-vista-bairros-fortaleza-com-garagem-69m2-venda-RS104992-id-2751455096/",
               valorVenda:"104992.00",
               valorOriginal:"104992.00",
               endereco:"Rua Francisco Vilela, N. 357 APTO. 101 TIPO A - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 69m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:69,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7981934,
                  lng:-38.5159241
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-boa-vista-castelao-bairros-fortaleza-com-garagem-69m2-venda-RS104992-id-2763211620/",
               valorVenda:"104992.00",
               valorOriginal:"104992.00",
               endereco:"Rua Francisco Vilela, 357 - Boa Vista Castelao, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 69m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:69,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7981934,
                  lng:-38.5159241
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-boa-vista-castelao-bairros-fortaleza-com-garagem-69m2-venda-RS104992-id-2759141436/",
               valorVenda:"104992.00",
               valorOriginal:"104992.00",
               endereco:"Rua Francisco Vilela, 355 - Boa Vista Castelao, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 69m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:69,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.798022,
                  lng:-38.5157743
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-boa-vista-castelao-bairros-fortaleza-com-garagem-69m2-venda-RS104992-id-2758504463/",
               valorVenda:"104992.00",
               valorOriginal:"104992.00",
               endereco:"Rua Francisco Vilela, 357 - Boa Vista Castelao, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 69m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:69,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7981934,
                  lng:-38.5159241
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-boa-vista-castelao-bairros-fortaleza-com-garagem-69m2-venda-RS104992-id-2761042564/",
               valorVenda:"104992.00",
               valorOriginal:"104992.00",
               endereco:"Rua Francisco Vilela, 357 - Boa Vista Castelao, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 69m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:69,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7981934,
                  lng:-38.5159241
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-boa-vista-castelao-bairros-fortaleza-com-garagem-69m2-venda-RS104992-id-2756720420/",
               valorVenda:"104992.00",
               valorOriginal:"104992.00",
               endereco:"Rua Francisco Vilela, 357 - Boa Vista Castelao, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 69m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:69,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7981934,
                  lng:-38.5159241
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-jangurussu-bairros-fortaleza-com-garagem-41m2-venda-RS105000-id-2750172049/",
               valorVenda:"105000.00",
               valorOriginal:"105000.00",
               endereco:"Rua Luciano Alves, 100 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa de condomínio com 2 Quartos à Venda, 41m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:41,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8460169,
                  lng:-38.5099305
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS105000-id-2760827680/",
               valorVenda:"105000.00",
               valorOriginal:"105000.00",
               endereco:"Rua das Oiticicas, 100 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8040967,
                  lng:-38.5354466
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-50m2-venda-RS105000-id-2763520213/",
               valorVenda:"105000.00",
               valorOriginal:"105000.00",
               endereco:"Avenida Presidente Costa e Silva, 4777 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8267414,
                  lng:-38.5374601
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS105000-id-2759142115/",
               valorVenda:"105000.00",
               valorOriginal:"105000.00",
               endereco:"Rua Coronel Chico Alves, 21 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8070918,
                  lng:-38.52605519999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-rachel-de-queiroz-bairros-fortaleza-com-garagem-10m2-venda-RS105000-id-2762790028/",
               valorVenda:"105000.00",
               valorOriginal:"105000.00",
               endereco:"Rua A, 295 - Rachel de Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 10m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:10,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7946806,
                  lng:-38.5608694
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS105000-id-2713914193/",
               valorVenda:"105000.00",
               valorOriginal:"105000.00",
               endereco:"Avenida Presidente Costa e Silva, 11 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.827331,
                  lng:-38.535459
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS105000-id-2762991945/",
               valorVenda:"105000.00",
               valorOriginal:"105000.00",
               endereco:"Rua A, 100 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7944891,
                  lng:-38.5607019
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS105000-id-2714123122/",
               valorVenda:"105000.00",
               valorOriginal:"105000.00",
               endereco:"Avenida Presidente Costa e Silva, 856 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8130318,
                  lng:-38.5720556
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS105000-id-2713896731/",
               valorVenda:"105000.00",
               valorOriginal:"105000.00",
               endereco:"Avenida Presidente Costa e Silva, 80 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8158106,
                  lng:-38.5687396
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-rachel-de-queiroz-bairros-fortaleza-com-garagem-45m2-venda-RS105000-id-2762791915/",
               valorVenda:"105000.00",
               valorOriginal:"105000.00",
               endereco:"Rua A, 295 - Rachel de Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7946806,
                  lng:-38.5608694
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS105000-id-2760446667/",
               valorVenda:"105000.00",
               valorOriginal:"105000.00",
               endereco:"Rua das Oiticicas, 100 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8040967,
                  lng:-38.5354466
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-rachel-de-queiroz-bairros-fortaleza-com-garagem-45m2-venda-RS105000-id-2762795220/",
               valorVenda:"105000.00",
               valorOriginal:"105000.00",
               endereco:"Rua A, 295 - Rachel de Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7946806,
                  lng:-38.5608694
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-rachel-de-queiroz-bairros-fortaleza-com-garagem-46m2-venda-RS105000-id-2763514819/",
               valorVenda:"105000.00",
               valorOriginal:"105000.00",
               endereco:"Rua A, 295 - Rachel de Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7946806,
                  lng:-38.5608694
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-10m2-venda-RS105000-id-2761175415/",
               valorVenda:"105000.00",
               valorOriginal:"105000.00",
               endereco:"Rua das Oiticicas, 111 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/2262190cc9714ac83bb8ec96b26bdd91/foto-1-de-apartamento-com-2-quartos-a-venda-10m-em-passare-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 10m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:10,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8042186,
                  lng:-38.5352584
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-rachel-de-queiroz-bairros-fortaleza-com-garagem-45m2-venda-RS105000-id-2762792019/",
               valorVenda:"105000.00",
               valorOriginal:"105000.00",
               endereco:"Rua A, 295 - Rachel de Queiroz, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/d4014e708bfc06a227e82610199065ac/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-rachel-de-queiroz-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7946806,
                  lng:-38.5608694
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-rachel-de-queiroz-bairros-fortaleza-com-garagem-45m2-venda-RS105000-id-2762926968/",
               valorVenda:"105000.00",
               valorOriginal:"105000.00",
               endereco:"Rua A, 295 - Rachel de Queiroz, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/60a0a2a29b50a419ca4719055ff8374c/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-rachel-de-queiroz-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7946806,
                  lng:-38.5608694
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-47m2-venda-RS105000-id-2737718739/",
               valorVenda:"105000.00",
               valorOriginal:"105000.00",
               endereco:"Rua 6, 56 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/270dd135384df4f7b0d433b1852c7a59/foto-1-de-apartamento-com-2-quartos-a-venda-47m-em-passare-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.808709699999999,
                  lng:-38.5435709
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-49m2-venda-RS105000-id-2750778813/",
               valorVenda:"105000.00",
               valorOriginal:"105000.00",
               endereco:"Rua C, 278 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8051074,
                  lng:-38.5503155
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-49m2-venda-RS105000-id-2748951495/",
               valorVenda:"105000.00",
               valorOriginal:"105000.00",
               endereco:"Rua C, 19 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8026892,
                  lng:-38.5492699
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-rachel-de-queiroz-bairros-fortaleza-com-garagem-45m2-venda-RS105000-id-2763585814/",
               valorVenda:"105000.00",
               valorOriginal:"105000.00",
               endereco:"Rua A, 179 - Rachel de Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8056036,
                  lng:-38.5547384
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-46m2-venda-RS106000-id-2521504262/",
               valorVenda:"106000.00",
               valorOriginal:"106000.00",
               endereco:"Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.873815,
                  lng:-38.5073967
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aracape-bairros-fortaleza-136m2-venda-RS106667-id-2750588142/",
               valorVenda:"106667.00",
               valorOriginal:"106667.00",
               endereco:"Rua Doutor Procópio, N. 1380  UNID 01 - ARACAPE, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 136m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:136,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8258521,
                  lng:-38.589985
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-aracape-bairros-fortaleza-136m2-venda-RS106667-id-2759767947/",
               valorVenda:"106667.00",
               valorOriginal:"106667.00",
               endereco:"Rua Doutor Procópio, N. 1380  UNID 01 - ARACAPE, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 136m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:136,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8258521,
                  lng:-38.589985
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-57m2-venda-RS106845-id-2750334121/",
               valorVenda:"106845.00",
               valorOriginal:"106845.00",
               endereco:"Rua Pedro Aguiar, N. 333 APTO. 202 . - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 57m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:57,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7931269,
                  lng:-38.5444012
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-57m2-venda-RS106845-id-2759767237/",
               valorVenda:"106845.00",
               valorOriginal:"106845.00",
               endereco:"Rua Pedro Aguiar, N. 333 APTO. 202 . - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 57m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:57,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7931269,
                  lng:-38.5444012
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cambeba-bairros-fortaleza-com-garagem-44m2-venda-RS107000-id-2755516417/",
               valorVenda:"107000.00",
               valorOriginal:"107000.00",
               endereco:"Rua Fausto Aguiar, 100 - Cambeba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8064003,
                  lng:-38.4950237
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS109000-id-2753261914/",
               valorVenda:"109000.00",
               valorOriginal:"109000.00",
               endereco:"Rua Fausto Aguiar, 100 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8064003,
                  lng:-38.4950237
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-eusebio-bairros-fortaleza-com-garagem-44m2-venda-RS109000-id-2755929440/",
               valorVenda:"109000.00",
               valorOriginal:"109000.00",
               endereco:"Rua Fausto Aguiar, 100 - Eusebio, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8064003,
                  lng:-38.4950237
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS109000-id-2759371025/",
               valorVenda:"109000.00",
               valorOriginal:"109000.00",
               endereco:"Rua das Oiticicas, 100 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8040967,
                  lng:-38.5354466
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-43m2-venda-RS109000-id-2741094461/",
               valorVenda:"109000.00",
               valorOriginal:"109000.00",
               endereco:"Avenida I, 901 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 43m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:43,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8322278,
                  lng:-38.551241
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-granja-lisboa-bairros-fortaleza-com-garagem-171m2-venda-RS109315-id-2759767229/",
               valorVenda:"109315.00",
               valorOriginal:"109315.00",
               endereco:"Rua Sousa Carvalho, N. 3436  LT 21 QD 10 - Granja Lisboa, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 171m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:171,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7768901,
                  lng:-38.612243
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-granja-lisboa-bairros-fortaleza-com-garagem-171m2-venda-RS109315-id-2744814415/",
               valorVenda:"109315.00",
               valorOriginal:"109315.00",
               endereco:"Rua Sousa Carvalho, N. 3436  LT 21 QD 10 - Granja Lisboa, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 171m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:171,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7768901,
                  lng:-38.612243
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-55m2-venda-RS110000-id-2714436294/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Coronel de Queiroz, 620 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.834008,
                  lng:-38.5678844
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-49m2-venda-RS110000-id-2746121795/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua Crisanto Arruda, 12 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8189855,
                  lng:-38.5246342
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-agua-fria-bairros-fortaleza-com-garagem-86m2-venda-RS110000-id-2634543471/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua F, 50 - Agua Fria, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 86m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:86,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7825574,
                  lng:-38.473022
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cambeba-bairros-fortaleza-com-garagem-50m2-venda-RS110000-id-2761988017/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua Amâncio Valente, 100 - Cambeba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.805208,
                  lng:-38.4934654
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-55m2-venda-RS110000-id-2714436293/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Coronel de Queiroz, 620 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.834008,
                  lng:-38.5678844
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-60m2-venda-RS110000-id-2722250078/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Coronel de Queiroz, 620 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.834008,
                  lng:-38.5678844
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-49m2-venda-RS110000-id-2760712652/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua Nilo Firmeza, 120 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8086563,
                  lng:-38.5785033
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jangurussu-bairros-fortaleza-com-garagem-45m2-venda-RS110000-id-2725962755/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua Nunes Feijó, 1100 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8469009,
                  lng:-38.50433260000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-55m2-venda-RS110000-id-2714435905/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Coronel de Queiroz, 620 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.834008,
                  lng:-38.5678844
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-55m2-venda-RS110000-id-2722249750/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Coronel de Queiroz, 620 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.834008,
                  lng:-38.5678844
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-70m2-venda-RS110000-id-2574323117/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8003068,
                  lng:-38.5069443
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-59m2-venda-RS110000-id-2745817304/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua C, 267 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 59m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:59,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8159113,
                  lng:-38.5545326
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-edson-queiroz-bairros-fortaleza-com-garagem-75m2-venda-RS110000-id-2734993313/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua F, 1 - Edson Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 75m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:75,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7764564,
                  lng:-38.4730443
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-4-quartos-mondubim-bairros-fortaleza-120m2-venda-RS110000-id-2742470755/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua 4, 385 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 4 Quartos à Venda, 120m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:120,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8261705,
                  lng:-38.571357
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-siqueira-bairros-fortaleza-com-garagem-53m2-venda-RS110000-id-2683325401/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua Jardim do Éden - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 53m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:53,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7987769,
                  lng:-38.6291351
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-48m2-venda-RS110000-id-2723741836/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua das Carnaúbas, 100 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8051886,
                  lng:-38.5344246
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-edson-queiroz-bairros-fortaleza-com-garagem-75m2-venda-RS110000-id-2657969895/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Edson Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 75m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:75,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7708535,
                  lng:-38.4746411
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-planalto-ayrton-senna-bairros-fortaleza-com-garagem-44m2-venda-RS110000-id-2734654052/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Avenida Radialista João Ramos, 815 - Planalto Ayrton Senna, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/554680dad92e9552c23c176a62369534/foto-1-de-apartamento-com-2-quartos-a-venda-44m-em-planalto-ayrton-senna-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8413507,
                  lng:-38.5764046
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-siqueira-bairros-fortaleza-com-garagem-53m2-venda-RS110000-id-2683325401/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua Jardim do Éden - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/fe486d694f80bf63c00d2f8409fa0345/foto-1-de-apartamento-com-2-quartos-a-venda-53m-em-siqueira-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 53m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:53,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7987769,
                  lng:-38.6291351
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-edson-queiroz-bairros-fortaleza-com-garagem-75m2-venda-RS110000-id-2734993313/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua F, 1 - Edson Queiroz, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/322b612a268cf441ce21218216a05060/foto-1-de-apartamento-com-3-quartos-a-venda-75m-em-edson-queiroz-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 75m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:75,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7764564,
                  lng:-38.4730443
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-4-quartos-mondubim-bairros-fortaleza-120m2-venda-RS110000-id-2742470755/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua 4, 385 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/94b317466657b42ed2f3e75676707496/foto-1-de-casa-com-4-quartos-a-venda-120m-em-mondubim-fortaleza.webp",
               titulo:"Casa com 4 Quartos à Venda, 120m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:120,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8261705,
                  lng:-38.571357
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jangurussu-bairros-fortaleza-com-garagem-43m2-venda-RS110000-id-2610729291/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua Florêncio Fontenele, 420 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 43m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:43,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8343326,
                  lng:-38.5072206
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jangurussu-bairros-fortaleza-com-garagem-48m2-venda-RS110000-id-2743032553/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Travessa Tiradentes, 1570 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8492637,
                  lng:-38.5032559
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jangurussu-bairros-fortaleza-60m2-venda-RS110000-id-2759512834/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Dom Lustosa, 985 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8478487,
                  lng:-38.5116161
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-conjunto-esperanca-bairros-fortaleza-com-garagem-65m2-venda-RS110000-id-2614538700/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Avenida Contorno Norte, 1800 - Conjunto Esperança, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 65m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:65,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8086265,
                  lng:-38.5921971
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-vila-peri-bairros-fortaleza-com-garagem-60m2-venda-RS110000-id-2733686430/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Arruda Câmara, 509 - Vila Peri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7885625,
                  lng:-38.5847669
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-48m2-venda-RS110000-id-2723739611/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua das Carnaúbas, 100 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8051886,
                  lng:-38.5344246
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-52m2-venda-RS110000-id-2751874029/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua 6, 178 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8085286,
                  lng:-38.5519423
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cajazeiras-bairros-fortaleza-com-garagem-57m2-venda-RS110000-id-2732236197/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Avenida Deputado Paulino Rocha, 1001 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 57m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:57,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8062282,
                  lng:-38.5081022
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS110000-id-2725934037/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua Nunes Feijó, 1100 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8469009,
                  lng:-38.50433260000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jangurussu-bairros-fortaleza-com-garagem-45m2-venda-RS110000-id-2725934842/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua Nunes Feijó, 1100 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8469009,
                  lng:-38.50433260000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-55m2-venda-RS110000-id-2722250344/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Coronel de Queiroz, 620 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.834008,
                  lng:-38.5678844
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS110000-id-2763512417/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua das Oiticicas, 100 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8040967,
                  lng:-38.5354466
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cambeba-bairros-fortaleza-com-garagem-50m2-venda-RS110000-id-2762496335/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua Amâncio Valente, 100 - Cambeba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.805208,
                  lng:-38.4934654
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-granja-lisboa-bairros-fortaleza-com-garagem-46m2-venda-RS110000-id-2748365471/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua Guararema - Granja Lisboa, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.78782,
                  lng:-38.6194641
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-conjunto-esperanca-bairros-fortaleza-44m2-venda-RS110000-id-2760145514/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua Bento Gonçalves, 700 - Conjunto Esperança, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.811395799999999,
                  lng:-38.5874631
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-granja-lisboa-bairros-fortaleza-com-garagem-46m2-venda-RS110000-id-2750211249/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua Guararema - Granja Lisboa, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.78782,
                  lng:-38.6194641
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-41m2-venda-RS110000-id-2623841561/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Avenida I - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 41m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:41,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8353486,
                  lng:-38.5537971
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-48m2-venda-RS110000-id-2735824515/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Avenida da Saudade - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos para Venda/Aluguel 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8160621,
                  lng:-38.5361605
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-1-quartos-lagoa-redonda-bairros-fortaleza-80m2-venda-RS110000-id-2711598021/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua Joserisse Hortêncio dos Santos, 534 - Lagoa Redonda, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com  Quarto à Venda, 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8228484,
                  lng:-38.4588535
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cambeba-bairros-fortaleza-com-garagem-44m2-venda-RS110000-id-2761195738/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua Amâncio Valente, 100 - Cambeba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.805208,
                  lng:-38.4934654
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-barra-do-ceara-bairros-fortaleza-46m2-venda-RS110000-id-2554665943/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Avenida Mozart Pinheiro de Lucena - Barra do Ceará, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7064809,
                  lng:-38.5922568
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-45m2-venda-RS110000-id-2746391188/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua 6, 55 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7949112,
                  lng:-38.5411836
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS110000-id-2762504115/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua das Oiticicas, 100 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8040967,
                  lng:-38.5354466
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-49m2-venda-RS110000-id-2746154100/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua C, 267 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8159113,
                  lng:-38.5545326
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-bonsucesso-bairros-fortaleza-com-garagem-46m2-venda-RS110000-id-2625414690/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua Bias Mendes, 1302 - Bonsucesso, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7763578,
                  lng:-38.591015
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/kitnet-1-quartos-parreao-bairros-fortaleza-42m2-venda-RS110000-id-2755011820/",
               valorVenda:"110000.00",
               valorOriginal:"110000.00",
               endereco:"Rua Padre João Piamarta, 1 - Parreão, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com  Quarto à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7551925,
                  lng:-38.5405214
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-1-quartos-dias-macedo-bairros-fortaleza-com-garagem-65m2-venda-RS110900-id-2763183579/",
               valorVenda:"110900.00",
               valorOriginal:"110900.00",
               endereco:"Rua João Fonseca, 525 - Dias Macedo, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com  Quarto à Venda, 65m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:65,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7874456,
                  lng:-38.5231468
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-1-quartos-jangurussu-bairros-fortaleza-305m2-venda-RS111212-id-2760106073/",
               valorVenda:"111212.00",
               valorOriginal:"111212.00",
               endereco:"Rua Antônio Reis, N. 321 A  . - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com  Quarto à Venda, 305m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:305,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8454421,
                  lng:-38.5130262
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-passare-bairros-fortaleza-com-garagem-1056m2-venda-RS111786-id-2755402722/",
               valorVenda:"111786.00",
               valorOriginal:"111786.00",
               endereco:"Rua São Cipriano, N. 171  CS 05 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 1056m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:1056,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8114783,
                  lng:-38.52091739999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-paupina-bairros-fortaleza-com-garagem-47m2-venda-RS111816-id-2756831780/",
               valorVenda:"111816.00",
               valorOriginal:"111816.00",
               endereco:"Rua Central do Loteamento Parque Messejana, N. 100 APTO. 301 BL 03 - Paupina, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8462847,
                  lng:-38.4865106
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-65m2-venda-RS112000-id-2742415861/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Francisca Nogueira Ramos, 300 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 65m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:65,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8071192,
                  lng:-38.5038274
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-52m2-venda-RS112000-id-2732731222/",
               valorVenda:"112000.00",
               valorOriginal:"112000.00",
               endereco:"Avenida Presidente Costa e Silva, 87 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8210881,
                  lng:-38.5584261
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cajazeiras-bairros-fortaleza-com-garagem-60m2-venda-RS112000-id-2567323733/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Francisco Nogueira, 266 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/c0ad4f1563f00b6aea7529c7d7677418/foto-1-de-apartamento-com-2-quartos-a-venda-60m-em-cajazeiras-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.796183799999999,
                  lng:-38.5092585
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-52m2-venda-RS112000-id-2732523217/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Presidente Costa e Silva, 210 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/6cb9af0e8613765e397f7d3be018f30b/foto-1-de-apartamento-com-2-quartos-a-venda-52m-em-passare-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.816092,
                  lng:-38.5684964
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-49m2-venda-RS112000-id-2734351118/",
               valorVenda:"112000.00",
               valorOriginal:"112000.00",
               endereco:"Avenida Presidente Costa e Silva, 190 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/15c25856f6a8a57443c094a1d5f6b147/foto-1-de-apartamento-com-2-quartos-a-venda-49m-em-passare-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8160546,
                  lng:-38.5685287
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-59m2-venda-RS112000-id-2732874727/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Presidente Costa e Silva, 980 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/cfcfd52f56ad4e8a0721cede9ba9887f/foto-1-de-apartamento-com-2-quartos-a-venda-59m-em-passare-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 59m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:59,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8133271,
                  lng:-38.5706168
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cajazeiras-bairros-fortaleza-55m2-venda-RS113000-id-2743177460/",
               valorVenda:"113000.00",
               valorOriginal:"113000.00",
               endereco:"Rua Francisca Nogueira Ramos, 266 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.806648,
                  lng:-38.5041571
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-montese-bairros-fortaleza-com-garagem-70m2-venda-RS113021-id-2755402518/",
               valorVenda:"113021.00",
               valorOriginal:"113021.00",
               endereco:"Rua Barão de Canindé, N. 630 APTO. 406 BL 1B - Montese, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7629551,
                  lng:-38.5530905
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cajazeiras-bairros-fortaleza-com-garagem-56m2-venda-RS114000-id-2738423422/",
               valorVenda:"114000.00",
               valorOriginal:"114000.00",
               endereco:"Avenida Deputado Paulino Rocha, 1001 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8062282,
                  lng:-38.5081022
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-45m2-venda-RS115000-id-2737519221/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Avenida I, 855 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8417237,
                  lng:-38.555731
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-55m2-venda-RS115000-id-2711829236/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Coronel de Queiroz, 620 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.834008,
                  lng:-38.5678844
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-55m2-venda-RS115000-id-2711832008/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Coronel de Queiroz, 620 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.834008,
                  lng:-38.5678844
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-55m2-venda-RS115000-id-2711830526/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Coronel de Queiroz, 620 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.834008,
                  lng:-38.5678844
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-52m2-venda-RS115000-id-2755018307/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Rua Mirtil Meyer, 169 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.808834799999999,
                  lng:-38.5749588
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-planalto-ayrton-senna-bairros-fortaleza-com-garagem-64m2-venda-RS115000-id-2712848696/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Rua Coronel de Queiroz, 34 - Planalto Ayrton Senna, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 64m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:64,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.835714599999999,
                  lng:-38.5629746
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cajazeiras-bairros-fortaleza-com-garagem-55m2-venda-RS115000-id-2702442383/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Rua Francisca Nogueira Ramos, 266 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.806648,
                  lng:-38.5041571
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-tauape-bairros-fortaleza-73m2-venda-RS115000-id-2761671964/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Pontes Vieira, 600 - Tauape, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 73m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:73,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7531409,
                  lng:-38.5158879
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pan-americano-bairros-fortaleza-com-garagem-56m2-venda-RS115000-id-2760340515/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Rua Santa Catarina, 50 - Pan Americano, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7516945,
                  lng:-38.5640163
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-59m2-venda-RS115000-id-2736246709/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Rua 05H do Loteamento Cidade Jardim, 59 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 59m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:59,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8289449,
                  lng:-38.5389966
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cajazeiras-bairros-fortaleza-com-garagem-61m2-venda-RS115000-id-2761168434/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Tibúrcio Pereira, 430 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.797367199999999,
                  lng:-38.5090925
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-cambeba-bairros-fortaleza-com-garagem-45m2-venda-RS115000-id-2759210918/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Rua Fausto Aguiar, 927 - Cambeba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com  Quarto à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8137452,
                  lng:-38.4918698
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-sao-bento-bairros-fortaleza-com-garagem-96m2-venda-RS115000-id-2681750810/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Rua I, 96 - São Bento, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 96m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:96,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8581529,
                  lng:-38.4911523
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aracape-bairros-fortaleza-com-garagem-45m2-venda-RS115000-id-2759052027/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Rua Doutor Procópio, 123 - ARACAPE, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8320058,
                  lng:-38.5814728
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-77m2-venda-RS115000-id-2732522819/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Rua 05H do Loteamento Cidade Jardim, 618 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 77m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:77,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8289449,
                  lng:-38.5389966
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-passare-bairros-fortaleza-com-garagem-70m2-venda-RS115000-id-2759745550/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Rua 05H do Loteamento Cidade Jardim, 100 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8289449,
                  lng:-38.5389966
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-67m2-venda-RS115000-id-2758007117/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Rua 05H do Loteamento Cidade Jardim, 187 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 67m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:67,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8289449,
                  lng:-38.5389966
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aracape-bairros-fortaleza-com-garagem-59m2-venda-RS115000-id-2754733005/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Doutor Procópio, 178 - ARACAPE, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 59m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:59,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8316152,
                  lng:-38.5820227
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/imovel-comercial-1-quartos-centro-bairros-fortaleza-32m2-venda-RS115000-id-2660386763/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Avenida Santos Dumont, 304 - Centro, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com  Quarto à Venda, 32m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:32,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7291185,
                  lng:-38.5207227
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-57m2-venda-RS115000-id-2717830033/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Avenida Presidente Costa e Silva, 93 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 57m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:57,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8210881,
                  lng:-38.5584261
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-bonsucesso-bairros-fortaleza-com-garagem-48m2-venda-RS115000-id-2755232412/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Rua Bias Mendes, 1301 - Bonsucesso, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7766704,
                  lng:-38.5910547
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-45m2-venda-RS115000-id-2737011665/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Avenida I, 100 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.82499,
                  lng:-38.55068
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-45m2-venda-RS115000-id-2737015020/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Avenida I, 100 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.82499,
                  lng:-38.55068
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-55m2-venda-RS115000-id-2711831850/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Coronel de Queiroz, 620 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.834008,
                  lng:-38.5678844
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-45m2-venda-RS115000-id-2737013059/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Avenida I, 100 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.82499,
                  lng:-38.55068
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-sao-bento-bairros-fortaleza-53m2-venda-RS115000-id-2713331297/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Rua E, 162 - São Bento, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 53m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:53,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8084357,
                  lng:-38.6194732
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-60m2-venda-RS115000-id-2714130606/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Avenida Presidente Costa e Silva, 968 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8169642,
                  lng:-38.5674828
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pan-americano-bairros-fortaleza-com-garagem-65m2-venda-RS115000-id-2740171120/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Estado do Rio, 50 - Pan Americano, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 65m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:65,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7510773,
                  lng:-38.5663
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-60m2-venda-RS115000-id-2734351013/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Rua 05H do Loteamento Cidade Jardim, 67 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8289449,
                  lng:-38.5389966
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-passare-bairros-fortaleza-com-garagem-70m2-venda-RS115000-id-2759221421/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Rua 05H do Loteamento Cidade Jardim, 1000 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/66d865568fbf24547344e8a646a19b2c/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-70m-em-passare-fortaleza.webp",
               titulo:"Casa com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8289449,
                  lng:-38.5389966
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-edson-queiroz-bairros-fortaleza-com-garagem-85m2-venda-RS115000-id-2547635621/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua F - Edson Queiroz, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/9c4e86708f61013b30fb3e16223014a3/foto-1-de-apartamento-com-3-quartos-a-venda-85m-em-edson-queiroz-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 85m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:85,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.781464,
                  lng:-38.4734363
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aracape-bairros-fortaleza-com-garagem-49m2-venda-RS115000-id-2754206009/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Rua Nilo Firmeza, 173 - ARACAPE, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/7af5816a399ad5400e002a5213be7497/foto-1-de-apartamento-com-2-quartos-a-venda-49m-em-aracape-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8268444,
                  lng:-38.5917148
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-planalto-ayrton-senna-bairros-fortaleza-com-garagem-64m2-venda-RS115000-id-2726657210/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Coronel de Queiroz, 980 - Planalto Ayrton Senna, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/33ca7e5c256be7cb5fcc2c60154d6019/foto-1-de-apartamento-com-2-quartos-a-venda-64m-em-planalto-ayrton-senna-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 64m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:64,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8335996,
                  lng:-38.5690362
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-64m2-venda-RS115000-id-2759732927/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Rua 05H do Loteamento Cidade Jardim, 638 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 64m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:64,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8289449,
                  lng:-38.5389966
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-cambeba-bairros-fortaleza-com-garagem-44m2-venda-RS115000-id-2751895233/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Rua Fausto Aguiar - Cambeba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com  Quarto à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8111709,
                  lng:-38.4928116
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-60m2-venda-RS115000-id-2762918436/",
               valorVenda:"115000.00",
               valorOriginal:"115000.00",
               endereco:"Rua 05H do Loteamento Cidade Jardim, 204 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8289449,
                  lng:-38.5389966
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-paupina-bairros-fortaleza-com-garagem-100m2-venda-RS116030-id-2744484823/",
               valorVenda:"116030.00",
               valorOriginal:"116030.00",
               endereco:"Rua Paulo Ribeiro, N. S/N  CS - Paupina, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 100m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:100,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8519708,
                  lng:-38.4847211
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cambeba-bairros-fortaleza-com-garagem-48m2-venda-RS118000-id-2753203777/",
               valorVenda:"118000.00",
               valorOriginal:"118000.00",
               endereco:"Rua Fausto Aguiar, 927 - Cambeba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8137452,
                  lng:-38.4918698
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-45m2-venda-RS118000-id-2699140877/",
               valorVenda:"118000.00",
               valorOriginal:"118000.00",
               endereco:"Avenida I, 120 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8251675,
                  lng:-38.55084739999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-45m2-venda-RS118000-id-2699140979/",
               valorVenda:"118000.00",
               valorOriginal:"118000.00",
               endereco:"Avenida I, 123 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8252611,
                  lng:-38.5505693
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-45m2-venda-RS118000-id-2699140876/",
               valorVenda:"118000.00",
               valorOriginal:"118000.00",
               endereco:"Avenida I, 125 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8252772,
                  lng:-38.5505745
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-edson-queiroz-bairros-fortaleza-com-garagem-42m2-venda-RS118000-id-2628924336/",
               valorVenda:"118000.00",
               valorOriginal:"118000.00",
               endereco:"Rua Cidade Ecológica - Edson Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7736393,
                  lng:-38.4666013
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-50m2-venda-RS118331-id-2759463347/",
               valorVenda:"118331.00",
               valorOriginal:"118331.00",
               endereco:"Rua 08H, 255 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8324922,
                  lng:-38.5529854
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-50m2-venda-RS118331-id-2763501648/",
               valorVenda:"118331.00",
               valorOriginal:"118331.00",
               endereco:"Rua 08H, N. 255 APTO. 405 BLOCO B - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8324922,
                  lng:-38.5529854
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parangaba-bairros-fortaleza-com-garagem-44m2-venda-RS119000-id-2758762713/",
               valorVenda:"119000.00",
               valorOriginal:"119000.00",
               endereco:"Rua Coronel Chico Alves, 100 - Parangaba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8067329,
                  lng:-38.5263069
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS119000-id-2757589248/",
               valorVenda:"119000.00",
               valorOriginal:"119000.00",
               endereco:"Rua Coronel Chico Alves, 100 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8067329,
                  lng:-38.5263069
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS119000-id-2757158322/",
               valorVenda:"119000.00",
               valorOriginal:"119000.00",
               endereco:"Rua Coronel Chico Alves, 100 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8067329,
                  lng:-38.5263069
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-55m2-venda-RS120000-id-2760856283/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua 08H, 496 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8321954,
                  lng:-38.5362323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-presidente-vargas-bairros-fortaleza-43m2-venda-RS120000-id-2752008899/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Herculano Pena, 101 - Parque Presidente Vargas, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 43m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:43,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8275418,
                  lng:-38.5940218
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jangurussu-bairros-fortaleza-com-garagem-42m2-venda-RS120000-id-2755213895/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Jaborandi, 825 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8377923,
                  lng:-38.5137009
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-59m2-venda-RS120000-id-2760891050/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua 08H, 496 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 59m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:59,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8321954,
                  lng:-38.5362323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-46m2-venda-RS120000-id-2677641321/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Antônio Luís Almeida Vale - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8200421,
                  lng:-38.5617842
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jangurussu-bairros-fortaleza-53m2-venda-RS120000-id-2743032071/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Florêncio Fontenele, 380 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 53m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:53,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.833748999999999,
                  lng:-38.5084662
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cambeba-bairros-fortaleza-com-garagem-44m2-venda-RS120000-id-2751923915/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Fausto Aguiar, 927 - Cambeba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8137452,
                  lng:-38.4918698
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-10m2-venda-RS120000-id-2761179726/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua 08H, 496 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 10m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:10,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8321954,
                  lng:-38.5362323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-47m2-venda-RS120000-id-2634889273/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua 6, 55 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7949112,
                  lng:-38.5411836
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-44m2-venda-RS120000-id-2739559725/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Avenida I, 901 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8322278,
                  lng:-38.551241
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cambeba-bairros-fortaleza-com-garagem-44m2-venda-RS120000-id-2751623532/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Fausto Aguiar, 927 - Cambeba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8137452,
                  lng:-38.4918698
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-vila-peri-bairros-fortaleza-com-garagem-42m2-venda-RS120000-id-2591463610/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Vila Peri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.786086099999999,
                  lng:-38.5830201
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-47m2-venda-RS120000-id-2738585729/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Avenida I - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8353486,
                  lng:-38.5537971
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-novo-mondubim-bairros-fortaleza-com-garagem-48m2-venda-RS120000-id-2749627001/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Alfredo Mamede - Novo Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8103898,
                  lng:-38.582511
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-55m2-venda-RS120000-id-2760859121/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua 08H, 497 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8324922,
                  lng:-38.5529854
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-50m2-venda-RS120000-id-2707332165/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Professora Stella Cochrane, 155 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7945846,
                  lng:-38.5350382
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pan-americano-bairros-fortaleza-com-garagem-56m2-venda-RS120000-id-2751343532/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Estado do Rio, 50 - Pan Americano, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7510773,
                  lng:-38.5663
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-4300m2-venda-RS120000-id-2747751212/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua 3, n°155 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 4300m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:4300,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7939925,
                  lng:-38.5431714
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jangurussu-bairros-fortaleza-53m2-venda-RS120000-id-2743032071/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Florêncio Fontenele, 380 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/74a176e000c4b5d75c325fada5d988f4/foto-1-de-apartamento-com-2-quartos-a-venda-53m-em-jangurussu-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 53m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:53,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.833748999999999,
                  lng:-38.5084662
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cambeba-bairros-fortaleza-com-garagem-44m2-venda-RS120000-id-2751923915/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Fausto Aguiar, 927 - Cambeba, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/f769baba2eab0c45e9051507874b067b/foto-1-de-apartamento-com-2-quartos-a-venda-44m-em-cambeba-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8137452,
                  lng:-38.4918698
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-10m2-venda-RS120000-id-2761179726/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua 08H, 496 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/1aa542b3fcee53c0e9eca4de145809b1/foto-1-de-apartamento-com-2-quartos-a-venda-10m-em-prefeito-jose-walter-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 10m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:10,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8321954,
                  lng:-38.5362323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-vila-peri-bairros-fortaleza-com-garagem-42m2-venda-RS120000-id-2591463610/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Vila Peri, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/e2c0d69347851320127e8a0e864e4555/foto-1-de-apartamento-com-2-quartos-a-venda-42m-em-vila-peri-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.786086099999999,
                  lng:-38.5830201
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jangurussu-bairros-fortaleza-com-garagem-42m2-venda-RS120000-id-2755213895/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Jaborandi, 825 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8377923,
                  lng:-38.5137009
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-passare-bairros-fortaleza-com-garagem-62m2-venda-RS120000-id-2750432877/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua dos Mandacarus, 186 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 62m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:62,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8087485,
                  lng:-38.5357444
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-46m2-venda-RS120000-id-2749784802/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua 2, 155 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7930314,
                  lng:-38.53034
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cambeba-bairros-fortaleza-com-garagem-50m2-venda-RS120000-id-2760822127/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Amâncio Valente, 100 - Cambeba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.805208,
                  lng:-38.4934654
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-48m2-venda-RS120000-id-2623833556/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Antônio Barbosa - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8311705,
                  lng:-38.564649
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-4300m2-venda-RS120000-id-2747751212/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua 3, n°155 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 4300m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:4300,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7939925,
                  lng:-38.5431714
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cambeba-bairros-fortaleza-com-garagem-48m2-venda-RS120000-id-2751890387/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Fausto Aguiar, 179 - Cambeba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8072492,
                  lng:-38.4943584
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-presidente-vargas-bairros-fortaleza-com-garagem-57m2-venda-RS120000-id-2728159566/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Travessa Herculano Pena, 766 - Parque Presidente Vargas, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 57m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:57,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.824958899999999,
                  lng:-38.5972828
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-novo-mondubim-bairros-fortaleza-42m2-venda-RS120000-id-2555172010/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Bento Gonçalves, 700 - Novo Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8111061,
                  lng:-38.5816104
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-passare-bairros-fortaleza-com-garagem-60m2-venda-RS120000-id-2751033160/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua 05H do Loteamento Cidade Jardim, 100 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8289449,
                  lng:-38.5389966
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-50m2-venda-RS120000-id-2760876825/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua 08H, 496 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8321954,
                  lng:-38.5362323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-50m2-venda-RS120000-id-2760897547/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua 08H, 496 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8321954,
                  lng:-38.5362323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-44m2-venda-RS120000-id-2523517007/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Andrelândia, 100 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.824089899999999,
                  lng:-38.4926395
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cajazeiras-bairros-fortaleza-70m2-venda-RS120000-id-2711150801/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Francisco Nogueira, 340 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7964818,
                  lng:-38.5089499
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-66m2-venda-RS120000-id-2650485074/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Portugal, 55 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 66m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:66,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.828381599999999,
                  lng:-38.5097461
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cambeba-bairros-fortaleza-com-garagem-48m2-venda-RS120000-id-2751595313/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Fausto Aguiar, 167 - Cambeba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8071457,
                  lng:-38.4944043
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS120000-id-2760850408/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua 05H do Loteamento Cidade Jardim, 100 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8289449,
                  lng:-38.5389966
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cajazeiras-bairros-fortaleza-com-garagem-55m2-venda-RS120000-id-2756018031/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Avenida Deputado Paulino Rocha, 1001 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8062282,
                  lng:-38.5081022
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-monte-castelo-bairros-fortaleza-118m2-venda-RS120000-id-2749346402/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Bernardo Porto, 573 - Monte Castelo, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 118m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:118,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7200619,
                  lng:-38.5576303
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cambeba-bairros-fortaleza-com-garagem-48m2-venda-RS120000-id-2751724830/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Fausto Aguiar, 927 - Cambeba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8137452,
                  lng:-38.4918698
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cambeba-bairros-fortaleza-com-garagem-50m2-venda-RS120000-id-2761440139/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Amâncio Valente, 100 - Cambeba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.805208,
                  lng:-38.4934654
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-4-quartos-manuel-satiro-bairros-fortaleza-com-garagem-40m2-venda-RS120000-id-2751936801/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Contorno Oeste, 860 - Manuel Sátiro, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 4 Quartos à Venda, 40m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:40,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8078791,
                  lng:-38.58806939999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-1-quartos-lagoa-redonda-bairros-fortaleza-110m2-venda-RS120000-id-2646903241/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Lagoa Redonda, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com  Quarto à Venda, 110m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:110,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.826101899999999,
                  lng:-38.4645212
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cambeba-bairros-fortaleza-com-garagem-50m2-venda-RS120000-id-2760821615/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Amâncio Valente, 100 - Cambeba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.805208,
                  lng:-38.4934654
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cambeba-bairros-fortaleza-com-garagem-49m2-venda-RS120000-id-2760818332/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Amâncio Valente, 100 - Cambeba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.805208,
                  lng:-38.4934654
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-couto-fernandes-bairros-fortaleza-com-garagem-84m2-venda-RS120000-id-2761617624/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Oscar Bezerra, 44 - Couto Fernandes, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com  Quarto à Venda, 84m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:84,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7606079,
                  lng:-38.5595243
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-42m2-venda-RS120000-id-2687634028/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Recanto Verde, 2020 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.843423,
                  lng:-38.5137123
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-cristo-redentor-bairros-fortaleza-20m2-venda-RS120000-id-2752143320/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Avenida Presidente Castelo Branco, 4070 - Cristo Redentor, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 20m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:20,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7081018,
                  lng:-38.5688176
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jangurussu-bairros-fortaleza-com-garagem-60m2-venda-RS120000-id-2592970023/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Paulina de Arruda, 647 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8280428,
                  lng:-38.5115182
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-46m2-venda-RS120000-id-2746786410/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.873815,
                  lng:-38.5073967
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cambeba-bairros-fortaleza-com-garagem-44m2-venda-RS120000-id-2756679227/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Fausto Aguiar, 89 - Cambeba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8063416,
                  lng:-38.4948708
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-50m2-venda-RS120000-id-2733324336/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Antônio Barbosa, 1572 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8338478,
                  lng:-38.5656714
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-sao-bento-bairros-fortaleza-com-garagem-85m2-venda-RS120000-id-2682861387/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua I, 96 - São Bento, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/8c555d1dcbc63e0081c72f0c0459ab0e/foto-1-de-casa-com-3-quartos-a-venda-85m-em-sao-bento-fortaleza.webp",
               titulo:"Casa com 3 Quartos à Venda, 85m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:85,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8581529,
                  lng:-38.4911523
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-manuel-satiro-bairros-fortaleza-com-garagem-49m2-venda-RS120000-id-2743159862/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Creuza Roque - Manuel Sátiro, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/14b781c55daf434e7da5fa6c3096f80b/foto-1-de-apartamento-com-2-quartos-a-venda-49m-em-manuel-satiro-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8008107,
                  lng:-38.5807245
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-48m2-venda-RS120000-id-2737569118/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Elisiário Mendes, 800 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/8d9ede98ff81e209fa64e1b8995268a0/foto-1-de-apartamento-com-2-quartos-a-venda-48m-em-messejana-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.814639999999999,
                  lng:-38.4949347
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-42m2-venda-RS120000-id-2651400483/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua 6, 55 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/740b69cd7d4113a3c3cdce549a189b8e/foto-1-de-apartamento-com-2-quartos-a-venda-42m-em-itaperi-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7949112,
                  lng:-38.5411836
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-45m2-venda-RS120000-id-2755966900/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8118712,
                  lng:-38.5746698
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-iracema-bairros-fortaleza-59m2-venda-RS120000-id-2748743711/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Newton Craveiro, 120 - Parque Iracema, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 59m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:59,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8093583,
                  lng:-38.49919269999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-edson-queiroz-bairros-fortaleza-com-garagem-68m2-venda-RS120000-id-2521504247/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Edson Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 68m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:68,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7708535,
                  lng:-38.4746411
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-44m2-venda-RS120000-id-2758187470/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Via Paisagística do Loteamento Itaperussú, 06 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7965175,
                  lng:-38.5361463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-lagoa-redonda-bairros-fortaleza-com-garagem-50m2-venda-RS120000-id-2568644465/",
               valorVenda:"120000.00",
               valorOriginal:"120000.00",
               endereco:"Rua Lourdes Vidal Alves, 714 - Lagoa Redonda, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8240867,
                  lng:-38.4733137
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jose-de-alencar-bairros-fortaleza-com-garagem-57m2-venda-RS120219-id-2761046424/",
               valorVenda:"120219.00",
               valorOriginal:"120219.00",
               endereco:"Rua Manuel Teixeira, 827 - JOSE DE ALENCAR, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 57m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:57,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8191497,
                  lng:-38.481005
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-santa-maria-bairros-fortaleza-com-garagem-66m2-venda-RS121487-id-2760108114/",
               valorVenda:"121487.00",
               valorOriginal:"121487.00",
               endereco:"Rua Padre Pedro de Alencar, N. 1662 APTO. 404 BL C - Parque Santa Maria, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 66m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:66,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8425132,
                  lng:-38.4946428
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-46m2-venda-RS122000-id-2434758117/",
               valorVenda:"122000.00",
               valorOriginal:"122000.00",
               endereco:"Avenida Bernardo Manuel - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8080314,
                  lng:-38.5541846
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-dias-macedo-bairros-fortaleza-com-garagem-92m2-venda-RS123996-id-2759767976/",
               valorVenda:"123996.00",
               valorOriginal:"123996.00",
               endereco:"Rua João Fonseca, N. 525  LT 11 - Dias Macedo, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 92m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:92,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7874456,
                  lng:-38.5231468
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-dias-macedo-bairros-fortaleza-com-garagem-92m2-venda-RS123996-id-2754971754/",
               valorVenda:"123996.00",
               valorOriginal:"123996.00",
               endereco:"Rua João Fonseca, N. 525  LT 11 - Dias Macedo, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 92m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:92,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7874456,
                  lng:-38.5231468
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-70m2-venda-RS125000-id-2762918720/",
               valorVenda:"125000.00",
               valorOriginal:"125000.00",
               endereco:"Rua Francisco Nogueira, 340 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7964818,
                  lng:-38.5089499
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-conjunto-esperanca-bairros-fortaleza-com-garagem-46m2-venda-RS125000-id-2760221141/",
               valorVenda:"125000.00",
               valorOriginal:"125000.00",
               endereco:"Conjunto Esperança, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8170209,
                  lng:-38.5906824
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-50m2-venda-RS125000-id-2723473344/",
               valorVenda:"125000.00",
               valorOriginal:"125000.00",
               endereco:"Rua Professora Stella Cochrane, 155 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.79372,
                  lng:-38.53553
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-edson-queiroz-bairros-fortaleza-com-garagem-42m2-venda-RS125000-id-2676349343/",
               valorVenda:"125000.00",
               valorOriginal:"125000.00",
               endereco:"Rua Cidade Ecológica, 96 - Edson Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.773067299999999,
                  lng:-38.4704433
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS125000-id-2755575013/",
               valorVenda:"125000.00",
               valorOriginal:"125000.00",
               endereco:"Rua 05H do Loteamento Cidade Jardim, 1001 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8289449,
                  lng:-38.5389966
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-4-quartos-farias-brito-bairros-fortaleza-com-garagem-105m2-venda-RS125000-id-2746512800/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Rúbia Sampaio, 1155 - Farias Brito, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 4 Quartos à Venda, 105m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:105,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7319682,
                  lng:-38.5404811
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-parque-iracema-bairros-fortaleza-68m2-venda-RS125000-id-2710399045/",
               valorVenda:"125000.00",
               valorOriginal:"125000.00",
               endereco:"Rua Newton Craveiro - Parque Iracema, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 68m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:68,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8089918,
                  lng:-38.4961533
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-55m2-venda-RS125000-id-2746383172/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Godofredo Maciel - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7968768,
                  lng:-38.5699834
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-carlito-pamplona-bairros-fortaleza-com-garagem-42m2-venda-RS125000-id-2728696816/",
               valorVenda:"125000.00",
               valorOriginal:"125000.00",
               endereco:"Rua Dom Hélio Campos, 493 - Carlito Pamplona, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.713278,
                  lng:-38.555268
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-manuel-satiro-bairros-fortaleza-com-garagem-74m2-venda-RS125000-id-2749636712/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Doutor João Amora, 1275 - Manuel Sátiro, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 74m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:74,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7997539,
                  lng:-38.5855446
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS125000-id-2745222496/",
               valorVenda:"125000.00",
               valorOriginal:"125000.00",
               endereco:"Rua Coronel Chico Alves, 21 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8070918,
                  lng:-38.52605519999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-bom-jardim-bairros-fortaleza-com-garagem-65m2-venda-RS125000-id-2713336773/",
               valorVenda:"125000.00",
               valorOriginal:"125000.00",
               endereco:"Bom Jardim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos para Venda/Aluguel 65m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:65,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.79469,
                  lng:-38.6006716
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-46m2-venda-RS125000-id-2608828842/",
               valorVenda:"125000.00",
               valorOriginal:"125000.00",
               endereco:"Rua 3 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8699602,
                  lng:-38.5204344
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-49m2-venda-RS125000-id-2746393024/",
               valorVenda:"125000.00",
               valorOriginal:"125000.00",
               endereco:"Rua I, 945 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8165895,
                  lng:-38.5377352
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-parangaba-bairros-fortaleza-com-garagem-94m2-venda-RS125000-id-2710130615/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida João Pessoa, 7164 - Parangaba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 94m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:94,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7722742,
                  lng:-38.5620347
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-novo-mondubim-bairros-fortaleza-com-garagem-50m2-venda-RS125000-id-2758545952/",
               valorVenda:"125000.00",
               valorOriginal:"125000.00",
               endereco:"Rua Bento Gonçalves - Novo Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8118941,
                  lng:-38.5876076
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-61m2-venda-RS125000-id-2624422845/",
               valorVenda:"125000.00",
               valorOriginal:"125000.00",
               endereco:"Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8003068,
                  lng:-38.5069443
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-canindezinho-bairros-fortaleza-com-garagem-44m2-venda-RS125000-id-2749625433/",
               valorVenda:"125000.00",
               valorOriginal:"125000.00",
               endereco:"Rua São Francisco - Canindezinho, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8188258,
                  lng:-38.6133463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-57m2-venda-RS125000-id-2740356580/",
               valorVenda:"125000.00",
               valorOriginal:"125000.00",
               endereco:"Avenida da Saudade, 980 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 57m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:57,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8111763,
                  lng:-38.5290082
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-50m2-venda-RS125000-id-2706933018/",
               valorVenda:"125000.00",
               valorOriginal:"125000.00",
               endereco:"Rua Professora Stella Cochrane - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7956033,
                  lng:-38.5369268
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-cristo-redentor-bairros-fortaleza-com-garagem-85m2-venda-RS125000-id-2654749660/",
               valorVenda:"125000.00",
               valorOriginal:"125000.00",
               endereco:"Rua Vítor Meireles, 349 - Cristo Redentor, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 85m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:85,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7086461,
                  lng:-38.56615559999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS125000-id-2735078211/",
               valorVenda:"125000.00",
               valorOriginal:"125000.00",
               endereco:"Avenida da Saudade, 980 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8111763,
                  lng:-38.5290082
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-messejana-bairros-fortaleza-com-garagem-62m2-venda-RS125000-id-2752111227/",
               valorVenda:"125000.00",
               valorOriginal:"125000.00",
               endereco:"Rua Frederico Severo, 201 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/896e46ab23374bc0a164b92f44a60e51/foto-1-de-apartamento-com-3-quartos-a-venda-62m-em-messejana-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 62m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:62,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8178201,
                  lng:-38.4948463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jangurussu-bairros-fortaleza-com-garagem-44m2-venda-RS125000-id-2613017595/",
               valorVenda:"125000.00",
               valorOriginal:"125000.00",
               endereco:"Rua Florêncio Fontenele, 380 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/5dcdf776a75cbaafc913ad2d3c4f34a4/foto-1-de-apartamento-com-2-quartos-para-venda-ou-aluguel-44m-em-jangurussu-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda/Aluguel 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.833748999999999,
                  lng:-38.5084662
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-vila-peri-bairros-fortaleza-com-garagem-42m2-venda-RS127000-id-2682232798/",
               valorVenda:"127000.00",
               valorOriginal:"127000.00",
               endereco:"Rua Arruda Câmara - Vila Peri, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/8eb68719ea10f189082abbc394bf6686/foto-1-de-apartamento-com-2-quartos-a-venda-42m-em-vila-peri-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7902243,
                  lng:-38.5828552
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS127000-id-2759196024/",
               valorVenda:"127000.00",
               valorOriginal:"127000.00",
               endereco:"Rua Coronel Chico Alves, 21 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/b77c9143f12b4e92a3d989b2cd972227/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-passare-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8070918,
                  lng:-38.52605519999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS127900-id-2758839559/",
               valorVenda:"127900.00",
               valorOriginal:"127900.00",
               endereco:"Rua Coronel Chico Alves - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8071856,
                  lng:-38.5265093
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-novo-mondubim-bairros-fortaleza-44m2-venda-RS128000-id-2725246355/",
               valorVenda:"128000.00",
               valorOriginal:"128000.00",
               endereco:"Rua 101 - Novo Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8105478,
                  lng:-38.5886361
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS129000-id-2758833921/",
               valorVenda:"129000.00",
               valorOriginal:"129000.00",
               endereco:"Rua Coronel Chico Alves, 45 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.807107099999999,
                  lng:-38.5262406
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-46m2-venda-RS129000-id-2630778302/",
               valorVenda:"129000.00",
               valorOriginal:"129000.00",
               endereco:"Rua 3, 351 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8699602,
                  lng:-38.5204344
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-messejana-bairros-fortaleza-com-garagem-34m2-venda-RS129000-id-2710303131/",
               valorVenda:"129000.00",
               valorOriginal:"129000.00",
               endereco:"Rua Professor Paulo Maria de Aragão, 121 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com  Quarto à Venda, 34m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:34,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8353851,
                  lng:-38.4843885
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-44m2-venda-RS129000-id-2661391872/",
               valorVenda:"129000.00",
               valorOriginal:"129000.00",
               endereco:"Avenida I, 945 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8416808,
                  lng:-38.5557188
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS129000-id-2758828817/",
               valorVenda:"129000.00",
               valorOriginal:"129000.00",
               endereco:"Rua Coronel Chico Alves, 10 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.806475799999999,
                  lng:-38.5261337
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-46m2-venda-RS129000-id-2746127699/",
               valorVenda:"129000.00",
               valorOriginal:"129000.00",
               endereco:"Rua B, 19 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.796145799999999,
                  lng:-38.5608921
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-cristo-redentor-bairros-fortaleza-com-garagem-84m2-venda-RS129000-id-2753020719/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Senhora Santana, 468 - Cristo Redentor, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 84m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:84,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.706446,
                  lng:-38.568577
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS129000-id-2760207420/",
               valorVenda:"129000.00",
               valorOriginal:"129000.00",
               endereco:"Rua Coronel Chico Alves, 100 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8067329,
                  lng:-38.5263069
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-lagoa-redonda-bairros-fortaleza-com-garagem-65m2-venda-RS129031-id-2750587636/",
               valorVenda:"129031.00",
               valorOriginal:"129031.00",
               endereco:"Avenida Professor José Arthur de Carvalho, N. 912  0 - Lagoa Redonda, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 65m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:65,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8174924,
                  lng:-38.4504312
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-lagoa-redonda-bairros-fortaleza-com-garagem-65m2-venda-RS129031-id-2759767728/",
               valorVenda:"129031.00",
               valorOriginal:"129031.00",
               endereco:"Avenida Professor José Arthur de Carvalho, N. 912  0 - Lagoa Redonda, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 65m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:65,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8174924,
                  lng:-38.4504312
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-50m2-venda-RS129850-id-2541005140/",
               valorVenda:"129850.00",
               valorOriginal:"129850.00",
               endereco:"Avenida I - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8353486,
                  lng:-38.5537971
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-45m2-venda-RS129900-id-2745796711/",
               valorVenda:"129900.00",
               valorOriginal:"129900.00",
               endereco:"Rua B, 267 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8038567,
                  lng:-38.5551518
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-santa-maria-bairros-fortaleza-com-garagem-50m2-venda-RS129990-id-2748409542/",
               valorVenda:"129990.00",
               valorOriginal:"129990.00",
               endereco:"Estrada do Ancuri, 1570 - Parque Santa Maria, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8402897,
                  lng:-38.4969419
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-45m2-venda-RS130000-id-2722123134/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua B, 96 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8039623,
                  lng:-38.5545254
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-44m2-venda-RS130000-id-2686036601/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua Creuza Roque, 559 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.801276,
                  lng:-38.5800207
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-95m2-venda-RS130000-id-2747012603/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua 05H do Loteamento Cidade Jardim, 270 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 95m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:95,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8289449,
                  lng:-38.5389966
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-edson-queiroz-bairros-fortaleza-com-garagem-42m2-venda-RS130000-id-2679287396/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua Cidade Ecológica, 120 - Edson Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7733118,
                  lng:-38.4693829
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS130000-id-2759682587/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua Coronel Chico Alves, 17 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8065754,
                  lng:-38.5259334
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-90m2-venda-RS130000-id-2754449960/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua 05H do Loteamento Cidade Jardim, 270 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8289449,
                  lng:-38.5389966
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-48m2-venda-RS130000-id-2739984418/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8163239,
                  lng:-38.5316373
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-conjunto-esperanca-bairros-fortaleza-com-garagem-60m2-venda-RS130000-id-2653011074/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Estrada do Siqueira, 1800 - Conjunto Esperança, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8087549,
                  lng:-38.5922328
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-43m2-venda-RS130000-id-2735511542/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Via Paisagística do Loteamento Itaperussú, 08 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 43m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:43,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7965175,
                  lng:-38.5361463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-49m2-venda-RS130000-id-2761039811/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua I, 945 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8165895,
                  lng:-38.5377352
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-44m2-venda-RS130000-id-2695444480/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua Monte Líbano, 96 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8210451,
                  lng:-38.5591579
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-lagoa-redonda-bairros-fortaleza-com-garagem-80m2-venda-RS130000-id-2700820479/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Leonice Aguiar, 162 - Lagoa Redonda, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8299273,
                  lng:-38.4656088
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-conjunto-esperanca-bairros-fortaleza-com-garagem-50m2-venda-RS130000-id-2736908222/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua Bento Gonçalves - Conjunto Esperança, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8118941,
                  lng:-38.5876076
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-conjunto-esperanca-bairros-fortaleza-com-garagem-44m2-venda-RS130000-id-2763136448/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua 101, 1311 - Conjunto Esperança, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8104141,
                  lng:-38.589138
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-95m2-venda-RS130000-id-2747253568/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua 05H do Loteamento Cidade Jardim, 271 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 95m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:95,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8289449,
                  lng:-38.5389966
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-52m2-venda-RS130000-id-2631923520/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua Jorge Figueiredo, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8975353,
                  lng:-38.5104733
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-edson-queiroz-bairros-fortaleza-42m2-venda-RS130000-id-2679811616/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua Cidade Ecológica, 120 - Edson Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7733118,
                  lng:-38.4693829
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-45m2-venda-RS130000-id-2755133316/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua Coronel Chico Alves, 150 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/8642a68a6d818ed692c454b8c2d15948/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-passare-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8068484,
                  lng:-38.526387
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-95m2-venda-RS130000-id-2749254509/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua 05H do Loteamento Cidade Jardim, 270 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/c4f0c455d23aeae0c53fc4c0d229c6b7/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-95m-em-prefeito-jose-walter-fortaleza.webp",
               titulo:"Casa com 3 Quartos à Venda, 95m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:95,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8289449,
                  lng:-38.5389966
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS130000-id-2751936499/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua dos Sabiás, 2 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/ed6b94fc37b402a3b344e52c2da42897/foto-1-de-apartamento-com-2-quartos-a-venda-44m-em-passare-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.803210099999999,
                  lng:-38.5379391
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-52m2-venda-RS130000-id-2631923520/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua Jorge Figueiredo, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/3aba879348d86202bc27a572043cbe01/foto-1-de-apartamento-com-2-quartos-a-venda-52m-em-pedras-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8975353,
                  lng:-38.5104733
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-conjunto-esperanca-bairros-fortaleza-com-garagem-50m2-venda-RS130000-id-2736908222/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua Bento Gonçalves - Conjunto Esperança, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8118941,
                  lng:-38.5876076
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS130000-id-2726303873/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua 3 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8699602,
                  lng:-38.5204344
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jangurussu-bairros-fortaleza-com-garagem-45m2-venda-RS130000-id-2674284614/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua Florêncio Fontenele - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8329417,
                  lng:-38.507368
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-90m2-venda-RS130000-id-2746930876/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua 05H do Loteamento Cidade Jardim - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8289449,
                  lng:-38.5389966
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-bela-vista-bairros-fortaleza-com-garagem-66m2-venda-RS130000-id-2733015915/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua Minas Gerais, 149 - Bela Vista, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 66m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:66,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7524001,
                  lng:-38.5621782
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-conjunto-esperanca-bairros-fortaleza-com-garagem-44m2-venda-RS130000-id-2763136448/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua 101, 1311 - Conjunto Esperança, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8104141,
                  lng:-38.589138
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-95m2-venda-RS130000-id-2747253568/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua 05H do Loteamento Cidade Jardim, 271 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 95m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:95,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8289449,
                  lng:-38.5389966
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-48m2-venda-RS130000-id-2739984418/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8163239,
                  lng:-38.5316373
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-lagoa-redonda-bairros-fortaleza-com-garagem-80m2-venda-RS130000-id-2700820479/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Leonice Aguiar, 162 - Lagoa Redonda, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8299273,
                  lng:-38.4656088
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-centro-bairros-fortaleza-49m2-venda-RS130000-id-2757845414/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Avenida Heraclito Graça, 300 - Centro, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com  Quarto à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7344374,
                  lng:-38.5220568
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-edson-queiroz-bairros-fortaleza-42m2-venda-RS130000-id-2679811616/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua Cidade Ecológica, 120 - Edson Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7733118,
                  lng:-38.4693829
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-passare-bairros-fortaleza-com-garagem-78m2-venda-RS130000-id-2699219873/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Alencar Oliveira, 630 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 78m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:78,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8160193,
                  lng:-38.5201557
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-padre-andrade-bairros-fortaleza-com-garagem-77m2-venda-RS130000-id-2614848813/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Alcântara Bilhar, 677 - Padre Andrade, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 77m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:77,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7366381,
                  lng:-38.5818265
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-novo-mondubim-bairros-fortaleza-com-garagem-60m2-venda-RS130000-id-2558621082/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Estrada do Siqueira - Novo Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8111136,
                  lng:-38.5890064
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-50m2-venda-RS130000-id-2645701671/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Joaquim Martins, 455 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda/Aluguel 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.80376,
                  lng:-38.5408
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS130000-id-2762243158/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua Coronel Chico Alves, 27 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8070868,
                  lng:-38.5262266
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/sobrado-3-quartos-autran-nunes-bairros-fortaleza-com-garagem-80m2-venda-RS130000-id-2731436230/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua José Carvalho, 316 - Autran Nunes, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.750975,
                  lng:-38.595251
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/sobrado-3-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-90m2-venda-RS130000-id-2747355316/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua 05H do Loteamento Cidade Jardim, 270 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8289449,
                  lng:-38.5389966
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-45m2-venda-RS130000-id-2763053746/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7887464,
                  lng:-38.548393
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS130000-id-2759718013/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua Coronel Chico Alves, 152 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8068531,
                  lng:-38.5263902
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-bela-vista-bairros-fortaleza-com-garagem-53m2-venda-RS130000-id-2664426603/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua Minas Gerais - Bela Vista, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 53m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:53,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7548879,
                  lng:-38.5643436
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-autran-nunes-bairros-fortaleza-com-garagem-57m2-venda-RS130000-id-2727001439/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua José Carvalho, 316 - Autran Nunes, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 57m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:57,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.750975,
                  lng:-38.595251
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-90m2-venda-RS130000-id-2747677794/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua 08H, 490 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8324922,
                  lng:-38.5529854
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-43m2-venda-RS130000-id-2747733301/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Via Paisagística do Loteamento Itaperussú, 6 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 43m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:43,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7965175,
                  lng:-38.5361463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-antonio-bezerra-bairros-fortaleza-com-garagem-42m2-venda-RS130000-id-2756392114/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua Hugo Vítor, 940 - Antônio Bezerra, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7476905,
                  lng:-38.5905602
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-44m2-venda-RS130000-id-2723438692/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua B, 970 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8148615,
                  lng:-38.5365258
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-85m2-venda-RS130000-id-2748448909/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua 05H do Loteamento Cidade Jardim, 272 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 85m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:85,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8289449,
                  lng:-38.5389966
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-mondubim-bairros-fortaleza-com-garagem-75m2-venda-RS130000-id-2749626899/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Presidente Costa e Silva - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 75m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:75,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8181199,
                  lng:-38.5647353
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cajazeiras-bairros-fortaleza-com-garagem-55m2-venda-RS130000-id-2760258188/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Avenida Deputado Paulino Rocha, 1001 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8062282,
                  lng:-38.5081022
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-bela-vista-bairros-fortaleza-com-garagem-57m2-venda-RS130000-id-2585152109/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua Santa Catarina, 50 - Bela Vista, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 57m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:57,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7516945,
                  lng:-38.5640163
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-novo-mondubim-bairros-fortaleza-com-garagem-48m2-venda-RS130000-id-2739664215/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua Alfredo Mamede, 637 - Novo Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.81069,
                  lng:-38.5832137
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-46m2-venda-RS130000-id-2744767401/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua B, 51 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.796436,
                  lng:-38.5618282
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-43m2-venda-RS130000-id-2747733301/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Via Paisagística do Loteamento Itaperussú, 6 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/c7671f0b2c061f0e471f7138e096724e/foto-1-de-apartamento-com-2-quartos-a-venda-43m-em-itaperi-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 43m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:43,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7965175,
                  lng:-38.5361463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-45m2-venda-RS130000-id-2763053746/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Itaperi, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/aa3538cff1ae4c300cfcaacf13195f48/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-itaperi-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7887464,
                  lng:-38.548393
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-bela-vista-bairros-fortaleza-com-garagem-66m2-venda-RS130000-id-2573199767/",
               valorVenda:"130000.00",
               valorOriginal:"130000.00",
               endereco:"Rua Minas Gerais, 149 - Bela Vista, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/94ca2386578efcf4249a16375ba7872e/foto-1-de-apartamento-com-2-quartos-a-venda-66m-em-bela-vista-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 66m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:66,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7524001,
                  lng:-38.5621782
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS130003-id-2669843142/",
               valorVenda:"130003.00",
               valorOriginal:"130003.00",
               endereco:"Rua Pereira Coutinho, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/4fc931369e00eed9e91b2cd67a0862fa/foto-1-de-apartamento-com-2-quartos-a-venda-50m-em-pedras-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.88204,
                  lng:-38.5092349
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-45m2-venda-RS132000-id-2741941274/",
               valorVenda:"132000.00",
               valorOriginal:"132000.00",
               endereco:"Rua B, 980 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8148903,
                  lng:-38.5365463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-47m2-venda-RS132000-id-2563944612/",
               valorVenda:"132000.00",
               valorOriginal:"132000.00",
               endereco:"Rua Professora Stella Cochrane, 2 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7931791,
                  lng:-38.535258
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-45m2-venda-RS132000-id-2747147209/",
               valorVenda:"132000.00",
               valorOriginal:"132000.00",
               endereco:"Rua B, 268 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.803859999999999,
                  lng:-38.5553701
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-granja-lisboa-bairros-fortaleza-112m2-venda-RS132178-id-2750588008/",
               valorVenda:"132178.00",
               valorOriginal:"132178.00",
               endereco:"Rua Sargento João Pinheiro, N. 540  QD 152 - Granja Lisboa, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 112m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:112,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7801314,
                  lng:-38.6222901
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-vila-uniao-bairros-fortaleza-120m2-venda-RS132506-id-2761481556/",
               valorVenda:"132506.00",
               valorOriginal:"132506.00",
               endereco:"Avenida dos Expedicionários, 4885 - Vila União, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 120m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:120,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7620816,
                  lng:-38.5401953
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS134000-id-2758064542/",
               valorVenda:"134000.00",
               valorOriginal:"134000.00",
               endereco:"Rua Coronel Chico Alves, 199 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8071707,
                  lng:-38.526499
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS134000-id-2760830623/",
               valorVenda:"134000.00",
               valorOriginal:"134000.00",
               endereco:"Rua Coronel Chico Alves, 100 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8067329,
                  lng:-38.5263069
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS134000-id-2651642492/",
               valorVenda:"134000.00",
               valorOriginal:"134000.00",
               endereco:"Vila São Rafael, 96 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.793095,
                  lng:-38.5659336
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pan-americano-bairros-fortaleza-com-garagem-56m2-venda-RS135000-id-2746952440/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua Estado do Rio, 50 - Pan Americano, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7510773,
                  lng:-38.5663
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-parque-presidente-vargas-bairros-fortaleza-com-garagem-130m2-venda-RS135000-id-2704786860/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua Iná Brito, 1457 - Parque Presidente Vargas, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 130m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:130,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8246015,
                  lng:-38.5966472
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS135000-id-2759008514/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua Coronel Chico Alves, 21 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8070918,
                  lng:-38.52605519999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS135000-id-2758005720/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua Coronel Chico Alves, 100 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8067329,
                  lng:-38.5263069
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-62m2-venda-RS135000-id-2753413118/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Frederico Severo, 201 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 62m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:62,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8178201,
                  lng:-38.4948463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS135000-id-2740170291/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Avenida Frei Cirilo, 980 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.813233,
                  lng:-38.4992473
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-44m2-venda-RS135000-id-2732237021/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua B, 980 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8148903,
                  lng:-38.5365463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-44m2-venda-RS135000-id-2735059915/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua B, 980 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8148903,
                  lng:-38.5365463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-61m2-venda-RS135000-id-2731707228/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua Tibúrcio Pereira, 340 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7972332,
                  lng:-38.5100965
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-1-quartos-mondubim-bairros-fortaleza-101m2-venda-RS135000-id-2759612309/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com  Quarto à Venda, 101m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:101,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8118712,
                  lng:-38.5746698
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-44m2-venda-RS135000-id-2735108507/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua B, 76 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.803972,
                  lng:-38.55448459999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-50m2-venda-RS135000-id-2665330612/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8163239,
                  lng:-38.5316373
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-44m2-venda-RS135000-id-2745443808/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua B - Dendê, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7959576,
                  lng:-38.5612306
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS135000-id-2745527311/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua B, 51 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.796436,
                  lng:-38.5618282
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-edson-queiroz-bairros-fortaleza-com-garagem-75m2-venda-RS135000-id-2730047540/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua F, 10 - Edson Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 75m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:75,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7764564,
                  lng:-38.4730443
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-44m2-venda-RS135000-id-2732521320/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua B, 210 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8045006,
                  lng:-38.5505457
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-54m2-venda-RS135000-id-2745924507/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Coronel Manuel Albano - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8056261,
                  lng:-38.5728245
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-centro-bairros-fortaleza-com-garagem-75m2-venda-RS135000-id-2647851455/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua Conselheiro Estelita, 375 - Centro, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 75m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:75,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.72425,
                  lng:-38.5393455
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-66m2-venda-RS135000-id-2763307115/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua Padre Paulino, 330 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 66m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:66,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7976029,
                  lng:-38.5087857
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-serrinha-bairros-fortaleza-com-garagem-51m2-venda-RS135000-id-2634421337/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua Caramuru, 105 - Serrinha, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 51m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:51,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7885471,
                  lng:-38.5408779
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-44m2-venda-RS135000-id-2732236187/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua B, 980 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8148903,
                  lng:-38.5365463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-49m2-venda-RS135000-id-2751936119/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Avenida I, 945 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8416808,
                  lng:-38.5557188
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-45m2-venda-RS135000-id-2763054931/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7887464,
                  lng:-38.548393
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS135000-id-2652368458/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Vila São Rafael, 96 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.793095,
                  lng:-38.5659336
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-60m2-venda-RS135000-id-2648673090/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua Coronel Manuel Albano, 946 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/e2926b6144d0ae3c2ec1c499f7ca4888/foto-1-de-apartamento-com-2-quartos-a-venda-60m-em-mondubim-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8046593,
                  lng:-38.5748968
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-45m2-venda-RS135000-id-2758226165/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua 2, 155 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/bb38bb95350359c1c9b572a3bd8d1d63/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-itaperi-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7930314,
                  lng:-38.53034
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cidade-dos-funcionarios-bairros-fortaleza-com-garagem-44m2-venda-RS135000-id-2719822034/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Avenida José Leon, 148 - Cidade dos Funcionários, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/2efdf34e5460e0a9aff92e6c4df47760/foto-1-de-apartamento-com-2-quartos-a-venda-44m-em-cidade-dos-funcionarios-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7869457,
                  lng:-38.5094169
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-bom-jardim-bairros-fortaleza-100m2-venda-RS135000-id-2626011955/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua Doutor Fernando Augusto, 3988 - Bom Jardim, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/59ee607438b1f650533a5d81e47bc493/foto-1-de-casa-com-2-quartos-a-venda-100m-em-bom-jardim-fortaleza.webp",
               titulo:"Casa com 2 Quartos à Venda, 100m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:100,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7852701,
                  lng:-38.6279789
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-44m2-venda-RS135000-id-2658351815/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua Coronel Manuel Albano, 96 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8072827,
                  lng:-38.5670897
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-50m2-venda-RS135000-id-2740222952/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua A, 200 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8160193,
                  lng:-38.5599917
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-61m2-venda-RS135000-id-2694989976/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8003068,
                  lng:-38.5069443
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-61m2-venda-RS135000-id-2749175643/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua Tenente Tito Barros, 341 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7977384,
                  lng:-38.5113799
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-lagoa-redonda-bairros-fortaleza-com-garagem-100m2-venda-RS135000-id-2658557409/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua Gerardo Lima - Lagoa Redonda, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 100m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:100,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8243175,
                  lng:-38.4651228
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-messejana-bairros-fortaleza-com-garagem-50m2-venda-RS135000-id-2744739307/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Frei Cirilo, 19 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8124537,
                  lng:-38.4995671
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-messejana-bairros-fortaleza-com-garagem-62m2-venda-RS135000-id-2761610560/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Frederico Severo, 201 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 62m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:62,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8178201,
                  lng:-38.4948463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-farias-brito-bairros-fortaleza-63m2-venda-RS135000-id-2687437329/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua Dom Jerônimo, 346 - Farias Brito, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 63m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:63,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7344241,
                  lng:-38.54330210000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-54m2-venda-RS135000-id-2745989101/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Coronel Manuel Albano, 60 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8079429,
                  lng:-38.5646483
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-44m2-venda-RS135000-id-2739922098/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Avenida Frei Cirilo, 980 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.813233,
                  lng:-38.4992473
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-lagoa-redonda-bairros-fortaleza-com-garagem-80m2-venda-RS135000-id-2598765356/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Gerardo Lima, 159 - Lagoa Redonda, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8248734,
                  lng:-38.4649159
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-sao-joao-do-tauape-bairros-fortaleza-com-garagem-54m2-venda-RS135000-id-2682419932/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"São João do Tauape, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7555878,
                  lng:-38.5045014
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-parangaba-bairros-fortaleza-43m2-venda-RS135000-id-2647773657/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua Eusébio de Queirós, 2058 - Parangaba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 43m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:43,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7729001,
                  lng:-38.556476
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-61m2-venda-RS135000-id-2698741677/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua Tibúrcio Pereira, 340 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7972332,
                  lng:-38.5100965
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-62m2-venda-RS135000-id-2753416025/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Frederico Severo, 201 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 62m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:62,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8178201,
                  lng:-38.4948463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-45m2-venda-RS135000-id-2760112734/",
               valorVenda:"135000.00",
               valorOriginal:"135000.00",
               endereco:"Rua 6 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7953168,
                  lng:-38.5386024
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-49m2-venda-RS136000-id-2701892475/",
               valorVenda:"136000.00",
               valorOriginal:"136000.00",
               endereco:"Rua Quartzo, 40 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8888451,
                  lng:-38.5192932
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-antonio-bezerra-bairros-fortaleza-com-garagem-61m2-venda-RS136000-id-2589873680/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Sargento Hermínio Sampaio, 5400 - Antônio Bezerra, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7339313,
                  lng:-38.5878285
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-edson-queiroz-bairros-fortaleza-com-garagem-60m2-venda-RS136270-id-2761648561/",
               valorVenda:"136270.00",
               valorOriginal:"136270.00",
               endereco:"Rua Cidade Ecológica, N. 420 APTO. 303 BL.07 . - Edson Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7743182,
                  lng:-38.46572949999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cidade-dos-funcionarios-bairros-fortaleza-com-garagem-45m2-venda-RS136270-id-2754325109/",
               valorVenda:"136270.00",
               valorOriginal:"136270.00",
               endereco:"Avenida José Leon, N. 1078 APTO. 504 BL H - Cidade dos Funcionários, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.787842,
                  lng:-38.5026262
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS136532-id-2754310526/",
               valorVenda:"136532.00",
               valorOriginal:"136532.00",
               endereco:"Avenida da Saudade, N. 3225 APTO. 304 BL 04 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8152399,
                  lng:-38.5352715
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-40m2-venda-RS137000-id-2730712634/",
               valorVenda:"137000.00",
               valorOriginal:"137000.00",
               endereco:"Rua Monte Líbano, 946 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 40m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:40,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.828243,
                  lng:-38.564801
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-bela-vista-bairros-fortaleza-com-garagem-53m2-venda-RS138000-id-2754510301/",
               valorVenda:"138000.00",
               valorOriginal:"138000.00",
               endereco:"Rua Minas Gerais, 149 - Bela Vista, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 53m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:53,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7524001,
                  lng:-38.5621782
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-60m2-venda-RS138000-id-2755568635/",
               valorVenda:"138000.00",
               valorOriginal:"138000.00",
               endereco:"Rua Tibúrcio Pereira, 431 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7979455,
                  lng:-38.5096364
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-55m2-venda-RS138000-id-2754730300/",
               valorVenda:"138000.00",
               valorOriginal:"138000.00",
               endereco:"Rua Tibúrcio Pereira, 431 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7979455,
                  lng:-38.5096364
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-bela-vista-bairros-fortaleza-com-garagem-53m2-venda-RS138000-id-2753553400/",
               valorVenda:"138000.00",
               valorOriginal:"138000.00",
               endereco:"Rua Minas Gerais, 149 - Bela Vista, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 53m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:53,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7524001,
                  lng:-38.5621782
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-bela-vista-bairros-fortaleza-com-garagem-53m2-venda-RS138000-id-2752737096/",
               valorVenda:"138000.00",
               valorOriginal:"138000.00",
               endereco:"Rua Professora Lilia Abreu - Bela Vista, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 53m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:53,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7497613,
                  lng:-38.5661536
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS138000-id-2693047951/",
               valorVenda:"138000.00",
               valorOriginal:"138000.00",
               endereco:"Rua Quartzo, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889129,
                  lng:-38.5193213
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-passare-bairros-fortaleza-com-garagem-110m2-venda-RS138800-id-2754417396/",
               valorVenda:"138800.00",
               valorOriginal:"138800.00",
               endereco:"Rua B - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 110m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:110,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8144516,
                  lng:-38.5288442
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-44m2-venda-RS139000-id-2674272577/",
               valorVenda:"139000.00",
               valorOriginal:"139000.00",
               endereco:"Rua 6, 8 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7952806,
                  lng:-38.5383807
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-46m2-venda-RS139000-id-2734103339/",
               valorVenda:"139000.00",
               valorOriginal:"139000.00",
               endereco:"Rua Padre Severiano, 90 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8342701,
                  lng:-38.4916857
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-serrinha-bairros-fortaleza-com-garagem-45m2-venda-RS139000-id-2724530344/",
               valorVenda:"139000.00",
               valorOriginal:"139000.00",
               endereco:"Rua Caramuru, 105 - Serrinha, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7885471,
                  lng:-38.5408779
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cambeba-bairros-fortaleza-com-garagem-56m2-venda-RS139000-id-2663395226/",
               valorVenda:"139000.00",
               valorOriginal:"139000.00",
               endereco:"Rua Nenê Arruda, 1140 - Cambeba, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/65bf9c4a6d2565c4f0b791ff91c0f257/foto-1-de-apartamento-com-2-quartos-a-venda-56m-em-cambeba-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8199349,
                  lng:-38.4784928
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS139000-id-2681220000/",
               valorVenda:"139000.00",
               valorOriginal:"139000.00",
               endereco:"Rua Quartzo, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/042d4e71395f27197daf7653811feb6e/foto-1-de-apartamento-com-2-quartos-a-venda-50m-em-pedras-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889129,
                  lng:-38.5193213
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS139000-id-2744629166/",
               valorVenda:"139000.00",
               valorOriginal:"139000.00",
               endereco:"Rua Quartzo, 105 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/a31252721707a69fb404f7dcdc7e29fc/foto-1-de-apartamento-com-2-quartos-a-venda-50m-em-pedras-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8885334,
                  lng:-38.5198688
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-centro-bairros-fortaleza-62m2-venda-RS139000-id-2753617601/",
               valorVenda:"139000.00",
               valorOriginal:"139000.00",
               endereco:"Rua Pedro I, 223 - Centro, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/41ad63b19dc2e96a050c33f5bbe662a8/foto-1-de-apartamento-com-2-quartos-a-venda-62m-em-centro-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 62m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:62,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.732004299999999,
                  lng:-38.5267262
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-72m2-venda-RS139619-id-2759409124/",
               valorVenda:"139619.00",
               valorOriginal:"139619.00",
               endereco:"Rua O, N. 421  LT 2425  QD 11 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 72m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:72,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.831411499999999,
                  lng:-38.5501154
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-72m2-venda-RS139619-id-2759768225/",
               valorVenda:"139619.00",
               valorOriginal:"139619.00",
               endereco:"Rua O, N. 421  LT 2425  QD 11 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 72m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:72,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.831411499999999,
                  lng:-38.5501154
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-50m2-venda-RS139900-id-2727636887/",
               valorVenda:"139900.00",
               valorOriginal:"139900.00",
               endereco:"Rua C, 6 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8115872,
                  lng:-38.5319967
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-vila-peri-bairros-fortaleza-com-garagem-42m2-venda-RS139900-id-2740379030/",
               valorVenda:"139900.00",
               valorOriginal:"139900.00",
               endereco:"Rua Arruda Câmara, 509 - Vila Peri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7885625,
                  lng:-38.5847669
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-47m2-venda-RS139990-id-2741218271/",
               valorVenda:"139990.00",
               valorOriginal:"139990.00",
               endereco:"Rua das Aroeiras, 200 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8046022,
                  lng:-38.5370229
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-48m2-venda-RS140000-id-2751687805/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Coronel Chico Alves, 72 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8066694,
                  lng:-38.5262631
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-siqueira-bairros-fortaleza-com-garagem-90m2-venda-RS140000-id-2751936221/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Eriveu Ramos, 2812 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.802882799999999,
                  lng:-38.6333404
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jacarecanga-bairros-fortaleza-com-garagem-59m2-venda-RS140000-id-2731784414/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Francisco Sá - Jacarecanga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 59m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:59,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7209371,
                  lng:-38.5465937
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-63m2-venda-RS140000-id-2761033528/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Tibúrcio Pereira, 431 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 63m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:63,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7979455,
                  lng:-38.5096364
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-58m2-venda-RS140000-id-2527851934/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Trajano de Morais, 333 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 58m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:58,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8111579,
                  lng:-38.5234274
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-vila-peri-bairros-fortaleza-com-garagem-65m2-venda-RS140000-id-2753972218/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Dom Henrique, 11 - Vila Peri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 65m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:65,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7908775,
                  lng:-38.5749968
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS140000-id-2728992427/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8163239,
                  lng:-38.5316373
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-barra-do-ceara-bairros-fortaleza-com-garagem-47m2-venda-RS140000-id-2723963135/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Irapuã, 250 - Barra do Ceará, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7042666,
                  lng:-38.5849161
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-manuel-satiro-bairros-fortaleza-com-garagem-48m2-venda-RS140000-id-2705585320/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Alfredo Mamede, 637 - Manuel Sátiro, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.81069,
                  lng:-38.5832137
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-cambeba-bairros-fortaleza-com-garagem-44m2-venda-RS140000-id-2715987298/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Avenida Minervina Moreira, 38 - Cambeba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com  Quarto à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8016386,
                  lng:-38.481387
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cajazeiras-bairros-fortaleza-com-garagem-67m2-venda-RS140000-id-2750762895/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Francisca Nogueira Ramos, 266 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 67m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:67,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.806648,
                  lng:-38.5041571
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-45m2-venda-RS140000-id-2754942525/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Professora Stella Cochrane - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7945283,
                  lng:-38.5360939
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pan-americano-bairros-fortaleza-com-garagem-57m2-venda-RS140000-id-2711045123/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Estado do Rio, 50 - Pan Americano, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 57m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:57,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7510773,
                  lng:-38.5663
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-padre-andrade-bairros-fortaleza-com-garagem-77m2-venda-RS140000-id-2505650711/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Alcântara Bilhar, 677 - Padre Andrade, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 77m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:77,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7366381,
                  lng:-38.5818265
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/sobrado-4-quartos-parque-dois-irmaos-bairros-fortaleza-100m2-venda-RS140000-id-2757322617/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Via de Pedestre 2, 188 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 4 Quartos à Venda, 100m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:100,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8096945,
                  lng:-38.5460576
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-46m2-venda-RS140000-id-2759290424/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Avenida Presidente Juscelino Kubitschek, 3371 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8103381,
                  lng:-38.5269586
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-48m2-venda-RS140000-id-2762625825/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8163239,
                  lng:-38.5316373
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-novo-mondubim-bairros-fortaleza-com-garagem-73m2-venda-RS140000-id-2725814257/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Alfredo Mamede, 637 - Novo Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos para Venda/Aluguel 73m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:73,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.81069,
                  lng:-38.5832137
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-jangurussu-bairros-fortaleza-com-garagem-72m2-venda-RS140000-id-2751934933/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua 9, 137 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 72m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:72,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8252702,
                  lng:-38.5171503
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jacarecanga-bairros-fortaleza-com-garagem-68m2-venda-RS140000-id-2742793158/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Francisco Sá, 2410 - Jacarecanga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 68m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:68,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7205388,
                  lng:-38.5472087
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aracape-bairros-fortaleza-com-garagem-66m2-venda-RS140000-id-2763280140/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Nossa Senhora Aparecida - ARACAPE, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 66m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:66,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8358431,
                  lng:-38.5884881
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-50m2-venda-RS140000-id-2733278889/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Professora Stella Cochrane - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7945283,
                  lng:-38.5360939
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cidade-dos-funcionarios-bairros-fortaleza-com-garagem-55m2-venda-RS140000-id-2655862363/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida José Leon - Cidade dos Funcionários, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7884259,
                  lng:-38.499423
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-bela-vista-bairros-fortaleza-com-garagem-50m2-venda-RS140000-id-1042087091/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Estado do Rio, 55 - Bela Vista, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.751001,
                  lng:-38.5659075
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-presidente-kennedy-bairros-fortaleza-com-garagem-60m2-venda-RS140000-id-2748482417/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Frei Damião, 143 - Presidente Kennedy, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.726975,
                  lng:-38.5718768
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-henrique-jorge-bairros-fortaleza-com-garagem-45m2-venda-RS140000-id-2613216906/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Padre Sá Leitão, 55 - Henrique Jorge, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7559889,
                  lng:-38.5787099
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-padre-andrade-bairros-fortaleza-com-garagem-67m2-venda-RS140000-id-2667567740/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Alcântara Bilhar, 677 - Padre Andrade, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 67m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:67,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7366381,
                  lng:-38.5818265
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-henrique-jorge-bairros-fortaleza-com-garagem-45m2-venda-RS140000-id-2613216906/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Padre Sá Leitão, 55 - Henrique Jorge, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/41ee367a22fb437a2936c4bc7a68e87a/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-henrique-jorge-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7559889,
                  lng:-38.5787099
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jacarecanga-bairros-fortaleza-com-garagem-60m2-venda-RS140000-id-2749508289/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Francisco Sá, 2420 - Jacarecanga, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/5d9bfa1e889a8c73ee685ba5f1ef1ab3/foto-1-de-apartamento-com-2-quartos-a-venda-60m-em-jacarecanga-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7205116,
                  lng:-38.547435
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jangurussu-bairros-fortaleza-com-garagem-150m2-venda-RS140000-id-2715204293/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Florêncio Fontenele, 380 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/f9a1f456e62b03ade1742944344ee6b7/foto-1-de-apartamento-com-2-quartos-a-venda-150m-em-jangurussu-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 150m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:150,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.833748999999999,
                  lng:-38.5084662
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-48m2-venda-RS140000-id-2751930508/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Coronel Chico Alves, 65 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/27b8c6c4958fdcbc5811e1ee3d09b4ce/foto-1-de-apartamento-com-2-quartos-a-venda-48m-em-passare-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.807128399999999,
                  lng:-38.5262555
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-48m2-venda-RS140000-id-2756346754/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Avenida dos Paroaras, 101 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8125776,
                  lng:-38.536265
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jangurussu-bairros-fortaleza-com-garagem-45m2-venda-RS140000-id-2681467906/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Francisco Alves Ribeiro, 135 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8309654,
                  lng:-38.5089204
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-61m2-venda-RS140000-id-2758106223/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Tibúrcio Pereira, 431 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/fit-in/142x80/named.images.sp/84727e3f5497d74f9ca9690c3c40c185/{description}.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7979455,
                  lng:-38.5096364
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-48m2-venda-RS140000-id-2698525604/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Antônio Luís Almeida Vale, 100 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.820155499999999,
                  lng:-38.5619096
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-46m2-venda-RS140000-id-2757659871/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Avenida Presidente Juscelino Kubitschek, 3371 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8103381,
                  lng:-38.5269586
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS140000-id-2682545442/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Jorge Figueiredo, 1 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8978245,
                  lng:-38.5104087
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-40m2-venda-RS140000-id-2751573614/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Joaquim Martins, 455 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 40m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:40,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.80376,
                  lng:-38.5408
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-45m2-venda-RS140000-id-2756598421/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Professora Stella Cochrane - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7945283,
                  lng:-38.5360939
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-maraponga-bairros-fortaleza-com-garagem-65m2-venda-RS140000-id-2644077271/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Bélgica, 896 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 65m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:65,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.789622899999999,
                  lng:-38.5658816
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-70m2-venda-RS140000-id-2762922727/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Otília Carvalho, 340 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8015934,
                  lng:-38.5071081
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-messejana-bairros-fortaleza-com-garagem-60m2-venda-RS140000-id-2741250759/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8327352,
                  lng:-38.4940863
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-48m2-venda-RS140000-id-2759329328/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Professora Stella Cochrane, 340 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7953486,
                  lng:-38.537146
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-54m2-venda-RS140000-id-2752079422/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua das Oiticicas, 659 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8090226,
                  lng:-38.5367869
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-51m2-venda-RS140000-id-2638879079/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 51m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:51,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8163239,
                  lng:-38.5316373
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-siqueira-bairros-fortaleza-com-garagem-120m2-venda-RS140000-id-2733179635/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Nova, 1432 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos para Venda/Aluguel 120m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:120,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.804103599999999,
                  lng:-38.6276933
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-presidente-kennedy-bairros-fortaleza-com-garagem-60m2-venda-RS140000-id-2748482417/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Frei Damião, 143 - Presidente Kennedy, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.726975,
                  lng:-38.5718768
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-50m2-venda-RS140000-id-2751934379/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida dos Paroaras, 101 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8125776,
                  lng:-38.536265
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS140000-id-2751871514/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua das Oiticicas, 111 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8042186,
                  lng:-38.5352584
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-barra-do-ceara-bairros-fortaleza-com-garagem-46m2-venda-RS140000-id-2685712136/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Irapuã, 250 - Barra do Ceará, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7042666,
                  lng:-38.5849161
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-10m2-venda-RS140000-id-2751889894/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Coronel Chico Alves, 21 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 10m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:10,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8070918,
                  lng:-38.52605519999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-padre-andrade-bairros-fortaleza-com-garagem-67m2-venda-RS140000-id-2667567740/",
               valorVenda:"140000.00",
               valorOriginal:"140000.00",
               endereco:"Rua Alcântara Bilhar, 677 - Padre Andrade, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 67m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:67,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7366381,
                  lng:-38.5818265
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-50m2-venda-RS140000-id-2733302537/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Via Paisagística do Loteamento Itaperussú, 6 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7965175,
                  lng:-38.5361463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-jangurussu-bairros-fortaleza-com-garagem-290m2-venda-RS141850-id-2758184013/",
               valorVenda:"141850.00",
               valorOriginal:"141850.00",
               endereco:"Rua Morada Nova, N. 390 APTO. . LT 13 QD 43 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 290m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:290,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8438948,
                  lng:-38.510887
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cidade-dos-funcionarios-bairros-fortaleza-com-garagem-52m2-venda-RS141900-id-2674129811/",
               valorVenda:"141900.00",
               valorOriginal:"141900.00",
               endereco:"Avenida José Leon, 1078 - Cidade dos Funcionários, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.787842,
                  lng:-38.5026262
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-57m2-venda-RS142000-id-2762633220/",
               valorVenda:"142000.00",
               valorOriginal:"142000.00",
               endereco:"Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 57m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:57,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8163239,
                  lng:-38.5316373
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-1-quartos-canindezinho-bairros-fortaleza-com-garagem-150m2-venda-RS142000-id-2760108013/",
               valorVenda:"142000.00",
               valorOriginal:"142000.00",
               endereco:"Rua Topázio, N. 40  LT 02 QD 22 - Canindezinho, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com  Quarto à Venda, 150m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:150,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7186775,
                  lng:-38.5568574
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-49m2-venda-RS142000-id-2674972326/",
               valorVenda:"142000.00",
               valorOriginal:"142000.00",
               endereco:"Rua Quartzo, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889129,
                  lng:-38.5193213
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS143000-id-2754506718/",
               valorVenda:"143000.00",
               valorOriginal:"143000.00",
               endereco:"Rua Quartzo, 324 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8871972,
                  lng:-38.52124750000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS143000-id-2760461914/",
               valorVenda:"143000.00",
               valorOriginal:"143000.00",
               endereco:"Rua Quartzo, 439 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8873616,
                  lng:-38.52139469999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS143000-id-2762225019/",
               valorVenda:"143000.00",
               valorOriginal:"143000.00",
               endereco:"Rua Quartzo, 476 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8871311,
                  lng:-38.521323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS143000-id-2752964405/",
               valorVenda:"143000.00",
               valorOriginal:"143000.00",
               endereco:"Rua Quartzo, 193 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8881027,
                  lng:-38.5205334
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS143000-id-2668057908/",
               valorVenda:"143000.00",
               valorOriginal:"143000.00",
               endereco:"Rua Quartzo, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889129,
                  lng:-38.5193213
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS143000-id-2758747013/",
               valorVenda:"143000.00",
               valorOriginal:"143000.00",
               endereco:"Rua Quartzo, 418 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/30b4502d5d502b1a5255b3b904a75078/foto-1-de-apartamento-com-2-quartos-a-venda-50m-em-pedras-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8871311,
                  lng:-38.521323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS143000-id-2762225019/",
               valorVenda:"143000.00",
               valorOriginal:"143000.00",
               endereco:"Rua Quartzo, 476 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/d4733a9eb7aa5cbd93daf1535f3a2f30/foto-1-de-apartamento-com-2-quartos-a-venda-50m-em-pedras-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8871311,
                  lng:-38.521323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS143000-id-2668057908/",
               valorVenda:"143000.00",
               valorOriginal:"143000.00",
               endereco:"Rua Quartzo, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/96933787acca1ff414b1476eae1e7713/foto-1-de-apartamento-com-2-quartos-a-venda-50m-em-pedras-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889129,
                  lng:-38.5193213
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS143000-id-2672836131/",
               valorVenda:"143000.00",
               valorOriginal:"143000.00",
               endereco:"Rua Quartzo, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/814da668985b5e7bc9e46084799bd90b/foto-1-de-apartamento-com-2-quartos-a-venda-50m-em-pedras-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889129,
                  lng:-38.5193213
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-49m2-venda-RS143000-id-2727000635/",
               valorVenda:"143000.00",
               valorOriginal:"143000.00",
               endereco:"Rua Quartzo, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889129,
                  lng:-38.5193213
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS143000-id-2751634876/",
               valorVenda:"143000.00",
               valorOriginal:"143000.00",
               endereco:"Rua Quartzo, 81 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8888508,
                  lng:-38.5197391
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS143002-id-2664902109/",
               valorVenda:"143002.00",
               valorOriginal:"143002.00",
               endereco:"Rua Quartzo, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889129,
                  lng:-38.5193213
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS143002-id-2667658697/",
               valorVenda:"143002.00",
               valorOriginal:"143002.00",
               endereco:"Rua Quartzo, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889129,
                  lng:-38.5193213
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS143003-id-2670551203/",
               valorVenda:"143003.00",
               valorOriginal:"143003.00",
               endereco:"Rua Quartzo, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889129,
                  lng:-38.5193213
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS143005-id-2664992226/",
               valorVenda:"143005.00",
               valorOriginal:"143005.00",
               endereco:"Rua Quartzo, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889129,
                  lng:-38.5193213
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS143007-id-2669089606/",
               valorVenda:"143007.00",
               valorOriginal:"143007.00",
               endereco:"Rua Quartzo, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889129,
                  lng:-38.5193213
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS143007-id-2665304209/",
               valorVenda:"143007.00",
               valorOriginal:"143007.00",
               endereco:"Rua Quartzo, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889129,
                  lng:-38.5193213
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cidade-dos-funcionarios-bairros-fortaleza-com-garagem-52m2-venda-RS143900-id-2661561182/",
               valorVenda:"143900.00",
               valorOriginal:"143900.00",
               endereco:"Avenida José Leon, 1078 - Cidade dos Funcionários, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.787842,
                  lng:-38.5026262
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS144000-id-2750721604/",
               valorVenda:"144000.00",
               valorOriginal:"144000.00",
               endereco:"Rua Quartzo, 58 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8886878,
                  lng:-38.5194818
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS144000-id-2758746331/",
               valorVenda:"144000.00",
               valorOriginal:"144000.00",
               endereco:"Rua Quartzo, 418 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8871311,
                  lng:-38.521323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-54m2-venda-RS144000-id-2757151431/",
               valorVenda:"144000.00",
               valorOriginal:"144000.00",
               endereco:"Rua Quartzo, 378 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8871311,
                  lng:-38.521323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS144000-id-2762225816/",
               valorVenda:"144000.00",
               valorOriginal:"144000.00",
               endereco:"Rua Quartzo, 477 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8873616,
                  lng:-38.52139469999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS144000-id-2749501164/",
               valorVenda:"144000.00",
               valorOriginal:"144000.00",
               endereco:"Rua Quartzo, 10 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.888971,
                  lng:-38.5191422
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS144000-id-2750205401/",
               valorVenda:"144000.00",
               valorOriginal:"144000.00",
               endereco:"Rua Quartzo, 66 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.888667499999999,
                  lng:-38.5195061
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS144000-id-2751635369/",
               valorVenda:"144000.00",
               valorOriginal:"144000.00",
               endereco:"Rua Quartzo, 82 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8884952,
                  lng:-38.5196072
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-54m2-venda-RS144000-id-2755527503/",
               valorVenda:"144000.00",
               valorOriginal:"144000.00",
               endereco:"Rua Quartzo, 352 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8871311,
                  lng:-38.521323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS144000-id-2754522908/",
               valorVenda:"144000.00",
               valorOriginal:"144000.00",
               endereco:"Rodovia Quarto Anel Viário, 322 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.807203,
                  lng:-38.6390385
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS144000-id-2752966722/",
               valorVenda:"144000.00",
               valorOriginal:"144000.00",
               endereco:"Rua Quartzo, 194 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.887903499999999,
                  lng:-38.5203057
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS144000-id-2760462940/",
               valorVenda:"144000.00",
               valorOriginal:"144000.00",
               endereco:"Rua Quartzo, 4440 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8879534,
                  lng:-38.5205027
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-ii-bairros-fortaleza-com-garagem-52m2-venda-RS144900-id-2751399326/",
               valorVenda:"144900.00",
               valorOriginal:"144900.00",
               endereco:"Rua Coronel Francisco Montenegro, 168 - Praia do Futuro II, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7549215,
                  lng:-38.4462288
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-ii-bairros-fortaleza-com-garagem-50m2-venda-RS144900-id-2756677921/",
               valorVenda:"144900.00",
               valorOriginal:"144900.00",
               endereco:"Rua Coronel Francisco Montenegro, 16 - Praia do Futuro II, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7555058,
                  lng:-38.44746019999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-ii-bairros-fortaleza-com-garagem-52m2-venda-RS144900-id-2755014718/",
               valorVenda:"144900.00",
               valorOriginal:"144900.00",
               endereco:"Rua Coronel Francisco Montenegro - Praia do Futuro II, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7556095,
                  lng:-38.447676
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-ii-bairros-fortaleza-com-garagem-52m2-venda-RS144900-id-2744949526/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Coronel Francisco Montenegro, 567 - Praia do Futuro II, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7564778,
                  lng:-38.4494244
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-i-bairros-fortaleza-com-garagem-52m2-venda-RS144900-id-2738100313/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua 5, 980 - Praia do Futuro I, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.765437,
                  lng:-38.4417593
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-ii-bairros-fortaleza-com-garagem-52m2-venda-RS144900-id-2756408218/",
               valorVenda:"144900.00",
               valorOriginal:"144900.00",
               endereco:"Rua Coronel Francisco Montenegro, 89 - Praia do Futuro II, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7552537,
                  lng:-38.4469272
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-ii-bairros-fortaleza-com-garagem-49m2-venda-RS144900-id-2732732020/",
               valorVenda:"144900.00",
               valorOriginal:"144900.00",
               endereco:"Rua Coronel Francisco Montenegro, 78 - Praia do Futuro II, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7552898,
                  lng:-38.4470053
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-ii-bairros-fortaleza-com-garagem-49m2-venda-RS144900-id-2757624537/",
               valorVenda:"144900.00",
               valorOriginal:"144900.00",
               endereco:"Rua Coronel Francisco Montenegro, 71 - Praia do Futuro II, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7554755,
                  lng:-38.447439
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-ii-bairros-fortaleza-com-garagem-49m2-venda-RS144900-id-2758061524/",
               valorVenda:"144900.00",
               valorOriginal:"144900.00",
               endereco:"Rua Coronel Francisco Montenegro, 152 - Praia do Futuro II, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7549887,
                  lng:-38.4463551
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS145000-id-2746917201/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Quartzo, 241 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.887844299999999,
                  lng:-38.5208266
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-71m2-venda-RS145000-id-2756263022/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Tibúrcio Pereira, 430 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 71m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:71,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.797367199999999,
                  lng:-38.5090925
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS145000-id-2745244132/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Coronel Chico Alves, 643 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.808065499999999,
                  lng:-38.5271112
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS145000-id-2745154685/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Coronel Chico Alves, 345 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/8db658d1157c83ca6d8269bbf2840e8d/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-passare-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.808065499999999,
                  lng:-38.5271112
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-60m2-venda-RS145000-id-2761964618/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Tibúrcio Pereira, 431 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/a131568f58488f1378cb390044902c57/foto-1-de-apartamento-com-3-quartos-a-venda-60m-em-cajazeiras-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7979455,
                  lng:-38.5096364
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-57m2-venda-RS145000-id-2762799416/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Prudente Brasil, 633 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/01eddf7aa7e13b91fff2138e3193df01/foto-1-de-apartamento-com-2-quartos-a-venda-57m-em-passare-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 57m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:57,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8085569,
                  lng:-38.5338357
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-alvaro-weyne-bairros-fortaleza-com-garagem-44m2-venda-RS145000-id-2669737610/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Dona Mendinha, 1025 - Álvaro Weyne, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/449b5370a0134f632dfeebcf545eb6da/foto-1-de-apartamento-com-2-quartos-a-venda-44m-em-alvaro-weyne-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7117278,
                  lng:-38.5708085
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-48m2-venda-RS145000-id-2730005431/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Avenida Bernardo Manuel, 8550 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7942973,
                  lng:-38.5502458
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-bela-vista-bairros-fortaleza-com-garagem-53m2-venda-RS145000-id-2754735717/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Minas Gerais, 149 - Bela Vista, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 53m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:53,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7524001,
                  lng:-38.5621782
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-montese-bairros-fortaleza-com-garagem-67m2-venda-RS145000-id-2751738060/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Oscar Bezerra, 44 - Montese, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 67m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:67,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7606079,
                  lng:-38.5595243
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-barra-do-ceara-bairros-fortaleza-com-garagem-47m2-venda-RS145000-id-2749838826/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Irapuã, 250 - Barra do Ceará, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7042666,
                  lng:-38.5849161
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-henrique-jorge-bairros-fortaleza-com-garagem-62m2-venda-RS145000-id-2693636980/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Franco Rocha, 397 - Henrique Jorge, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 62m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:62,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.754819799999999,
                  lng:-38.5801647
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jangurussu-bairros-fortaleza-com-garagem-45m2-venda-RS145000-id-2748852199/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Recanto Verde, 2020 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.843423,
                  lng:-38.5137123
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-46m2-venda-RS145000-id-2565823704/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Professora Stella Cochrane - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7945283,
                  lng:-38.5360939
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-jose-bonifacio-bairros-fortaleza-80m2-venda-RS145000-id-2744634216/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Lauro Maia, 193 - José Bonifácio, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7420141,
                  lng:-38.5237882
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-bairros-fortaleza-com-garagem-49m2-venda-RS145000-id-2757160131/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Avenida César Cals, 450 - Praia do Futuro, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7492867,
                  lng:-38.44709840000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/cobertura-2-quartos-ancuri-bairros-fortaleza-com-garagem-100m2-venda-RS145000-id-2606081502/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua 3, 11 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 100m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:100,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8695634,
                  lng:-38.5188252
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS145000-id-2757150639/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Quartzo, 378 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8871311,
                  lng:-38.521323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-alvaro-weyne-bairros-fortaleza-com-garagem-44m2-venda-RS145000-id-2751853030/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Dona Mendinha, 1025 - Álvaro Weyne, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7117278,
                  lng:-38.5708085
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS145000-id-2747131805/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Quartzo, 10 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.888971,
                  lng:-38.5191422
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-44m2-venda-RS145000-id-2752515495/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Farias Lemos, 800 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8136949,
                  lng:-38.4942168
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS145000-id-2743742601/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 45 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8884323,
                  lng:-38.5449349
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS145000-id-2693964332/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Quartzo, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889129,
                  lng:-38.5193213
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS145000-id-2751359322/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Coronel Chico Alves, 278 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8073852,
                  lng:-38.5268243
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS145000-id-2745047521/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Coronel Chico Alves, 54 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8066273,
                  lng:-38.5262349
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-barra-do-ceara-bairros-fortaleza-com-garagem-44m2-venda-RS145000-id-2724157337/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Irapuã, 398 - Barra do Ceará, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.704580199999999,
                  lng:-38.5854863
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-siqueira-bairros-fortaleza-com-garagem-80m2-venda-RS145000-id-2753419226/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Israel França, 1403 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8274445,
                  lng:-38.6210732
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-antonio-bezerra-bairros-fortaleza-45m2-venda-RS145000-id-2737045658/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Rui Monte, 1070 - Antônio Bezerra, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7481792,
                  lng:-38.5855888
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS145000-id-2757616252/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Coronel Chico Alves, 18 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8065432,
                  lng:-38.5261787
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-48m2-venda-RS145000-id-2697800694/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Quartzo, 40 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8888451,
                  lng:-38.5192932
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS145000-id-2716415848/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Quartzo, 34 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8888587,
                  lng:-38.5192769
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-60m2-venda-RS145000-id-2655632703/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua das Oiticicas - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8075049,
                  lng:-38.5364841
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-48m2-venda-RS145000-id-2751936012/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Coronel Manuel Albano, 100 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8072557,
                  lng:-38.5670178
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-barra-do-ceara-bairros-fortaleza-com-garagem-46m2-venda-RS145000-id-2733680423/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Avenida Francisco Sá, 250 - Barra do Ceará, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7225781,
                  lng:-38.5415981
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-44m2-venda-RS145000-id-2744736785/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Farias Lemos, 800 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8136949,
                  lng:-38.4942168
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-44m2-venda-RS145000-id-2748686912/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Farias Lemos, 800 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8136949,
                  lng:-38.4942168
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-56m2-venda-RS145000-id-2751936893/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Avenida Godofredo Maciel, 2441 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.796825,
                  lng:-38.5697178
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-papicu-bairros-fortaleza-com-garagem-82m2-venda-RS145000-id-2700709696/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Emídio Lobo, 180 - Papicu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 82m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:82,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.738091299999999,
                  lng:-38.4709166
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-novo-mondubim-bairros-fortaleza-com-garagem-48m2-venda-RS145000-id-2736440022/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Alfredo Mamede, 637 - Novo Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.81069,
                  lng:-38.5832137
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS145000-id-2757616252/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Coronel Chico Alves, 18 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/21af7dfa98902a2fa66978ca16d15bf5/foto-1-de-apartamento-com-2-quartos-a-venda-44m-em-passare-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8065432,
                  lng:-38.5261787
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-48m2-venda-RS145000-id-2697800694/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Quartzo, 40 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/a8eb7e70cf1d66ef21423874bf82640e/foto-1-de-apartamento-com-2-quartos-a-venda-48m-em-pedras-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8888451,
                  lng:-38.5192932
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-48m2-venda-RS145000-id-2751936012/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Coronel Manuel Albano, 100 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/3276e20d97da5732d6f67d816b899f48/foto-1-de-apartamento-com-2-quartos-a-venda-48m-em-mondubim-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8072557,
                  lng:-38.5670178
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS145000-id-2745047521/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Coronel Chico Alves, 54 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/01dc75b2249fe5ba80e1dc22938a3078/foto-1-de-apartamento-com-2-quartos-a-venda-44m-em-passare-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8066273,
                  lng:-38.5262349
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-barra-do-ceara-bairros-fortaleza-com-garagem-46m2-venda-RS145000-id-2733680423/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Avenida Francisco Sá, 250 - Barra do Ceará, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7225781,
                  lng:-38.5415981
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-alvaro-weyne-bairros-fortaleza-com-garagem-44m2-venda-RS145000-id-2751854654/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Dona Mendinha, 1026 - Álvaro Weyne, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7121606,
                  lng:-38.57128549999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-edson-queiroz-bairros-fortaleza-com-garagem-66m2-venda-RS145000-id-2750000605/",
               valorVenda:"145000.00",
               valorOriginal:"145000.00",
               endereco:"Rua Cidade Ecológica, 420 - Edson Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 66m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:66,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7743182,
                  lng:-38.46572949999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS145001-id-2680254207/",
               valorVenda:"145001.00",
               valorOriginal:"145001.00",
               endereco:"Rua Quartzo, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889129,
                  lng:-38.5193213
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-48m2-venda-RS145001-id-2703340000/",
               valorVenda:"145001.00",
               valorOriginal:"145001.00",
               endereco:"Rua Quartzo, 40 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8888451,
                  lng:-38.5192932
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cidade-dos-funcionarios-bairros-fortaleza-com-garagem-52m2-venda-RS145900-id-2648818487/",
               valorVenda:"145900.00",
               valorOriginal:"145900.00",
               endereco:"Avenida José Leon, 1078 - Cidade dos Funcionários, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.787842,
                  lng:-38.5026262
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS146000-id-2752969995/",
               valorVenda:"146000.00",
               valorOriginal:"146000.00",
               endereco:"Rua Quartzo, 196 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8878927,
                  lng:-38.5203174
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS146000-id-2749502233/",
               valorVenda:"146000.00",
               valorOriginal:"146000.00",
               endereco:"Rua Quartzo, 12 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.888971,
                  lng:-38.5191422
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS146000-id-2750205243/",
               valorVenda:"146000.00",
               valorOriginal:"146000.00",
               endereco:"Rua Quartzo, 58 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8886878,
                  lng:-38.5194818
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-46m2-venda-RS146000-id-2720606335/",
               valorVenda:"146000.00",
               valorOriginal:"146000.00",
               endereco:"Rua Quartzo, 1 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8891673,
                  lng:-38.5193405
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS146000-id-2750722116/",
               valorVenda:"146000.00",
               valorOriginal:"146000.00",
               endereco:"Rua Quartzo, 58 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8886878,
                  lng:-38.5194818
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-46m2-venda-RS146000-id-2721441152/",
               valorVenda:"146000.00",
               valorOriginal:"146000.00",
               endereco:"Rua Quartzo, 323 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8873742,
                  lng:-38.5213798
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS146000-id-2755529902/",
               valorVenda:"146000.00",
               valorOriginal:"146000.00",
               endereco:"Rua Quartzo, 354 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8871311,
                  lng:-38.521323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS146000-id-2751640128/",
               valorVenda:"146000.00",
               valorOriginal:"146000.00",
               endereco:"Rua Quartzo, 84 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.888545599999999,
                  lng:-38.5196543
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS146000-id-2747352500/",
               valorVenda:"146000.00",
               valorOriginal:"146000.00",
               endereco:"Rua Quartzo, 14 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.888971,
                  lng:-38.5191422
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS146000-id-2757152921/",
               valorVenda:"146000.00",
               valorOriginal:"146000.00",
               endereco:"Rua Quartzo, 380 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8871311,
                  lng:-38.521323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cidade-dos-funcionarios-bairros-fortaleza-com-garagem-52m2-venda-RS146900-id-2674144089/",
               valorVenda:"146900.00",
               valorOriginal:"146900.00",
               endereco:"Avenida José Leon, 1078 - Cidade dos Funcionários, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.787842,
                  lng:-38.5026262
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS147000-id-2758746262/",
               valorVenda:"147000.00",
               valorOriginal:"147000.00",
               endereco:"Rua Quartzo, 419 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8873616,
                  lng:-38.52139469999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS147000-id-2760829224/",
               valorVenda:"147000.00",
               valorOriginal:"147000.00",
               endereco:"Rua Quartzo, 431 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8873616,
                  lng:-38.52139469999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-61m2-venda-RS148000-id-2651540466/",
               valorVenda:"148000.00",
               valorOriginal:"148000.00",
               endereco:"Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8003068,
                  lng:-38.5069443
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-46m2-venda-RS148000-id-2719872649/",
               valorVenda:"148000.00",
               valorOriginal:"148000.00",
               endereco:"Rua Coronel Manuel Albano, 60 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8079429,
                  lng:-38.5646483
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-sao-joao-do-tauape-bairros-fortaleza-com-garagem-54m2-venda-RS148000-id-2749876600/",
               valorVenda:"148000.00",
               valorOriginal:"148000.00",
               endereco:"Rua Evaristo Reis, 309 - São João do Tauape, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7613438,
                  lng:-38.5092168
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-48m2-venda-RS149000-id-2758175007/",
               valorVenda:"149000.00",
               valorOriginal:"149000.00",
               endereco:"Rua José Pedra, 8600 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7918131,
                  lng:-38.5485416
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-bairros-fortaleza-com-garagem-44m2-venda-RS149000-id-2757598560/",
               valorVenda:"149000.00",
               valorOriginal:"149000.00",
               endereco:"Avenida César Cals, 450 - Praia do Futuro, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7492867,
                  lng:-38.44709840000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-48m2-venda-RS149000-id-2763191426/",
               valorVenda:"149000.00",
               valorOriginal:"149000.00",
               endereco:"Rua José Pedra, 8600 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7918131,
                  lng:-38.5485416
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-56m2-venda-RS149000-id-2637882715/",
               valorVenda:"149000.00",
               valorOriginal:"149000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 1 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8436581,
                  lng:-38.5003576
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-bairros-fortaleza-com-garagem-44m2-venda-RS149000-id-2757026529/",
               valorVenda:"149000.00",
               valorOriginal:"149000.00",
               endereco:"Avenida César Cals, 400 - Praia do Futuro, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7492867,
                  lng:-38.44709840000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-47m2-venda-RS149000-id-2732090434/",
               valorVenda:"149000.00",
               valorOriginal:"149000.00",
               endereco:"Rua Professora Stella Cochrane - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7956033,
                  lng:-38.5369268
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-48m2-venda-RS149000-id-2758486304/",
               valorVenda:"149000.00",
               valorOriginal:"149000.00",
               endereco:"Rua José Pedra, 8600 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7918131,
                  lng:-38.5485416
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-48m2-venda-RS149000-id-2761026878/",
               valorVenda:"149000.00",
               valorOriginal:"149000.00",
               endereco:"Rua José Pedra, 8600 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7982914,
                  lng:-38.5505277
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-52m2-venda-RS149000-id-2691782716/",
               valorVenda:"149000.00",
               valorOriginal:"149000.00",
               endereco:"Rua Quartzo, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889129,
                  lng:-38.5193213
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-46m2-venda-RS149000-id-2760084416/",
               valorVenda:"149000.00",
               valorOriginal:"149000.00",
               endereco:"Avenida Presidente Juscelino Kubitschek, 3371 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8103381,
                  lng:-38.5269586
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-48m2-venda-RS149000-id-2759144321/",
               valorVenda:"149000.00",
               valorOriginal:"149000.00",
               endereco:"Rua José Pedra, 8600 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/16e94f6ec28c99f209c0a2b12c94a5b2/foto-1-de-apartamento-com-2-quartos-a-venda-48m-em-itaperi-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7918131,
                  lng:-38.5485416
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-bairros-fortaleza-com-garagem-44m2-venda-RS149000-id-2755509095/",
               valorVenda:"149000.00",
               valorOriginal:"149000.00",
               endereco:"Avenida César Cals, 400 - Praia do Futuro, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/a93b523d6b93bcc7995f17dc80cf61c4/foto-1-de-apartamento-com-2-quartos-a-venda-44m-em-praia-do-futuro-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7492867,
                  lng:-38.44709840000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-44m2-venda-RS149000-id-2741128353/",
               valorVenda:"149000.00",
               valorOriginal:"149000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/9db6a371362a1a7d3735debf8b1b6cb7/foto-1-de-apartamento-com-2-quartos-a-venda-44m-em-boa-vista-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-granja-lisboa-bairros-fortaleza-com-garagem-63m2-venda-RS149500-id-2732090541/",
               valorVenda:"149500.00",
               valorOriginal:"149500.00",
               endereco:"Rua Barra Mansa - Granja Lisboa, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/a736b39f9991d031f516180acda6f563/foto-1-de-apartamento-com-3-quartos-a-venda-63m-em-granja-lisboa-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 63m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:63,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7929744,
                  lng:-38.6199839
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-democrito-rocha-bairros-fortaleza-com-garagem-63m2-venda-RS149900-id-2747673451/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Pedro Muniz, 401 - Demócrito Rocha, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 63m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:63,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7655946,
                  lng:-38.5636052
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-50m2-venda-RS149900-id-2741795676/",
               valorVenda:"149900.00",
               valorOriginal:"149900.00",
               endereco:"Avenida I - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8353486,
                  lng:-38.5537971
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-benfica-bairros-fortaleza-com-garagem-63m2-venda-RS150000-id-2763374529/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Carapinima - Benfica, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 63m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:63,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7390432,
                  lng:-38.5393531
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cajazeiras-bairros-fortaleza-com-garagem-80m2-venda-RS150000-id-2705534317/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Francisca Nogueira Ramos, 266 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos para Venda/Aluguel 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.806648,
                  lng:-38.5041571
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-eusebio-bairros-fortaleza-90m2-venda-RS150000-id-2737038181/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Eusébio de Queirós - Eusebio, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7655379,
                  lng:-38.5528914
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-siqueira-bairros-fortaleza-com-garagem-67m2-venda-RS150000-id-2746481166/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 67m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:67,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8024954,
                  lng:-38.6277436
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cidade-dos-funcionarios-bairros-fortaleza-com-garagem-100m2-venda-RS150000-id-2682684955/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Avenida Desembargador Gonzaga, 333 - Cidade dos Funcionários, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 100m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:100,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7900746,
                  lng:-38.5028399
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS150000-id-2728899623/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rodovia Quarto Anel Viário, 4 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8045594,
                  lng:-38.64024819999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cajazeiras-bairros-fortaleza-com-garagem-57m2-venda-RS150000-id-2517593205/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Francisca Nogueira Ramos, 266 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 57m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:57,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.806648,
                  lng:-38.5041571
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-barra-do-ceara-bairros-fortaleza-com-garagem-70m2-venda-RS150000-id-2756702241/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Abelardo Barbosa - Barra do Ceará, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7001452,
                  lng:-38.5847288
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cidade-dos-funcionarios-bairros-fortaleza-com-garagem-47m2-venda-RS150000-id-2748993518/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Avenida José Leon, 1078 - Cidade dos Funcionários, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.787842,
                  lng:-38.5026262
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-48m2-venda-RS150000-id-2650957369/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Coronel Manuel Albano, 946 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8046593,
                  lng:-38.5748968
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-48m2-venda-RS150000-id-2726304063/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua das Oiticicas - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8075049,
                  lng:-38.5364841
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jacarecanga-bairros-fortaleza-com-garagem-59m2-venda-RS150000-id-2760853323/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Francisco Sá, 2420 - Jacarecanga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 59m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:59,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7205116,
                  lng:-38.547435
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-48m2-venda-RS150000-id-2751291795/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua José Pedra, 8600 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7918131,
                  lng:-38.5485416
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-48m2-venda-RS150000-id-2746657020/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua José Pedra, 8600 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7918131,
                  lng:-38.5485416
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-paupina-bairros-fortaleza-com-garagem-132m2-venda-RS150000-id-2750286813/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua A, 57 - Paupina, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 132m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:132,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8416037,
                  lng:-38.4923222
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-55m2-venda-RS150000-id-2718437767/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua das Aroeiras, 200 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8046022,
                  lng:-38.5370229
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-66m2-venda-RS150000-id-2608274786/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Portugal, 55 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 66m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:66,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.828381599999999,
                  lng:-38.5097461
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-barra-do-ceara-bairros-fortaleza-com-garagem-83m2-venda-RS150000-id-2580623517/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Arquimedes Memória, 000 - Barra do Ceará, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 83m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:83,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7132976,
                  lng:-38.5867027
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-itaperi-bairros-fortaleza-com-garagem-70m2-venda-RS150000-id-2762838416/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Betel - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7922126,
                  lng:-38.5526492
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-de-iracema-bairros-fortaleza-com-garagem-53m2-venda-RS150000-id-2755922525/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Avenida César Cals, 100 - Praia de Iracema, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 53m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:53,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.719105,
                  lng:-38.465432
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-henrique-jorge-bairros-fortaleza-com-garagem-44m2-venda-RS150000-id-2761363940/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Padre Sá Leitão, 55 - Henrique Jorge, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7559889,
                  lng:-38.5787099
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-henrique-jorge-bairros-fortaleza-com-garagem-50m2-venda-RS150000-id-2758729242/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Franco Rocha - Henrique Jorge, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7544316,
                  lng:-38.5808723
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-paupina-bairros-fortaleza-com-garagem-63m2-venda-RS150000-id-2670547007/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Sítio Asa Branca, 428 - Paupina, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 63m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:63,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8522411,
                  lng:-38.4831747
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-54m2-venda-RS150000-id-2743760098/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Quartzo, 60 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8886827,
                  lng:-38.5194879
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS150000-id-2750723793/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rodovia Quarto Anel Viário, 59 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8533957,
                  lng:-38.5591446
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-alvaro-weyne-bairros-fortaleza-com-garagem-74m2-venda-RS150000-id-2641819899/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua do Oriente, 000 - Álvaro Weyne, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 74m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:74,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7120408,
                  lng:-38.5626583
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS150000-id-2686927207/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Alencar Oliveira - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8147776,
                  lng:-38.5199405
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS150000-id-2695129592/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Quartzo, 40 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8888451,
                  lng:-38.5192932
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-conjunto-esperanca-bairros-fortaleza-com-garagem-108m2-venda-RS150000-id-2697889275/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua 101, 137 - Conjunto Esperança, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 108m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:108,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8138302,
                  lng:-38.5892686
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS150000-id-2746918607/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rodovia Quarto Anel Viário, 245 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.805885,
                  lng:-38.6386897
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-alvaro-weyne-bairros-fortaleza-com-garagem-74m2-venda-RS150000-id-2641819899/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua do Oriente, 000 - Álvaro Weyne, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/cede81a76c5b17af5c891b952a3fa9d1/foto-1-de-casa-com-3-quartos-a-venda-74m-em-alvaro-weyne-fortaleza.webp",
               titulo:"Casa com 3 Quartos à Venda, 74m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:74,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7120408,
                  lng:-38.5626583
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-paupina-bairros-fortaleza-com-garagem-63m2-venda-RS150000-id-2670547007/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Sítio Asa Branca, 428 - Paupina, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/132f7b381cfa37b0786769c67d9086aa/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-63m-em-paupina-fortaleza.webp",
               titulo:"Casa com 3 Quartos à Venda, 63m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:63,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8522411,
                  lng:-38.4831747
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS150000-id-2695129592/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Quartzo, 40 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/0a2c4247e7a28bf22fb8d927ccc9759a/foto-1-de-apartamento-com-2-quartos-a-venda-50m-em-pedras-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8888451,
                  lng:-38.5192932
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-47m2-venda-RS150000-id-2645700339/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/0f104613e5f4ecc6280b8bffb5c55af5/foto-1-de-apartamento-com-2-quartos-a-venda-47m-em-prefeito-jose-walter-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8324922,
                  lng:-38.5529854
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-parque-santa-rosa-bairros-fortaleza-com-garagem-110m2-venda-RS150000-id-2733867826/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Iná Brito, 1070 - Parque Santa Rosa, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 110m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:110,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8236841,
                  lng:-38.5959274
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-maraponga-bairros-fortaleza-com-garagem-60m2-venda-RS150000-id-2651661066/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Suíça, 96 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7931485,
                  lng:-38.5701363
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS150000-id-2743660177/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8163239,
                  lng:-38.5316373
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-novo-mondubim-bairros-fortaleza-com-garagem-48m2-venda-RS150000-id-2756347821/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Alfredo Mamede, 637 - Novo Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.81069,
                  lng:-38.5832137
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS150000-id-2746918607/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rodovia Quarto Anel Viário, 245 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.805885,
                  lng:-38.6386897
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-48m2-venda-RS150000-id-2748667312/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua José Pedra, 8600 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7982914,
                  lng:-38.5505277
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-padre-andrade-bairros-fortaleza-com-garagem-61m2-venda-RS150000-id-2710065085/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Alcântara Bilhar, 677 - Padre Andrade, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7366381,
                  lng:-38.5818265
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-42m2-venda-RS150000-id-2736582817/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Murará, 101 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8439561,
                  lng:-38.4930956
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS150000-id-2752971014/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rodovia Quarto Anel Viário, 197 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8600912,
                  lng:-38.5385681
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-48m2-venda-RS150000-id-2751832693/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Quartzo, 95 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8887642,
                  lng:-38.5198257
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jangurussu-bairros-fortaleza-com-garagem-42m2-venda-RS150000-id-2646444807/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8474819,
                  lng:-38.5151273
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-48m2-venda-RS150000-id-2747211997/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua José Pedra, 8600 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7918131,
                  lng:-38.5485416
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cidade-dos-funcionarios-bairros-fortaleza-com-garagem-52m2-venda-RS150000-id-2757542322/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Manoel Monteiro, 540 - Cidade dos Funcionários, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7914704,
                  lng:-38.4940945
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS150000-id-2732317441/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rodovia Quarto Anel Viário, 1 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8377743,
                  lng:-38.6049853
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-48m2-venda-RS150000-id-2650957369/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Coronel Manuel Albano, 946 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8046593,
                  lng:-38.5748968
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-48m2-venda-RS150000-id-2726304063/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua das Oiticicas - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8075049,
                  lng:-38.5364841
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-barra-do-ceara-bairros-fortaleza-com-garagem-170m2-venda-RS150000-id-2748091919/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua 8, 45 - Barra do Ceará, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 170m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:170,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7146063,
                  lng:-38.5817054
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-itaperi-bairros-fortaleza-com-garagem-70m2-venda-RS150000-id-2762838416/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Betel - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7922126,
                  lng:-38.5526492
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-50m2-venda-RS150000-id-2727305163/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua das Oiticicas - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8075049,
                  lng:-38.5364841
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-48m2-venda-RS150000-id-2749672497/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua José Pedra, 8600 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7918131,
                  lng:-38.5485416
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jangurussu-bairros-fortaleza-com-garagem-53m2-venda-RS150000-id-2678651802/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Rio Jordão, 1190 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 53m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:53,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.848362299999999,
                  lng:-38.5031153
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jacarecanga-bairros-fortaleza-com-garagem-59m2-venda-RS150000-id-2760853323/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Francisco Sá, 2420 - Jacarecanga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 59m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:59,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7205116,
                  lng:-38.547435
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cajazeiras-bairros-fortaleza-com-garagem-57m2-venda-RS150000-id-2517593205/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Francisca Nogueira Ramos, 266 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 57m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:57,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.806648,
                  lng:-38.5041571
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-54m2-venda-RS150000-id-2743760098/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Quartzo, 60 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8886827,
                  lng:-38.5194879
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-40m2-venda-RS150000-id-2649159557/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Coronel Manuel Albano, 946 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 40m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:40,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8046593,
                  lng:-38.5748968
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-bela-vista-bairros-fortaleza-com-garagem-62m2-venda-RS150000-id-2754624402/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Minas Gerais, 149 - Bela Vista, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 62m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:62,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7524001,
                  lng:-38.5621782
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-henrique-jorge-bairros-fortaleza-com-garagem-53m2-venda-RS150000-id-2748154097/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Cuiabá, 1600 - Henrique Jorge, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 53m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:53,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7632519,
                  lng:-38.589156
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cidade-dos-funcionarios-bairros-fortaleza-com-garagem-67m2-venda-RS150000-id-2750936125/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Desembargador Gonzaga - Cidade dos Funcionários, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 67m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:67,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7949476,
                  lng:-38.500899
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-48m2-venda-RS150000-id-2755927938/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua José Pedra, 8600 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7918131,
                  lng:-38.5485416
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-10m2-venda-RS150000-id-2752701130/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua 08H, 496 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 10m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:10,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8321954,
                  lng:-38.5362323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-61m2-venda-RS150000-id-2635949722/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Tito Barros Filho, 341 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7977384,
                  lng:-38.5113799
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS150000-id-2569767656/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8163239,
                  lng:-38.5316373
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-bom-jardim-bairros-fortaleza-com-garagem-80m2-venda-RS150000-id-2573949752/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Nova Conquista, 388 - Bom Jardim, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/bcefa21263c8200bb4917e2384f696c0/foto-1-de-casa-com-2-quartos-a-venda-80m-em-bom-jardim-fortaleza.webp",
               titulo:"Casa com 2 Quartos à Venda, 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7963395,
                  lng:-38.59824709999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jacarecanga-bairros-fortaleza-com-garagem-70m2-venda-RS150000-id-2713942547/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Francisco Sá, 2420 - Jacarecanga, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/2b8cc35fa1b64b0facc8992a28aad979/foto-1-de-apartamento-com-2-quartos-a-venda-70m-em-jacarecanga-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7205116,
                  lng:-38.547435
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-itaperi-bairros-fortaleza-com-garagem-65m2-venda-RS150000-id-2753971809/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua José Meneleu, 760 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/083879523a76a290f4f66e50d4929476/foto-1-de-apartamento-com-3-quartos-a-venda-65m-em-itaperi-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 65m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:65,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7873514,
                  lng:-38.5621649
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-messejana-bairros-fortaleza-com-garagem-100m2-venda-RS150000-id-2733893589/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Sítio Asa Branca, 387 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/d44918fc7423ad5306c93517415559ec/foto-1-de-casa-de-condominio-com-3-quartos-a-venda-100m-em-messejana-fortaleza.webp",
               titulo:"Casa com 3 Quartos à Venda, 100m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:100,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.852136,
                  lng:-38.48364
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-46m2-venda-RS150000-id-2735016644/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Francisco Alves Ribeiro, 165 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.831391,
                  lng:-38.509546
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-henrique-jorge-bairros-fortaleza-com-garagem-53m2-venda-RS150000-id-2760827930/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Manaus - Henrique Jorge, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 53m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:53,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7564971,
                  lng:-38.5927024
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-48m2-venda-RS150000-id-2731031828/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Avenida Bernardo Manuel, 8500 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8111662,
                  lng:-38.5550462
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS150000-id-2569767656/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8163239,
                  lng:-38.5316373
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cristo-redentor-bairros-fortaleza-com-garagem-62m2-venda-RS150000-id-2713993293/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Dona Mendinha - Cristo Redentor, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 62m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:62,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7095019,
                  lng:-38.5701189
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-49m2-venda-RS150000-id-2735510448/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Betel - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7922126,
                  lng:-38.5526492
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS150000-id-2694159209/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Quartzo, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889129,
                  lng:-38.5193213
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-barra-do-ceara-bairros-fortaleza-com-garagem-46m2-venda-RS150000-id-2733685863/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Avenida Francisco Sá, 250 - Barra do Ceará, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7225781,
                  lng:-38.5415981
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-montese-bairros-fortaleza-com-garagem-70m2-venda-RS150000-id-2528440720/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Montese, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7683772,
                  lng:-38.5517784
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-55m2-venda-RS150000-id-2581492840/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua das Aroeiras, 200 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8046022,
                  lng:-38.5370229
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS150000-id-2701896078/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Quartzo, 40 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8888451,
                  lng:-38.5192932
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-10m2-venda-RS150000-id-2752706031/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua 08H, 496 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 10m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:10,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8321954,
                  lng:-38.5362323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-48m2-venda-RS150000-id-2748943003/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua José Pedra, 8600 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7918131,
                  lng:-38.5485416
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-barra-do-ceara-bairros-fortaleza-com-garagem-57m2-venda-RS150000-id-2733908106/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Irapuã, 250 - Barra do Ceará, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 57m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:57,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7042666,
                  lng:-38.5849161
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-henrique-jorge-bairros-fortaleza-com-garagem-78m2-venda-RS150000-id-2750123417/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Franco Rocha, 397 - Henrique Jorge, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 78m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:78,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.754819799999999,
                  lng:-38.5801647
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS150000-id-2736722921/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rodovia Quarto Anel Viário, 1 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8377743,
                  lng:-38.6049853
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-48m2-venda-RS150000-id-2751934458/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Coronel Manuel Albano, 100 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8072557,
                  lng:-38.5670178
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS150000-id-2748413406/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rodovia Quarto Anel Viário, 146 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8341286,
                  lng:-38.6128949
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cidade-dos-funcionarios-bairros-fortaleza-com-garagem-66m2-venda-RS150000-id-2750740528/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Desembargador Gonzaga, 532 - Cidade dos Funcionários, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 66m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:66,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7916229,
                  lng:-38.5027285
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-44m2-venda-RS150000-id-2740360999/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Coronel Manuel Albano, 989 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8051021,
                  lng:-38.574943
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-48m2-venda-RS150000-id-2752991395/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Quartzo, 203 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.887942,
                  lng:-38.5205158
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-83m2-venda-RS150000-id-2658398785/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Tibúrcio Pereira, 265 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 83m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:83,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8032854,
                  lng:-38.5055073
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-49m2-venda-RS150000-id-2746393608/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua I, 945 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8165895,
                  lng:-38.5377352
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-50m2-venda-RS150000-id-2630311121/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8163239,
                  lng:-38.5316373
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS150000-id-2750210732/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rodovia Quarto Anel Viário, 59 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8533957,
                  lng:-38.5591446
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-joquei-clube-bairros-fortaleza-com-garagem-70m2-venda-RS150000-id-2758571370/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Silveira Filho - Jóquei Clube, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7725314,
                  lng:-38.577064
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-48m2-venda-RS150000-id-2650460789/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Coronel Manuel Albano, 949 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.805222099999999,
                  lng:-38.5746169
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-montese-bairros-fortaleza-com-garagem-70m2-venda-RS150000-id-2698297476/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Queirós Ribeiro - Montese, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7632769,
                  lng:-38.5461164
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-4-quartos-parangaba-bairros-fortaleza-com-garagem-98m2-venda-RS150000-id-2752690408/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Avenida Doutor Silas Munguba, 835 - Parangaba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 4 Quartos à Venda, 98m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:98,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7806485,
                  lng:-38.5579469
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-henrique-jorge-bairros-fortaleza-com-garagem-50m2-venda-RS150000-id-2700817797/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Franco Rocha, 397 - Henrique Jorge, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.754819799999999,
                  lng:-38.5801647
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-antonio-bezerra-bairros-fortaleza-com-garagem-70m2-venda-RS150000-id-2749071296/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Tenente Queirós - Antônio Bezerra, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7452887,
                  lng:-38.5919187
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-novo-mondubim-bairros-fortaleza-com-garagem-48m2-venda-RS150000-id-2743408689/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Novo Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8076224,
                  lng:-38.5842946
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-58m2-venda-RS150000-id-2646526860/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 32 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/3bb4f91850996f6e791295709d9654da/foto-1-de-apartamento-com-2-quartos-a-venda-58m-em-ancuri-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 58m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:58,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8396533,
                  lng:-38.495106
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-passare-bairros-fortaleza-com-garagem-67m2-venda-RS150000-id-2446940078/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Crisanto Arruda - Passaré, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/0d507ab240b88e46101740a26e48d423/foto-1-de-apartamento-com-3-quartos-a-venda-67m-em-passare-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 67m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:67,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8138221,
                  lng:-38.523546
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-70m2-venda-RS150000-id-2574535363/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Tenente Tito Barros, 341 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/e7c5ef8fb00e9c6abd4be4d5bec9d054/foto-1-de-apartamento-com-3-quartos-a-venda-70m-em-cajazeiras-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7977384,
                  lng:-38.5113799
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-jardim-guanabara-bairros-fortaleza-com-garagem-70m2-venda-RS150000-id-2749059297/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Padre Teodoro - Jardim Guanabara, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/c6f2a2419124292bf6601f0c8c525855/foto-1-de-casa-com-3-quartos-a-venda-70m-em-jardim-guanabara-fortaleza.webp",
               titulo:"Casa com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7231772,
                  lng:-38.5904941
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-ii-bairros-fortaleza-com-garagem-59m2-venda-RS150000-id-2744719713/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Coronel Francisco Montenegro, 989 - Praia do Futuro II, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 59m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:59,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7564778,
                  lng:-38.4494244
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-45m2-venda-RS150000-id-2750760263/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Farias Lemos - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8131383,
                  lng:-38.4943558
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-parangaba-bairros-fortaleza-com-garagem-99m2-venda-RS150000-id-2750192901/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Parangaba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 99m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:99,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7771358,
                  lng:-38.5620583
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-48m2-venda-RS150000-id-2648071188/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Coronel Manuel Albano, 946 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8046593,
                  lng:-38.5748968
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-antonio-bezerra-bairros-fortaleza-175m2-venda-RS150000-id-2619030028/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Hipólito Pamplona, 745 - Antônio Bezerra, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 175m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:175,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7473658,
                  lng:-38.5957314
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-parquelandia-bairros-fortaleza-com-garagem-78m2-venda-RS150000-id-2695455121/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Dom Manuel de Medeiros, 1965 - Parquelândia, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 78m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:78,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7388465,
                  lng:-38.561618
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-44m2-venda-RS150000-id-2698186987/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Avenida I, 945 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8416808,
                  lng:-38.5557188
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-parangaba-bairros-fortaleza-65m2-venda-RS150000-id-2716288826/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Checoslováquia - Parangaba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 65m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:65,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7852765,
                  lng:-38.5702685
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-54m2-venda-RS150000-id-2746426476/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 215 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8883821,
                  lng:-38.5449154
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-50m2-venda-RS150000-id-2753662115/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua 6, 1 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8079042,
                  lng:-38.543533
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-henrique-jorge-bairros-fortaleza-com-garagem-44m2-venda-RS150000-id-2760343513/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Padre Sá Leitão, 55 - Henrique Jorge, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7559889,
                  lng:-38.5787099
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-52m2-venda-RS150000-id-2735640735/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8327352,
                  lng:-38.4940863
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-54m2-venda-RS150000-id-2749504622/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Quartzo, 12 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.888971,
                  lng:-38.5191422
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS150000-id-2751644109/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rodovia Quarto Anel Viário, 83 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8533903,
                  lng:-38.5591579
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-com-garagem-45m2-venda-RS150000-id-2722135033/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua B, 96 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8039623,
                  lng:-38.5545254
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-santa-maria-bairros-fortaleza-com-garagem-50m2-venda-RS150000-id-2703322878/",
               valorVenda:"150000.00",
               valorOriginal:"150000.00",
               endereco:"Rua Padre Pedro de Alencar, 1720 - Parque Santa Maria, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8427944,
                  lng:-38.4946012
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-mondubim-bairros-fortaleza-com-garagem-100m2-venda-RS150406-id-2759767915/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua IV, N. 181A  . - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 100m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:100,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8182332,
                  lng:-38.58071229999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-mondubim-bairros-fortaleza-com-garagem-114m2-venda-RS150406-id-2756755315/",
               valorVenda:"150406.00",
               valorOriginal:"150406.00",
               endereco:"Rua IV, 181 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 114m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:114,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.818275,
                  lng:-38.5806858
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-mondubim-bairros-fortaleza-com-garagem-114m2-venda-RS150406-id-2758508815/",
               valorVenda:"150406.00",
               valorOriginal:"150406.00",
               endereco:"Rua IV, 181 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 114m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:114,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.818275,
                  lng:-38.5806858
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-mondubim-bairros-fortaleza-com-garagem-100m2-venda-RS150406-id-2750333330/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua IV, N. 181A  . - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 100m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:100,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8182332,
                  lng:-38.58071229999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cajazeiras-bairros-fortaleza-com-garagem-44m2-venda-RS150900-id-2760107921/",
               valorVenda:"150900.00",
               valorOriginal:"150900.00",
               endereco:"Avenida Geógrafo Antônio Ribeiro Zaranza, N. 2598 APTO. 2 BL 11 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8082689,
                  lng:-38.5132471
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-paupina-bairros-fortaleza-40m2-venda-RS151626-id-2761648081/",
               valorVenda:"151626.00",
               valorOriginal:"151626.00",
               endereco:"Rua C, N. 100 APTO. 203 TIPO B  BL 09  2 PAV - Paupina, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 40m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:40,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8514253,
                  lng:-38.4897925
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-58m2-venda-RS152000-id-2747796908/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 58m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:58,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7887464,
                  lng:-38.548393
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-48m2-venda-RS153000-id-2652393192/",
               valorVenda:"153000.00",
               valorOriginal:"153000.00",
               endereco:"Rua Coronel Manuel Albano, 96 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8072827,
                  lng:-38.5670897
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-1-quartos-sao-bento-bairros-fortaleza-89m2-venda-RS153000-id-2749263850/",
               valorVenda:"153000.00",
               valorOriginal:"153000.00",
               endereco:"Rua B - São Bento, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com  Quarto à Venda, 89m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:89,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8080687,
                  lng:-38.611385
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-50m2-venda-RS153000-id-2670292319/",
               valorVenda:"153000.00",
               valorOriginal:"153000.00",
               endereco:"Rodovia Quarto Anel Viário, 32 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8534018,
                  lng:-38.5591296
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-47m2-venda-RS154000-id-2552588820/",
               valorVenda:"154000.00",
               valorOriginal:"154000.00",
               endereco:"Avenida Bernardo Manuel - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7882912,
                  lng:-38.5482911
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS154000-id-2744635234/",
               valorVenda:"154000.00",
               valorOriginal:"154000.00",
               endereco:"Rua Quartzo, 106 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8883755,
                  lng:-38.5197899
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-66m2-venda-RS154000-id-54711209/",
               valorVenda:"154000.00",
               valorOriginal:"154000.00",
               endereco:"Rua Padre Paulino, 401 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 66m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:66,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7986302,
                  lng:-38.509714
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS154000-id-2731120625/",
               valorVenda:"154000.00",
               valorOriginal:"154000.00",
               endereco:"Rua Quartzo, 2 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.888971,
                  lng:-38.5191422
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS154000-id-2727002945/",
               valorVenda:"154000.00",
               valorOriginal:"154000.00",
               endereco:"Rua Quartzo, 35 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8890755,
                  lng:-38.51945060000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-66m2-venda-RS154000-id-2482987976/",
               valorVenda:"154000.00",
               valorOriginal:"154000.00",
               endereco:"Rua Padre Paulino, 401 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 66m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:66,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7986302,
                  lng:-38.509714
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-paupina-bairros-fortaleza-60m2-venda-RS154230-id-2756794648/",
               valorVenda:"154230.00",
               valorOriginal:"154230.00",
               endereco:"Rua Gardênia, 603 - Paupina, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/c4d8193cca9e1bfe10a1a56e2f016438/foto-1-de-apartamento-com-3-quartos-a-venda-60m-em-paupina-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8368985,
                  lng:-38.4886579
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-paupina-bairros-fortaleza-60m2-venda-RS154230-id-2756755931/",
               valorVenda:"154230.00",
               valorOriginal:"154230.00",
               endereco:"Rua Gardênia, 603 - Paupina, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/41bb67445f38ac2c399f64f5f1086b39/foto-1-de-apartamento-com-3-quartos-a-venda-60m-em-paupina-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8368985,
                  lng:-38.4886579
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-paupina-bairros-fortaleza-60m2-venda-RS154230-id-2759758971/",
               valorVenda:"154230.00",
               valorOriginal:"154230.00",
               endereco:"Rua Gardênia, 603 - Paupina, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/ac38d4fd93746003fa359d6bf4f1fc0d/foto-1-de-apartamento-com-3-quartos-a-venda-60m-em-paupina-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8368985,
                  lng:-38.4886579
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-paupina-bairros-fortaleza-60m2-venda-RS154230-id-2750333133/",
               valorVenda:"154230.00",
               valorOriginal:"154230.00",
               endereco:"Rua Gardênia, N. 603 APTO. 209 . - Paupina, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/4531317efbf129b67a1623ac32c4b18b/foto-1-de-apartamento-com-3-quartos-a-venda-60m-em-paupina-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8368985,
                  lng:-38.4886579
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-paupina-bairros-fortaleza-60m2-venda-RS154230-id-2759767821/",
               valorVenda:"154230.00",
               valorOriginal:"154230.00",
               endereco:"Rua Gardênia, N. 603 APTO. 209 . - Paupina, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8368985,
                  lng:-38.4886579
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-sao-joao-do-tauape-bairros-fortaleza-com-garagem-45m2-venda-RS154900-id-2644953909/",
               valorVenda:"154900.00",
               valorOriginal:"154900.00",
               endereco:"Rua Evaristo Reis, 105 - São João do Tauape, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7616131,
                  lng:-38.5078411
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-novo-mondubim-bairros-fortaleza-com-garagem-48m2-venda-RS154999-id-2517131078/",
               valorVenda:"154999.00",
               valorOriginal:"154999.00",
               endereco:"Rua Alfredo Mamede, 637 - Novo Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.81069,
                  lng:-38.5832137
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-maraponga-bairros-fortaleza-com-garagem-66m2-venda-RS155000-id-2703253293/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Suíça, 120 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 66m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:66,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7928434,
                  lng:-38.5703155
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-48m2-venda-RS155000-id-2654821556/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Coronel Manuel Albano, 96 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8072827,
                  lng:-38.5670897
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-ii-bairros-fortaleza-com-garagem-49m2-venda-RS155000-id-2759688473/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Coronel Francisco Montenegro, 10 - Praia do Futuro II, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7555296,
                  lng:-38.4475085
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-antonio-bezerra-bairros-fortaleza-com-garagem-56m2-venda-RS155000-id-2725463233/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Rui Monte, 1220 - Antônio Bezerra, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7490282,
                  lng:-38.5849711
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-48m2-venda-RS155000-id-2758753446/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Quartzo, 420 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8871311,
                  lng:-38.521323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-55m2-venda-RS155000-id-2749815038/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Tibúrcio Pereira, 431 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7979455,
                  lng:-38.5096364
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-santa-filomena-bairros-fortaleza-com-garagem-55m2-venda-RS155000-id-2612676279/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Santa Filomena, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com  Quarto à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7269327,
                  lng:-38.4987796
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-sao-joao-do-tauape-bairros-fortaleza-com-garagem-50m2-venda-RS155000-id-2645848339/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Evaristo Reis, 946 - São João do Tauape, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.760990899999999,
                  lng:-38.5098937
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-bairros-fortaleza-com-garagem-44m2-venda-RS155000-id-2758718526/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Avenida César Cals, 100 - Praia do Futuro, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7492867,
                  lng:-38.44709840000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-coacu-bairros-fortaleza-com-garagem-47m2-venda-RS155000-id-2749342948/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua José Cavalcante Sobrinho - Coaçu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8374179,
                  lng:-38.486866
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-48m2-venda-RS155000-id-2760828735/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Quartzo, 432 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8871311,
                  lng:-38.521323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-padre-andrade-bairros-fortaleza-61m2-venda-RS155000-id-2704505270/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Alcântara Bilhar, 677 - Padre Andrade, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7366381,
                  lng:-38.5818265
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-messejana-bairros-fortaleza-com-garagem-60m2-venda-RS155000-id-2745797747/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Bady Amed, 31 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8104369,
                  lng:-38.4744396
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-maraponga-bairros-fortaleza-com-garagem-50m2-venda-RS155000-id-2713240982/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Suíça, 103 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7931588,
                  lng:-38.5702212
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jacarecanga-bairros-fortaleza-com-garagem-47m2-venda-RS155000-id-2647716948/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Avenida Francisco Sá, 2420 - Jacarecanga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7205116,
                  lng:-38.547435
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-52m2-venda-RS155000-id-2695074108/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rodovia Quarto Anel Viário, 40 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8534,
                  lng:-38.55913400000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-48m2-venda-RS155000-id-2757153527/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Quartzo, 381 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8873616,
                  lng:-38.52139469999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS155000-id-2720589839/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rodovia Quarto Anel Viário, 10 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.804593999999999,
                  lng:-38.640228
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-69m2-venda-RS155000-id-2741539570/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Francisco Nogueira, 250 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 69m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:69,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7959695,
                  lng:-38.5092146
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS155000-id-2749506224/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Quartzo, 9 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8891673,
                  lng:-38.5193405
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-50m2-venda-RS155000-id-2750226905/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Tibúrcio Pereira, 431 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7979455,
                  lng:-38.5096364
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS155000-id-2644255581/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Benjamim Brasil - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8051118,
                  lng:-38.5659198
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-70m2-venda-RS155000-id-2757580129/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Tibúrcio Pereira - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7995732,
                  lng:-38.5089427
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS155000-id-2762229220/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Quartzo, 4778 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8879534,
                  lng:-38.5205027
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS155000-id-2702224055/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rodovia Quarto Anel Viário, 40 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8534,
                  lng:-38.55913400000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cambeba-bairros-fortaleza-com-garagem-44m2-venda-RS155000-id-2737708245/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Farias Lemos, 800 - Cambeba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8136949,
                  lng:-38.4942168
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-50m2-venda-RS155000-id-2723341871/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Murará, 101 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8439561,
                  lng:-38.4930956
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-sao-joao-do-tauape-bairros-fortaleza-com-garagem-60m2-venda-RS155000-id-2648689263/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Evaristo Reis, 946 - São João do Tauape, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.760990899999999,
                  lng:-38.5098937
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-44m2-venda-RS155000-id-2521505833/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7887464,
                  lng:-38.548393
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-55m2-venda-RS155000-id-2749815038/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Tibúrcio Pereira, 431 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/8f9d8831e9757ed7187b7f9605f45820/foto-1-de-apartamento-com-3-quartos-a-venda-55m-em-cajazeiras-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7979455,
                  lng:-38.5096364
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-48m2-venda-RS155000-id-2654821556/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Coronel Manuel Albano, 96 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/63d6a0d409c79b9d483bac635ccc6b49/foto-1-de-apartamento-com-2-quartos-a-venda-48m-em-mondubim-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8072827,
                  lng:-38.5670897
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-48m2-venda-RS155000-id-2758753446/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Quartzo, 420 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/2624910b88c73404ccd272367d999278/foto-1-de-casa-com-2-quartos-a-venda-48m-em-pedras-fortaleza.webp",
               titulo:"Casa com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8871311,
                  lng:-38.521323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS155000-id-2758751265/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Quartzo, 421 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/8d60ea7be00a32f57af6785969dd9904/foto-1-de-apartamento-com-2-quartos-a-venda-50m-em-pedras-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8873616,
                  lng:-38.52139469999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS155000-id-2752981609/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Quartzo, 198 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.887881999999999,
                  lng:-38.5203292
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-carlito-pamplona-bairros-fortaleza-com-garagem-82m2-venda-RS155000-id-2741845355/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Pasteur, 1116 - Carlito Pamplona, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 82m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:82,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.715233,
                  lng:-38.56057089999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-messejana-bairros-fortaleza-com-garagem-60m2-venda-RS155000-id-2745797747/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Bady Amed, 31 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8104369,
                  lng:-38.4744396
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS155000-id-2762229220/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Quartzo, 4778 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8879534,
                  lng:-38.5205027
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS155000-id-2702224055/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rodovia Quarto Anel Viário, 40 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8534,
                  lng:-38.55913400000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-maraponga-bairros-fortaleza-com-garagem-46m2-venda-RS155000-id-2707596182/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Suíça, 258 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7931759,
                  lng:-38.5721601
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-damas-bairros-fortaleza-com-garagem-73m2-venda-RS155000-id-2744683240/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Avenida João Pessoa, 329 - Damas, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 73m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:73,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7523912,
                  lng:-38.5494485
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-44m2-venda-RS155000-id-2521505833/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7887464,
                  lng:-38.548393
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS155000-id-2750723412/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Quartzo, 59 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.888939,
                  lng:-38.5196142
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cambeba-bairros-fortaleza-com-garagem-44m2-venda-RS155000-id-2737708245/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Farias Lemos, 800 - Cambeba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8136949,
                  lng:-38.4942168
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS155000-id-2644255581/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Benjamim Brasil - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8051118,
                  lng:-38.5659198
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-52m2-venda-RS155000-id-2714592609/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Professora Stella Cochrane, 1 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.793131799999999,
                  lng:-38.53504909999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-maraponga-bairros-fortaleza-com-garagem-66m2-venda-RS155000-id-2703252801/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Suíça, 120 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 66m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:66,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7928434,
                  lng:-38.5703155
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-maraponga-bairros-fortaleza-com-garagem-66m2-venda-RS155000-id-2703252795/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Suíça, 120 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 66m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:66,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7928434,
                  lng:-38.5703155
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-maraponga-bairros-fortaleza-com-garagem-66m2-venda-RS155000-id-2703253002/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Suíça, 120 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 66m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:66,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7928434,
                  lng:-38.5703155
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-maraponga-bairros-fortaleza-com-garagem-66m2-venda-RS155000-id-2703253001/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Suíça, 120 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 66m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:66,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7928434,
                  lng:-38.5703155
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-66m2-venda-RS155000-id-2673106375/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Padre Paulino, 401 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 66m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:66,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7986302,
                  lng:-38.509714
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-ii-bairros-fortaleza-com-garagem-52m2-venda-RS155000-id-2751343202/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Coronel Francisco Montenegro, 178 - Praia do Futuro II, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7548795,
                  lng:-38.4461499
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-maraponga-bairros-fortaleza-com-garagem-61m2-venda-RS155000-id-2700374774/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Suíça, 258 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7931759,
                  lng:-38.5721601
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS155000-id-2751645448/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Quartzo, 85 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8887908,
                  lng:-38.5197932
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-ii-bairros-fortaleza-com-garagem-52m2-venda-RS155000-id-2758839521/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Coronel Francisco Montenegro, 12 - Praia do Futuro II, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.755521699999999,
                  lng:-38.4474924
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-69m2-venda-RS155000-id-2746990401/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Francisco Nogueira, 340 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 69m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:69,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7964818,
                  lng:-38.5089499
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-joaquim-tavora-bairros-fortaleza-com-garagem-128m2-venda-RS155000-id-2564048476/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Capitão Gustavo, 1 - Joaquim Tavora, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 128m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:128,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7499033,
                  lng:-38.5177442
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-96m2-venda-RS155000-id-2655094961/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Coronel Manuel Albano, 96 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 96m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:96,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8072827,
                  lng:-38.5670897
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-65m2-venda-RS155000-id-2568644808/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Tenente Tito Barros, 431 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 65m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:65,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.798326,
                  lng:-38.5104483
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-montese-bairros-fortaleza-76m2-venda-RS155000-id-2738811518/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Tenente Moacir Matos, 55 - Montese, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 76m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:76,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7638542,
                  lng:-38.5575775
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-49m2-venda-RS155000-id-2691948553/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Benjamim Brasil - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8051118,
                  lng:-38.5659198
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-coacu-bairros-fortaleza-com-garagem-145m2-venda-RS155000-id-2628099680/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Jovêntino Caetano - Coaçu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 145m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:145,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8420281,
                  lng:-38.4713603
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-joquei-clube-bairros-fortaleza-com-garagem-72m2-venda-RS155000-id-2762854826/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Guarani - Jóquei Clube, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 72m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:72,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7634079,
                  lng:-38.5797818
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-51m2-venda-RS155000-id-2702228273/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rodovia Quarto Anel Viário, 40 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 51m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:51,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8534,
                  lng:-38.55913400000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-maraponga-bairros-fortaleza-com-garagem-66m2-venda-RS155000-id-2762035215/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Suíça, 120 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 66m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:66,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7928434,
                  lng:-38.5703155
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-sao-joao-do-tauape-bairros-fortaleza-com-garagem-60m2-venda-RS155000-id-2648826278/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Evaristo Reis, 946 - São João do Tauape, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.760990899999999,
                  lng:-38.5098937
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS155000-id-2750212001/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Quartzo, 59 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/8af55e37c420a29f7ccc92625ae064b1/foto-1-de-apartamento-com-2-quartos-a-venda-50m-em-pedras-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.888939,
                  lng:-38.5196142
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-maraponga-bairros-fortaleza-50m2-venda-RS155000-id-2711741711/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Suíça, 777 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/979f5a950535bc6bd617799ae8191014/foto-1-de-apartamento-com-3-quartos-a-venda-50m-em-maraponga-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7939999,
                  lng:-38.577205
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-messejana-bairros-fortaleza-com-garagem-56m2-venda-RS155000-id-2743953263/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua 3, 131 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/1eb1801af55d66f16f375bfa0712cda5/foto-1-de-apartamento-com-3-quartos-a-venda-56m-em-messejana-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8279751,
                  lng:-38.4913708
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-maraponga-bairros-fortaleza-com-garagem-59m2-venda-RS155000-id-2706000792/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Suíça, 258 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/59aac63eb8882a620ed402e2c214046e/foto-1-de-apartamento-com-3-quartos-a-venda-59m-em-maraponga-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 59m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:59,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7931759,
                  lng:-38.5721601
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-69m2-venda-RS155000-id-2746990401/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Francisco Nogueira, 340 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 69m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:69,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7964818,
                  lng:-38.5089499
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-joaquim-tavora-bairros-fortaleza-com-garagem-128m2-venda-RS155000-id-2564048476/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Capitão Gustavo, 1 - Joaquim Tavora, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 128m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:128,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7499033,
                  lng:-38.5177442
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-66m2-venda-RS155000-id-2673106375/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Padre Paulino, 401 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 66m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:66,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7986302,
                  lng:-38.509714
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-61m2-venda-RS155000-id-2551721737/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Tibúrcio Pereira, 430 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.797367199999999,
                  lng:-38.5090925
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-sao-joao-do-tauape-bairros-fortaleza-com-garagem-60m2-venda-RS155000-id-2648826278/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Evaristo Reis, 946 - São João do Tauape, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.760990899999999,
                  lng:-38.5098937
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-bairros-fortaleza-com-garagem-50m2-venda-RS155000-id-2760849849/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Avenida César Cals, 100 - Praia do Futuro, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7492867,
                  lng:-38.44709840000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-coacu-bairros-fortaleza-com-garagem-145m2-venda-RS155000-id-2628099680/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Jovêntino Caetano - Coaçu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 145m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:145,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8420281,
                  lng:-38.4713603
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-1-quartos-joaquim-tavora-bairros-fortaleza-com-garagem-50m2-venda-RS155000-id-2721560744/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Henrique Rabelo, 1627 - Joaquim Tavora, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com  Quarto à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.745136699999999,
                  lng:-38.5211359
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-96m2-venda-RS155000-id-2655094961/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Coronel Manuel Albano, 96 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 96m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:96,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8072827,
                  lng:-38.5670897
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS155000-id-2760838636/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Quartzo, 433 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8873616,
                  lng:-38.52139469999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-sao-joao-do-tauape-bairros-fortaleza-com-garagem-60m2-venda-RS155000-id-2647240023/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Evaristo Reis, 946 - São João do Tauape, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.760990899999999,
                  lng:-38.5098937
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-vila-velha-bairros-fortaleza-com-garagem-43m2-venda-RS155000-id-2711633912/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Avenida L, 990 - Vila Velha, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 43m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:43,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7134444,
                  lng:-38.6002356
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-mondubim-bairros-fortaleza-com-garagem-48m2-venda-RS155000-id-2647904971/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Coronel Manuel Albano, 949 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.805222099999999,
                  lng:-38.5746169
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dende-bairros-fortaleza-49m2-venda-RS155000-id-2701477380/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Betel, 2355 - Dendê, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7992141,
                  lng:-38.5564768
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-bairros-fortaleza-com-garagem-44m2-venda-RS155000-id-2759682526/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Avenida César Cals, 100 - Praia do Futuro, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7492867,
                  lng:-38.44709840000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-ii-bairros-fortaleza-com-garagem-52m2-venda-RS155000-id-2736242181/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Coronel Francisco Montenegro, 95 - Praia do Futuro II, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.755233899999999,
                  lng:-38.4468846
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-49m2-venda-RS155000-id-2691948553/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Benjamim Brasil - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8051118,
                  lng:-38.5659198
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-montese-bairros-fortaleza-76m2-venda-RS155000-id-2738811518/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Tenente Moacir Matos, 55 - Montese, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 76m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:76,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7638542,
                  lng:-38.5575775
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-ii-bairros-fortaleza-com-garagem-52m2-venda-RS155000-id-2751343202/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Coronel Francisco Montenegro, 178 - Praia do Futuro II, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7548795,
                  lng:-38.4461499
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-maraponga-bairros-fortaleza-com-garagem-61m2-venda-RS155000-id-2700374774/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Suíça, 258 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7931759,
                  lng:-38.5721601
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-maraponga-bairros-fortaleza-com-garagem-60m2-venda-RS155000-id-2699658486/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Suíça, 258 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7931759,
                  lng:-38.5721601
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-61m2-venda-RS155000-id-2762046715/",
               valorVenda:"155000.00",
               valorOriginal:"155000.00",
               endereco:"Rua Tenente Tito Barros, 341 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7977384,
                  lng:-38.5113799
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-200m2-venda-RS155990-id-2734545729/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua 85, 1180 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 200m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:200,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8413741,
                  lng:-38.5613594
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-48m2-venda-RS156000-id-2685727752/",
               valorVenda:"156000.00",
               valorOriginal:"156000.00",
               endereco:"Rua Quartzo, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889129,
                  lng:-38.5193213
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-50m2-venda-RS157000-id-2751645748/",
               valorVenda:"157000.00",
               valorOriginal:"157000.00",
               endereco:"Rodovia Quarto Anel Viário, 87 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8533893,
                  lng:-38.5591601
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS157000-id-2747720594/",
               valorVenda:"157000.00",
               valorOriginal:"157000.00",
               endereco:"Rua Quartzo, 131 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8884955,
                  lng:-38.5201042
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-50m2-venda-RS157000-id-2749505945/",
               valorVenda:"157000.00",
               valorOriginal:"157000.00",
               endereco:"Rua Quartzo, 9 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8891673,
                  lng:-38.5193405
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-50m2-venda-RS157000-id-2748410642/",
               valorVenda:"157000.00",
               valorOriginal:"157000.00",
               endereco:"Rodovia Quarto Anel Viário, 145 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.834114,
                  lng:-38.613951
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-50m2-venda-RS157000-id-2748157145/",
               valorVenda:"157000.00",
               valorOriginal:"157000.00",
               endereco:"Rodovia Quarto Anel Viário, 145 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.834114,
                  lng:-38.613951
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS157000-id-2748410016/",
               valorVenda:"157000.00",
               valorOriginal:"157000.00",
               endereco:"Rua Quartzo, 145 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8884231,
                  lng:-38.5201833
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS157000-id-2750724721/",
               valorVenda:"157000.00",
               valorOriginal:"157000.00",
               endereco:"Rua Quartzo, 61 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889273,
                  lng:-38.5196283
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS157000-id-2748137701/",
               valorVenda:"157000.00",
               valorOriginal:"157000.00",
               endereco:"Rua Quartzo, 123 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8885368,
                  lng:-38.520059
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS157000-id-2750724721/",
               valorVenda:"157000.00",
               valorOriginal:"157000.00",
               endereco:"Rua Quartzo, 61 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/eb722f59e6cfb4b9b1f0332cf14641d3/foto-1-de-apartamento-com-2-quartos-a-venda-55m-em-pedras-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889273,
                  lng:-38.5196283
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-50m2-venda-RS157000-id-2751645748/",
               valorVenda:"157000.00",
               valorOriginal:"157000.00",
               endereco:"Rodovia Quarto Anel Viário, 87 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/061963d200a165c7ff36ce3ca05f9b3c/foto-1-de-apartamento-com-2-quartos-a-venda-50m-em-ancuri-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8533893,
                  lng:-38.5591601
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS157000-id-2757158317/",
               valorVenda:"157000.00",
               valorOriginal:"157000.00",
               endereco:"Rua Quartzo, 382 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/bf680179efd546f0c974a9fd1215aa30/foto-1-de-apartamento-com-2-quartos-a-venda-55m-em-pedras-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8871311,
                  lng:-38.521323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS157000-id-2751648799/",
               valorVenda:"157000.00",
               valorOriginal:"157000.00",
               endereco:"Rua Quartzo, 88 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/f4678afbb75ab08b6b9a1887a111f697/foto-1-de-apartamento-com-2-quartos-a-venda-55m-em-pedras-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8885401,
                  lng:-38.5196611
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-50m2-venda-RS157000-id-2750212340/",
               valorVenda:"157000.00",
               valorOriginal:"157000.00",
               endereco:"Rodovia Quarto Anel Viário, 59 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8533957,
                  lng:-38.5591446
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS157000-id-2752983405/",
               valorVenda:"157000.00",
               valorOriginal:"157000.00",
               endereco:"Rua Quartzo, 199 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8879534,
                  lng:-38.5205027
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-50m2-venda-RS157000-id-2746211506/",
               valorVenda:"157000.00",
               valorOriginal:"157000.00",
               endereco:"Rodovia Quarto Anel Viário, 217 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8055542,
                  lng:-38.6388912
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-52m2-venda-RS157000-id-2743785656/",
               valorVenda:"157000.00",
               valorOriginal:"157000.00",
               endereco:"Rodovia Quarto Anel Viário, 70 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8635114,
                  lng:-38.4708955
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-50m2-venda-RS157000-id-2744402516/",
               valorVenda:"157000.00",
               valorOriginal:"157000.00",
               endereco:"Rodovia Quarto Anel Viário, 98 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8051007,
                  lng:-38.6399324
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-52m2-venda-RS157000-id-2690371628/",
               valorVenda:"157000.00",
               valorOriginal:"157000.00",
               endereco:"Rua Doutor Manuel Moreira, 524 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8097739,
                  lng:-38.5696757
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-50m2-venda-RS157000-id-2750724793/",
               valorVenda:"157000.00",
               valorOriginal:"157000.00",
               endereco:"Rodovia Quarto Anel Viário, 60 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8533955,
                  lng:-38.55914509999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS157000-id-2750215301/",
               valorVenda:"157000.00",
               valorOriginal:"157000.00",
               endereco:"Rua Quartzo, 61 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889273,
                  lng:-38.5196283
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-50m2-venda-RS157000-id-2747719807/",
               valorVenda:"157000.00",
               valorOriginal:"157000.00",
               endereco:"Rodovia Quarto Anel Viário, 130 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8541657,
                  lng:-38.55795699999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-granja-lisboa-bairros-fortaleza-198m2-venda-RS157168-id-2759409414/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Luminosa, N. 3672 - Granja Lisboa, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 198m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:198,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7804122,
                  lng:-38.6212059
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-granja-lisboa-bairros-fortaleza-198m2-venda-RS157168-id-2759767526/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Luminosa, N. 3672 - Granja Lisboa, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 198m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:198,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7804122,
                  lng:-38.6212059
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-paupina-bairros-fortaleza-com-garagem-41m2-venda-RS157432-id-2761648952/",
               valorVenda:"157432.00",
               valorOriginal:"157432.00",
               endereco:"Rua A, N. 30 APTO. 203 BL.27 .VG N.394 - Paupina, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com  Quarto à Venda, 41m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:41,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.841008099999999,
                  lng:-38.49297139999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-52m2-venda-RS158000-id-2758752249/",
               valorVenda:"158000.00",
               valorOriginal:"158000.00",
               endereco:"Rodovia Quarto Anel Viário, 417 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8078488,
                  lng:-38.6379723
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-59m2-venda-RS158000-id-2668108029/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 59m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:59,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8163239,
                  lng:-38.5316373
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-52m2-venda-RS158000-id-2751652750/",
               valorVenda:"158000.00",
               valorOriginal:"158000.00",
               endereco:"Rodovia Quarto Anel Viário, 89 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8533889,
                  lng:-38.5591612
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-52m2-venda-RS158000-id-2750727296/",
               valorVenda:"158000.00",
               valorOriginal:"158000.00",
               endereco:"Rodovia Quarto Anel Viário, 62 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.853395,
                  lng:-38.5591462
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-52m2-venda-RS158000-id-2752983158/",
               valorVenda:"158000.00",
               valorOriginal:"158000.00",
               endereco:"Rodovia Quarto Anel Viário, 199 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8600912,
                  lng:-38.5385681
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS158000-id-2758757614/",
               valorVenda:"158000.00",
               valorOriginal:"158000.00",
               endereco:"Rodovia Quarto Anel Viário, 420 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8079625,
                  lng:-38.6386263
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS158000-id-2757159414/",
               valorVenda:"158000.00",
               valorOriginal:"158000.00",
               endereco:"Rodovia Quarto Anel Viário, 386 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7830343,
                  lng:-38.6573012
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-52m2-venda-RS158000-id-2750213256/",
               valorVenda:"158000.00",
               valorOriginal:"158000.00",
               endereco:"Rodovia Quarto Anel Viário, 62 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.853395,
                  lng:-38.5591462
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS158000-id-2668795036/",
               valorVenda:"158000.00",
               valorOriginal:"158000.00",
               endereco:"Rodovia Quarto Anel Viário, 32 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8534018,
                  lng:-38.5591296
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS158000-id-2762909216/",
               valorVenda:"158000.00",
               valorOriginal:"158000.00",
               endereco:"Rua Alencar Oliveira, 470 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8146422,
                  lng:-38.5200259
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS158000-id-2760841161/",
               valorVenda:"158000.00",
               valorOriginal:"158000.00",
               endereco:"Rodovia Quarto Anel Viário, 437 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8079968,
                  lng:-38.6378911
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-52m2-venda-RS158000-id-2760840266/",
               valorVenda:"158000.00",
               valorOriginal:"158000.00",
               endereco:"Rodovia Quarto Anel Viário, 438 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8081019,
                  lng:-38.63855059999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-messejana-bairros-fortaleza-com-garagem-60m2-venda-RS158000-id-2694330581/",
               valorVenda:"158000.00",
               valorOriginal:"158000.00",
               endereco:"Rua 3, 1832 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8699602,
                  lng:-38.5204344
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-52m2-venda-RS158000-id-2749507322/",
               valorVenda:"158000.00",
               valorOriginal:"158000.00",
               endereco:"Rodovia Quarto Anel Viário, 31 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.853402,
                  lng:-38.559129
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-52m2-venda-RS158000-id-2757157324/",
               valorVenda:"158000.00",
               valorOriginal:"158000.00",
               endereco:"Rodovia Quarto Anel Viário, 386 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7830343,
                  lng:-38.6573012
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-couto-fernandes-bairros-fortaleza-com-garagem-45m2-venda-RS158000-id-2701907576/",
               valorVenda:"158000.00",
               valorOriginal:"158000.00",
               endereco:"Rua Oscar Bezerra - Couto Fernandes, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7608315,
                  lng:-38.5588876
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS158000-id-2762227124/",
               valorVenda:"158000.00",
               valorOriginal:"158000.00",
               endereco:"Rodovia Quarto Anel Viário, 470 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8083499,
                  lng:-38.638416
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-47m2-venda-RS158000-id-2743083858/",
               valorVenda:"158000.00",
               valorOriginal:"158000.00",
               endereco:"Rua Murará, 101 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8439561,
                  lng:-38.4930956
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-serrinha-bairros-fortaleza-com-garagem-54m2-venda-RS158000-id-2740296334/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Bernardo Manuel, 7842 - Serrinha, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.786238,
                  lng:-38.5478959
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-paupina-bairros-fortaleza-com-garagem-42m2-venda-RS159000-id-2751139636/",
               valorVenda:"159000.00",
               valorOriginal:"159000.00",
               endereco:"Rua Murará, 101 - Paupina, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8439561,
                  lng:-38.4930956
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/sobrado-3-quartos-pici-bairros-fortaleza-com-garagem-120m2-venda-RS159000-id-2621410713/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Travessa José Veloso Juca, 80 - Pici, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/6a91d7ddaa096a40bf98400981d5e764/foto-1-de-sobrado-com-3-quartos-a-venda-120m-em-pici-fortaleza.webp",
               titulo:"Casa com 3 Quartos à Venda, 120m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:120,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7498851,
                  lng:-38.5820325
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-vila-uniao-bairros-fortaleza-com-garagem-90m2-venda-RS159000-id-2648018969/",
               valorVenda:"159000.00",
               valorOriginal:"159000.00",
               endereco:"Rua Esperanto, 535 - Vila União, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/5ee59b6cc53bf47e6f5542cb59c22294/foto-1-de-apartamento-com-3-quartos-a-venda-90m-em-vila-uniao-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7590418,
                  lng:-38.5377871
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cambeba-bairros-fortaleza-com-garagem-61m2-venda-RS159000-id-2717961369/",
               valorVenda:"159000.00",
               valorOriginal:"159000.00",
               endereco:"Rua Nenê Arruda, 1140 - Cambeba, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/a19beb9bd5b58b736e849682f94f5f71/foto-1-de-apartamento-com-2-quartos-a-venda-61m-em-cambeba-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8199349,
                  lng:-38.4784928
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jangurussu-bairros-fortaleza-com-garagem-45m2-venda-RS159000-id-2759037729/",
               valorVenda:"159000.00",
               valorOriginal:"159000.00",
               endereco:"Rua Francisco Alves Ribeiro, 165 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/b2cd342bf3332f798b37ae5e45e98ff7/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-jangurussu-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8313686,
                  lng:-38.509495
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-paupina-bairros-fortaleza-com-garagem-41m2-venda-RS159000-id-2761651616/",
               valorVenda:"159000.00",
               valorOriginal:"159000.00",
               endereco:"Rua A, N. 108 APTO. 404 BL.07 . - Paupina, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 41m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:41,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8163131,
                  lng:-38.5592095
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-4-quartos-passare-bairros-fortaleza-com-garagem-109m2-venda-RS159000-id-2665173110/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Heróis do Acre - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 4 Quartos à Venda, 109m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:109,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8031789,
                  lng:-38.5361332
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-56m2-venda-RS159000-id-2732878218/",
               valorVenda:"159000.00",
               valorOriginal:"159000.00",
               endereco:"Rua das Aroeiras, 980 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.80984,
                  lng:-38.5386811
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-4-quartos-mondubim-bairros-fortaleza-120m2-venda-RS159000-id-2726528343/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Joaquim Jerônimo - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 4 Quartos à Venda, 120m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:120,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8036925,
                  lng:-38.562525
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jacarecanga-bairros-fortaleza-com-garagem-60m2-venda-RS159990-id-2760197130/",
               valorVenda:"159990.00",
               valorOriginal:"159990.00",
               endereco:"Avenida Francisco Sá, 2420 - Jacarecanga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7205116,
                  lng:-38.547435
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cidade-dos-funcionarios-bairros-fortaleza-com-garagem-55m2-venda-RS159999-id-2741246865/",
               valorVenda:"159999.00",
               valorOriginal:"159999.00",
               endereco:"Avenida José Leon - Cidade dos Funcionários, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7884259,
                  lng:-38.499423
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dias-macedo-bairros-fortaleza-com-garagem-48m2-venda-RS160000-id-2562115678/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Dias Macedo, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7867582,
                  lng:-38.51726350000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS160000-id-2752982936/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rodovia Quarto Anel Viário, 200 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8600912,
                  lng:-38.5385681
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-43m2-venda-RS160000-id-2741173161/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 43m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:43,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7887464,
                  lng:-38.548393
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-passare-bairros-fortaleza-com-garagem-58m2-venda-RS160000-id-2747257707/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 58m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:58,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8163239,
                  lng:-38.5316373
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-serrinha-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2744133466/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Caramuru, 163 - Serrinha, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7888792,
                  lng:-38.5408427
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-48m2-venda-RS160000-id-2626950605/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Bernardo Manuel, 8500 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.794096199999999,
                  lng:-38.5502144
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS160000-id-2758756948/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 410 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.868984499999999,
                  lng:-38.5244208
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS160000-id-2727001343/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rodovia Quarto Anel Viário, 21 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8045586,
                  lng:-38.6399675
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-sao-joao-do-tauape-bairros-fortaleza-com-garagem-60m2-venda-RS160000-id-2648073058/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Evaristo Reis, 946 - São João do Tauape, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.760990899999999,
                  lng:-38.5098937
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-jacarecanga-bairros-fortaleza-com-garagem-70m2-venda-RS160000-id-2757769135/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Adriano Martins, 81 - Jacarecanga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7213415,
                  lng:-38.5443154
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cidade-dos-funcionarios-bairros-fortaleza-com-garagem-48m2-venda-RS160000-id-2746216900/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida José Leon - Cidade dos Funcionários, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7884259,
                  lng:-38.499423
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS160000-id-2759409319/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Holanda, N. 574 APTO. 304 BL 06 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com  Quarto à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.805614899999999,
                  lng:-38.5586338
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-parangaba-bairros-fortaleza-com-garagem-70m2-venda-RS160000-id-2761354131/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Primeiro de Janeiro, 1085 - Parangaba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7837613,
                  lng:-38.5702359
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-48m2-venda-RS160000-id-2709653473/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Bernardo Manuel, 8500 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.794096199999999,
                  lng:-38.5502144
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-centro-bairros-fortaleza-com-garagem-75m2-venda-RS160000-id-2721674465/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Guilherme Rocha, 1476 - Centro, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 75m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:75,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7232953,
                  lng:-38.5389427
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-43m2-venda-RS160000-id-2747035996/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 43m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:43,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7887464,
                  lng:-38.548393
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS160000-id-2755966437/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rodovia Quarto Anel Viário, 365 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.807464,
                  lng:-38.6381834
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-edson-queiroz-bairros-fortaleza-com-garagem-75m2-venda-RS160000-id-2745657593/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Edson Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 75m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:75,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7708535,
                  lng:-38.4746411
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-messejana-bairros-fortaleza-com-garagem-68m2-venda-RS160000-id-2518789344/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Portugal, 14 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 68m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:68,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.828354,
                  lng:-38.5089256
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-montese-bairros-fortaleza-com-garagem-86m2-venda-RS160000-id-2735513109/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Desembargador Praxedes, 480 - Montese, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 86m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:86,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7596395,
                  lng:-38.549537
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cidade-dos-funcionarios-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2638480360/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Cidade dos Funcionários, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7971588,
                  lng:-38.4999808
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS160000-id-2762670667/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Quartzo, 477 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8873616,
                  lng:-38.52139469999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-51m2-venda-RS160000-id-2737525722/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Bernardo Manuel, 8500 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 51m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:51,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.794096199999999,
                  lng:-38.5502144
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-50m2-venda-RS160000-id-2737525715/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Bernardo Manuel, 8550 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7942973,
                  lng:-38.5502458
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2737525221/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Bernardo Manuel, 8550 - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7942891,
                  lng:-38.55030319999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2733624915/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Via Paisagística do Loteamento Itaperussú, 8 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7965175,
                  lng:-38.5361463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS160000-id-2759409319/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Holanda, N. 574 APTO. 304 BL 06 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/c03cc28b870b05d512ab33cfd4ed13d5/foto-1-de-apartamento-com-1-quarto-a-venda-44m-em-maraponga-fortaleza.webp",
               titulo:"Apartamento com  Quarto à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.805614899999999,
                  lng:-38.5586338
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-53m2-venda-RS160000-id-2638703257/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Messejana, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/f4254a30808aaa82a252619eb9790cf9/foto-1-de-apartamento-com-2-quartos-a-venda-53m-em-messejana-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 53m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:53,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8327352,
                  lng:-38.4940863
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-48m2-venda-RS160000-id-2709653473/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Bernardo Manuel, 8500 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/35bee0ad1031e69cd1cfa261216bc478/foto-1-de-apartamento-com-2-quartos-a-venda-48m-em-itaperi-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.794096199999999,
                  lng:-38.5502144
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-lagoa-redonda-bairros-fortaleza-com-garagem-47m2-venda-RS160000-id-2747255664/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Lagoa Redonda, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/faf34f8b2a170fc00d84a9013156fbbb/foto-1-de-apartamento-com-2-quartos-a-venda-47m-em-lagoa-redonda-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.826101899999999,
                  lng:-38.4645212
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-montese-bairros-fortaleza-com-garagem-70m2-venda-RS160000-id-2664760314/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Tuiuti, 35 - Montese, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7679862,
                  lng:-38.5489364
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-vila-velha-bairros-fortaleza-com-garagem-44m2-venda-RS160000-id-2727700848/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Beta, 188 - Vila Velha, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7136144,
                  lng:-38.60170110000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2755822330/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Via Paisagística do Loteamento Itaperussú, 08 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7965175,
                  lng:-38.5361463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS160000-id-2749507726/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 35 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.872304299999999,
                  lng:-38.527493
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-serrinha-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2744133466/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Caramuru, 163 - Serrinha, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7888792,
                  lng:-38.5408427
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-edson-queiroz-bairros-fortaleza-com-garagem-65m2-venda-RS160000-id-2736210363/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Cidade Ecológica, 420 - Edson Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 65m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:65,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7743182,
                  lng:-38.46572949999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-4-quartos-vicente-pinzon-bairros-fortaleza-com-garagem-120m2-venda-RS160000-id-2526698809/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Visconde de Cairu, 551 - Vicente Pinzon, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 4 Quartos à Venda, 120m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:120,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.727414499999999,
                  lng:-38.4654416
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-sao-joao-do-tauape-bairros-fortaleza-com-garagem-48m2-venda-RS160000-id-2650932884/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Evaristo Reis, 946 - São João do Tauape, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.760990899999999,
                  lng:-38.5098937
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-50m2-venda-RS160000-id-2733299022/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Bernardo Manuel - Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8080314,
                  lng:-38.5541846
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2752230094/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua 6, 55 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7949112,
                  lng:-38.5411836
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-damas-bairros-fortaleza-com-garagem-44m2-venda-RS160000-id-2754619695/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Matos Vasconcelos, 1358 - Damas, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7561828,
                  lng:-38.5551866
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-centro-bairros-fortaleza-com-garagem-75m2-venda-RS160000-id-2746946218/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Guilherme Rocha, 1476 - Centro, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 75m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:75,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7232953,
                  lng:-38.5389427
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-benfica-bairros-fortaleza-com-garagem-100m2-venda-RS160000-id-2713315743/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Major Laurindo, 200 - Benfica, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 100m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:100,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7477864,
                  lng:-38.5397144
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS160000-id-2750728810/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 61 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8885154,
                  lng:-38.5449712
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-montese-bairros-fortaleza-com-garagem-86m2-venda-RS160000-id-2735513109/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Desembargador Praxedes, 480 - Montese, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 86m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:86,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7596395,
                  lng:-38.549537
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-mondubim-bairros-fortaleza-com-garagem-150m2-venda-RS160000-id-2693049977/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Mangaba, 36 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 150m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:150,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8192199,
                  lng:-38.5859977
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS160000-id-2727001343/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rodovia Quarto Anel Viário, 21 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8045586,
                  lng:-38.6399675
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-jacarecanga-bairros-fortaleza-com-garagem-70m2-venda-RS160000-id-2757769135/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Adriano Martins, 81 - Jacarecanga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7213415,
                  lng:-38.5443154
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-50m2-venda-RS160000-id-2726582134/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Coronel Manuel Albano, 60 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8079429,
                  lng:-38.5646483
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-60m2-venda-RS160000-id-2551824760/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Trajano de Morais, 430 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8126352,
                  lng:-38.5242267
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS160000-id-2760843428/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rodovia Quarto Anel Viário, 441 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8080264,
                  lng:-38.6378749
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-passare-bairros-fortaleza-com-garagem-53m2-venda-RS160000-id-2756457563/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua das Carnaúbas - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 53m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:53,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8077291,
                  lng:-38.5351862
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS160000-id-2747711672/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Quartzo, 131 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8884955,
                  lng:-38.5201042
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS160000-id-2710892872/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Alencar Oliveira - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8147776,
                  lng:-38.5199405
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cidade-dos-funcionarios-bairros-fortaleza-com-garagem-48m2-venda-RS160000-id-2746216900/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida José Leon - Cidade dos Funcionários, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7884259,
                  lng:-38.499423
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS160000-id-2754971895/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rodovia Quarto Anel Viário, 302 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.807048,
                  lng:-38.6391226
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/sobrado-4-quartos-vila-ellery-bairros-fortaleza-106m2-venda-RS160000-id-2724169801/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Vila Regina, 9 - Vila Ellery, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 4 Quartos à Venda, 106m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:106,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7238141,
                  lng:-38.56146409999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-48m2-venda-RS160000-id-2747962001/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Via Paisagística do Loteamento Itaperussú, 08 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7965175,
                  lng:-38.5361463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS160000-id-2752986000/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rodovia Quarto Anel Viário, 201 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8600912,
                  lng:-38.5385681
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS160000-id-2750213762/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 63 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8885137,
                  lng:-38.5449705
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-centro-bairros-fortaleza-com-garagem-75m2-venda-RS160000-id-2721674465/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Guilherme Rocha, 1476 - Centro, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 75m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:75,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7232953,
                  lng:-38.5389427
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS160000-id-2746208706/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 213 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8883839,
                  lng:-38.5449159
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-montese-bairros-fortaleza-com-garagem-86m2-venda-RS160000-id-2735513109/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Desembargador Praxedes, 480 - Montese, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/97351f4c8517593a268bd87aaac7dcb7/foto-1-de-apartamento-com-3-quartos-a-venda-86m-em-montese-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 86m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:86,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7596395,
                  lng:-38.549537
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS160000-id-2750213762/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 63 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/ec5239f9607e40b1a151b8876c7609a5/foto-1-de-apartamento-com-2-quartos-a-venda-55m-em-pedras-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8885137,
                  lng:-38.5449705
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS160000-id-2752986000/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rodovia Quarto Anel Viário, 201 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/57e57e958b511fb40cf3be100842855e/foto-1-de-apartamento-com-2-quartos-a-venda-55m-em-ancuri-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8600912,
                  lng:-38.5385681
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-benfica-bairros-fortaleza-com-garagem-100m2-venda-RS160000-id-2713315743/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Major Laurindo, 200 - Benfica, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/258eef7fb1478b4a3717d20ab17ab978/foto-1-de-apartamento-com-3-quartos-a-venda-100m-em-benfica-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 100m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:100,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7477864,
                  lng:-38.5397144
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2752230094/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua 6, 55 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7949112,
                  lng:-38.5411836
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-49m2-venda-RS160000-id-2597803664/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Trajano de Morais - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8144303,
                  lng:-38.524638
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-parangaba-bairros-fortaleza-com-garagem-70m2-venda-RS160000-id-2761354131/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Primeiro de Janeiro, 1085 - Parangaba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7837613,
                  lng:-38.5702359
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS160000-id-2752982936/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rodovia Quarto Anel Viário, 200 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8600912,
                  lng:-38.5385681
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-montese-bairros-fortaleza-com-garagem-76m2-venda-RS160000-id-2698724594/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Tenente Moacir Matos, 55 - Montese, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 76m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:76,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7638542,
                  lng:-38.5575775
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-edson-queiroz-bairros-fortaleza-com-garagem-65m2-venda-RS160000-id-2736210363/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Cidade Ecológica, 420 - Edson Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 65m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:65,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7743182,
                  lng:-38.46572949999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2761150824/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua 6, 55 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7949112,
                  lng:-38.5411836
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-48m2-venda-RS160000-id-2747962001/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Via Paisagística do Loteamento Itaperussú, 08 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7965175,
                  lng:-38.5361463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-centro-bairros-fortaleza-com-garagem-75m2-venda-RS160000-id-2746946218/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Guilherme Rocha, 1476 - Centro, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 75m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:75,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7232953,
                  lng:-38.5389427
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-jacarecanga-bairros-fortaleza-com-garagem-70m2-venda-RS160000-id-2757769135/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Adriano Martins, 81 - Jacarecanga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7213415,
                  lng:-38.5443154
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-messejana-bairros-fortaleza-com-garagem-70m2-venda-RS160000-id-2754588494/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua 3, 131 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8279751,
                  lng:-38.4913708
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-sao-joao-do-tauape-bairros-fortaleza-com-garagem-61m2-venda-RS160000-id-2740113714/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Tibúrcio Rodrigues, 100 - São João do Tauape, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7589017,
                  lng:-38.5183524
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-75m2-venda-RS160000-id-2735462959/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Padre Paulino, 401 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 75m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:75,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7986302,
                  lng:-38.509714
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2733623829/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Via Paisagística do Loteamento Itaperussú, 8 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7965175,
                  lng:-38.5361463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2734569271/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Via Paisagística do Loteamento Itaperussú, 8 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7965175,
                  lng:-38.5361463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2737525816/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Bernardo Manuel, 8500 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.794096199999999,
                  lng:-38.5502144
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2733622566/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Via Paisagística do Loteamento Itaperussú, 8 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7965175,
                  lng:-38.5361463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2733619901/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Via Paisagística do Loteamento Itaperussú, 8 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7965175,
                  lng:-38.5361463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2748272345/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Via Paisagística do Loteamento Itaperussú, 08 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7965175,
                  lng:-38.5361463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-montese-bairros-fortaleza-64m2-venda-RS160000-id-2756039622/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Quinze de Novembro - Montese, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 64m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:64,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7691378,
                  lng:-38.5553235
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS160000-id-2757160221/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rodovia Quarto Anel Viário, 388 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8077145,
                  lng:-38.6387609
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-padre-andrade-bairros-fortaleza-com-garagem-61m2-venda-RS160000-id-2647523168/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Alcântara Bilhar, 677 - Padre Andrade, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7366381,
                  lng:-38.5818265
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-52m2-venda-RS160000-id-2644984077/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Doutor Manuel Moreira, 521 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8094833,
                  lng:-38.5697596
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-barra-do-ceara-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2694021677/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Beta - Barra do Ceará, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7138553,
                  lng:-38.6019329
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-sao-joao-do-tauape-bairros-fortaleza-com-garagem-48m2-venda-RS160000-id-2654953181/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Evaristo Reis, 96 - São João do Tauape, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7615188,
                  lng:-38.5077852
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS160000-id-2760843618/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 440 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8862814,
                  lng:-38.5442221
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-paupina-bairros-fortaleza-com-garagem-41m2-venda-RS160000-id-2761648560/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua A, N. 30 APTO. 302 BL.18 .VG N.349 - Paupina, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 41m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:41,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.841008099999999,
                  lng:-38.49297139999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-48m2-venda-RS160000-id-2636075680/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Coronel Manuel Albano, 60 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8079429,
                  lng:-38.5646483
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-henrique-jorge-bairros-fortaleza-com-garagem-44m2-venda-RS160000-id-2707630174/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Padre Sá Leitão, 55 - Henrique Jorge, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7559889,
                  lng:-38.5787099
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2758647729/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua 6, 55 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7949112,
                  lng:-38.5411836
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS160000-id-2749510002/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 36 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8884323,
                  lng:-38.5449349
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-parangaba-bairros-fortaleza-com-garagem-65m2-venda-RS160000-id-2753960706/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Bibio Frota, 571 - Parangaba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 65m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:65,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7898028,
                  lng:-38.5720806
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-castelao-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2687172237/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Alencar Oliveira, 470 - Castelão, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/9aa13328ef45967d5628f4e3b9b83aaf/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-castelao-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8146422,
                  lng:-38.5200259
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cidade-dos-funcionarios-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2758504468/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida José Leon - Cidade dos Funcionários, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/13502ea5a644111f9b1e6faa52d88098/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-cidade-dos-funcionarios-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7884259,
                  lng:-38.499423
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-passare-bairros-fortaleza-com-garagem-52m2-venda-RS160000-id-2646445770/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Passaré, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/529233a8d107ccc519e176894b1f2b24/foto-1-de-apartamento-com-3-quartos-a-venda-52m-em-passare-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8163239,
                  lng:-38.5316373
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-48m2-venda-RS160000-id-2747237539/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Via Paisagística do Loteamento Itaperussú, 08 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/268b4087563148f9beae9e0bae69ea3f/foto-1-de-apartamento-com-2-quartos-a-venda-48m-em-itaperi-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7965175,
                  lng:-38.5361463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2734569271/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Via Paisagística do Loteamento Itaperussú, 8 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7965175,
                  lng:-38.5361463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2733623829/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Via Paisagística do Loteamento Itaperussú, 8 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7965175,
                  lng:-38.5361463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2737525816/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Bernardo Manuel, 8500 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.794096199999999,
                  lng:-38.5502144
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2733619901/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Via Paisagística do Loteamento Itaperussú, 8 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7965175,
                  lng:-38.5361463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2733622566/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Via Paisagística do Loteamento Itaperussú, 8 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7965175,
                  lng:-38.5361463
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-barra-do-ceara-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2694021677/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Beta - Barra do Ceará, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7138553,
                  lng:-38.6019329
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS160000-id-2760843618/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 440 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8862814,
                  lng:-38.5442221
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS160000-id-2746204450/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rodovia Quarto Anel Viário, 212 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8058029,
                  lng:-38.6395258
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-barra-do-ceara-bairros-fortaleza-com-garagem-47m2-venda-RS160000-id-2677766800/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Irapuã, 250 - Barra do Ceará, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7042666,
                  lng:-38.5849161
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-alvaro-weyne-bairros-fortaleza-com-garagem-130m2-venda-RS160000-id-2697358782/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Travessa dos Jasmins, 58 - Álvaro Weyne, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 130m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:130,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7144166,
                  lng:-38.5716059
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cidade-dos-funcionarios-bairros-fortaleza-com-garagem-53m2-venda-RS160000-id-2736679513/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida José Leon, 1078 - Cidade dos Funcionários, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 53m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:53,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.787842,
                  lng:-38.5026262
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-48m2-venda-RS160000-id-2636075680/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Coronel Manuel Albano, 60 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8079429,
                  lng:-38.5646483
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-paupina-bairros-fortaleza-com-garagem-41m2-venda-RS160000-id-2761648560/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua A, N. 30 APTO. 302 BL.18 .VG N.349 - Paupina, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 41m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:41,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.841008099999999,
                  lng:-38.49297139999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-planalto-ayrton-senna-bairros-fortaleza-com-garagem-47m2-venda-RS160000-id-2716378297/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Artur de Sousa, 2011 - Planalto Ayrton Senna, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8241343,
                  lng:-38.5780593
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jardim-das-oliveiras-bairros-fortaleza-com-garagem-46m2-venda-RS160000-id-2746732926/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida José Leon - Jardim das Oliveiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7877002,
                  lng:-38.502339
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS160000-id-2685828096/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rodovia Quarto Anel Viário, 32 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8534018,
                  lng:-38.5591296
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-49m2-venda-RS160000-id-2751934452/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Trajano de Morais, 333 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8111579,
                  lng:-38.5234274
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-serrinha-bairros-fortaleza-44m2-venda-RS160000-id-2568829100/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua VI - Serrinha, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7953168,
                  lng:-38.5386024
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS160000-id-2757160017/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 386 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8880897,
                  lng:-38.5448653
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jardim-das-oliveiras-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2746814194/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Jardim das Oliveiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7810571,
                  lng:-38.5018418
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-46m2-venda-RS160000-id-2744540048/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Farias Lemos, 800 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8136949,
                  lng:-38.4942168
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-conjunto-esperanca-bairros-fortaleza-com-garagem-73m2-venda-RS160000-id-2757588116/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Tenente Anderson, 101 - Conjunto Esperança, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 73m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:73,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.816967,
                  lng:-38.5878795
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-barra-do-ceara-bairros-fortaleza-com-garagem-46m2-venda-RS160000-id-2719857352/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Irapuã, 250 - Barra do Ceará, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7042666,
                  lng:-38.5849161
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-pici-bairros-fortaleza-com-garagem-85m2-venda-RS160000-id-2760243268/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Planalto do Pici, 379 - Pici, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 85m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:85,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7544561,
                  lng:-38.5779962
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pirambu-bairros-fortaleza-com-garagem-110m2-venda-RS160000-id-2656426084/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua São Raimundo, 854 - Pirambú, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 110m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:110,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7072673,
                  lng:-38.5587995
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-66m2-venda-RS160000-id-2753634521/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Tibúrcio Pereira, 431 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 66m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:66,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7979455,
                  lng:-38.5096364
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2756093343/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Benjamim Brasil - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8051118,
                  lng:-38.5659198
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-messejana-bairros-fortaleza-com-garagem-70m2-venda-RS160000-id-2748685594/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua 3, 131 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8279751,
                  lng:-38.4913708
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-passare-bairros-fortaleza-com-garagem-65m2-venda-RS160000-id-2763144419/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 65m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:65,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8163239,
                  lng:-38.5316373
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-44m2-venda-RS160000-id-2744648352/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Farias Lemos, 800 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8136949,
                  lng:-38.4942168
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-sao-joao-do-tauape-bairros-fortaleza-com-garagem-48m2-venda-RS160000-id-2652118768/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Evaristo Reis, 96 - São João do Tauape, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7615188,
                  lng:-38.5077852
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-4-quartos-damas-bairros-fortaleza-com-garagem-110m2-venda-RS160000-id-2761524920/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Damas, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 4 Quartos à Venda, 110m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:110,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7523244,
                  lng:-38.5494211
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-joaquim-tavora-bairros-fortaleza-com-garagem-55m2-venda-RS160000-id-2755028753/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Doutor José Lourenço, 2115 - Joaquim Tavora, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/526c7629d0b176d4e397c0913178c707/foto-1-de-apartamento-com-2-quartos-a-venda-55m-em-joaquim-tavora-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7419489,
                  lng:-38.5099376
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jardim-das-oliveiras-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2670599900/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Jardim das Oliveiras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/2e753f2709a4955be19c2f8ba95e44cf/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-jardim-das-oliveiras-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7810571,
                  lng:-38.5018418
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-montese-bairros-fortaleza-com-garagem-76m2-venda-RS160000-id-2759324943/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Tenente Moacir Matos, 54 - Montese, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/68736167be49398b025df5a11b43da7e/foto-1-de-apartamento-com-2-quartos-a-venda-76m-em-montese-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 76m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:76,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.764040199999999,
                  lng:-38.5578953
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-46m2-venda-RS160000-id-2755514598/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Mário Araújo - Passaré, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/a080742c40d6c0595dc519083cc87c3d/foto-1-de-apartamento-com-2-quartos-a-venda-46m-em-passare-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8163239,
                  lng:-38.5316373
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS160000-id-2762230420/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rodovia Quarto Anel Viário, 2765 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8714637,
                  lng:-38.4982874
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-padre-andrade-bairros-fortaleza-com-garagem-61m2-venda-RS160000-id-2647523168/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Alcântara Bilhar, 677 - Padre Andrade, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7366381,
                  lng:-38.5818265
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-48m2-venda-RS160000-id-2578884520/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Heróis do Acre, 725 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8032441,
                  lng:-38.5351488
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS160000-id-2749510002/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 36 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8884323,
                  lng:-38.5449349
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-50m2-venda-RS160000-id-2751368218/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Murará, 101 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8439561,
                  lng:-38.4930956
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-montese-bairros-fortaleza-64m2-venda-RS160000-id-2756039622/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Quinze de Novembro - Montese, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 64m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:64,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7691378,
                  lng:-38.5553235
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-joquei-clube-bairros-fortaleza-com-garagem-120m2-venda-RS160000-id-2687122319/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Estrada do Pici - Jóquei Clube, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 120m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:120,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7657938,
                  lng:-38.5781716
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-sao-joao-do-tauape-bairros-fortaleza-com-garagem-54m2-venda-RS160000-id-2711972349/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Evaristo Reis, 309 - São João do Tauape, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7613438,
                  lng:-38.5092168
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-maraponga-bairros-fortaleza-com-garagem-70m2-venda-RS160000-id-2738419642/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua José Meneleu, 750 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7868044,
                  lng:-38.5618909
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-50m2-venda-RS160000-id-2749446271/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Juscelino Kubitschek, 3371 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8103381,
                  lng:-38.5269586
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-48m2-venda-RS160000-id-2742005008/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida Bernardo Manuel, 8500 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.794096199999999,
                  lng:-38.5502144
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-sao-joao-do-tauape-bairros-fortaleza-com-garagem-45m2-venda-RS160000-id-2648326085/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Rua Evaristo Reis, 96 - São João do Tauape, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7615188,
                  lng:-38.5077852
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-messejana-bairros-fortaleza-com-garagem-59m2-venda-RS160000-id-2648900908/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 59m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:59,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8327352,
                  lng:-38.4940863
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-55m2-venda-RS160000-id-2589110535/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8118712,
                  lng:-38.5746698
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cidade-dos-funcionarios-bairros-fortaleza-com-garagem-48m2-venda-RS160000-id-2541098113/",
               valorVenda:"160000.00",
               valorOriginal:"160000.00",
               endereco:"Avenida José Leon, 1078 - Cidade dos Funcionários, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.787842,
                  lng:-38.5026262
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-60m2-venda-RS161000-id-2748152297/",
               valorVenda:"161000.00",
               valorOriginal:"161000.00",
               endereco:"Rodovia Quarto Anel Viário, 141 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8052324,
                  lng:-38.6394786
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-60m2-venda-RS161000-id-2762671826/",
               valorVenda:"161000.00",
               valorOriginal:"161000.00",
               endereco:"Rodovia Quarto Anel Viário, 476 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.808284899999999,
                  lng:-38.6381843
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-60m2-venda-RS161000-id-2749510401/",
               valorVenda:"161000.00",
               valorOriginal:"161000.00",
               endereco:"Rodovia Quarto Anel Viário, 38 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8534005,
                  lng:-38.5591329
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-60m2-venda-RS161000-id-2758758236/",
               valorVenda:"161000.00",
               valorOriginal:"161000.00",
               endereco:"Rodovia Quarto Anel Viário, 422 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.807978,
                  lng:-38.6386179
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-60m2-venda-RS161000-id-2757159640/",
               valorVenda:"161000.00",
               valorOriginal:"161000.00",
               endereco:"Rodovia Quarto Anel Viário, 389 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8076416,
                  lng:-38.638086
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-60m2-venda-RS161000-id-2747707299/",
               valorVenda:"161000.00",
               valorOriginal:"161000.00",
               endereco:"Rodovia Quarto Anel Viário, 128 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.805273499999999,
                  lng:-38.6398316
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-60m2-venda-RS161000-id-2750731795/",
               valorVenda:"161000.00",
               valorOriginal:"161000.00",
               endereco:"Rodovia Quarto Anel Viário, 66 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8533941,
                  lng:-38.5591484
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-60m2-venda-RS161000-id-2746204800/",
               valorVenda:"161000.00",
               valorOriginal:"161000.00",
               endereco:"Rodovia Quarto Anel Viário, 211 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8054833,
                  lng:-38.6389344
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-60m2-venda-RS161000-id-2755968619/",
               valorVenda:"161000.00",
               valorOriginal:"161000.00",
               endereco:"Rodovia Quarto Anel Viário, 367 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8074788,
                  lng:-38.6381753
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-60m2-venda-RS161000-id-2748401899/",
               valorVenda:"161000.00",
               valorOriginal:"161000.00",
               endereco:"Rodovia Quarto Anel Viário, 141 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8052324,
                  lng:-38.6394786
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-ancuri-bairros-fortaleza-com-garagem-60m2-venda-RS161000-id-2744085221/",
               valorVenda:"161000.00",
               valorOriginal:"161000.00",
               endereco:"Rodovia Quarto Anel Viário, 101 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8050265,
                  lng:-38.6396732
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-60m2-venda-RS161000-id-2760848863/",
               valorVenda:"161000.00",
               valorOriginal:"161000.00",
               endereco:"Rodovia Quarto Anel Viário, 442 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.808132899999999,
                  lng:-38.6385338
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-60m2-venda-RS161000-id-2752985723/",
               valorVenda:"161000.00",
               valorOriginal:"161000.00",
               endereco:"Rodovia Quarto Anel Viário, 202 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8600912,
                  lng:-38.5385681
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS162000-id-2644384929/",
               valorVenda:"162000.00",
               valorOriginal:"162000.00",
               endereco:"Rua Pereira Coutinho, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.88204,
                  lng:-38.5092349
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-46m2-venda-RS162000-id-2543316161/",
               valorVenda:"162000.00",
               valorOriginal:"162000.00",
               endereco:"Rua Oscar Benevides - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8109387,
                  lng:-38.5715851
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-curio-bairros-fortaleza-com-garagem-56m2-venda-RS163000-id-2717438536/",
               valorVenda:"163000.00",
               valorOriginal:"163000.00",
               endereco:"Rua Cosme Almeida - Curió, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8265788,
                  lng:-38.4763938
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-tauape-bairros-fortaleza-96m2-venda-RS163000-id-2704506577/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Paulo Firmeza, 775 - Tauape, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 96m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:96,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7569664,
                  lng:-38.5124797
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-tauape-bairros-fortaleza-96m2-venda-RS163000-id-2704506577/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Paulo Firmeza, 775 - Tauape, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/a58ef10a92b782a95234b33833e6547b/foto-1-de-apartamento-com-3-quartos-a-venda-96m-em-tauape-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 96m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:96,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7569664,
                  lng:-38.5124797
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS163000-id-2669086999/",
               valorVenda:"163000.00",
               valorOriginal:"163000.00",
               endereco:"Rua Quartzo, 32 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/32f1bbd2d1dc150433be982b936b0489/foto-1-de-apartamento-com-2-quartos-a-venda-54m-em-ancuri-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8118814,
                  lng:-38.5591833
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-57m2-venda-RS163000-id-2648976808/",
               valorVenda:"163000.00",
               valorOriginal:"163000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 32 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/c14b30b1dfb1fc4715825522d4168c30/foto-1-de-apartamento-com-2-quartos-a-venda-57m-em-ancuri-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 57m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:57,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8396533,
                  lng:-38.495106
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-guajeru-bairros-fortaleza-com-garagem-56m2-venda-RS163000-id-2743242349/",
               valorVenda:"163000.00",
               valorOriginal:"163000.00",
               endereco:"Rua Cosme Almeida, 90 - Guajerú, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/f026faec017515051c933ddbe034b6a0/foto-1-de-casa-com-2-quartos-a-venda-56m-em-guajeru-fortaleza.webp",
               titulo:"Casa com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8246072,
                  lng:-38.4760466
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS163000-id-2665854872/",
               valorVenda:"163000.00",
               valorOriginal:"163000.00",
               endereco:"Rua Quartzo, 32 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8118814,
                  lng:-38.5591833
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-1-quartos-edson-queiroz-bairros-fortaleza-com-garagem-180m2-venda-RS163789-id-2758183137/",
               valorVenda:"163789.00",
               valorOriginal:"163789.00",
               endereco:"Rua Francisco Neiva, N. 2361 APTO. . LT 22 QD 23 - Edson Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com  Quarto à Venda, 180m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:180,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.783324299999999,
                  lng:-38.45647770000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS164000-id-2758553629/",
               valorVenda:"164000.00",
               valorOriginal:"164000.00",
               endereco:"Rodovia Quarto Anel Viário, 415 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.807834,
                  lng:-38.6379804
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS164000-id-2760849451/",
               valorVenda:"164000.00",
               valorOriginal:"164000.00",
               endereco:"Rodovia Quarto Anel Viário, 442 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.808132899999999,
                  lng:-38.6385338
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS164000-id-2762674922/",
               valorVenda:"164000.00",
               valorOriginal:"164000.00",
               endereco:"Rodovia Quarto Anel Viário, 477 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8083322,
                  lng:-38.6378008
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS164000-id-2758758631/",
               valorVenda:"164000.00",
               valorOriginal:"164000.00",
               endereco:"Rodovia Quarto Anel Viário, 423 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8078932,
                  lng:-38.6379479
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-parangaba-bairros-fortaleza-com-garagem-84m2-venda-RS165000-id-2575378913/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Avenida João Pessoa, 7183 - Parangaba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 84m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:84,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7727816,
                  lng:-38.5619707
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-vicente-pinzon-bairros-fortaleza-com-garagem-70m2-venda-RS165000-id-2703182599/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Miguel Calmont, 222 - Vicente Pinzon, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7268186,
                  lng:-38.462592
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-45m2-venda-RS165000-id-2689667168/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua 6 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7953168,
                  lng:-38.5386024
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS165000-id-2749508765/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 39 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8534002,
                  lng:-38.5591334
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-56m2-venda-RS165000-id-2749513865/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 40 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8534,
                  lng:-38.55913400000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS165000-id-2748397862/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 145 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.834114,
                  lng:-38.613951
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-48m2-venda-RS165000-id-2751840024/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua 6, 161 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.791526199999999,
                  lng:-38.5349882
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-47m2-venda-RS165000-id-2723470838/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Avenida Heróis do Acre, 755 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8032466,
                  lng:-38.5355512
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS165000-id-2757163519/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Quartzo, 390 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8871311,
                  lng:-38.521323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-54m2-venda-RS165000-id-2749519407/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 41 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8884323,
                  lng:-38.5449349
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-maraponga-bairros-fortaleza-com-garagem-60m2-venda-RS165000-id-2721642348/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Suíça, 98 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7931494,
                  lng:-38.5701437
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-56m2-venda-RS165000-id-2750739993/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 68 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.853393699999999,
                  lng:-38.5591496
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS165000-id-2757159929/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 389 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8076416,
                  lng:-38.638086
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-sao-joao-do-tauape-bairros-fortaleza-com-garagem-83m2-venda-RS165000-id-2760120744/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Pontes Vieira, 656 - São João do Tauape, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 83m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:83,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7530815,
                  lng:-38.5152733
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS165000-id-2750739295/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 67 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8533939,
                  lng:-38.559149
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-messejana-bairros-fortaleza-com-garagem-60m2-venda-RS165000-id-2699141486/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Irmãos Fontenele, 123 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.830977,
                  lng:-38.5106286
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-lagoa-redonda-bairros-fortaleza-38m2-venda-RS165000-id-2750164951/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua 09 - Lagoa Redonda, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 38m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:38,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.826101899999999,
                  lng:-38.4645212
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS165000-id-2762675925/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 478 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8083004,
                  lng:-38.6381759
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-56m2-venda-RS165000-id-2760874665/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 444 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8081484,
                  lng:-38.6385253
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS165000-id-2750218633/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 65 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8533943,
                  lng:-38.5591479
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-54m2-venda-RS165000-id-2755985323/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Avenida Dionísio Alencar, 371 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8882062,
                  lng:-38.5448727
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS165000-id-2748135657/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Quartzo, 142 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.888182399999999,
                  lng:-38.5200009
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-54m2-venda-RS165000-id-2751828994/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 87 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.888493,
                  lng:-38.5449614
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS165000-id-2747901118/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Quartzo, 131 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8884955,
                  lng:-38.5201042
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-barra-do-ceara-bairros-fortaleza-com-garagem-47m2-venda-RS165000-id-2733568113/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Irapuã, 250 - Barra do Ceará, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7042666,
                  lng:-38.5849161
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS165000-id-2752989510/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 205 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.805644,
                  lng:-38.6393239
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS165000-id-2752989510/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 205 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/a52da569d056d2ac23e19a70c56ade95/foto-1-de-apartamento-com-2-quartos-a-venda-54m-em-ancuri-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.805644,
                  lng:-38.6393239
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-56m2-venda-RS165000-id-2760874665/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 444 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/1070436fde607dd14af18e87be3ba9aa/foto-1-de-apartamento-com-2-quartos-a-venda-56m-em-ancuri-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8081484,
                  lng:-38.6385253
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-parangaba-bairros-fortaleza-com-garagem-84m2-venda-RS165000-id-2575378913/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Avenida João Pessoa, 7183 - Parangaba, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/d57a1dcf64a245d668db3e28fe6c8bff/foto-1-de-apartamento-com-3-quartos-a-venda-84m-em-parangaba-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 84m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:84,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7727816,
                  lng:-38.5619707
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS165000-id-2750218633/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 65 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/b00c1de58c909d4832c6ada3da5bf6ab/foto-1-de-apartamento-com-2-quartos-a-venda-55m-em-ancuri-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8533943,
                  lng:-38.5591479
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-maraponga-bairros-fortaleza-com-garagem-45m2-venda-RS165000-id-2710320574/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Suíça, 88 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.793145,
                  lng:-38.5701069
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS165000-id-2758762315/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Quartzo, 425 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8873616,
                  lng:-38.52139469999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS165000-id-2750739958/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 66 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8533941,
                  lng:-38.5591484
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-56m2-venda-RS165000-id-2750739993/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 68 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.853393699999999,
                  lng:-38.5591496
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-sao-joao-do-tauape-bairros-fortaleza-com-garagem-83m2-venda-RS165000-id-2760120744/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Pontes Vieira, 656 - São João do Tauape, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 83m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:83,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7530815,
                  lng:-38.5152733
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS165000-id-2757159929/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 389 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8076416,
                  lng:-38.638086
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-montese-bairros-fortaleza-com-garagem-78m2-venda-RS165000-id-2636214863/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Montese, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 78m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:78,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7683772,
                  lng:-38.5517784
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-damas-bairros-fortaleza-com-garagem-88m2-venda-RS165000-id-2735391625/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Carlos Câmara, 1300 - Damas, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 88m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:88,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.747601399999999,
                  lng:-38.54378639999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS165000-id-2752987059/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Quartzo, 204 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8879749,
                  lng:-38.5204793
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS165000-id-2762676515/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Quartzo, 478 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8871311,
                  lng:-38.521323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-47m2-venda-RS165000-id-2723470838/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Avenida Heróis do Acre, 755 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8032466,
                  lng:-38.5355512
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-maraponga-bairros-fortaleza-com-garagem-60m2-venda-RS165000-id-2721642348/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Suíça, 98 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7931494,
                  lng:-38.5701437
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-56m2-venda-RS165000-id-2749513865/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 40 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8534,
                  lng:-38.55913400000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS165000-id-2749508765/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 39 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8534002,
                  lng:-38.5591334
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-alvaro-weyne-bairros-fortaleza-com-garagem-44m2-venda-RS165000-id-2669737895/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Dona Mendinha, 1025 - Álvaro Weyne, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7117278,
                  lng:-38.5708085
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-damas-bairros-fortaleza-com-garagem-64m2-venda-RS165000-id-2737856640/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Carlos Câmara, 1300 - Damas, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 64m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:64,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.747601399999999,
                  lng:-38.54378639999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-44m2-venda-RS165000-id-2742467876/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Murará, 101 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8439561,
                  lng:-38.4930956
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS165000-id-2755971716/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Quartzo, 369 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8873616,
                  lng:-38.52139469999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-damas-bairros-fortaleza-com-garagem-71m2-venda-RS165000-id-2749626998/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Zacarias Gondim - Damas, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 71m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:71,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7680234,
                  lng:-38.5584587
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS165000-id-2758761113/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 423 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8078932,
                  lng:-38.6379479
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-54m2-venda-RS165000-id-2751828749/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 92 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8884887,
                  lng:-38.5449596
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-vila-uniao-bairros-fortaleza-com-garagem-160m2-venda-RS165000-id-2673906093/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Vila União, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 160m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:160,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7674077,
                  lng:-38.5373841
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-messejana-bairros-fortaleza-com-garagem-60m2-venda-RS165000-id-2699141773/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Irmãos Fontenele, 123 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.830977,
                  lng:-38.5106286
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-messejana-bairros-fortaleza-com-garagem-60m2-venda-RS165000-id-2699141485/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Irmãos Fontenele, 123 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.830977,
                  lng:-38.5106286
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-damas-bairros-fortaleza-com-garagem-72m2-venda-RS165000-id-2660658254/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua João Melo, 329 - Damas, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/fit-in/142x80/named.images.sp/84b5ca7753f83ebb6a758de05e058413/{description}.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 72m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:72,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7565224,
                  lng:-38.5541022
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-56m2-venda-RS165000-id-2757159659/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 389 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8076416,
                  lng:-38.638086
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-56m2-venda-RS165000-id-2758761518/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 424 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8079934,
                  lng:-38.6386095
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-50m2-venda-RS165000-id-2624473797/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8163239,
                  lng:-38.5316373
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-57m2-venda-RS165000-id-2753418112/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Paulina de Arruda, 14 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 57m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:57,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8272108,
                  lng:-38.5117223
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS165000-id-2659490892/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 40 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8534,
                  lng:-38.55913400000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS165000-id-2750740993/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Quartzo, 62 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8886776,
                  lng:-38.519494
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-47m2-venda-RS165000-id-2735324823/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Quartzo, 1 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8891673,
                  lng:-38.5193405
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-56m2-venda-RS165000-id-2752986004/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 2708 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/92ef6b8d626113f04df7fcd8bad77bf0/foto-1-de-apartamento-com-2-quartos-a-venda-56m-em-ancuri-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8736274,
                  lng:-38.4927633
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-messejana-bairros-fortaleza-com-garagem-60m2-venda-RS165000-id-2699141773/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Irmãos Fontenele, 123 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/e1644172abb7f35df216f34a7b6fb9e0/foto-1-de-apartamento-com-3-quartos-a-venda-60m-em-messejana-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.830977,
                  lng:-38.5106286
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-messejana-bairros-fortaleza-com-garagem-60m2-venda-RS165000-id-2699141485/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Irmãos Fontenele, 123 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/eeb4d532f1ff65a87bcd3cc6130a7327/foto-1-de-apartamento-com-3-quartos-a-venda-60m-em-messejana-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.830977,
                  lng:-38.5106286
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS165000-id-2750740993/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Quartzo, 62 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/dbd4fa2f72837825ffa0b35aea55f877/foto-1-de-apartamento-com-2-quartos-a-venda-55m-em-pedras-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8886776,
                  lng:-38.519494
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS165000-id-2659490892/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 40 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8534,
                  lng:-38.55913400000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-70m2-venda-RS165000-id-2758167616/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Francisco Nogueira - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7975379,
                  lng:-38.5081974
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-3-quartos-engenheiro-luciano-cavalcante-bairros-fortaleza-com-garagem-170m2-venda-RS165000-id-2675168706/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Desembargador Feliciano de Ataíde, 845 - Engenheiro Luciano Cavalcante, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 170m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:170,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7852371,
                  lng:-38.4949725
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-54m2-venda-RS165000-id-2750220272/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 66 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8885111,
                  lng:-38.54496940000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS165000-id-2751825211/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Quartzo, 91 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8887787,
                  lng:-38.519808
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS165000-id-2760860127/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 443 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8080412,
                  lng:-38.6378667
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-montese-bairros-fortaleza-com-garagem-69m2-venda-RS165000-id-2692988918/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Avenida dos Expedicionários, 655 - Montese, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 69m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:69,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7660771,
                  lng:-38.541514
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-47m2-venda-RS165000-id-2735324823/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Quartzo, 1 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8891673,
                  lng:-38.5193405
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-56m2-venda-RS165000-id-2748398822/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 141 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8052324,
                  lng:-38.6394786
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS165000-id-2760876394/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 360 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8882497,
                  lng:-38.5448833
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-1-quartos-jacarecanga-bairros-fortaleza-42m2-venda-RS165000-id-2686325945/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Messias Filomeno, 35 - Jacarecanga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com  Quarto à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.719109999999999,
                  lng:-38.5454909
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-48m2-venda-RS165000-id-2751685896/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua 6, 55 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7949112,
                  lng:-38.5411836
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cidade-dos-funcionarios-bairros-fortaleza-com-garagem-80m2-venda-RS165000-id-2663953201/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Escrivão Azevedo, 110 - Cidade dos Funcionários, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7962668,
                  lng:-38.505481
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jardim-das-oliveiras-bairros-fortaleza-com-garagem-55m2-venda-RS165000-id-2759529931/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Avenida José Leon, 1078 - Jardim das Oliveiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.787842,
                  lng:-38.5026262
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-paupina-bairros-fortaleza-com-garagem-44m2-venda-RS165000-id-2743409063/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Paupina, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8514253,
                  lng:-38.4897925
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-56m2-venda-RS165000-id-2755982629/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 370 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.807575,
                  lng:-38.6388366
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-damas-bairros-fortaleza-com-garagem-72m2-venda-RS165000-id-2652125460/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Desembargador Praxedes, 329 - Damas, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 72m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:72,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7588229,
                  lng:-38.5504617
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-47m2-venda-RS165000-id-2746941773/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Joaquim Jerônimo, 559 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8044328,
                  lng:-38.563506
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS165000-id-2744069800/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 99 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8050139,
                  lng:-38.6396787
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-47m2-venda-RS165000-id-2697587479/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua I - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8350447,
                  lng:-38.4844834
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-henrique-jorge-bairros-fortaleza-com-garagem-44m2-venda-RS165000-id-2759832635/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Padre Sá Leitão, 55 - Henrique Jorge, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7559889,
                  lng:-38.5787099
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-henrique-jorge-bairros-fortaleza-com-garagem-51m2-venda-RS165000-id-2735130214/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Franco Rocha, 397 - Henrique Jorge, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 51m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:51,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.754819799999999,
                  lng:-38.5801647
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-4-quartos-mondubim-bairros-fortaleza-com-garagem-47m2-venda-RS165000-id-2736992422/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Coronel Manuel Albano, 60 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 4 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8079429,
                  lng:-38.5646483
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-vila-ellery-bairros-fortaleza-com-garagem-52m2-venda-RS165000-id-2736212313/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Henrique Ellery, 121 - Vila Ellery, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7198918,
                  lng:-38.56136559999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS165000-id-2750745297/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 68 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.853393699999999,
                  lng:-38.5591496
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-damas-bairros-fortaleza-com-garagem-72m2-venda-RS165000-id-2660658254/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua João Melo, 329 - Damas, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/fit-in/142x80/named.images.sp/84b5ca7753f83ebb6a758de05e058413/{description}.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 72m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:72,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7565224,
                  lng:-38.5541022
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-56m2-venda-RS165000-id-2758761518/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 424 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8079934,
                  lng:-38.6386095
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-50m2-venda-RS165000-id-2624473797/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8163239,
                  lng:-38.5316373
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-45m2-venda-RS165000-id-2732337030/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Artur de Sousa - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8259402,
                  lng:-38.5749859
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS165000-id-2677358699/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Pereira Coutinho, 2 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.88204,
                  lng:-38.5092349
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-joaquim-tavora-bairros-fortaleza-com-garagem-147m2-venda-RS165000-id-2742123557/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Ildefonso Albano, 2955 - Joaquim Tavora, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 147m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:147,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.746569699999999,
                  lng:-38.5174174
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-56m2-venda-RS165000-id-2757159659/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rodovia Quarto Anel Viário, 389 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8076416,
                  lng:-38.638086
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cidade-dos-funcionarios-bairros-fortaleza-com-garagem-48m2-venda-RS165000-id-2742653782/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Avenida José Leon, 1078 - Cidade dos Funcionários, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/8941b636492c5c134a13546339d9d496/foto-1-de-apartamento-com-2-quartos-a-venda-48m-em-cidade-dos-funcionarios-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.787842,
                  lng:-38.5026262
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-paupina-bairros-fortaleza-com-garagem-61m2-venda-RS165000-id-2740209321/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Diva, 1163 - Paupina, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/d4cd0e2e7254804ff9e9f7a4234e494f/foto-1-de-apartamento-com-2-quartos-para-venda-ou-aluguel-61m-em-paupina-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos para Venda/Aluguel 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8407993,
                  lng:-38.4929523
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-54m2-venda-RS165000-id-2757164015/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 388 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/7bd1696d100f5c9f6d4a564401dcc617/foto-1-de-apartamento-com-2-quartos-a-venda-54m-em-pedras-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8448128,
                  lng:-38.5015501
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-44m2-venda-RS165000-id-2743625657/",
               valorVenda:"165000.00",
               valorOriginal:"165000.00",
               endereco:"Rua Murará, 101 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/71c059e01beb48fe0a93a8e8d11b292f/foto-1-de-apartamento-com-2-quartos-a-venda-44m-em-messejana-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8439561,
                  lng:-38.4930956
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS165001-id-2670293927/",
               valorVenda:"165001.00",
               valorOriginal:"165001.00",
               endereco:"Avenida Dionísio Leonel Alencar, 32 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8396533,
                  lng:-38.495106
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS165002-id-2666446358/",
               valorVenda:"165002.00",
               valorOriginal:"165002.00",
               endereco:"Rua Quartzo, 32 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8118814,
                  lng:-38.5591833
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS165002-id-2675644601/",
               valorVenda:"165002.00",
               valorOriginal:"165002.00",
               endereco:"Avenida Dionísio Leonel Alencar, 32 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8396533,
                  lng:-38.495106
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-54m2-venda-RS165003-id-2669418522/",
               valorVenda:"165003.00",
               valorOriginal:"165003.00",
               endereco:"Rua Quartzo, 32 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8118814,
                  lng:-38.5591833
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS165003-id-2669846205/",
               valorVenda:"165003.00",
               valorOriginal:"165003.00",
               endereco:"Rua Pereira Coutinho, 1 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.88242,
                  lng:-38.509082
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-damas-bairros-fortaleza-com-garagem-55m2-venda-RS166000-id-2745201538/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Carlos Câmara, 1305 - Damas, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7478879,
                  lng:-38.5436388
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-benfica-bairros-fortaleza-com-garagem-57m2-venda-RS166000-id-2742514557/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Carlos Câmara, 1300 - Benfica, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 57m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:57,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.747601399999999,
                  lng:-38.54378639999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-54m2-venda-RS167000-id-2757164329/",
               valorVenda:"167000.00",
               valorOriginal:"167000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 2750 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8766385,
                  lng:-38.5291072
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-54m2-venda-RS167000-id-2762676718/",
               valorVenda:"167000.00",
               valorOriginal:"167000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 3350 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8631976,
                  lng:-38.5179784
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-54m2-venda-RS167000-id-2760877388/",
               valorVenda:"167000.00",
               valorOriginal:"167000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 361 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8882487,
                  lng:-38.5448831
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-114m2-venda-RS167000-id-2758344016/",
               valorVenda:"167000.00",
               valorOriginal:"167000.00",
               endereco:"Avenida Heróis do Acre, 50 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 114m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:114,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8029352,
                  lng:-38.5291654
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-54m2-venda-RS167000-id-2758763718/",
               valorVenda:"167000.00",
               valorOriginal:"167000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 411 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8436536,
                  lng:-38.5005147
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-siqueira-bairros-fortaleza-com-garagem-40m2-venda-RS167434-id-2629718558/",
               valorVenda:"167434.00",
               valorOriginal:"167434.00",
               endereco:"Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 40m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:40,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8024954,
                  lng:-38.6277436
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-55m2-venda-RS168000-id-2746213570/",
               valorVenda:"168000.00",
               valorOriginal:"168000.00",
               endereco:"Vila Almira - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8222439,
                  lng:-38.4987414
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS168000-id-2746724821/",
               valorVenda:"168000.00",
               valorOriginal:"168000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 233 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8883656,
                  lng:-38.5449114
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-benfica-bairros-fortaleza-com-garagem-60m2-venda-RS168000-id-2571351350/",
               valorVenda:"168000.00",
               valorOriginal:"168000.00",
               endereco:"Benfica, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7400131,
                  lng:-38.5402324
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-itaoca-bairros-fortaleza-com-garagem-90m2-venda-RS168000-id-2724557953/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Mundica Paula, 681 - Itaoca, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7732615,
                  lng:-38.5586829
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-47m2-venda-RS168000-id-2750742702/",
               valorVenda:"168000.00",
               valorOriginal:"168000.00",
               endereco:"Rodovia Quarto Anel Viário, 67 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8533939,
                  lng:-38.559149
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-47m2-venda-RS168000-id-2743789858/",
               valorVenda:"168000.00",
               valorOriginal:"168000.00",
               endereco:"Rodovia Quarto Anel Viário, 80 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8533909,
                  lng:-38.5591562
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-47m2-venda-RS168000-id-2751830611/",
               valorVenda:"168000.00",
               valorOriginal:"168000.00",
               endereco:"Rodovia Quarto Anel Viário, 93 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8049899,
                  lng:-38.6396939
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-barra-do-ceara-bairros-fortaleza-com-garagem-65m2-venda-RS168000-id-2719736946/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Coronel Carvalho, 240 - Barra do Ceará, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 65m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:65,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7045424,
                  lng:-38.5914302
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-65m2-venda-RS168000-id-2619276601/",
               valorVenda:"168000.00",
               valorOriginal:"168000.00",
               endereco:"Rua 42, 40 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 65m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:65,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8282915,
                  lng:-38.5585064
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-montese-bairros-fortaleza-com-garagem-82m2-venda-RS169000-id-2736197208/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Queirós Ribeiro, 1034 - Montese, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 82m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:82,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.766999799999999,
                  lng:-38.549619
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-passare-bairros-fortaleza-com-garagem-68m2-venda-RS169000-id-2736914960/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 68m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:68,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8163239,
                  lng:-38.5316373
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-passare-bairros-fortaleza-com-garagem-54m2-venda-RS169000-id-2752512140/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Crisanto Arruda, 785 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8160674,
                  lng:-38.5239729
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS169000-id-2757186422/",
               valorVenda:"169000.00",
               valorOriginal:"169000.00",
               endereco:"Rua Barão Aquiraz, 153 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8326886,
                  lng:-38.4899668
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-ii-bairros-fortaleza-com-garagem-52m2-venda-RS169000-id-2758745614/",
               valorVenda:"169000.00",
               valorOriginal:"169000.00",
               endereco:"Rua Coronel Francisco Montenegro, 17 - Praia do Futuro II, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7555018,
                  lng:-38.4474521
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-43m2-venda-RS169000-id-2721964670/",
               valorVenda:"169000.00",
               valorOriginal:"169000.00",
               endereco:"Rua 2, 99 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 43m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:43,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8196403,
                  lng:-38.5727892
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS169000-id-2752984696/",
               valorVenda:"169000.00",
               valorOriginal:"169000.00",
               endereco:"Rua Barão Aquiraz, 153 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8326886,
                  lng:-38.4899668
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-henrique-jorge-bairros-fortaleza-com-garagem-83m2-venda-RS169000-id-2647976174/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Henrique Jorge, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 83m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:83,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.760993899999999,
                  lng:-38.5843464
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS169000-id-2752062817/",
               valorVenda:"169000.00",
               valorOriginal:"169000.00",
               endereco:"Rua Barão Aquiraz, 153 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8326886,
                  lng:-38.4899668
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS169000-id-2760515147/",
               valorVenda:"169000.00",
               valorOriginal:"169000.00",
               endereco:"Rua Barão Aquiraz, 150 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8324729,
                  lng:-38.490108
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS169000-id-2763204220/",
               valorVenda:"169000.00",
               valorOriginal:"169000.00",
               endereco:"Rua Barão Aquiraz, 140 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/191916b1a89f40c05cb1d19e8f81b515/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-messejana-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.83247,
                  lng:-38.4901071
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-montese-bairros-fortaleza-com-garagem-70m2-venda-RS169000-id-2719874770/",
               valorVenda:"169000.00",
               valorOriginal:"169000.00",
               endereco:"Rua Irmã Bazet, 730 - Montese, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/de8e2c77a8cf6462a0c4122019ba8635/foto-1-de-apartamento-com-3-quartos-a-venda-70m-em-montese-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7652118,
                  lng:-38.5513352
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS169000-id-2758228618/",
               valorVenda:"169000.00",
               valorOriginal:"169000.00",
               endereco:"Rua Barão Aquiraz, 153 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/5f7650958e6f807e407201c14cb47f7b/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-messejana-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8326886,
                  lng:-38.4899668
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-passare-bairros-fortaleza-com-garagem-68m2-venda-RS169000-id-2713861301/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Passaré, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/b8fec54fb4f5d28c5f723bb04e889737/foto-1-de-apartamento-com-3-quartos-a-venda-68m-em-passare-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 68m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:68,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8163239,
                  lng:-38.5316373
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS169000-id-2762030127/",
               valorVenda:"169000.00",
               valorOriginal:"169000.00",
               endereco:"Rua Barão Aquiraz, 150 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8324729,
                  lng:-38.490108
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS169000-id-2758852233/",
               valorVenda:"169000.00",
               valorOriginal:"169000.00",
               endereco:"Rua Barão Aquiraz, 151 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8326886,
                  lng:-38.4899668
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS169000-id-2760515147/",
               valorVenda:"169000.00",
               valorOriginal:"169000.00",
               endereco:"Rua Barão Aquiraz, 150 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8324729,
                  lng:-38.490108
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-41m2-venda-RS169000-id-2641350974/",
               valorVenda:"169000.00",
               valorOriginal:"169000.00",
               endereco:"Rua Murará, 100 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 41m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:41,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.844198,
                  lng:-38.493833
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS169000-id-2756453724/",
               valorVenda:"169000.00",
               valorOriginal:"169000.00",
               endereco:"Rua Barão Aquiraz, 150 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8324729,
                  lng:-38.490108
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-50m2-venda-RS169000-id-2559344872/",
               valorVenda:"169000.00",
               valorOriginal:"169000.00",
               endereco:"Via Marginal Riacho Martinho, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7893619,
                  lng:-38.5181394
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-60m2-venda-RS169000-id-2649359485/",
               valorVenda:"169000.00",
               valorOriginal:"169000.00",
               endereco:"Rua Pereira Coutinho, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.88204,
                  lng:-38.5092349
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-44m2-venda-RS169900-id-2742104969/",
               valorVenda:"169900.00",
               valorOriginal:"169900.00",
               endereco:"Rua Monte Líbano, 960 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8285257,
                  lng:-38.565002
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-44m2-venda-RS169900-id-2743373498/",
               valorVenda:"169900.00",
               valorOriginal:"169900.00",
               endereco:"Rua Monte Líbano - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8262274,
                  lng:-38.5631478
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-canindezinho-bairros-fortaleza-com-garagem-56m2-venda-RS169900-id-2762043475/",
               valorVenda:"169900.00",
               valorOriginal:"169900.00",
               endereco:"Travessa Santa Rita, 1140 - Canindezinho, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8021252,
                  lng:-38.5904278
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-71m2-venda-RS169900-id-2741443796/",
               valorVenda:"169900.00",
               valorOriginal:"169900.00",
               endereco:"Rua Jorge Figueiredo, 2298 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 71m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:71,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8886249,
                  lng:-38.5204569
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-61m2-venda-RS170000-id-2666109298/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Padre Paulino, 340 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.79831,
                  lng:-38.5095055
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-fatima-bairros-fortaleza-com-garagem-49m2-venda-RS170000-id-2748917816/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Avenida dos Expedicionários - Fátima, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7500731,
                  lng:-38.5366211
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pan-americano-bairros-fortaleza-69m2-venda-RS170000-id-2718099399/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Mato Grosso, 1006 - Pan Americano, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 69m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:69,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7591869,
                  lng:-38.56538
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-4-quartos-vila-ellery-bairros-fortaleza-120m2-venda-RS170000-id-2697364900/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Gonçalo de Lagos, 444 - Vila Ellery, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 4 Quartos à Venda, 120m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:120,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.723989,
                  lng:-38.5615455
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-edson-queiroz-bairros-fortaleza-com-garagem-66m2-venda-RS170000-id-2733383157/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Edson Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 66m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:66,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7708535,
                  lng:-38.4746411
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-1-quartos-benfica-bairros-fortaleza-com-garagem-54m2-venda-RS170000-id-2686095796/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida da Universidade, 1870 - Benfica, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com  Quarto à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7345884,
                  lng:-38.5335112
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-ii-bairros-fortaleza-com-garagem-52m2-venda-RS170000-id-2758894144/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Coronel Francisco Montenegro - Praia do Futuro II, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7556095,
                  lng:-38.447676
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cidade-2000-bairros-fortaleza-com-garagem-95m2-venda-RS170000-id-2609750176/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Avenida das Graviolas - Cidade 2000, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 95m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:95,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7519827,
                  lng:-38.4737964
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS170000-id-2749523801/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Quartzo, 46 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.888815,
                  lng:-38.5193449
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-maraponga-bairros-fortaleza-com-garagem-68m2-venda-RS170000-id-2743121464/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Joaquim Jerônimo - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 68m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:68,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8036925,
                  lng:-38.562525
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jangurussu-bairros-fortaleza-com-garagem-60m2-venda-RS170000-id-2757917150/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Dom Lustosa, 985 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8478487,
                  lng:-38.5116161
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-69m2-venda-RS170000-id-2727176736/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Safira, 70 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 69m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:69,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8877383,
                  lng:-38.5186886
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-montese-bairros-fortaleza-com-garagem-98m2-venda-RS170000-id-2608621882/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Montese, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 98m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:98,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7683772,
                  lng:-38.5517784
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parque-dois-irmaos-bairros-fortaleza-com-garagem-48m2-venda-RS170000-id-2553326115/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Parque Dois Irmãos, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8089995,
                  lng:-38.5495815
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-96m2-venda-RS170000-id-2652851492/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Monte Líbano, 96 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 96m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:96,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8210451,
                  lng:-38.5591579
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-50m2-venda-RS170000-id-2761528817/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Cibele, 65 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8212098,
                  lng:-38.4973772
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-bom-jardim-bairros-fortaleza-com-garagem-90m2-venda-RS170000-id-2747545004/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Travessa Santa Rita, 990 - Bom Jardim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8048461,
                  lng:-38.6076125
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-1-quartos-bonsucesso-bairros-fortaleza-com-garagem-80m2-venda-RS170000-id-2696988177/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua São Francisco do Bosque, 212 - Bonsucesso, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com  Quarto à Venda, 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.784252,
                  lng:-38.588286
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS170000-id-2752988885/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rodovia Quarto Anel Viário, 206 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8057226,
                  lng:-38.6395695
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-floresta-bairros-fortaleza-60m2-venda-RS170000-id-2577481999/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Erondina Cavalcante, 68 - Floresta, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7208833,
                  lng:-38.5745477
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-henrique-jorge-bairros-fortaleza-com-garagem-51m2-venda-RS170000-id-2677400298/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Henrique Jorge, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 51m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:51,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.760993899999999,
                  lng:-38.5843464
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-ii-bairros-fortaleza-com-garagem-52m2-venda-RS170000-id-2758897725/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Coronel Francisco Montenegro, 34 - Praia do Futuro II, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/4d94c52c44635d92dc8c0bd03364fd53/foto-1-de-apartamento-com-2-quartos-a-venda-52m-em-praia-do-futuro-ii-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7554344,
                  lng:-38.4473153
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-padre-andrade-bairros-fortaleza-com-garagem-60m2-venda-RS170000-id-2724011150/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Alcântara Bilhar, 677 - Padre Andrade, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/03614506e3561186094e15f4fabfad9e/foto-1-de-apartamento-com-2-quartos-a-venda-60m-em-padre-andrade-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7366381,
                  lng:-38.5818265
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-mondubim-bairros-fortaleza-com-garagem-153m2-venda-RS170000-id-2754176208/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua São Francisco, 78 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/5928fe9aa75f3ca57e2383cff113fd58/foto-1-de-casa-com-3-quartos-a-venda-153m-em-mondubim-fortaleza.webp",
               titulo:"Casa com 3 Quartos à Venda, 153m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:153,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8187812,
                  lng:-38.585804
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-couto-fernandes-bairros-fortaleza-com-garagem-67m2-venda-RS170000-id-2691316745/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Oscar Bezerra, 44 - Couto Fernandes, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/319c15f278d502bac70f077bdfad5c72/foto-1-de-apartamento-com-3-quartos-a-venda-67m-em-couto-fernandes-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 67m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:67,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7606079,
                  lng:-38.5595243
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-fatima-bairros-fortaleza-60m2-venda-RS170000-id-72414928/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Carlos Gomes - Fátima, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.740601,
                  lng:-38.5297303
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-45m2-venda-RS170000-id-2756286025/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Coronel Chico Alves, 120 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8067791,
                  lng:-38.5263389
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-serrinha-bairros-fortaleza-com-garagem-50m2-venda-RS170000-id-2761566348/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Avenida Bernardo Manuel - Serrinha, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7875025,
                  lng:-38.5479782
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-bom-jardim-bairros-fortaleza-com-garagem-150m2-venda-RS170000-id-2713462532/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Travessa Santa Rita, 1000 - Bom Jardim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 150m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:150,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8048461,
                  lng:-38.6076125
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-76m2-venda-RS170000-id-2744701047/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Tibúrcio Pereira, 266 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 76m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:76,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8032881,
                  lng:-38.5055081
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-serrinha-bairros-fortaleza-com-garagem-47m2-venda-RS170000-id-2723340642/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Avenida Bernardo Manuel - Serrinha, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7875025,
                  lng:-38.5479782
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-montese-bairros-fortaleza-com-garagem-76m2-venda-RS170000-id-2586323837/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Montese, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 76m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:76,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7683772,
                  lng:-38.5517784
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-49m2-venda-RS170000-id-2756821174/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Coronel Chico Alves, 168 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.80689,
                  lng:-38.5264159
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-69m2-venda-RS170000-id-2691841091/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Tibúrcio Pereira, 341 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 69m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:69,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.796835,
                  lng:-38.5098508
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-90m2-venda-RS170000-id-2758650753/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua 55 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8273797,
                  lng:-38.5527842
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-jangurussu-bairros-fortaleza-com-garagem-59m2-venda-RS170000-id-2723484738/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Dom Lustosa, 985 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 59m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:59,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8478487,
                  lng:-38.5116161
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-49m2-venda-RS170000-id-2658071303/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Travessa Maria Gomes de Sá - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8225757,
                  lng:-38.584707
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-siqueira-bairros-fortaleza-com-garagem-51m2-venda-RS170000-id-2747543097/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Osiá Montenegro, 215 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 51m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:51,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8138729,
                  lng:-38.6138127
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-barroso-bairros-fortaleza-com-garagem-64m2-venda-RS170000-id-2646441546/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Barroso, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 64m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:64,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8208563,
                  lng:-38.5085865
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-44m2-venda-RS170000-id-2754224111/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua do Murará, 101 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8439561,
                  lng:-38.4930956
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-barra-do-ceara-bairros-fortaleza-com-garagem-44m2-venda-RS170000-id-2713993203/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Barra do Ceará, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7040082,
                  lng:-38.5791793
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-44m2-venda-RS170000-id-2646735645/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Artur de Sousa - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8259402,
                  lng:-38.5749859
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-siqueira-bairros-fortaleza-com-garagem-50m2-venda-RS170000-id-2740961656/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Osiá Montenegro, 201 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa de condomínio com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8135741,
                  lng:-38.6144889
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-floresta-bairros-fortaleza-60m2-venda-RS170000-id-2577481999/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Erondina Cavalcante, 68 - Floresta, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7208833,
                  lng:-38.5745477
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-50m2-venda-RS170000-id-2761528817/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Cibele, 65 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8212098,
                  lng:-38.4973772
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS170000-id-2752988885/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rodovia Quarto Anel Viário, 206 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8057226,
                  lng:-38.6395695
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-1-quartos-bonsucesso-bairros-fortaleza-com-garagem-80m2-venda-RS170000-id-2696988177/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua São Francisco do Bosque, 212 - Bonsucesso, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com  Quarto à Venda, 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.784252,
                  lng:-38.588286
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-61m2-venda-RS170000-id-2666109298/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Padre Paulino, 340 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.79831,
                  lng:-38.5095055
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-itaperi-bairros-fortaleza-com-garagem-48m2-venda-RS170000-id-2560112422/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Avenida Bernardo Manuel, 8550 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7942973,
                  lng:-38.5502458
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-serrinha-bairros-fortaleza-com-garagem-47m2-venda-RS170000-id-2710145611/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Avenida Bernardo Manuel - Serrinha, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7875025,
                  lng:-38.5479782
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-siqueira-bairros-fortaleza-com-garagem-50m2-venda-RS170000-id-2633196368/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Avenida General Osório de Paiva, 255 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.774903299999999,
                  lng:-38.5650626
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-58m2-venda-RS170000-id-2757166240/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rodovia Quarto Anel Viário, 390 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 58m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:58,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.80773,
                  lng:-38.6387525
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-messejana-bairros-fortaleza-com-garagem-49m2-venda-RS170000-id-2745328657/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8327352,
                  lng:-38.4940863
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS170000-id-2757163940/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rodovia Quarto Anel Viário, 389 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8076416,
                  lng:-38.638086
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-80m2-venda-RS170000-id-2567322139/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Pereira Coutinho, 1505 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8938363,
                  lng:-38.5136552
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-58m2-venda-RS170000-id-2762677417/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rodovia Quarto Anel Viário, 479 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 58m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:58,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.808347,
                  lng:-38.6377927
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-manuel-satiro-bairros-fortaleza-com-garagem-87m2-venda-RS170000-id-2567995526/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Manuel Sátiro, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 87m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:87,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7939055,
                  lng:-38.57826439999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-80m2-venda-RS170000-id-2567322139/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Pereira Coutinho, 1505 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/57971cacc33e95f9cb77736dfb0e5867/foto-1-de-casa-com-2-quartos-a-venda-80m-em-pedras-fortaleza.webp",
               titulo:"Casa com 2 Quartos à Venda, 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8938363,
                  lng:-38.5136552
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-montese-bairros-fortaleza-com-garagem-90m2-venda-RS170000-id-2735116855/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Irmã Bazet, 760 - Montese, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/68446ae91f037e257a568a8b9689f27b/foto-1-de-apartamento-com-3-quartos-a-venda-90m-em-montese-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7653524,
                  lng:-38.5511956
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-vicente-pinzon-bairros-fortaleza-com-garagem-53m2-venda-RS170000-id-2703322980/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Oliveira Filho, 1300 - Vicente Pinzon, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/1f2e990bc2d87ad8cdeacb8e23977a2a/foto-1-de-apartamento-com-2-quartos-a-venda-53m-em-vicente-pinzon-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 53m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:53,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7292487,
                  lng:-38.4630392
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-aracape-bairros-fortaleza-com-garagem-60m2-venda-RS170000-id-2692125453/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Cosme Gerônimo, 1465 - ARACAPE, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/88ebd4ddc9653ffdf31d6bb0f1eed3f2/foto-1-de-apartamento-com-3-quartos-a-venda-60m-em-aracape-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8245472,
                  lng:-38.5883855
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-couto-fernandes-bairros-fortaleza-45m2-venda-RS170000-id-2704510558/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Oscar Bezerra, 270 - Couto Fernandes, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.757509,
                  lng:-38.5593168
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-parquelandia-bairros-fortaleza-com-garagem-71m2-venda-RS170000-id-2715562514/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Bezerra de Menezes, 785 - Parquelândia, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 71m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:71,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7334717,
                  lng:-38.5517933
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS170000-id-2750232500/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rodovia Quarto Anel Viário, 69 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.853393399999999,
                  lng:-38.5591501
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-bela-vista-bairros-fortaleza-com-garagem-50m2-venda-RS170000-id-2753478931/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Avenida Alberto Craveiro, 100 - Bela Vista, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7854741,
                  lng:-38.51683939999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-conjunto-ceara-i-bairros-fortaleza-com-garagem-64m2-venda-RS170000-id-2579562908/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua 1119, 66 - Conjunto Ceara I, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 64m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:64,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7624546,
                  lng:-38.6133984
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-messejana-bairros-fortaleza-com-garagem-49m2-venda-RS170000-id-2711658906/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Miracema, 690 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8368006,
                  lng:-38.5011746
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-maraponga-bairros-fortaleza-com-garagem-64m2-venda-RS170000-id-2752008801/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Suíça, 121 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 64m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:64,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7931625,
                  lng:-38.5702575
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-lagoa-redonda-bairros-fortaleza-com-garagem-60m2-venda-RS170000-id-2513390737/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Osvaldo Cruz, 620 - Lagoa Redonda, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa de condomínio com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7311402,
                  lng:-38.4989483
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-itaperi-bairros-fortaleza-com-garagem-63m2-venda-RS170000-id-2722498457/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Doutor Manuel Teófilo, 382 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 63m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:63,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7831553,
                  lng:-38.5612422
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-rachel-de-queiroz-bairros-fortaleza-com-garagem-50m2-venda-RS170000-id-2749783923/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Avenida Bernardo Manuel, 8666 - Rachel de Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7953002,
                  lng:-38.5505934
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-58m2-venda-RS170000-id-2758768617/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rodovia Quarto Anel Viário, 426 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 58m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:58,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8080089,
                  lng:-38.6386011
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-tauape-bairros-fortaleza-com-garagem-60m2-venda-RS170000-id-2762800321/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Evaristo Reis, 309 - Tauape, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7613438,
                  lng:-38.5092168
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-couto-fernandes-bairros-fortaleza-com-garagem-67m2-venda-RS170000-id-2655934780/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Oscar Bezerra, 44 - Couto Fernandes, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 67m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:67,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7606079,
                  lng:-38.5595243
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-siqueira-bairros-fortaleza-com-garagem-51m2-venda-RS170000-id-2740702565/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Osiá Montenegro, 215 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 51m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:51,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8138729,
                  lng:-38.6138127
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-jardim-america-bairros-fortaleza-com-garagem-64m2-venda-RS170000-id-2731467513/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Júlio César, 1620 - Jardim América, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 64m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:64,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7558147,
                  lng:-38.5479421
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-51m2-venda-RS170000-id-2682317694/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Antônio Luís Almeida Vale, 100 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 51m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:51,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.820155499999999,
                  lng:-38.5619096
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-parquelandia-bairros-fortaleza-com-garagem-73m2-venda-RS170000-id-2695454834/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Avenida Humberto Monte, 1789 - Parquelândia, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 73m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:73,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7453096,
                  lng:-38.5638751
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-montese-bairros-fortaleza-com-garagem-76m2-venda-RS170000-id-2754407227/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Isaie Bóris, 350 - Montese, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos para Venda/Aluguel 76m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:76,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.767227199999999,
                  lng:-38.5492061
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-planalto-ayrton-senna-bairros-fortaleza-com-garagem-44m2-venda-RS170000-id-2751181602/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Planalto Ayrton Senna, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8283473,
                  lng:-38.5715343
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-44m2-venda-RS170000-id-2750166798/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Vila Três Américas - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8118712,
                  lng:-38.5746698
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-49m2-venda-RS170000-id-2719391134/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Antônio Divino, 325 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8052309,
                  lng:-38.5618494
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-ii-bairros-fortaleza-com-garagem-52m2-venda-RS170000-id-2758900322/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Coronel Francisco Montenegro, 1234 - Praia do Futuro II, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7556095,
                  lng:-38.447676
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-58m2-venda-RS170000-id-2755968628/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rodovia Quarto Anel Viário, 368 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 58m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:58,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8075595,
                  lng:-38.638845
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-monte-castelo-bairros-fortaleza-com-garagem-60m2-venda-RS170000-id-2752992026/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Viana de Carvalho, 180 - Monte Castelo, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7236218,
                  lng:-38.5547287
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-ancuri-bairros-fortaleza-com-garagem-46m2-venda-RS170000-id-2730953914/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua 3, 359 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8695634,
                  lng:-38.5188252
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-barroso-bairros-fortaleza-com-garagem-64m2-venda-RS170000-id-2639636076/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Valéria, 100 - Barroso, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 64m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:64,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8208172,
                  lng:-38.5054765
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-planalto-ayrton-senna-bairros-fortaleza-com-garagem-45m2-venda-RS170000-id-2722049250/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Artur de Sousa, 2011 - Planalto Ayrton Senna, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8241343,
                  lng:-38.5780593
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-61m2-venda-RS170000-id-2751505998/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Tenente Tito Barros - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8013826,
                  lng:-38.5072349
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-jangurussu-bairros-fortaleza-com-garagem-80m2-venda-RS170000-id-2693154328/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua 426 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.760584,
                  lng:-38.6051627
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS170000-id-2758767615/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rodovia Quarto Anel Viário, 425 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.807908,
                  lng:-38.6379398
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-ii-bairros-fortaleza-com-garagem-52m2-venda-RS170000-id-2758896130/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Coronel Francisco Montenegro, 1345 - Praia do Futuro II, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7556095,
                  lng:-38.447676
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS170000-id-2746922093/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Quartzo, 248 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8875832,
                  lng:-38.5207911
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-montese-bairros-fortaleza-com-garagem-76m2-venda-RS170000-id-2754407227/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Isaie Bóris, 350 - Montese, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/7e37d5f5656d1c69745930b5e8ca7e9d/foto-1-de-apartamento-com-2-quartos-para-venda-ou-aluguel-76m-em-montese-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos para Venda/Aluguel 76m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:76,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.767227199999999,
                  lng:-38.5492061
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-61m2-venda-RS170000-id-2751505998/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Tenente Tito Barros - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/6ffd8bf7f270a9f1cff41e3f3ba2d9a6/foto-1-de-apartamento-com-3-quartos-a-venda-61m-em-cajazeiras-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8013826,
                  lng:-38.5072349
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-barroso-bairros-fortaleza-com-garagem-64m2-venda-RS170000-id-2639636076/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Valéria, 100 - Barroso, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/d4f8d679e7ad040ecf1cfaf884b2af10/foto-1-de-apartamento-com-3-quartos-a-venda-64m-em-barroso-fortaleza.webp",
               titulo:"Apartamento com 3 Quartos à Venda, 64m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:64,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8208172,
                  lng:-38.5054765
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-couto-fernandes-bairros-fortaleza-45m2-venda-RS170000-id-2704510558/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Oscar Bezerra, 270 - Couto Fernandes, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/f84ed064f641b516ddf93dac3e0ee301/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-couto-fernandes-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.757509,
                  lng:-38.5593168
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-rachel-de-queiroz-bairros-fortaleza-com-garagem-50m2-venda-RS170000-id-2749783923/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Avenida Bernardo Manuel, 8666 - Rachel de Queiroz, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7953002,
                  lng:-38.5505934
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-lagoa-redonda-bairros-fortaleza-com-garagem-60m2-venda-RS170000-id-2513390737/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Osvaldo Cruz, 620 - Lagoa Redonda, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa de condomínio com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7311402,
                  lng:-38.4989483
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS170000-id-2750232500/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rodovia Quarto Anel Viário, 69 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.853393399999999,
                  lng:-38.5591501
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-parquelandia-bairros-fortaleza-com-garagem-71m2-venda-RS170000-id-2715562514/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Bezerra de Menezes, 785 - Parquelândia, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 71m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:71,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7334717,
                  lng:-38.5517933
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-itaperi-bairros-fortaleza-com-garagem-63m2-venda-RS170000-id-2722498457/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Doutor Manuel Teófilo, 382 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 63m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:63,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7831553,
                  lng:-38.5612422
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-bela-vista-bairros-fortaleza-com-garagem-50m2-venda-RS170000-id-2753478931/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Avenida Alberto Craveiro, 100 - Bela Vista, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7854741,
                  lng:-38.51683939999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-de-condominio-2-quartos-ancuri-bairros-fortaleza-com-garagem-46m2-venda-RS170000-id-2730953914/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua 3, 359 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8695634,
                  lng:-38.5188252
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-58m2-venda-RS170000-id-2755968628/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rodovia Quarto Anel Viário, 368 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 58m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:58,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8075595,
                  lng:-38.638845
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS170000-id-2758767615/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rodovia Quarto Anel Viário, 425 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.807908,
                  lng:-38.6379398
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cambeba-bairros-fortaleza-58m2-venda-RS170000-id-2661658360/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Ciro Monteiro, 249 - Cambeba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 58m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:58,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8024469,
                  lng:-38.4810208
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-planalto-ayrton-senna-bairros-fortaleza-com-garagem-45m2-venda-RS170000-id-2722049250/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Artur de Sousa, 2011 - Planalto Ayrton Senna, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8241343,
                  lng:-38.5780593
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-jangurussu-bairros-fortaleza-com-garagem-80m2-venda-RS170000-id-2693154328/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua 426 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.760584,
                  lng:-38.6051627
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-mondubim-bairros-fortaleza-com-garagem-153m2-venda-RS170000-id-2757039220/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua São Francisco, 78 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 153m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:153,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8187812,
                  lng:-38.585804
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-jardim-america-bairros-fortaleza-com-garagem-64m2-venda-RS170000-id-2731467513/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Júlio César, 1620 - Jardim América, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 64m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:64,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7558147,
                  lng:-38.5479421
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-parquelandia-bairros-fortaleza-com-garagem-73m2-venda-RS170000-id-2695454834/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Avenida Humberto Monte, 1789 - Parquelândia, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 73m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:73,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7453096,
                  lng:-38.5638751
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-51m2-venda-RS170000-id-2682317694/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Antônio Luís Almeida Vale, 100 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 51m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:51,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.820155499999999,
                  lng:-38.5619096
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-ii-bairros-fortaleza-com-garagem-52m2-venda-RS170000-id-2758900322/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Coronel Francisco Montenegro, 1234 - Praia do Futuro II, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7556095,
                  lng:-38.447676
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-49m2-venda-RS170000-id-2719391134/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Antônio Divino, 325 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8052309,
                  lng:-38.5618494
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-planalto-ayrton-senna-bairros-fortaleza-com-garagem-44m2-venda-RS170000-id-2751181602/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Planalto Ayrton Senna, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8283473,
                  lng:-38.5715343
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-44m2-venda-RS170000-id-2750166798/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Vila Três Américas - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8118712,
                  lng:-38.5746698
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-monte-castelo-bairros-fortaleza-com-garagem-60m2-venda-RS170000-id-2752992026/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Viana de Carvalho, 180 - Monte Castelo, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7236218,
                  lng:-38.5547287
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-messejana-bairros-fortaleza-com-garagem-50m2-venda-RS170000-id-2726672381/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Miracema, 690 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8368006,
                  lng:-38.5011746
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-mondubim-bairros-fortaleza-com-garagem-153m2-venda-RS170000-id-2754460204/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua São Francisco, 78 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 153m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:153,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8187812,
                  lng:-38.585804
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-com-garagem-44m2-venda-RS170000-id-2751190162/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Benjamim Brasil, 1100 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8058382,
                  lng:-38.5643756
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-44m2-venda-RS170000-id-2739621127/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Alencar Oliveira, 470 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8146422,
                  lng:-38.5200259
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS170000-id-2749524808/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rodovia Quarto Anel Viário, 47 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8533984,
                  lng:-38.5591379
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-praia-do-futuro-ii-bairros-fortaleza-com-garagem-52m2-venda-RS170000-id-2758897828/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Coronel Francisco Montenegro, 5 - Praia do Futuro II, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 52m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:52,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7555494,
                  lng:-38.4475487
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-sapiranga-bairros-fortaleza-50m2-venda-RS170000-id-2714763595/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rua Coronel Olegário Memória, 1362 - Sapiranga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7908624,
                  lng:-38.4679996
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-56m2-venda-RS170000-id-2743797768/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Rodovia Quarto Anel Viário, 85 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8533898,
                  lng:-38.559159
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-itaperi-bairros-fortaleza-com-garagem-72m2-venda-RS170000-id-2753974398/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Doutor Manuel Teófilo, 995 - Itaperi, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 72m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:72,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7868361,
                  lng:-38.562494
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-cajazeiras-bairros-fortaleza-44m2-venda-RS170000-id-2751183840/",
               valorVenda:"170000.00",
               valorOriginal:"170000.00",
               endereco:"Avenida Geógrafo Antônio Ribeiro Zaranza - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7990971,
                  lng:-38.5125899
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-85m2-venda-RS170004-id-2659501562/",
               valorVenda:"170004.00",
               valorOriginal:"170004.00",
               endereco:"Avenida Dionísio Leonel Alencar, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 85m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:85,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8884323,
                  lng:-38.5449349
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-jangurussu-bairros-fortaleza-99m2-venda-RS170162-id-2763275119/",
               valorVenda:"170162.00",
               valorOriginal:"170162.00",
               endereco:"Rua Antônio Alves Ribeiro, 220 - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/f01b2bc8791debc8a6548e3bdab6c190/foto-1-de-casa-com-3-quartos-a-venda-99m-em-jangurussu-fortaleza.webp",
               titulo:"Casa com 3 Quartos à Venda, 99m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:99,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8327464,
                  lng:-38.510416
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-54m2-venda-RS170500-id-2572453684/",
               valorVenda:"170500.00",
               valorOriginal:"170500.00",
               endereco:"Rua Coronel de Queiroz - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/1bfb1393b292bcaa9b5c29023a986616/foto-1-de-apartamento-com-2-quartos-a-venda-54m-em-prefeito-jose-walter-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8353447,
                  lng:-38.5643351
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS172000-id-2758252114/",
               valorVenda:"172000.00",
               valorOriginal:"172000.00",
               endereco:"Rua Gerardo Magela Soares Frota, 155 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/d9a587fb8a39ba2de29850bde3b234f3/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-messejana-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8396124,
                  lng:-38.4994698
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS172000-id-2760521771/",
               valorVenda:"172000.00",
               valorOriginal:"172000.00",
               endereco:"Rua Gerardo Magela Soares Frota, 142 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/d1292c57aaa79d89846edfb2fad7190d/foto-1-de-apartamento-com-2-quartos-a-venda-45m-em-messejana-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8396124,
                  lng:-38.4994698
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS172000-id-2753812693/",
               valorVenda:"172000.00",
               valorOriginal:"172000.00",
               endereco:"Rua Gerardo Magela Soares Frota, 152 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8396124,
                  lng:-38.4994698
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS172000-id-2756458261/",
               valorVenda:"172000.00",
               valorOriginal:"172000.00",
               endereco:"Rua Gerardo Magela Soares Frota, 120 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8396124,
                  lng:-38.4994698
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS172000-id-2758858530/",
               valorVenda:"172000.00",
               valorOriginal:"172000.00",
               endereco:"Rua Barão Aquiraz, 152 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8324735,
                  lng:-38.4901082
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS172000-id-2763210717/",
               valorVenda:"172000.00",
               valorOriginal:"172000.00",
               endereco:"Rua Gerardo Magela Soares Frota, 153 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8396124,
                  lng:-38.4994698
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS172000-id-2757190817/",
               valorVenda:"172000.00",
               valorOriginal:"172000.00",
               endereco:"Rua Gerardo Magela Soares Frota, 152 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8396124,
                  lng:-38.4994698
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS172000-id-2755560713/",
               valorVenda:"172000.00",
               valorOriginal:"172000.00",
               endereco:"Rua Gerardo Magela Soares Frota, 140 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8396124,
                  lng:-38.4994698
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS172000-id-2762033106/",
               valorVenda:"172000.00",
               valorOriginal:"172000.00",
               endereco:"Rua Gerardo Magela Soares Frota, 152 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8396124,
                  lng:-38.4994698
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-siqueira-bairros-fortaleza-41m2-venda-RS172000-id-2700080382/",
               valorVenda:"172000.00",
               valorOriginal:"172000.00",
               endereco:"Avenida Maciel Bezerra, 2714 - Siqueira, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 41m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:41,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8143052,
                  lng:-38.6315745
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-90m2-venda-RS173000-id-2746433057/",
               valorVenda:"173000.00",
               valorOriginal:"173000.00",
               endereco:"Rua Quartzo, 213 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.887983,
                  lng:-38.520667
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-47m2-venda-RS173000-id-2762678018/",
               valorVenda:"173000.00",
               valorOriginal:"173000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 365 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8882451,
                  lng:-38.5448822
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-173000m2-venda-RS173000-id-2750759709/",
               valorVenda:"173000.00",
               valorOriginal:"173000.00",
               endereco:"Rua Quartzo, 70 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 173000m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:173000,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8886574,
                  lng:-38.5195183
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS173000-id-2750232150/",
               valorVenda:"173000.00",
               valorOriginal:"173000.00",
               endereco:"Rodovia Quarto Anel Viário, 68 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.853393699999999,
                  lng:-38.5591496
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS173000-id-2752993560/",
               valorVenda:"173000.00",
               valorOriginal:"173000.00",
               endereco:"Rodovia Quarto Anel Viário, 207 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.805652,
                  lng:-38.6393191
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-47m2-venda-RS173000-id-2757166130/",
               valorVenda:"173000.00",
               valorOriginal:"173000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 389 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 47m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:47,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.888066299999999,
                  lng:-38.5448647
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-89m2-venda-RS173000-id-2747352516/",
               valorVenda:"173000.00",
               valorOriginal:"173000.00",
               endereco:"Rua Quartzo, 13 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 89m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:89,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8891673,
                  lng:-38.5193405
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS173000-id-2750760502/",
               valorVenda:"173000.00",
               valorOriginal:"173000.00",
               endereco:"Rua Quartzo, 71 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8888698,
                  lng:-38.5196972
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-64m2-venda-RS174900-id-2762833229/",
               valorVenda:"174900.00",
               valorOriginal:"174900.00",
               endereco:"Rodovia Quarto Anel Viário, 478 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 64m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:64,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8083004,
                  lng:-38.6381759
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-ancuri-bairros-fortaleza-com-garagem-64m2-venda-RS174900-id-2755524209/",
               valorVenda:"174900.00",
               valorOriginal:"174900.00",
               endereco:"Rodovia Quarto Anel Viário, 359 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 64m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:64,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8074196,
                  lng:-38.6382078
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-64m2-venda-RS174900-id-2755970547/",
               valorVenda:"174900.00",
               valorOriginal:"174900.00",
               endereco:"Rodovia Quarto Anel Viário, 369 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 64m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:64,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8074936,
                  lng:-38.6381672
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS175000-id-2725490237/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua José Cavalcante Sobrinho, 120 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8358563,
                  lng:-38.4877176
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-55m2-venda-RS175000-id-2720605843/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rodovia Quarto Anel Viário, 12 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8198619,
                  lng:-38.631029
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-50m2-venda-RS175000-id-2749806101/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Frei Cirilo, 150 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8129078,
                  lng:-38.4994823
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-46m2-venda-RS175000-id-2747970611/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-48m2-venda-RS175000-id-2562783099/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua José Cavalcante Sobrinho, 120 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8358563,
                  lng:-38.4877176
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-paupina-bairros-fortaleza-com-garagem-44m2-venda-RS175000-id-2743409862/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Paupina, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8514253,
                  lng:-38.4897925
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-84m2-venda-RS175000-id-2725436033/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Quartzo, 38 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 84m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:84,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889354,
                  lng:-38.5193431
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-45m2-venda-RS175000-id-2754509329/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-45m2-venda-RS175000-id-2747698818/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS175000-id-2750241220/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 69 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8885085,
                  lng:-38.54496820000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-44m2-venda-RS175000-id-2745140147/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 98 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.795312,
                  lng:-38.5182769
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-damas-bairros-fortaleza-com-garagem-70m2-venda-RS175000-id-2612848890/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida João Pessoa, 5061 - Damas, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7563719,
                  lng:-38.5527957
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-messejana-bairros-fortaleza-com-garagem-50m2-venda-RS175000-id-2731675521/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Murará, 101 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8439561,
                  lng:-38.4930956
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-alvaro-weyne-bairros-fortaleza-com-garagem-44m2-venda-RS175000-id-2740082627/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Dona Mendinha, 1025 - Álvaro Weyne, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/7f903398d64e792831d23af7c7a7547b/foto-1-de-apartamento-com-2-quartos-a-venda-44m-em-alvaro-weyne-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7117278,
                  lng:-38.5708085
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-prefeito-jose-walter-bairros-fortaleza-com-garagem-80m2-venda-RS175000-id-2756620222/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua C, 871 - Prefeito José Walter, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/35d1dbfd91c07bbd5bbb49ba9db2dedb/foto-1-de-casa-com-2-quartos-a-venda-80m-em-prefeito-jose-walter-fortaleza.webp",
               titulo:"Casa com 2 Quartos à Venda, 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.835966199999999,
                  lng:-38.5516673
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-89m2-venda-RS175000-id-2750761299/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Quartzo, 72 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/c0d16bfa431502bcfa3b6b949b34371d/foto-1-de-casa-com-2-quartos-a-venda-89m-em-pedras-fortaleza.webp",
               titulo:"Casa com 2 Quartos à Venda, 89m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:89,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8886523,
                  lng:-38.5195244
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-48m2-venda-RS175000-id-2746977493/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/5937f05b2fd4c043ff68641c0ff6f502/foto-1-de-apartamento-com-2-quartos-a-venda-48m-em-boa-vista-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-pedras-bairros-fortaleza-com-garagem-54m2-venda-RS175000-id-2732317720/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Quartzo, 1 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8891673,
                  lng:-38.5193405
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS175000-id-2746924683/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 248 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8883519,
                  lng:-38.5449081
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-pedras-bairros-fortaleza-com-garagem-54m2-venda-RS175000-id-2736722843/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Quartzo, 1 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8891673,
                  lng:-38.5193405
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS175000-id-2750761434/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Quartzo, 75 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.888813799999999,
                  lng:-38.5196989
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-45m2-venda-RS175000-id-2747184425/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-89m2-venda-RS175000-id-2762837540/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Quartzo, 481 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 89m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:89,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8873616,
                  lng:-38.52139469999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-37m2-venda-RS175000-id-2751183841/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Vereadora Zélia Correia de Sousa - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 37m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:37,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8155992,
                  lng:-38.5580367
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS175000-id-2761670528/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 365 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8882451,
                  lng:-38.5448822
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-45m2-venda-RS175000-id-2757860062/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-56m2-venda-RS175000-id-2750240493/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rodovia Quarto Anel Viário, 69 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.853393399999999,
                  lng:-38.5591501
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-154m2-venda-RS175000-id-2756227813/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Quartzo, 3368 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 154m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:154,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8879534,
                  lng:-38.5205027
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-pedras-bairros-fortaleza-com-garagem-84m2-venda-RS175000-id-2612991823/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Luís Monteiro, 2255 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 84m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:84,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8866441,
                  lng:-38.5076822
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-80m2-venda-RS175000-id-2748458145/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Quartzo, 39 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.888995099999999,
                  lng:-38.5194337
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-novo-mondubim-bairros-fortaleza-com-garagem-49m2-venda-RS175000-id-2648019071/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Alfredo Mamede, 550 - Novo Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 49m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:49,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8102309,
                  lng:-38.5819101
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-59m2-venda-RS175000-id-2748450807/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rodovia Quarto Anel Viário, 39 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 59m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:59,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8534002,
                  lng:-38.5591334
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-50m2-venda-RS175000-id-2745217203/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-com-garagem-55m2-venda-RS175000-id-2750783625/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Maria Gomes de Sá, 970 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8269099,
                  lng:-38.5797632
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-56m2-venda-RS175000-id-2755983555/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rodovia Quarto Anel Viário, 372 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8075905,
                  lng:-38.6388282
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-44m2-venda-RS175000-id-2750365895/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Itacira - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8064418,
                  lng:-38.5638506
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-montese-bairros-fortaleza-com-garagem-45m2-venda-RS175000-id-2762657174/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Romeu Martins, 481 - Montese, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7649671,
                  lng:-38.55434770000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-55m2-venda-RS175000-id-2748290815/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Travessa Elisiário Mendes, 151 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8162884,
                  lng:-38.4944804
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-50m2-venda-RS175000-id-2746216098/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-42m2-venda-RS175000-id-2749860216/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua José Hipólito, 95 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8325902,
                  lng:-38.49385669999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-56m2-venda-RS175000-id-2746461605/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Dionísio Leonel Alencar, 221 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8883766,
                  lng:-38.5449141
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-vicente-pinzon-bairros-fortaleza-com-garagem-89m2-venda-RS175000-id-2734054447/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Visconde de Cairu - Vicente Pinzon, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 89m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:89,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7265633,
                  lng:-38.4641615
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-jangurussu-bairros-fortaleza-com-garagem-74m2-venda-RS175000-id-2730818829/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua São João do Jangurussu - Jangurussu, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 74m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:74,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8437904,
                  lng:-38.5049616
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-90m2-venda-RS175000-id-2762835333/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Quartzo, 480 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8871311,
                  lng:-38.521323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-mondubim-bairros-fortaleza-com-garagem-63m2-venda-RS175000-id-2742420263/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Professor Manduca, 553 - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 63m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:63,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8043033,
                  lng:-38.562492
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-59m2-venda-RS175000-id-2750761138/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rodovia Quarto Anel Viário, 75 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 59m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:59,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8533921,
                  lng:-38.5591534
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-70m2-venda-RS175000-id-2750233182/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Quartzo, 68 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 70m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:70,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8886624,
                  lng:-38.5195122
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-48m2-venda-RS175000-id-2746998117/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-pedras-bairros-fortaleza-com-garagem-54m2-venda-RS175000-id-2731302117/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Quartzo, 3 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8891673,
                  lng:-38.5193405
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-59m2-venda-RS175000-id-2748450807/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rodovia Quarto Anel Viário, 39 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/f904e1bede573fcac76dffa5c7278384/foto-1-de-apartamento-com-2-quartos-a-venda-59m-em-ancuri-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 59m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:59,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8534002,
                  lng:-38.5591334
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-59m2-venda-RS175000-id-2750761138/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rodovia Quarto Anel Viário, 75 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/6cf9452e3ee6db9d87b537031dccf96e/foto-1-de-apartamento-com-2-quartos-a-venda-59m-em-ancuri-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 59m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:59,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8533921,
                  lng:-38.5591534
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-barra-do-ceara-bairros-fortaleza-com-garagem-54m2-venda-RS175000-id-2755805857/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida 20 de Janeiro, 3200 - Barra do Ceará, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/23119df313e480ee7b0d556d8ffa6269/foto-1-de-apartamento-com-2-quartos-a-venda-54m-em-barra-do-ceara-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 54m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:54,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7019885,
                  lng:-38.5894343
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-guajeru-bairros-fortaleza-com-garagem-50m2-venda-RS175000-id-2655651921/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Guajerú, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/df92599892d1d692ce6c8cafec6871a4/foto-1-de-apartamento-com-2-quartos-a-venda-50m-em-guajeru-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.831720299999999,
                  lng:-38.4788486
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-montese-bairros-fortaleza-com-garagem-45m2-venda-RS175000-id-2762657174/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Romeu Martins, 481 - Montese, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7649671,
                  lng:-38.55434770000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-maraponga-bairros-fortaleza-44m2-venda-RS175000-id-2750365895/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Itacira - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8064418,
                  lng:-38.5638506
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-55m2-venda-RS175000-id-2748290815/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Travessa Elisiário Mendes, 151 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8162884,
                  lng:-38.4944804
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-41m2-venda-RS175000-id-2711586694/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 41m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:41,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-dias-macedo-bairros-fortaleza-com-garagem-45m2-venda-RS175000-id-2743704270/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua 1, 1 - Dias Macedo, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7873628,
                  lng:-38.527183
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-48m2-venda-RS175000-id-2562783099/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua José Cavalcante Sobrinho, 120 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8358563,
                  lng:-38.4877176
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-45m2-venda-RS175000-id-2746483304/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-89m2-venda-RS175000-id-2751841690/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Quartzo, 95 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 89m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:89,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8887642,
                  lng:-38.5198257
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-59m2-venda-RS175000-id-2750240818/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rodovia Quarto Anel Viário, 69 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 59m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:59,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.853393399999999,
                  lng:-38.5591501
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-pedras-bairros-fortaleza-com-garagem-84m2-venda-RS175000-id-2616170601/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Luís Monteiro, 255 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 84m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:84,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8866441,
                  lng:-38.5076822
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-castelao-bairros-fortaleza-com-garagem-45m2-venda-RS175000-id-2759996750/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - Boa Vista Castelao, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-4-quartos-maraponga-bairros-fortaleza-com-garagem-83m2-venda-RS175000-id-2694807620/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Milton Barbosa de Sousa, 215 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 4 Quartos à Venda, 83m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:83,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7854594,
                  lng:-38.5674302
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-castelao-bairros-fortaleza-com-garagem-41m2-venda-RS175000-id-2736132655/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - Castelão, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 41m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:41,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-48m2-venda-RS175000-id-2749839707/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-48m2-venda-RS175000-id-2750254693/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Frei Cirilo, 150 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8129078,
                  lng:-38.4994823
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-ancuri-bairros-fortaleza-com-garagem-84m2-venda-RS175000-id-2616486664/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rodovia Quarto Anel Viário, 255 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 84m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:84,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8060031,
                  lng:-38.6386177
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-48m2-venda-RS175000-id-2748688404/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-45m2-venda-RS175000-id-2752295096/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-passare-bairros-fortaleza-com-garagem-48m2-venda-RS175000-id-2756861416/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida dos Paroaras, 115 - Passaré, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8125437,
                  lng:-38.53651869999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-82m2-venda-RS175000-id-2668797997/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Quartzo, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 82m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:82,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889129,
                  lng:-38.5193213
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-82m2-venda-RS175000-id-2668063402/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Quartzo, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 82m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:82,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889129,
                  lng:-38.5193213
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-maraponga-bairros-fortaleza-com-garagem-84m2-venda-RS175000-id-2687360715/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Capitão Américo Menezes, 277 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 84m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:84,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7821133,
                  lng:-38.5678687
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-82m2-venda-RS175000-id-2665292412/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Quartzo, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 82m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:82,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889129,
                  lng:-38.5193213
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-messejana-bairros-fortaleza-com-garagem-60m2-venda-RS175000-id-2717542536/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8327352,
                  lng:-38.4940863
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-45m2-venda-RS175000-id-2748362497/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua General Romel - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7991291,
                  lng:-38.5171116
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-montese-bairros-fortaleza-com-garagem-60m2-venda-RS175000-id-2758550423/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Romeu Martins, 481 - Montese, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 60m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:60,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7649671,
                  lng:-38.55434770000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-45m2-venda-RS175000-id-2725495469/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua José Cavalcante Sobrinho, 120 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8358563,
                  lng:-38.4877176
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS175000-id-2721709406/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Jorge Figueiredo, 80 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.897723,
                  lng:-38.514242
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-castelao-bairros-fortaleza-com-garagem-45m2-venda-RS175000-id-2761978531/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - Boa Vista Castelao, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS175000-id-2748448551/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Quartzo, 48 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.88879,
                  lng:-38.5193593
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-48m2-venda-RS175000-id-2746986919/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS175000-id-2746482708/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Quartzo, 223 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8879476,
                  lng:-38.5207077
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-80m2-venda-RS175000-id-2746455041/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Quartzo, 217 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/286x200/named.images.sp/aa273b58aeeeb4f0d519b5a2727e66ec/foto-1-de-casa-com-2-quartos-a-venda-80m-em-pedras-fortaleza.webp",
               titulo:"Casa com 2 Quartos à Venda, 80m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:80,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8879602,
                  lng:-38.5206933
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-59m2-venda-RS175000-id-2744405310/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rodovia Quarto Anel Viário, 97 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/3499df38499dd28097e9e58fc121a299/foto-1-de-apartamento-com-2-quartos-a-venda-59m-em-ancuri-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 59m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:59,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8050139,
                  lng:-38.6396787
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-89m2-venda-RS175000-id-2750239040/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Quartzo, 69 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/961435548fabf4575552e926cc57fe62/foto-1-de-casa-com-2-quartos-a-venda-89m-em-pedras-fortaleza.webp",
               titulo:"Casa com 2 Quartos à Venda, 89m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:89,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8888492,
                  lng:-38.5196585
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-42m2-venda-RS175000-id-2688170836/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Central do Loteamento Parque Messejana, 100 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/resizedimgs.vivareal.com/crop/240x240/named.images.sp/970d1f857f182e165dbd3def6259d192/foto-1-de-apartamento-com-2-quartos-a-venda-42m-em-messejana-fortaleza.webp",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8462847,
                  lng:-38.4865106
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-82m2-venda-RS175000-id-2676300994/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Quartzo, 32 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 82m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:82,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8889129,
                  lng:-38.5193213
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-59m2-venda-RS175000-id-2755543902/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rodovia Quarto Anel Viário, 363 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 59m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:59,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8074492,
                  lng:-38.6381915
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-parangaba-bairros-fortaleza-com-garagem-45m2-venda-RS175000-id-2734127420/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Capitão Américo Menezes, 455 - Parangaba, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7816792,
                  lng:-38.5692359
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-48m2-venda-RS175000-id-2747165794/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-83m2-venda-RS175000-id-2725435437/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Quartzo, 37 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 83m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:83,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8890631,
                  lng:-38.5194654
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-56m2-venda-RS175000-id-2755541336/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Quartzo, 368 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8871311,
                  lng:-38.521323
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-mondubim-bairros-fortaleza-38m2-venda-RS175000-id-2752005520/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Ana Brito - Mondubim, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 38m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:38,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8308773,
                  lng:-38.5849279
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-50m2-venda-RS175000-id-2747405709/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Alberto Craveiro, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-42m2-venda-RS175000-id-2691865953/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Central do Loteamento Parque Messejana, 100 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 42m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:42,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8462847,
                  lng:-38.4865106
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-44m2-venda-RS175000-id-2745280199/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-praia-do-futuro-bairros-fortaleza-com-garagem-90m2-venda-RS175000-id-2710851534/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Pintor Antônio Bandeira, 3050 - Praia do Futuro, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 90m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:90,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.742377599999999,
                  lng:-38.4555371
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-79m2-venda-RS175000-id-2762835213/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Quartzo, 479 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 79m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:79,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8873616,
                  lng:-38.52139469999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-44m2-venda-RS175000-id-2745157280/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 44m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:44,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-45m2-venda-RS175000-id-2750209723/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8012212,
                  lng:-38.5200995
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-45m2-venda-RS175000-id-2753641975/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-50m2-venda-RS175000-id-2757239313/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Avenida Alberto Craveiro, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 50m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:50,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-45m2-venda-RS175000-id-2750799721/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-ancuri-bairros-fortaleza-com-garagem-56m2-venda-RS175000-id-2750762201/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rodovia Quarto Anel Viário, 73 - Ancuri, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 56m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:56,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8533925,
                  lng:-38.5591523
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-pedras-bairros-fortaleza-com-garagem-55m2-venda-RS175000-id-2755540731/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Quartzo, 355 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 55m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:55,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8873616,
                  lng:-38.52139469999999
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-3-quartos-pedras-bairros-fortaleza-com-garagem-84m2-venda-RS175000-id-2619939616/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Jorge Figueiredo, 255 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 3 Quartos à Venda, 84m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:84,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8965286,
                  lng:-38.5120233
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-maraponga-bairros-fortaleza-com-garagem-83m2-venda-RS175000-id-2703441442/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Capitão Américo Menezes, 277 - Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 83m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:83,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7821133,
                  lng:-38.5678687
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-maraponga-bairros-fortaleza-com-garagem-83m2-venda-RS175000-id-2752983331/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Maraponga, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 83m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:83,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.793095,
                  lng:-38.5659336
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-48m2-venda-RS175000-id-2749900618/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 111 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 48m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:48,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.795723,
                  lng:-38.5183682
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-aldeota-bairros-fortaleza-com-garagem-63m2-venda-RS175000-id-2719677934/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Nogueira Acioli, 1481 - Aldeota, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 63m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:63,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7366879,
                  lng:-38.5187809
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-10m2-venda-RS175000-id-2753032056/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1240 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 10m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:10,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7892032,
                  lng:-38.5177181
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/casa-2-quartos-pedras-bairros-fortaleza-com-garagem-154m2-venda-RS175000-id-2662577806/",
               valorVenda:"NaN",
               valorOriginal:"NaN",
               endereco:"Rua Quartzo, 1 - Pedras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Casa com 2 Quartos à Venda, 154m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:1,
               descricaoTipoImovel:"Casa",
               area:154,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8891673,
                  lng:-38.5193405
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cajazeiras-bairros-fortaleza-com-garagem-61m2-venda-RS175000-id-2645699674/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Tibúrcio Pereira, 340 - Cajazeiras, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 61m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:61,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7972332,
                  lng:-38.5100965
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-messejana-bairros-fortaleza-com-garagem-46m2-venda-RS175000-id-2580040502/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Professor Paulo Maria de Aragão, 3 - Messejana, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 46m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:46,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8338895,
                  lng:-38.483647
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-45m2-venda-RS175000-id-2747407008/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.8012212,
                  lng:-38.5200995
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-boa-vista-bairros-fortaleza-com-garagem-45m2-venda-RS175000-id-2758574200/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida Alberto Craveiro, 1210 - BOA VISTA, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7888187,
                  lng:-38.5177059
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-2-quartos-montese-bairros-fortaleza-com-garagem-45m2-venda-RS175000-id-2763596718/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Rua Romeu Martins, 481 - Montese, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 2 Quartos à Venda, 45m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:45,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7649671,
                  lng:-38.55434770000001
               }
            },
            {
               url:"https:/www.vivareal.com.br/imovel/apartamento-3-quartos-cidade-dos-funcionarios-bairros-fortaleza-com-garagem-65m2-venda-RS175000-id-2751750828/",
               valorVenda:"175000.00",
               valorOriginal:"175000.00",
               endereco:"Avenida José Leon, 1256 - Cidade dos Funcionários, Fortaleza - CE",
               imagemUrl:"https:/cdn1.vivareal.com/p/1-86d7563/v/static/app/img/pixel.png",
               titulo:"Apartamento com 3 Quartos à Venda, 65m²",
               estado:"CE",
               cidade:"Aquiraz",
               tipoNegocio:2,
               descricaoTipoNegocio:"Venda",
               tipoImovel:2,
               descricaoTipoImovel:"Apartamento",
               area:65,
               areaTerreno:0,
               vendido:0,
               location:{
                  lat:-3.7878687,
                  lng:-38.5032772
               }
            }
         
    ]
