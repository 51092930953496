export const locations = [
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-res-manna-3-quartos-1-vaga-na-garagem-varanda-sacada-area-de-servico-2-w-imovel-caixa-economica-federal-cef-1340387-8787700686731-venda-direta-caixa",
        valorVenda: "49532.59",
        valorOriginal: "92000.00",
        endereco: "RUA HERCULANO PENA,N. 101 APTO. 110 BL L, PARQUE PRESIDENTE VARGAS - CEP: 60765-525, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/87/apartamento-caixa-em-fortaleza-ce-1340387-imovel-1340387-7e53cb76e182b7a694f27bdf569d2222a0e578a5-m.webp",
        titulo: "Apartamento Caixa em Fortaleza / CE - 1340387",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8275418,
            lng: -38.5940218
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-siqueira-2-quartos-varanda-sacada-area-de-servico-2-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1298448-8444409546390-venda-direta-caixa",
        valorVenda: "54171.51",
        valorOriginal: "102000.00",
        endereco: "RUA MIRACEL (ANTES RUA SDO),N. 1664  QD 80, LT 03, LOT JARDIM JATOBA, SIQUEIRA - CEP: 60732-385, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/48/casa-caixa-em-fortaleza-ce-1298448-imovel-1298448-19b3b9d5215785b51747ba329edfcf57b7c029e6-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1298448",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8048956,
            lng: -38.6307423
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cond-res-manna-2-quartos-1-vaga-na-garagem-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1600164-8444406686581-venda-direta-caixa",
        valorVenda: "54702.60",
        valorOriginal: "103000.00",
        endereco: "RUA HERCULANO PENA N 101 APTO 103 BLOCO G,N. 101 APTO. 103 BL C, PARQUE PRESIDENTE VARGAS - CEP: 60765-525, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/64/apartamento-caixa-em-fortaleza-ce-1600164-imovel-1600164-5a0cb875b695e5e6f657d18944f1687ac6c90b3c-m.webp",
        titulo: "Apartamento Caixa em Fortaleza / CE - 1600164",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8275418,
            lng: -38.5940218
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-res-manna-2-quartos-1-vaga-na-garagem-2-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1605329-8444411850394-venda-direta-caixa",
        valorVenda: "56658.74",
        valorOriginal: "102000.00",
        endereco: "RUA  HERCULANO PENA,N. 101 APTO. 101 BL E, PARQUE PRESIDENTE VARGAS - CEP: 60765-525, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/29/apartamento-caixa-em-fortaleza-ce-1605329-imovel-1605329-3a2a2ebf36937f99bf2e06e47ef92d2be633d5cd-m.webp",
        titulo: "Apartamento Caixa em Fortaleza / CE - 1605329",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8275418,
            lng: -38.5940218
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cond-res-manna-2-quartos-1-vaga-na-garagem-imovel-caixa-economica-federal-cef-1352410-8555531860941-venda-direta-caixa",
        valorVenda: "56826.98",
        valorOriginal: "107000.00",
        endereco: "RUA HERCULANO PENA,N. 101 APTO. 107 BL G, CANINDEZINHO - CEP: 60734-350, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/10/apartamento-caixa-em-fortaleza-ce-1352410-imovel-1352410-6638f0eda955fc6899f87b362255b8bf25ec443b-m.webp",
        titulo: "Apartamento Caixa em Fortaleza / CE - 1352410",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8206053,
            lng: -38.60488640000001
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-siqueira-2-quartos-2-vagas-na-garagem-area-de-servico-2-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1440054-8444408598710-venda-direta-caixa",
        valorVenda: "61278.34",
        valorOriginal: "115000.00",
        endereco: "R NOVA,N. 1991  QUADRA 99, LOTE 06 (PARTE), SIQUEIRA - CEP: 60732-393, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/54/casa-caixa-em-fortaleza-ce-1440054-imovel-1440054-0e97ef94766e619bf46841782735569d28fb45e0-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1440054",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8032636,
            lng: -38.6301847
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-res-zeca-de-abreu-2-quartos-varanda-sacada-area-de-servico-2-wc-sala-coz-imovel-caixa-economica-federal-cef-984498-8444420707248-venda-direta-caixa",
        valorVenda: "66650.61",
        valorOriginal: "130000.00",
        endereco: "RUA JOAO GENTIL,N. 1723 APTO. 202, GRANJA LISBOA - CEP: 60540-050, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/98/apartamento-caixa-em-fortaleza-ce-984498-imovel-984498-661585f3e566f6a3f2b060b52379a74e36d65c9f-m.webp",
        titulo: "Apartamento Caixa em Fortaleza / CE - 984498",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7918258,
            lng: -38.620189
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-loteamento-granja-santa-cecilia-imovel-caixa-economica-federal-cef-1888587-8444405528842-venda-direta-caixa",
        valorVenda: "68787.97",
        valorOriginal: "120000.00",
        endereco: "RUA NOVA,N. 1937  QD 99, LTS 10,11 E 12, SIQUEIRA - CEP: 60732-393, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/87/casa-caixa-em-fortaleza-ce-1888587-imovel-1888587-a875e991109afe196b05c6a0b51d74b35b70127c-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1888587",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8032636,
            lng: -38.6301847
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-casa-64-58-m2-de-area-privativa-290-4m2-de-area-do-terreno-2-qts-1-vaga-de-garagem-iptu-803701-imovel-caixa-economica-federal-cef-2039852",
        valorVenda: "69361.21",
        valorOriginal: "121000.00",
        endereco: "RUA NOVA,N. 2005  QD 99 LT A, SIQUEIRA - CEP: 60732-393, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/52/casa-em-fortaleza-ce-2039852-imovel-2039852-767b3c31c92705d225d9c6b4593c3460cfe95348-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 2039852",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7656942,
            lng: -38.4629363
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-loteamento-terras-da-granja-santa-cec-2-quartos-1-vaga-na-garagem-imovel-caixa-economica-federal-cef-1934168-8444408673690-venda-direta-caixa",
        valorVenda: "69361.21",
        valorOriginal: "121000.00",
        endereco: "RUA NOVA,N. 2005  QD 99 LT A, SIQUEIRA - CEP: 60732-393, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/68/casa-caixa-em-fortaleza-ce-1934168-imovel-1934168-3e5697732c74defec83fbde496a6236092edcfe3-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 1934168",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7656942,
            lng: -38.4629363
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-canindezinho-2-quartos-1-vaga-na-garagem-2-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1809456-8444404681191-venda-direta-caixa",
        valorVenda: "71654.14",
        valorOriginal: "125000.00",
        endereco: "RUA SAFIRA,N. 137  ., CANINDEZINHO - CEP: 60732-505, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/56/casa-caixa-em-fortaleza-ce-1809456-imovel-1809456-be48806c3c239553abf3057ae7fd986d09003b1a-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1809456",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8082085,
            lng: -38.6234447
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-lot-bairro-novo-3-quartos-2-vagas-na-garagem-2-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1944714-8444408102374-venda-direta-caixa",
        valorVenda: "71789.15",
        valorOriginal: "120000.00",
        endereco: "RUA 3,N. 155  ., PEDRAS - CEP: 60878-045, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/14/casa-caixa-em-fortaleza-ce-1944714-imovel-1944714-21466f9d5d350037dcb74f12bc5eeeb0a101a5d6-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 1944714",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8699981,
            lng: -38.5205683
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-loteamento-ganja-cecilia-1-vaga-na-garagem-imovel-caixa-economica-federal-cef-1580032-8444405978325-venda-direta-caixa",
        valorVenda: "73807.94",
        valorOriginal: "137000.00",
        endereco: "RUA NOVA,N. 1941, SIQUEIRA - CEP: 60732-393, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/32/casa-caixa-em-fortaleza-ce-1580032-imovel-1580032-2fd3dc3e06b54312ed030da25449c1a5d0c4017c-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1580032",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8048171,
            lng: -38.6245081
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-siqueira-2-quartos-varanda-sacada-area-de-servico-2-wc-sala-cozinha-eq-imovel-caixa-economica-federal-cef-1376334-8444407302647-venda-direta-caixa",
        valorVenda: "74346.69",
        valorOriginal: "138000.00",
        endereco: "RUA TUCUNDUBA,N. 2517  LOT GRANJA CECILIA, SIQUEIRA - CEP: 60544-280, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/34/casa-caixa-em-fortaleza-ce-1376334-imovel-1376334-64e422e936d01c98c61d32e52e95c6bc9b3d5f74-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1376334",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7930942,
            lng: -38.6229405
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-condominio-residencial-thhhe-dreamer-2-quartos-1-vaga-na-garagem-wc-sala-imovel-caixa-economica-federal-cef-1551850-8444406808334-venda-direta-caixa",
        valorVenda: "76022.37",
        valorOriginal: "135000.00",
        endereco: "RUA R HUMBERTO LOMEU, N. 2538, 302,,N. 2538 APTO. 302 3º PAVIMENTO DO TIPO B, GRANJA LISBOA - CEP: 60540-492, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/50/apartamento-caixa-em-fortaleza-ce-1551850-imovel-1551850-7bca70674eeab44e4a70bcca0501b8f9abb16067-m.webp",
        titulo: "Apartamento Caixa em Fortaleza / CE - 1551850",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.778726,
            lng: -38.6135385
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-res-manna-2-quartos-1-vaga-na-garagem-area-de-servico-2-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1182654-8444416107150-venda-direta-caixa",
        valorVenda: "76813.88",
        valorOriginal: "141000.00",
        endereco: "RUA HERCULANO PENA,N. 101 APTO. 102 BL K, PARQUE PRESIDENTE VARGAS - CEP: 60765-525, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/54/apartamento-caixa-em-fortaleza-ce-1182654-imovel-1182654-172b725d64cf0f12b0bb2f3114dfb56bde5f6a84-m.webp",
        titulo: "Apartamento Caixa em Fortaleza / CE - 1182654",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8275418,
            lng: -38.5940218
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-residencial-coracao-de-jesus-2-quartos-1-vaga-na-garagem-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1680299-8444411939204-venda-direta-caixa",
        valorVenda: "78556.78",
        valorOriginal: "130000.00",
        endereco: "RUA DOM HELIO CAMPOS ANTIGA RUA DA FELICIDADE,N. 493 APTO. 405 BL B, CARLITO PAMPLONA - CEP: 60311-630, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/99/apartamento-caixa-em-fortaleza-ce-1680299-imovel-1680299-2123cd697cf5b62a4459e83a3414191d25b1d39e-m.webp",
        titulo: "Apartamento Caixa em Fortaleza / CE - 1680299",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7139106,
            lng: -38.555791
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cond-jd-passare-imovel-caixa-economica-federal-cef-1939541-8787707003940-venda-direta-caixa",
        valorVenda: "78839.07",
        valorOriginal: "136100.00",
        endereco: "RUA ALENCAR OLIVEIRA,N. 470 APTO. 201 BL 13, PASSARE - CEP: 60861-820, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/41/apartamento-caixa-em-fortaleza-ce-1939541-imovel-1939541-0337cd5c850c70c994c44270a9632bffc162b9bf-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1939541",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8146422,
            lng: -38.5200259
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-siqueira-imovel-caixa-economica-federal-cef-1143448-8003100019429-venda-direta-caixa",
        valorVenda: "79100.08",
        valorOriginal: "169000.00",
        endereco: "RUA WILSON PEIXOTO (ANTIGA RUA I),N. 325, SIQUEIRA - CEP: 60732-428, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/48/casa-caixa-em-fortaleza-ce-1143448-imovel-1143448-ac93c151f9af88b47c95081707459e0b76828ca1-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1143448",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8151556,
            lng: -38.6187292
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-lot-sitio-varjota-2-quartos-2-vagas-na-garagem-2-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1592883-8444402909667-venda-direta-caixa",
        valorVenda: "81172.11",
        valorOriginal: "149000.00",
        endereco: "RUA BRILHANTE,N. 331  LT 58 59 QD 22, CANINDEZINHO - CEP: 60732-465, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/83/casa-caixa-em-fortaleza-ce-1592883-imovel-1592883-97a25b1894f86fa91dd377d3bd60cce52105a2c0-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1592883",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8083315,
            lng: -38.6262823
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-apartamento-residencial-vila-uniao-2-dormitorio-s-imovel-1243696",
        valorVenda: "81297.50",
        valorOriginal: "135000.00",
        endereco: "Rua São Mateus,  n°1.920, Apartamento 101, Vila União, Fortaleza, CE, 60835-690",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/96/apartamento-em-fortaleza-ce-1243696-imovel-1243696-080e8800a5d137f670b724e803fac60c8663a22b-m.webp",
        titulo: "Apartamento em Fortaleza / CE - 1243696",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7575544,
            lng: -38.53665609999999
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cond-jd-passare-imovel-caixa-economica-federal-cef-1939542-8787708925590-venda-direta-caixa",
        valorVenda: "81964.82",
        valorOriginal: "140000.00",
        endereco: "RUA ALENCAR DE OLIVEIRA,N. 470 APTO. 201 BL 04, PASSARE - CEP: 60000-000, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/42/apartamento-caixa-em-fortaleza-ce-1939542-imovel-1939542-6e0be5e4175f69d71155b4846c37f09ceb588454-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1939542",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8146422,
            lng: -38.5200259
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-bom-jardim-2-quartos-1-vaga-na-garagem-2-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1874035-8444414574460-venda-direta-caixa",
        valorVenda: "81964.82",
        valorOriginal: "140000.00",
        endereco: "RUA  JOAO VICTOR,N. 2474  PT LT 16, QD 30, BOM JARDIM - CEP: 60546-210, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/35/casa-caixa-em-fortaleza-ce-1874035-imovel-1874035-5443bee428dc5e0da1076bde24822f403177ade1-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1874035",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7936435,
            lng: -38.6274924
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-jardim-passare-2-quartos-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1939540-8787709033102-venda-direta-caixa",
        valorVenda: "83721.21",
        valorOriginal: "143000.00",
        endereco: "RUA ALENCAR OLIVEIRA,N. 470 APTO. 302 BL 08, PASSARE - CEP: 60861-820, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/40/apartamento-caixa-em-fortaleza-ce-1939540-imovel-1939540-082b36caabf3a4b3eff1d84e3fbf3619595fe135-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1939540",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8146422,
            lng: -38.5200259
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-residencial-2-quartos-2-vagas-na-garagem-2-wc-sala-cozinha-arresto-imovel-caixa-economica-federal-cef-1551851-8444415787310-venda-direta-caixa",
        valorVenda: "84440.79",
        valorOriginal: "155000.00",
        endereco: "RUA R MEDELIM, N. 3487,N. 3487, GRANJA LISBOA - CEP: 60540-000, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/51/casa-caixa-em-fortaleza-ce-1551851-imovel-1551851-6fbd533b718cdda4634da5d6cc33de2f95f11115-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1551851",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7914865,
            lng: -38.6299547
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-residencial-sao-francisco-2-quartos-area-de-servico-wc-sala-cozinha-equ-imovel-caixa-economica-federal-cef-1252925-8444408203774-venda-direta-caixa",
        valorVenda: "85943.74",
        valorOriginal: "151000.00",
        endereco: "AVENIDA DIONISIO ALENCAR (ANT ESTRADA DO ANCURI),N. 1596 APTO. 203 BL D, PEDRAS - CEP: 60874-805, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/25/apartamento-caixa-em-fortaleza-ce-1252925-imovel-1252925-43992f33294186f599dc2a71ab5df570b9bf7051-m.webp",
        titulo: "Apartamento Caixa em Fortaleza / CE - 1252925",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8708699,
            lng: -38.5265602
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-siqueira-2-quartos-area-de-servico-wc-sala-cozinha-casa-geminada-imovel-caixa-economica-federal-cef-1422628-8444424595088-venda-direta-caixa",
        valorVenda: "86070.28",
        valorOriginal: "156000.00",
        endereco: "RUA ORLANDO DIAS,N. 230  BAIRRO GRANJA LISBOA, SIQUEIRA - CEP: 60544-420, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/28/casa-caixa-em-fortaleza-ce-1422628-imovel-1422628-c301ae5ec6aae873be6d9e0895a22c654901c6bc-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1422628",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.79827,
            lng: -38.6205893
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-granja-lisboa-3-quartos-2-wc-sala-cozinha-terraco-imovel-caixa-economica-federal-cef-1888588-8555512189326-venda-direta-caixa",
        valorVenda: "89739.50",
        valorOriginal: "147000.00",
        endereco: "RUA MANUEL BEZERRA DOS SANTOS ANTIGA RUA SANTOS DU,N. 101  ., GRANJA LISBOA - CEP: 60540-456, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/88/casa-caixa-em-fortaleza-ce-1888588-imovel-1888588-bca80093ea34516197b9366589b2f70e6e26b623-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1888588",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7900146,
            lng: -38.6123975
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cond-resedencial-adhara-camilo-2-quartos-1-vaga-na-garagem-2-wc-sala-coz-imovel-caixa-economica-federal-cef-1771839-1555523975109-venda-direta-caixa",
        valorVenda: "89739.50",
        valorOriginal: "147000.00",
        endereco: "AVENIDA I,N. 1661 APTO. 102 ., PREFEITO JOSE WALTER - CEP: 60750-080, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/39/apartamento-caixa-em-fortaleza-ce-1771839-imovel-1771839-c415819554b26852a9aadc7828939927199e7ac1-m.webp",
        titulo: "Apartamento Caixa em Fortaleza / CE - 1771839",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8389435,
            lng: -38.5546209
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-apartamento-em-fortaleza-ce-desconto-de-26-16-do-valor-de-avaliacao-no-2-leilao-imovel-caixa-economica-federal-cef-2036385",
        valorVenda: "90746.77",
        valorOriginal: "155000.00",
        endereco: "RUA CORONEL OLEGARIO MEMORIA N. 1362 Apto. 104 BL 07- VILA ALENCAR",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/85/apartamento-em-fortaleza-ce-2036385-imovel-2036385-a00f65eee081688e5577bbe5fc86f28606af44fb-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2036385",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7908624,
            lng: -38.4679996
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-vila-alencar-2-quartos-1-vaga-na-garagem-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1924154-8787710616469-venda-direta-caixa",
        valorVenda: "90746.77",
        valorOriginal: "155000.00",
        endereco: "RUA CORONEL OLEGARIO MEMORIA,N. 1362 APTO. 104 BL 07, SAPIRANGACOITE - CEP: 60833-045, FORTALEZA - C...",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/54/apartamento-caixa-em-fortaleza-ce-1924154-imovel-1924154-1f7321b6a0de4a83d4ec1e9c8e2b1a08d9e34a1e-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1924154",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7908624,
            lng: -38.4679996
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-lot-jd-jatoba-2-quartos-area-de-servico-3-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1716131-8444405892102-venda-direta-caixa",
        valorVenda: "93629.95",
        valorOriginal: "158000.00",
        endereco: "RUA MIRACEL,N. 1450  LT 13  QD 80, SIQUEIRA - CEP: 60732-385, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/31/casa-caixa-em-fortaleza-ce-1716131-imovel-1716131-b4c96f602d8abaea641d0d842c17961d462d786e-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1716131",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8049323,
            lng: -38.6304163
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-lot-sitio-vajota-1-vaga-na-garagem-area-de-servico-2-wc-sala-2-dce-cozi-imovel-caixa-economica-federal-cef-1939536-8444409449548-venda-direta-caixa",
        valorVenda: "94815.14",
        valorOriginal: "160000.00",
        endereco: "RUA QUARTZO ROSA,N. 207  LT 02 QD 12, SIQUEIRA - CEP: 60732-498, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/36/casa-caixa-em-fortaleza-ce-1939536-imovel-1939536-f977d36776eab47659715e9f1c3411f31c8c555c-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 1939536",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8055467,
            lng: -38.623802
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-res-sagrada-familia-2-quartos-1-vaga-na-garagem-area-de-servico-wc-sala-imovel-caixa-economica-federal-cef-2029157-8444405824735-venda-direta-caixa",
        valorVenda: "95000.00",
        valorOriginal: 0,
        endereco: "AVENIDA ALDENI PONTES,N. 156 APTO. 201 BL I, JANGURUSSU - CEP: 60000-000, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/57/apartamento-caixa-em-fortaleza-ce-2029157-imovel-2029157-f02e4d3f1668cff2bf8782250ba81d9b3e6f87d4-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2029157",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.818896,
            lng: -38.4773059
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/eusebio/residencial-tupuiu-3-quartos-1-vaga-na-garagem-2-wc-sala-cozinha-terraco-imovel-caixa-economica-federal-cef-1912586-8444411884035-venda-direta-caixa",
        valorVenda: "96000.33",
        valorOriginal: "162000.00",
        endereco: "RUA OLHO DAGUA,N. 188  CS 01, TUPUIU - CEP: 61776-220, EUSEBIO - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/86/casa-caixa-em-eusebio-ce-1912586-imovel-1912586-a2bfb666bb78cbe9734df702c620d20787b70ca3-m.webp",
        titulo: "Casa Caixa em Eusébio / CE - 1912586",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8692972,
            lng: -38.4332422
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-siqueira-2-quartos-1-vaga-na-garagem-varanda-sacada-area-de-servico-2-wc-imovel-caixa-economica-federal-cef-1352400-8444412969743-venda-direta-caixa",
        valorVenda: "96211.56",
        valorOriginal: "167000.00",
        endereco: "RUA ESMERALDA,N. 76  UNIDADE A, SIQUEIRA - CEP: 60732-475, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/00/casa-caixa-em-fortaleza-ce-1352400-imovel-1352400-13569d4bbce97641abce124b1b24f70acaf04c81-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1352400",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8059566,
            lng: -38.6241216
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-apartamento-em-fortaleza-ce-imovel-caixa-economica-federal-cef-2038706",
        valorVenda: "96592.92",
        valorOriginal: "163000.00",
        endereco: "RUA CENTRAL DO LOTEAMENTO PARQUE MESSEJANA N. 100 Apto. 202 QD 02 BL 06- COND GRAN VILLAGE MESSEJANA...",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/06/apartamento-em-fortaleza-ce-2038706-imovel-2038706-bb6049c70829214873741deae67ecc726b01cedd-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2038706",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8462847,
            lng: -38.4865106
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cond-gran-village-messejana-ii-imovel-caixa-economica-federal-cef-1924145-8787713002984-venda-direta-caixa",
        valorVenda: "96592.92",
        valorOriginal: "163000.00",
        endereco: "RUA CENTRAL DO LOTEAMENTO PARQUE MESSEJANA,N. 100 APTO. 202 QD 02 BL 06, PAUPINA - CEP: 60872-509, F...",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/45/apartamento-caixa-em-fortaleza-ce-1924145-imovel-1924145-1a92ba780e3a8b281032324d5dfd920022f305c2-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1924145",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8462847,
            lng: -38.4865106
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-loteamento-jardim-jatoba-2-quartos-1-vaga-na-garagem-2-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1606938-8444408941317-venda-direta-caixa",
        valorVenda: "98760.13",
        valorOriginal: "179000.00",
        endereco: "RUA MIRACEL,N. 1335, SIQUEIRA - CEP: 60732-385, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/38/casa-caixa-em-fortaleza-ce-1606938-imovel-1606938-ee9526759c4ef648492a0c8b87f697602c8faec7-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1606938",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.805511699999999,
            lng: -38.628991
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cond-gran-village-messejana-ii-imovel-caixa-economica-federal-cef-1939539-8787713232157-venda-direta-caixa",
        valorVenda: "98963.30",
        valorOriginal: "167000.00",
        endereco: "RUA CENTRAL,N. 100 APTO. 3 BL 01, PAUPINA - CEP: 60872-509, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/39/apartamento-caixa-em-fortaleza-ce-1939539-imovel-1939539-70c6f2866c2bd1fb8b5c0c66edc4438eb9102192-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1939539",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8095875,
            lng: -38.5125143
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-siqueira-2-quartos-varanda-sacada-2-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1389981-8444407988328-venda-direta-caixa",
        valorVenda: "99311.87",
        valorOriginal: "180000.00",
        endereco: "R MIRACEL,N. 1472  L, SIQUEIRA - CEP: 60732-385, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/81/casa-caixa-em-fortaleza-ce-1389981-imovel-1389981-ced8265db9a5c3b94bd63a24c15dc1a8b34c667f-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1389981",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8049727,
            lng: -38.6302149
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-parque-santa-maria-2-quartos-1-vaga-na-garagem-wc-wc-emp-sala-cozinha-imovel-caixa-economica-federal-cef-1680298-8444406003581-venda-direta-caixa",
        valorVenda: "100741.08",
        valorOriginal: "170000.00",
        endereco: "RUA PEDESTRE 13,N. 106  0, PARQUE SANTA MARIA - CEP: 60873-173, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/98/casa-caixa-em-fortaleza-ce-1680298-imovel-1680298-1dfc87028d3acefd937337286d104b68c0c18efb-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1680298",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8548712,
            lng: -38.5044934
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-siqueira-2-quartos-1-vaga-na-garagem-2-wc-sala-cozinha-terraco-imovel-caixa-economica-federal-cef-1812505-8444415498318-venda-direta-caixa",
        valorVenda: "101904.73",
        valorOriginal: "165000.00",
        endereco: "RUA  SAFIRA,N. 115  A, SIQUEIRA - CEP: 60732-505, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/05/casa-caixa-em-fortaleza-ce-1812505-imovel-1812505-de80517ed14fb723c26145f1237bd6385d73e84e-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1812505",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8081542,
            lng: -38.6237423
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-residencial-odilon-guimaraes-3-quartos-1-vaga-na-garagem-varanda-sacada-a-imovel-caixa-economica-federal-cef-1804550-1444406294383-venda-direta-caixa",
        valorVenda: "101904.73",
        valorOriginal: "165000.00",
        endereco: "AVENIDA ODILON GUIMARAES,N. 2249 APTO. 102 BLOCO B, CURIO - CEP: 60831-295, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/50/apartamento-caixa-em-fortaleza-ce-1804550-imovel-1804550-3bb722f8922a22dcc9a6a9f93df43b8c0411134e-m.webp",
        titulo: "Apartamento Caixa em Fortaleza / CE - 1804550",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8197936,
            lng: -38.4755064
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-apartamento-48-04-m2-de-area-privativa-2-qts-2-wcs-sl-cozinha-1-vaga-de-garagem-iptu-0-matri-imovel-caixa-economica-federal-cef-2039853",
        valorVenda: "102518.87",
        valorOriginal: "173000.00",
        endereco: "RUA TROPICAL,N. 20 APTO. 8 LT UNICO  QD 01, PAUPINA - CEP: 60872-510, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/53/apartamento-em-fortaleza-ce-2039853-imovel-2039853-a43779d04c33274a677e207aa3f56f8f8daa38e5-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2039853",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8455868,
            lng: -38.4858383
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-condominio-gran-village-messejana-2-quartos-1-vaga-na-garagem-2-wc-sala-imovel-caixa-economica-federal-cef-1934172-8787704372456-venda-direta-caixa",
        valorVenda: "102518.87",
        valorOriginal: "173000.00",
        endereco: "RUA TROPICAL,N. 20 APTO. 8 LT UNICO  QD 01, PAUPINA - CEP: 60872-510, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/72/apartamento-caixa-em-fortaleza-ce-1934172-imovel-1934172-60230ccb10b6ac27c932d0e0d68f14e46e666494-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1934172",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8455868,
            lng: -38.4858383
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-res-bela-vista-ii-3-quartos-1-vaga-na-garagem-wc-cozinha-imovel-caixa-economica-federal-cef-1754452-8444408758768-venda-direta-caixa",
        valorVenda: "104992.75",
        valorOriginal: "170000.00",
        endereco: "RUA FRANCISCO VILELA,N. 357 APTO. 101 TIPO A, BOA VISTA - CEP: 60861-140, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/52/apartamento-caixa-em-fortaleza-ce-1754452-imovel-1754452-3e4f01181d1738b2fec2b4c5c56c265d56013089-m.webp",
        titulo: "Apartamento Caixa em Fortaleza / CE - 1754452",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7981934,
            lng: -38.5159241
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/aquiraz/residencial-cond-vila-jatoba-02-2-quartos-1-vaga-na-garagem-varanda-sacada-wc-sala-imovel-caixa-economica-federal-cef-1660428-8555535488656-venda-direta-caixa",
        valorVenda: "105481.84",
        valorOriginal: "178000.00",
        endereco: "RUA ERNESTO BENTO DE FREITAS,N. 856  CS 13, PAV TERREO, DISTRITO DE ITAPERA - CEP: 61700-000, AQUIRAZ - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/28/casa-caixa-em-aquiraz-ce-1660428-imovel-1660428-8f0ef62671461ae592fce65733fa67acca23d71b-m.webp",
        titulo: "Casa Caixa em Aquiraz / CE - 1660428",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.9415066,
            lng: -38.3457499
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cond-residencial-catalunha-2-quartos-wc-sala-lavabo-cozinha-acesso-pela-imovel-caixa-economica-federal-cef-1680297-8555516692626-venda-direta-caixa",
        valorVenda: "106667.03",
        valorOriginal: "180000.00",
        endereco: "RUA DOUTOR PROCOPIO,N. 1380  UNID 01, ARACAPE - CEP: 60764-605, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/97/casa-caixa-em-fortaleza-ce-1680297-imovel-1680297-6b7a7cc580a17951913ffeb54eb987480cf36a5b-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1680297",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8258521,
            lng: -38.589985
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-res-paladium-2-quartos-1-vaga-na-garagem-area-de-servico-2-wc-sala-cozi-imovel-caixa-economica-federal-cef-1748399-8444406819735-venda-direta-caixa",
        valorVenda: "106845.56",
        valorOriginal: "173000.00",
        endereco: "RUA PEDRO AGUIAR,N. 333 APTO. 202 ., ITAPERI - CEP: 60714-330, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/99/apartamento-caixa-em-fortaleza-ce-1748399-imovel-1748399-e2b7136a0735a2baaf58d5e09f110078af7d2124-m.webp",
        titulo: "Apartamento Caixa em Fortaleza / CE - 1748399",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7931269,
            lng: -38.5444012
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-granja-lisboa-2-quartos-1-vaga-na-garagem-2-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1658179-8555517178730-venda-direta-caixa",
        valorVenda: "109315.98",
        valorOriginal: "177000.00",
        endereco: "RUA SOUSA CARVALHO,N. 3436  LT 21 QD 10, GRANJA LISBOA - CEP: 60540-462, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/79/casa-caixa-em-fortaleza-ce-1658179-imovel-1658179-3521e2db640b1420cc86c3d915d71ac3ed3d1e22-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1658179",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7768901,
            lng: -38.612243
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-apartamento-residencial-papicu-2-dormitorio-s-2-vaga-s-de-garagem-imovel-1566212",
        valorVenda: "109706.00",
        valorOriginal: "144000.00",
        endereco: "Rua Eduardo Saboia, nº 75, Apartamento 103, Residencial Ticiana, Papicu, Fortaleza, CE, 60175-145",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/12/apartamento-em-fortaleza-ce-1566212-imovel-1566212-5b1df6456b4f354f1952b84b82b1a3c5050e0a3e-m.webp",
        titulo: "Apartamento em Fortaleza / CE - 1566212",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.735418,
            lng: -38.48398890000001
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-casa-padrao-0-vaga-s-de-garagem-imovel-banco-santander-2065827",
        valorVenda: "110900.00",
        valorOriginal: "220000.00",
        endereco: "Joao Fonseca, 525, 1º Pavimento, Altos, Fortaleza, CE",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/property/leilaoimovel-imovel-sem-foto.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 2065827",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7874456,
            lng: -38.5231468
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-jangurussu-imovel-caixa-economica-federal-cef-1939551-8444401281400-venda-direta-caixa",
        valorVenda: "111212.08",
        valorOriginal: "184000.00",
        endereco: "RUA ANTONIO REIS,N. 321 A  ., JANGURUSSU - CEP: 60870-550, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/51/casa-caixa-em-fortaleza-ce-1939551-imovel-1939551-1a30f87444b22ca816f2644e8b4366afd482bd7d-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 1939551",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8454421,
            lng: -38.5130262
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-lot-parque-estrela-3-quartos-2-vagas-na-garagem-2-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1896672-8444410668720-venda-direta-caixa",
        valorVenda: "111786.40",
        valorOriginal: "181000.00",
        endereco: "RUA SAO CIPRIANO,N. 171  CS 05, PASSARE - CEP: 60861-780, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/72/casa-caixa-em-fortaleza-ce-1896672-imovel-1896672-255ded217739d88d3bd10300d7e8417041b46957-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1896672",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8114783,
            lng: -38.52091739999999
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-res-carlos-pinheiro-3-quartos-1-vaga-na-garagem-imovel-caixa-economica-federal-cef-1896675-1444400714461-venda-direta-caixa",
        valorVenda: "113021.61",
        valorOriginal: "183000.00",
        endereco: "RUA BARAO DE CANINDE,N. 630 APTO. 406 BL 1B, MONTESE - CEP: 60425-542, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/75/apartamento-caixa-em-fortaleza-ce-1896675-imovel-1896675-9d6e3610bd070a07e7d7bb89c4cf8b3ee72655c0-m.webp",
        titulo: "Apartamento Caixa em Fortaleza / CE - 1896675",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7629551,
            lng: -38.5530905
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-lagoa-redonda-2-quartos-2-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1990498-8444404718648-venda-direta-caixa",
        valorVenda: "113346.01",
        valorOriginal: "184000.00",
        endereco: "RUA DESEMBARGADOR PAULO DE TARSO TELES RAMOS,N. 635  CS A, LAGOA REDONDA - CEP: 60831-080, FORTALEZA...",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/98/casa-caixa-em-fortaleza-ce-1990498-imovel-1990498-6fae34d56ae12d0f4c8eb65e334942a8cb70718b-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 1990498",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8229161,
            lng: -38.4687282
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-res-sao-bento-2-quartos-1-vaga-na-garagem-varanda-sacada-area-de-servico-imovel-caixa-economica-federal-cef-1200059-8444400156769-venda-direta-caixa",
        valorVenda: "116030.74",
        valorOriginal: "206000.00",
        endereco: "RUA PAULO RIBEIRO,N. S/N  CS 19C, QD 02, LT 07 E 08, PAUPINA - CEP: 60872-450, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/59/casa-caixa-em-fortaleza-ce-1200059-imovel-1200059-51c82995b87b0adc3b476ec51363505d0cf04711-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1200059",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8519708,
            lng: -38.4847211
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cond-res-paco-de-messejana-2-quartos-2-vagas-na-garagem-2-wc-sala-cozin-imovel-caixa-economica-federal-cef-1939543-8444416905375-venda-direta-caixa",
        valorVenda: "121487.11",
        valorOriginal: "201000.00",
        endereco: "RUA PADRE PEDRO DE ALENCAR,N. 1662 APTO. 404 BL C, PARQUE SANTA MARIA - CEP: 60873-082, FORTALEZA - ...",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/43/apartamento-caixa-em-fortaleza-ce-1939543-imovel-1939543-591f497a2d38482a261d22afc5a921de69403d4c-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1939543",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8425132,
            lng: -38.4946428
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/aquiraz/residencial-casa-residencial-parque-da-prainha-3-dormitorio-s-2-vaga-s-de-garagem-imovel-1551713",
        valorVenda: "121690.00",
        valorOriginal: "160000.00",
        endereco: "Rua C, 55, Parque da Prainha, Aquiraz, CE, 61700-000",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/13/casa-em-aquiraz-ce-1551713-imovel-1551713-3631798d3d708954d21b1519f4a9342ae433dda8-m.webp",
        titulo: "Casa em Aquiraz / CE - 1551713",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.901564399999999,
            lng: -38.3495005
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-pq-olinda-3-quartos-1-vaga-na-garagem-3-wc-sala-lavabo-cozinha-imovel-caixa-economica-federal-cef-1644599-1444418908560-venda-direta-caixa",
        valorVenda: "123996.39",
        valorOriginal: "197000.00",
        endereco: "RUA JOAO FONSECA,N. 525  LT 11, QD 62, DIAS MACEDO - CEP: 60860-410, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/99/casa-caixa-em-fortaleza-ce-1644599-imovel-1644599-2b62286dc022a8dcb1ed51b810ab71f36bd5785d-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1644599",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7874456,
            lng: -38.5231468
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-apartamento-75-26-m2-de-area-privativa-3-qts-varanda-3-wcs-sl-cozinha-1-vaga-de-garagem-iptu-imovel-caixa-economica-federal-cef-2039854",
        valorVenda: "125934.83",
        valorOriginal: "250000.00",
        endereco: "RUA FRANCISCO GLICERIO N. 935 APTO. 101 BL A, B. MARAPONGA COND PALAZZO VERDI",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/54/apartamento-em-fortaleza-ce-2039854-imovel-2039854-03912480ac68db7245a3cf2ffae7b4b83949e8e4-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2039854",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7958431,
            lng: -38.57083069999999
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cond-palazzo-verdi-3-quartos-1-vaga-na-garagem-varanda-sacada-3-wc-sala-imovel-caixa-economica-federal-cef-1934174-1444403514366-venda-direta-caixa",
        valorVenda: "125934.83",
        valorOriginal: "250000.00",
        endereco: "RUA FRANCISCO GLICERIO,N. 935 APTO. 101 BL A, MARAPONGA - CEP: 60711-055, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/74/apartamento-caixa-em-fortaleza-ce-1934174-imovel-1934174-9aa1b33b13793e604945286fe0fdea026dadc5ee-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1934174",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7958431,
            lng: -38.57083069999999
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cond-germana-gurgel-2-quartos-1-vaga-na-garagem-2-wc-sala-cozinha-indis-imovel-caixa-economica-federal-cef-1708132-1444401805290-venda-direta-caixa",
        valorVenda: "129031.78",
        valorOriginal: "205000.00",
        endereco: "RUA PROFESSOR JOSE ARTHUR DE CARVALHO,N. 912  0, LAGOA REDONDA - CEP: 60831-370, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/32/casa-caixa-em-fortaleza-ce-1708132-imovel-1708132-430ba5d55cbc8475d1b5b521b8bc0be2b83c4bea-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1708132",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8272963,
            lng: -38.4700048
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-tatu-munde-3-quartos-3-wc-sala-cozinha-terraco-imovel-caixa-economica-federal-cef-1660491-1555520815011-venda-direta-caixa",
        valorVenda: "132178.89",
        valorOriginal: "204000.00",
        endereco: "RUA SARGENTO JOAO PINHEIRO,N. 540  QD 152, LT 24, GRANJA LISBOA - CEP: 60540-511, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/91/casa-caixa-em-fortaleza-ce-1660491-imovel-1660491-1dd26e43148c665adb5c6c5aae1b0f5c84ee3124-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1660491",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7801314,
            lng: -38.6222901
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-apartamento-na-vila-uniao-em-fortaleza-ce-imovel-2061132",
        valorVenda: "132505.96",
        valorOriginal: 0,
        endereco: "Avenida dos Expedicionários, 4885, Vila União, Fortaleza/CE",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/32/apartamento-em-fortaleza-ce-2061132-imovel-2061132-0e48a16e922beacbc1b382445be536bf78c1f97b-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2061132",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7620816,
            lng: -38.5401953
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-casa-a-venda-em-leilao-imovel-bradesco-2044969",
        valorVenda: "135000.00",
        valorOriginal: 0,
        endereco: "Rua Godofredo de Oliveira, 63A - Casa (Parte do Lote 14 da Quadra 09) - Mondubim - Fortaleza / CE",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/69/casa-em-fortaleza-ce-2044969-imovel-2044969-305dc7cfe85cdeedf0a62664bd82868779c5065c-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 2044969",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8104124,
            lng: -38.5588492
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-edson-queiroz-3-quartos-1-vaga-na-garagem-wc-sala-cozinha-imovel-caixa-economica-federal-cef-2057164-8444404159010-venda-direta-caixa",
        valorVenda: "136270.00",
        valorOriginal: 0,
        endereco: "RUA CIDADE ECOLOGICA,N. 420 APTO. 303 BL.07 ., EDSON QUEIROZ - CEP: 60812-450, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/64/apartamento-caixa-em-fortaleza-ce-2057164-imovel-2057164-656b5e1c4456afa803ff2ba59dbe496eab25f582-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2057164",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7744073,
            lng: -38.4601311
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-res-primavera-2-quartos-1-vaga-na-garagem-area-de-servico-wc-sala-cozin-imovel-caixa-economica-federal-cef-1862014-8444406987867-venda-direta-caixa",
        valorVenda: "136270.00",
        valorOriginal: 0,
        endereco: "AVENIDA JOSE LEON,N. 1078 APTO. 504 BL H, CIDADE DOS FUNCIONARIOS - CEP: 60822-676, FORTALEZA - CEAR...",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/14/apartamento-caixa-em-fortaleza-ce-1862014-imovel-1862014-52eec35ab2a8bda2d17c6e453bce066808d19858-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1862014",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.787842,
            lng: -38.5026262
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-res-solar-do-bosque-2-quartos-1-vaga-na-garagem-area-de-servico-wc-sala-imovel-caixa-economica-federal-cef-1862015-8444409322726-venda-direta-caixa",
        valorVenda: "136532.96",
        valorOriginal: 0,
        endereco: "AVENIDA DA SAUDADE,N. 3225 APTO. 304 BL 04, PASSARE - CEP: 60861-330, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/15/apartamento-caixa-em-fortaleza-ce-1862015-imovel-1862015-b499e3c96d59ef96cc7e7137471a1d44ad74607f-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1862015",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8152399,
            lng: -38.5352715
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-apartamento-60-9-m2-de-area-privativa-2-qts-2-wcs-sl-cozinha-1-vaga-de-garagem-iptu-7175671-imovel-caixa-economica-federal-cef-2039857",
        valorVenda: "138473.13",
        valorOriginal: "220000.00",
        endereco: "AVENIDA AUGUSTO DOS ANJOS,N. 312 APTO. 307 BL 01 TIPO D  VG 94, BONSUCESSO - CEP: 60542-164, FORTALE...",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/57/apartamento-em-fortaleza-ce-2039857-imovel-2039857-2b2a515a83fc50b2ba749aa148dc451c040adc8a-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2039857",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7716982,
            lng: -38.571923
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-encanto-parangaba-cond-clube-2-quartos-1-vaga-na-garagem-2-wc-sala-cozin-imovel-caixa-economica-federal-cef-1934178-1555525768488-venda-direta-caixa",
        valorVenda: "138473.13",
        valorOriginal: "220000.00",
        endereco: "AVENIDA AUGUSTO DOS ANJOS,N. 312 APTO. 307 BL 01 TIPO D  VG 94, BONSUCESSO - CEP: 60542-164, FORTALE...",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/78/apartamento-caixa-em-fortaleza-ce-1934178-imovel-1934178-c245f7ec8cc009277ef9f6d6e7e05e12723d5b82-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1934178",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7716982,
            lng: -38.571923
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-prefeito-jose-walter-2-quartos-1-vaga-na-garagem-2-wc-sala-lavabo-cozin-imovel-caixa-economica-federal-cef-1680300-8444406889245-venda-direta-caixa",
        valorVenda: "139619.52",
        valorOriginal: "231000.00",
        endereco: "RUA E,N. 421  LT 2425  QD 11, PREFEITO JOSE WALTER - CEP: 60751-510, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/00/casa-caixa-em-fortaleza-ce-1680300-imovel-1680300-48f6ea707ebe7acd3414bf8a8aabf8bbbfcadf4f-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1680300",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8314329,
            lng: -38.548813
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-pq-santa-filomena-2-quartos-2-vagas-na-garagem-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1924150-8444400887992-venda-direta-caixa",
        valorVenda: "141850.00",
        valorOriginal: 0,
        endereco: "RUA MORADA NOVA,N. 390 APTO. . LT 13 QD 43, JANGURUSSU - CEP: 60870-610, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/50/casa-caixa-em-fortaleza-ce-1924150-imovel-1924150-4a7c245d3d9dbeb29c86619d574bf8bf3c5e69f1-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 1924150",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8438948,
            lng: -38.510887
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-lot-sitio-varjota-quarto-1-vaga-na-garagem-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1939554-8555501637128-venda-direta-caixa",
        valorVenda: "142000.00",
        valorOriginal: 0,
        endereco: "RUA TOPAZIO,N. 40  LT 02 QD 22, CANINDEZINHO - CEP: 60732-492, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/54/casa-caixa-em-fortaleza-ce-1939554-imovel-1939554-2357777869cd3077956f40fa5e4de3b66751f294-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 1939554",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7186775,
            lng: -38.5568574
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-lot-planalto-mondubim-3-quartos-2-vagas-na-garagem-3-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1827586-1444408911398-venda-direta-caixa",
        valorVenda: "150406.17",
        valorOriginal: "236000.00",
        endereco: "RUA  IV,N. 181A  ., MONDUBIM - CEP: 60767-830, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/86/casa-caixa-em-fortaleza-ce-1827586-imovel-1827586-e846991d98e168a497ddb29d9f11ce966a90b721-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1827586",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8182332,
            lng: -38.58071229999999
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-solar-das-aguas-2-quartos-1-vaga-na-garagem-wc-sala-cozinha-imovel-caixa-economica-federal-cef-2046153-8787712137088-venda-direta-caixa",
        valorVenda: "150900.00",
        valorOriginal: 0,
        endereco: "AVENIDA GEOGRAFO ANTONIO RIBEIRO ZARANZA,N. 2598 APTO. 2 BL 11, CAJAZEIRAS - CEP: 60864-345, FORTALE...",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/53/apartamento-caixa-em-fortaleza-ce-2046153-imovel-2046153-b5b4a5a285a3034219a9811de1a6798c19355330-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2046153",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8082689,
            lng: -38.5132471
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-res-pq-donatello-2-quartos-wc-sala-cozinha-imovel-caixa-economica-federal-cef-2057153-8787709698643-venda-direta-caixa",
        valorVenda: "151626.39",
        valorOriginal: 0,
        endereco: "RUA C,N. 100 APTO. 203 TIPO B  BL 09  2 PAV, PAUPINA - CEP: 60873-567, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/53/apartamento-caixa-em-fortaleza-ce-2057153-imovel-2057153-420e0d2918f6b3b934889d9d644cd621324548af-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2057153",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8514253,
            lng: -38.4897925
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/eusebio/residencial-res-eco-way-2-quartos-1-vaga-na-garagem-wc-sala-cozinha-imovel-caixa-economica-federal-cef-2046156-8787709143021-venda-direta-caixa",
        valorVenda: "151874.76",
        valorOriginal: 0,
        endereco: "AVENIDA VERDE,N. 280 APTO. 504 BL 20, COACU - CEP: 61771-800, EUSEBIO - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/56/apartamento-caixa-em-eusebio-ce-2046156-imovel-2046156-9ef4040feb81299b7530fa03be69923b1d5caea5-m.webp",
        titulo: "Apartamento em Leilão em Eusébio / CE - 2046156",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.857820299999999,
            lng: -38.4613341
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-hesed-residence-3-quartos-wc-sala-2-cozinhas-imovel-caixa-economica-federal-cef-1812504-1444418186319-venda-direta-caixa",
        valorVenda: "154230.06",
        valorOriginal: "242000.00",
        endereco: "RUA GARDENIA,N. 603 APTO. 209 ., PAUPINA - CEP: 60872-675, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/04/apartamento-caixa-em-fortaleza-ce-1812504-imovel-1812504-c782f4bcad8685a45256e4b1b94228abd50af63e-m.webp",
        titulo: "Apartamento Caixa em Fortaleza / CE - 1812504",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8368985,
            lng: -38.4886579
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/eusebio/residencial-res-eco-way-2-quartos-1-vaga-na-garagem-wc-sala-cozinha-imovel-caixa-economica-federal-cef-2005055-8787710480656-venda-direta-caixa",
        valorVenda: "156116.44",
        valorOriginal: 0,
        endereco: "ALAMEDA VERDE,N. 280 APTO. 304 BL 29, COACU - CEP: 61771-800, EUSEBIO - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/55/apartamento-caixa-em-eusebio-ce-2005055-imovel-2005055-08d6007aad7be3d84c07422b04edca0eb1161fa0-m.webp",
        titulo: "Apartamento em Leilão em Eusébio / CE - 2005055",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8556747,
            lng: -38.47014660000001
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-granja-lisboa-3-quartos-varanda-sacada-area-de-servico-2-wc-sala-cozinh-imovel-caixa-economica-federal-cef-1434188-8444400564964-venda-direta-caixa",
        valorVenda: "157168.26",
        valorOriginal: "264000.00",
        endereco: "RUA LUMINOSA,N. 3672, GRANJA LISBOA - CEP: 60540-552, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/88/casa-caixa-em-fortaleza-ce-1434188-imovel-1434188-84e0b1d8ac34b8bf6c095d48a0d924169d782c1e-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1434188",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7804122,
            lng: -38.6212059
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-res-parque-da-vinci-1-vaga-na-garagem-imovel-caixa-economica-federal-cef-2057156-8787706823161-venda-direta-caixa",
        valorVenda: "157432.67",
        valorOriginal: 0,
        endereco: "RUA A,N. 30 APTO. 203 BL.27 .VG N.394, PAUPINA - CEP: 60872-559, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/56/apartamento-caixa-em-fortaleza-ce-2057156-imovel-2057156-5edb7a55375c71700725e9458a54d14fa678c8a2-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2057156",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8427322,
            lng: -38.4919074
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-res-pq-michelangelo-2-quartos-1-vaga-na-garagem-area-de-servico-wc-sal-imovel-caixa-economica-federal-cef-2057155-8787705005848-venda-direta-caixa",
        valorVenda: "159000.00",
        valorOriginal: 0,
        endereco: "RUA A,N. 108 APTO. 404 BL.07 ., PAUPINA - CEP: 60872-559, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/55/apartamento-caixa-em-fortaleza-ce-2057155-imovel-2057155-089c260b412c501709a1f6508b0d030e0a256b29-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2057155",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8427322,
            lng: -38.4919074
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-res-parque-da-vinci-2-quartos-1-vaga-na-garagem-area-de-servico-wc-sala-imovel-caixa-economica-federal-cef-2057154-8787707930239-venda-direta-caixa",
        valorVenda: "160000.00",
        valorOriginal: 0,
        endereco: "RUA A,N. 30 APTO. 302 BL.18 .VG N.349, PAUPINA - CEP: 60872-559, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/54/apartamento-caixa-em-fortaleza-ce-2057154-imovel-2057154-d0cd073e5c37efa70cb33c0ccf7d78e928de2dae-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2057154",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8427322,
            lng: -38.4919074
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/eusebio/residencial-cond-grand-village-2-quartos-1-vaga-na-garagem-area-de-servico-wc-sala-imovel-caixa-economica-federal-cef-2008958-8787704580660-venda-direta-caixa",
        valorVenda: "160000.00",
        valorOriginal: 0,
        endereco: "AVENIDA CORONEL CICERO AS,N. 990 APTO. 5 BL 01, PARQUE HAWAI - CEP: 61761-390, EUSEBIO - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/58/apartamento-caixa-em-eusebio-ce-2008958-imovel-2008958-a5674c5b71e4e28a8396be038cf2f1480877c3ac-m.webp",
        titulo: "Apartamento em Leilão em Eusébio / CE - 2008958",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8988186,
            lng: -38.45416609999999
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-maraponga-ville-1-vaga-na-garagem-imovel-caixa-economica-federal-cef-1888589-8787710271992-venda-direta-caixa",
        valorVenda: "160000.00",
        valorOriginal: 0,
        endereco: "RUA HOLANDA,N. 574 APTO. 304 BL 06, PAV 4, MARAPONGA - CEP: 60711-005, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/89/apartamento-caixa-em-fortaleza-ce-1888589-imovel-1888589-ef507bc3c0bc827e85f91e01a030bf6c2afd497f-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1888589",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.793095,
            lng: -38.5659336
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/eusebio/residencial-cond-gran-village-2-2-quartos-1-vaga-na-garagem-area-de-servico-2-wc-sa-imovel-caixa-economica-federal-cef-2029147-8787711511571-venda-direta-caixa",
        valorVenda: "160548.25",
        valorOriginal: 0,
        endereco: "AVENIDA BRASILIA,N. 279 APTO. 401 BL 05, CENTRO - CEP: 61000-000, EUSEBIO - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/47/apartamento-caixa-em-eusebio-ce-2029147-imovel-2029147-f6201f1231330aee5ce5cd668be1582e2bbf26a9-m.webp",
        titulo: "Apartamento em Leilão em Eusébio / CE - 2029147",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8969089,
            lng: -38.4523047
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-apartamento-114-76m-fortaleza-ce-imovel-1869565",
        valorVenda: "162000.00",
        valorOriginal: "324000.00",
        endereco: "Av. Des. Moreira",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/65/apartamento-em-fortaleza-ce-1869565-imovel-1869565-bc4be49e1554c3d59a47bd8755a79d18e2309ad5-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1869565",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: 0,
            lng: 0
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-conj-res-lisboa-ii-quarto-1-vaga-na-garagem-2-varandas-sacadas-wc-sala-imovel-caixa-economica-federal-cef-1185361-8555500542280-venda-direta-caixa",
        valorVenda: "162000.00",
        valorOriginal: 0,
        endereco: "RUA GUARAREMA,N. 390  LT 32, QD 44, BONSUCESSO - CEP: 60000-000, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/61/casa-caixa-em-fortaleza-ce-1185361-imovel-1185361-dc0e7d127ab4a8ee4bd86b481d5a3f5eecbb909f-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 1185361",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7811382,
            lng: -38.5871361
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-casa-em-fortaleza-ce-imovel-caixa-economica-federal-cef-2036384",
        valorVenda: "163789.78",
        valorOriginal: "257000.00",
        endereco: "RUA FRANCISCO NEIVA N. 2361 LT 22 QD 23",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/84/casa-em-fortaleza-ce-2036384-imovel-2036384-6fbb7688de08a56eaa0665d52efe04884411f321-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 2036384",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.783324299999999,
            lng: -38.45647770000001
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-edson-queiroz-1-vaga-na-garagem-imovel-caixa-economica-federal-cef-1924151-1444408725152-venda-direta-caixa",
        valorVenda: "163789.78",
        valorOriginal: "257000.00",
        endereco: "RUA FRANCISCO NEIVA,N. 2361 APTO. . LT 22 QD 23, EDSON QUEIROZ - CEP: 60834-095, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/51/casa-caixa-em-fortaleza-ce-1924151-imovel-1924151-85e3bd5727c48d435ac415f034630054552ebdd4-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 1924151",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.783324299999999,
            lng: -38.45647770000001
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cond-gran-village-messejana-ii-2-quartos-1-vaga-na-garagem-wc-sala-cozin-imovel-caixa-economica-federal-cef-1990495-8787711251324-venda-direta-caixa",
        valorVenda: "165722.54",
        valorOriginal: "196000.00",
        endereco: "RUA CENTRAL,N. 100 APTO. 108 BL 06, PAUPINA - CEP: 60872-510, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/95/apartamento-caixa-em-fortaleza-ce-1990495-imovel-1990495-cd5cd329261d3a2d0395f0359bd273a313e1d2f0-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1990495",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8095875,
            lng: -38.5125143
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-ed-adelenice-1-vaga-na-garagem-imovel-caixa-economica-federal-cef-1912593-8555509130588-venda-direta-caixa",
        valorVenda: "167000.00",
        valorOriginal: 0,
        endereco: "RUA CAPITAO AMERICO MENEZES,N. 277 APTO. 203 BL A1, MARAPONGA - CEP: 60000-000, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/93/apartamento-caixa-em-fortaleza-ce-1912593-imovel-1912593-c4e042a884b178c7172b42e31f6debd2cf0bb515-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1912593",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7821133,
            lng: -38.5678687
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-messejana-3-quartos-3-wc-sala-cozinha-indisponibilidade-regularizacao-imovel-caixa-economica-federal-cef-1896671-1444409051465-venda-direta-caixa",
        valorVenda: "170162.92",
        valorOriginal: "267000.00",
        endereco: "RUA ANTONIO ALVES RIBEIRO,N. 220  LT 14 QD 07, MESSEJANA - CEP: 60865-030, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/71/casa-caixa-em-fortaleza-ce-1896671-imovel-1896671-78091ba2830e2da2f3cc96934d190cd16223cf67-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1896671",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8327464,
            lng: -38.510416
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-ce-fortaleza-imovel-caixa-economica-federal-cef-2008240",
        valorVenda: "176838.20",
        valorOriginal: 0,
        endereco: "Rua Manuel Teixeira, 970- CS 07",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/40/casa-em-fortaleza-ce-2008240-imovel-2008240-86df8634a7147aa952a0bb684d6eb4609ae2e9bf-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 2008240",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8177619,
            lng: -38.4802287
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cond-res-monaco-2-quartos-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1580023-8188800009448-venda-direta-caixa",
        valorVenda: "176838.20",
        valorOriginal: 0,
        endereco: "RUA MANUEL TEIXEIRA,N. 970  CASA 07, ALAGADICO - CEP: 60830-345, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/23/casa-caixa-em-fortaleza-ce-1580023-imovel-1580023-00445543ace559363ca2f32db79a39b3852540ba-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 1580023",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8177619,
            lng: -38.4802287
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-res-iracema-rocha-2-quartos-1-vaga-na-garagem-wc-sala-cozinha-imovel-caixa-economica-federal-cef-2005058-8555523800501-venda-direta-caixa",
        valorVenda: "177000.00",
        valorOriginal: 0,
        endereco: "AVENIDA CORONEL CARVALHO,N. 240 APTO. 404 ., BARRA DO CEARA - CEP: 60000-000, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/58/apartamento-caixa-em-fortaleza-ce-2005058-imovel-2005058-0c5eae5ba1dcb766895b9a20dded1530e4ec38ce-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2005058",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7045424,
            lng: -38.5914302
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-jangurussu-3-quartos-1-vaga-na-garagem-2-wc-sala-lavabo-cozinha-imovel-caixa-economica-federal-cef-2050682-8444410086470-venda-direta-caixa",
        valorVenda: "179189.90",
        valorOriginal: 0,
        endereco: "RUA SAO JOAO DO JANGURUSSU,N. 511  A, JANGURUSSU - CEP: 60870-750, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/82/casa-caixa-em-fortaleza-ce-2050682-imovel-2050682-a4abb02d70798b401e4ad2a1dc3028f44e6ecffb-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 2050682",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.84404,
            lng: -38.5050501
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/eusebio/residencial-res-eco-way-2-quartos-1-vaga-na-garagem-area-de-servico-wc-sala-cozinh-imovel-caixa-economica-federal-cef-2057167-8787708153180-venda-direta-caixa",
        valorVenda: "180000.00",
        valorOriginal: 0,
        endereco: "ALAMEDA VERDE,N. 280 APTO. 503 BL.31 .VG N.343, COACU - CEP: 61771-800, EUSEBIO - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/67/apartamento-caixa-em-eusebio-ce-2057167-imovel-2057167-ae5c3a0de9e93a57145845d12ce8df412c37f25f-m.webp",
        titulo: "Apartamento em Leilão em Eusébio / CE - 2057167",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8556747,
            lng: -38.47014660000001
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cond-gran-village-messejana-ii-2-quartos-1-vaga-na-garagem-2-wc-wc-emp-s-imovel-caixa-economica-federal-cef-2041500-8787711173668-venda-direta-caixa",
        valorVenda: "180000.00",
        valorOriginal: 0,
        endereco: "RUA CENTRAL,N. 100 APTO. 103 BL 02, TP 2QS, PAV 01, PAUPINA - CEP: 60872-509, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/00/apartamento-caixa-em-fortaleza-ce-2041500-imovel-2041500-93d1bfbc24fac7e1db5f1b0015223a9ccf680967-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2041500",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8095875,
            lng: -38.5125143
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/eusebio/residencial-cond-conquista-1-vaga-na-garagem-imovel-caixa-economica-federal-cef-1996291-1787702017561-venda-direta-caixa",
        valorVenda: "180000.00",
        valorOriginal: 0,
        endereco: "RUA GENTIL MONTE E SILVA,N. SN APTO. 101 BL 04, PNE, TAMATANDUBA - CEP: 61768-780, EUSEBIO - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/91/apartamento-caixa-em-eusebio-ce-1996291-imovel-1996291-4a9db655a3010b4b615dd344fa9f7237d6c87cf2-m.webp",
        titulo: "Apartamento em Leilão em Eusébio / CE - 1996291",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8683922,
            lng: -38.4710024
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cond-gran-village-messejana-ii-2-quartos-1-vaga-na-garagem-area-de-servico-imovel-caixa-economica-federal-cef-2008960-8787712957344-venda-direta-caixa",
        valorVenda: "182694.99",
        valorOriginal: 0,
        endereco: "RUA CENTRAL,N. 100 APTO. 205 BL 07, PAUPINA - CEP: 60000-000, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/60/apartamento-caixa-em-fortaleza-ce-2008960-imovel-2008960-6c7de1af87fecf0abb0fc1f23c978a71c84bfe8c-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2008960",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8095875,
            lng: -38.5125143
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cond-gran-village-messejana-ii-2-quartos-1-vaga-na-garagem-area-de-servic-imovel-caixa-economica-federal-cef-2057152-8787711176675-venda-direta-caixa",
        valorVenda: "183000.00",
        valorOriginal: 0,
        endereco: "RUA CENTRAL,N. 100 APTO. 104 BL.02 ., PAUPINA - CEP: 60872-509, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/52/apartamento-caixa-em-fortaleza-ce-2057152-imovel-2057152-2efe747a3de87ab448af21e5526c886e688da399-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2057152",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8095875,
            lng: -38.5125143
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-01-uma-unidade-habitacional-residencial-multifamiliar-em-condominio-constituido-do-apartamento-re-imovel-2062405",
        valorVenda: "186304.00",
        valorOriginal: "372608.00",
        endereco: "RUA OLIVEIRA VIANA, N",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/05/apartamento-residencial-em-fortaleza-ce-2062405-imovel-2062405-b5cee81bc3c98f5356e41e3bbad0dfb101fe5208-m.webp",
        titulo: "Apartamento Residencial em Leilão em Fortaleza / CE - 2062405",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -25.4902594,
            lng: -49.2513015
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-01-uma-unidade-habitacional-residencial-multifamiliar-em-condominio-constituido-do-apartamento-re-imovel-2012559",
        valorVenda: "186304.00",
        valorOriginal: "372608.00",
        endereco: "RUA OLIVEIRA VIANA, N",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/59/apartamento-residencial-em-fortaleza-ce-2012559-imovel-2012559-b5cee81bc3c98f5356e41e3bbad0dfb101fe5208-m.webp",
        titulo: "Apartamento Residencial em Leilão em Fortaleza / CE - 2012559",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -25.4902594,
            lng: -49.2513015
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-casa-a-venda-em-leilao-imovel-itau-unibanco-2048789",
        valorVenda: "190000.00",
        valorOriginal: 0,
        endereco: "Rua Rosita Silva Araújo, 692 - Residencial Sumaré IV - Siqueira - Fortaleza / CE",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/89/casa-em-fortaleza-ce-2048789-imovel-2048789-8a48138dbf852826bf0507a6cd6a128396171270-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 2048789",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8125238,
            lng: -38.6179324
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/eusebio/residencial-cond-gran-village-2-2-quartos-1-vaga-na-garagem-2-wc-sala-cozinha-imovel-caixa-economica-federal-cef-2050679-8787714089390-venda-direta-caixa",
        valorVenda: "191000.00",
        valorOriginal: 0,
        endereco: "AVENIDA BRASILIA,N. 279 APTO. 504 BL 15, PARQUE HAWAI - CEP: 61761-210, EUSEBIO - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/79/apartamento-caixa-em-eusebio-ce-2050679-imovel-2050679-514ed66da7bd938fff11b98033a1b310b66cfcf3-m.webp",
        titulo: "Apartamento em Leilão em Eusébio / CE - 2050679",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8969089,
            lng: -38.4523047
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-lot-arvoredo-2-quartos-2-vagas-na-garagem-3-wc-sala-2-cozinhas-indispon-imovel-caixa-economica-federal-cef-2041501-1444406931464-venda-direta-caixa",
        valorVenda: "192000.00",
        valorOriginal: 0,
        endereco: "RUA 3,N. 313A  ., MONDUBIM - CEP: 60752-330, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/01/casa-caixa-em-fortaleza-ce-2041501-imovel-2041501-520a772a5370cc2d21a23e38f806a8eec1fc46a3-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 2041501",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.825670999999999,
            lng: -38.5719337
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/eusebio/residencial-cond-gran-village-2-2-quartos-1-vaga-na-garagem-area-de-servico-2-wc-sa-imovel-caixa-economica-federal-cef-2029146-8787711483900-venda-direta-caixa",
        valorVenda: "195000.00",
        valorOriginal: 0,
        endereco: "AVENIDA BRASILIA,N. 279 APTO. 505 BL 07, PARQUE HAWAI - CEP: 61761-210, EUSEBIO - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/46/apartamento-caixa-em-eusebio-ce-2029146-imovel-2029146-657cb5ee20074fd58f93c71bbf3fe7149ed1d51b-m.webp",
        titulo: "Apartamento em Leilão em Eusébio / CE - 2029146",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8969089,
            lng: -38.4523047
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-sapirangacoite-3-quartos-2-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1660497-1444406041086-venda-direta-caixa",
        valorVenda: "195655.49",
        valorOriginal: "307000.00",
        endereco: "RUA RAFAEL TOBIAS,N. 1203  QD 112, LT 1B, SAPIRANGACOITE - CEP: 60833-196, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/97/casa-caixa-em-fortaleza-ce-1660497-imovel-1660497-2b78a8f8ee4e32fc90691e263b14190e26d6b7ee-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1660497",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7986155,
            lng: -38.4719975
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-fatima-3-quartos-1-vaga-na-garagem-area-de-servico-3-wc-wc-emp-sala-co-imovel-caixa-economica-federal-cef-1384629-1555509096683-venda-direta-caixa",
        valorVenda: "196460.32",
        valorOriginal: "330000.00",
        endereco: "RUA VICENTE NOGUEIRA BRAGA,N. 74, FATIMA - CEP: 60040-570, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/29/casa-caixa-em-fortaleza-ce-1384629-imovel-1384629-0cd021a60ceab01a0879705f88c94822faa93d76-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1384629",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7555475,
            lng: -38.52082499999999
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/eusebio/residencial-res-eco-way-2-quartos-1-vaga-na-garagem-wc-sala-cozinha-imovel-caixa-economica-federal-cef-2041504-8787709576793-venda-direta-caixa",
        valorVenda: "200000.00",
        valorOriginal: 0,
        endereco: "ALAMEDA VERDE,N. 280 APTO. 301 BL 15, PAV 3, COACU - CEP: 61771-800, EUSEBIO - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/04/apartamento-caixa-em-eusebio-ce-2041504-imovel-2041504-f8f22f9dbd001b57d65106512bf09a6772f1a898-m.webp",
        titulo: "Apartamento em Leilão em Eusébio / CE - 2041504",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8556747,
            lng: -38.47014660000001
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/eusebio/residencial-cond-primavera-imovel-caixa-economica-federal-cef-2029145-8555525546530-venda-direta-caixa",
        valorVenda: "200000.00",
        valorOriginal: 0,
        endereco: "RUA PRIMAVERA,N. SN  CASA 15, URUCUNEMA - CEP: 60000-000, EUSEBIO - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/45/casa-caixa-em-eusebio-ce-2029145-imovel-2029145-be3a13432fdadb46c21335d326e0919e945e4bc2-m.webp",
        titulo: "Casa em Leilão em Eusébio / CE - 2029145",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.9041946,
            lng: -38.4518669
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-res-parque-da-vinci-2-quartos-2-vagas-na-garagem-area-de-servico-wc-sala-imovel-caixa-economica-federal-cef-2008959-8787709114773-venda-direta-caixa",
        valorVenda: "200000.00",
        valorOriginal: 0,
        endereco: "RUA A,N. 30 APTO. 302 BL 02, PAUPINA - CEP: 60872-559, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/59/apartamento-caixa-em-fortaleza-ce-2008959-imovel-2008959-b71767c0abed6865518f0b8531dbc840b174af37-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2008959",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8427322,
            lng: -38.4919074
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-loteamento-cidade-leste-3-quartos-2-vagas-na-garagem-3-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1580038-1444408716790-venda-direta-caixa",
        valorVenda: "201222.99",
        valorOriginal: "338000.00",
        endereco: "RUA R FRANCISCO NEIVA,N. 2367  LOTE 22 QUADRA 23, EDSON QUEIROZ - CEP: 60834-095, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/38/casa-caixa-em-fortaleza-ce-1580038-imovel-1580038-7d0f820c1055e834a5016f1de713f7dfeb2851ae-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1580038",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7833188,
            lng: -38.4564313
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-lot-esplanada-do-castelao-3-quartos-2-vagas-na-garagem-2-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1836159-1444409923284-venda-direta-caixa",
        valorVenda: "202028.63",
        valorOriginal: "317000.00",
        endereco: "RUA N,N. 734  ., BOA VISTA CASTELAO - CEP: 60867-660, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/59/casa-caixa-em-fortaleza-ce-1836159-imovel-1836159-7f378e4438494169ca7d2659b2a3f55494e3d605-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1836159",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8091342,
            lng: -38.5177388
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-ed-cidade-1-vaga-na-garagem-imovel-caixa-economica-federal-cef-1939560-1555534673320-venda-direta-caixa",
        valorVenda: "203940.58",
        valorOriginal: "320000.00",
        endereco: "RUA GUILHERME ROCHA,N. 1299 APTO. 812 ., JACARECANGA - CEP: 60030-141, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/60/apartamento-caixa-em-fortaleza-ce-1939560-imovel-1939560-f79f2f06157bcb8c800d55581a650cff2415ac2b-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1939560",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7240544,
            lng: -38.53759549999999
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-barra-do-ceara-5-quartos-varanda-sacada-2-areas-de-servico-4-wc-4-salas-imovel-caixa-economica-federal-cef-1376345-1444400769657-venda-direta-caixa",
        valorVenda: "204795.00",
        valorOriginal: "344000.00",
        endereco: "RUA MOACIR,N. 477, BARRA DO CEARA - CEP: 60332-650, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/property/leilaoimovel-imovel-sem-foto.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1376345",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.706342999999999,
            lng: -38.5808517
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-lagoa-redonda-3-quartos-3-vagas-na-garagem-area-de-servico-3-wc-sala-co-imovel-caixa-economica-federal-cef-1380779-8444411287720-venda-direta-caixa",
        valorVenda: "208824.96",
        valorOriginal: "360000.00",
        endereco: "RUA C,N. 582  UNIDADE A,, LAGOA REDONDA - CEP: 60831-227, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/79/casa-caixa-em-fortaleza-ce-1380779-imovel-1380779-20dc14df2e41ce35a533f34b76b8537a611f8b10-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1380779",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8162732,
            lng: -38.554676
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-loteamento-parque-agua-fria-3-quartos-3-vagas-na-garagem-2-wc-wc-emp-sal-imovel-caixa-economica-federal-cef-1637714-1444407435967-venda-direta-caixa",
        valorVenda: "209405.54",
        valorOriginal: "337000.00",
        endereco: "RUA SAO JOAO DEL REI,N. 1859, SAPIRANGACOITE - CEP: 60833-285, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/14/casa-caixa-em-fortaleza-ce-1637714-imovel-1637714-ba1d7d8defb12b97bc3fa46e83039def4469bdaf-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1637714",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7993327,
            lng: -38.469295
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-res-portal-do-passaros-3-quartos-2-wc-sala-cozinha-imovel-caixa-economica-federal-cef-2057158-8787708249071-venda-direta-caixa",
        valorVenda: "210000.00",
        valorOriginal: 0,
        endereco: "RUA AMANCIO PEREIRA,N. 777 APTO. 401 BL.07 .VG. N. 01 LT. 1 - 15 QG.5, PASSARE - CEP: 60861-770, FOR...",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/58/apartamento-caixa-em-fortaleza-ce-2057158-imovel-2057158-4519f4cbcda2fd586b22908a18032de754ae540c-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2057158",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8163239,
            lng: -38.5316373
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-premium-condominio-clube-2-quartos-1-vaga-na-garagem-wc-sala-cozinha-imovel-caixa-economica-federal-cef-2015796-8555522294181-venda-direta-caixa",
        valorVenda: "210000.00",
        valorOriginal: 0,
        endereco: "RUA MINISTRO PETRONIO PORTELA,N. 418 APTO. 403 BL 03 VG 49, SALINAS - CEP: 60810-820, FORTALEZA - CE...",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/96/apartamento-caixa-em-fortaleza-ce-2015796-imovel-2015796-29cdcb183764546c852136b478c7f3bc20c5e9bd-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2015796",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7710523,
            lng: -38.4991629
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-res-gipsy-1-penhora-regularizacao-por-parte-do-adquirente-imovel-caixa-economica-federal-cef-2029158-1555527209087-venda-direta-caixa",
        valorVenda: "212000.00",
        valorOriginal: 0,
        endereco: "RUA VALDIZAR SALDANHA FONTENELE,N. 67  BL IV, CURIO - CEP: 60000-000, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/58/casa-caixa-em-fortaleza-ce-2029158-imovel-2029158-1b2a5a246e231b78fa897103b162b35ff2cd6764-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 2029158",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8324299,
            lng: -38.4644904
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-res-pq-donatello-2-quartos-1-vaga-na-garagem-wc-sala-cozinha-imovel-caixa-economica-federal-cef-2057157-8787716638553-venda-direta-caixa",
        valorVenda: "213000.00",
        valorOriginal: 0,
        endereco: "RUA  C,N. 100 APTO. 301 BL 24 PAV 3 LOT MESSEJANA, PAUPINA - CEP: 60872-561, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/57/apartamento-caixa-em-fortaleza-ce-2057157-imovel-2057157-405d1fd0573ca6513fe84fd8d14d7bdaaf230b0a-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2057157",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.841867,
            lng: -38.4906511
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/eusebio/residencial-timbu-2-quartos-1-vaga-na-garagem-2-wc-sala-cozinha-imovel-caixa-economica-federal-cef-2005050-1444417252202-venda-direta-caixa",
        valorVenda: "215000.00",
        valorOriginal: 0,
        endereco: "RUA TROPICAL,N. 24  ., TIMBU - CEP: 61777-330, EUSEBIO - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/50/casa-caixa-em-eusebio-ce-2005050-imovel-2005050-59ae08360e6c3f83f98cb1891abc162fed994723-m.webp",
        titulo: "Casa em Leilão em Eusébio / CE - 2005050",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8592221,
            lng: -38.4350372
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-apartamento-53-44-m2-de-area-privativa-1-vaga-de-garagem-iptu-8011753-matricula-84470-oficio-3-imovel-caixa-economica-federal-cef-2039856",
        valorVenda: "216686.86",
        valorOriginal: "340000.00",
        endereco: "RUA GUILHERME ROCHA,N. 1299 APTO. 3107 TIPO A  G 51, CENTRO - CEP: 60030-141, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/56/apartamento-em-fortaleza-ce-2039856-imovel-2039856-22edb9f5035e021d9bfef1750e697b06db4df7d0-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2039856",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7240544,
            lng: -38.53759549999999
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-edificio-cidade-1-vaga-na-garagem-imovel-caixa-economica-federal-cef-1934177-1555529999603-venda-direta-caixa",
        valorVenda: "216686.86",
        valorOriginal: "340000.00",
        endereco: "RUA GUILHERME ROCHA,N. 1299 APTO. 3107 TIPO A  G 51, CENTRO - CEP: 60030-141, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/77/apartamento-caixa-em-fortaleza-ce-1934177-imovel-1934177-31b22254fb134efe8053ca1682da1d99b3247a32-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1934177",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7240544,
            lng: -38.53759549999999
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cond-ed-brisas-da-serra-2-quartos-1-vaga-na-garagem-2-wc-sala-cozinha-imovel-caixa-economica-federal-cef-2029154-8091900014781-venda-direta-caixa",
        valorVenda: "217550.00",
        valorOriginal: 0,
        endereco: "RUA DUARTE PIMENTEL,N. 155 APTO. 302 TORRE ORQUIDEA, SERRINHA - CEP: 60000-000, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/54/apartamento-caixa-em-fortaleza-ce-2029154-imovel-2029154-87987801d40d1e1336ddf74e5c601fd926416553-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2029154",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7879747,
            lng: -38.5421681
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-mondubim-2-quartos-2-vagas-na-garagem-2-wc-sala-lavabo-cozinha-imovel-caixa-economica-federal-cef-2050680-8444407867067-venda-direta-caixa",
        valorVenda: "218000.00",
        valorOriginal: 0,
        endereco: "AVENIDA MARIA GOMES DE SA,N. 2139  LT 10 QD I, MONDUBIM - CEP: 60765-173, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/80/casa-caixa-em-fortaleza-ce-2050680-imovel-2050680-4b8255fd55ee1a877ec2e7ca9b90152ea6b5575f-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 2050680",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8208265,
            lng: -38.5860033
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-casa-a-venda-em-leilao-imovel-2062569",
        valorVenda: "222400.00",
        valorOriginal: 0,
        endereco: "Rua Professor Leite Gondim, 895 - Antônio Bezerra - Fortaleza / CE",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/69/casa-em-fortaleza-ce-2062569-imovel-2062569-1a4d1320ffe26884a9da803cd021d55283e72fa3-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 2062569",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7418748,
            lng: -38.59305639999999
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/eusebio/residencial-jd-santa-adelia-3-quartos-2-vagas-na-garagem-3-wc-wc-emp-sala-dce-cozi-imovel-caixa-economica-federal-cef-1827587-8444406852260-venda-direta-caixa",
        valorVenda: "226883.89",
        valorOriginal: "356000.00",
        endereco: "RUA SAO PEDRO,N. 35A  CS 35A, PRECABURA - CEP: 61774-055, EUSEBIO - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/87/casa-caixa-em-eusebio-ce-1827587-imovel-1827587-35ce139b4445296d9d15f6bac203823e73bfb6f5-m.webp",
        titulo: "Casa Caixa em Eusébio / CE - 1827587",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8140338,
            lng: -38.4356126
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/eusebio/residencial-cond-gran-village-eusebio-2-2-quartos-1-vaga-na-garagem-area-de-servico-imovel-caixa-economica-federal-cef-2057159-1787701292070-venda-direta-caixa",
        valorVenda: "230000.00",
        valorOriginal: 0,
        endereco: "AVENIDA BRASILIA,N. 279 APTO. 102 BL.11 .VG.563, PARQUE HAWAI - CEP: 61761-210, EUSEBIO - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/59/apartamento-caixa-em-eusebio-ce-2057159-imovel-2057159-b5579e84b9fe596c121958dae65f566a3e212c94-m.webp",
        titulo: "Apartamento em Leilão em Eusébio / CE - 2057159",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8969089,
            lng: -38.4523047
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-parque-dois-irmaos-3-quartos-2-wc-sala-lavabo-cozinha-imovel-caixa-economica-federal-cef-2057160-1444406152988-venda-direta-caixa",
        valorVenda: "237658.27",
        valorOriginal: 0,
        endereco: "RUA UCRANIA,N. 369A  LT.19 QD.20, PARQUE DOIS IRMAOS - CEP: 60861-420, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/60/casa-caixa-em-fortaleza-ce-2057160-imovel-2057160-0397f2390b5afb6c6e0c5db0d3f327d2c61943ac-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 2057160",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8194182,
            lng: -38.5412483
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/eusebio/residencial-eusebio-ce-bairro-centro-gran-village-eusebio-2-apto-area-priv-49-21m-imovel-2058671",
        valorVenda: "239162.98",
        valorOriginal: 0,
        endereco: "Av. Brasília, n 279",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/71/apartamento-em-eusebio-ce-2058671-imovel-2058671-45b29f6b242139957151be6a1a95168831467c98-m.webp",
        titulo: "Apartamento em Leilão em Eusébio / CE - 2058671",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: 38.6969637,
            lng: -9.1900544
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-com-res-laguna-res-3-quartos-1-vaga-na-garagem-area-de-servico-2-wc-sal-imovel-caixa-economica-federal-cef-2029155-1444407089589-venda-direta-caixa",
        valorVenda: "243000.00",
        valorOriginal: 0,
        endereco: "AVENIDA POMPILIO GOMES,N. 127 APTO. 503 BL IV, PASSARE - CEP: 60861-790, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/55/apartamento-caixa-em-fortaleza-ce-2029155-imovel-2029155-4c68b87136a5201b3b142635cebfbe36f23f76cb-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2029155",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8115386,
            lng: -38.51976579999999
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-apirangacoite-3-quartos-2-vagas-na-garagem-3-wc-wc-emp-sala-dce-cozinh-imovel-caixa-economica-federal-cef-2041503-1444400156825-venda-direta-caixa",
        valorVenda: "244000.00",
        valorOriginal: 0,
        endereco: "RUA SANDRA GENTIL,N. 611  ., APIRANGACOITE - CEP: 60000-000, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/03/casa-caixa-em-fortaleza-ce-2041503-imovel-2041503-93daf09ab0b37335353d96fe78a687c5c04c9914-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 2041503",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.792281,
            lng: -38.469133
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-lot-expedicionario-ii-3-quartos-1-vaga-na-garagem-3-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1866789-1444403860060-venda-direta-caixa",
        valorVenda: "245000.00",
        valorOriginal: 0,
        endereco: "RUA Z,N. 990  B LT 32 QD 11, RACHEL DE QUEIROZ - CEP: 60867-520, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/89/casa-caixa-em-fortaleza-ce-1866789-imovel-1866789-43ea874d57e4d5f95cd980eea6e940813e95a81e-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 1866789",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8064322,
            lng: -38.5086421
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-pq-jose-alencar-3-quartos-2-vagas-na-garagem-2-wc-sala-cozinha-imovel-caixa-economica-federal-cef-2029159-8444411416848-venda-direta-caixa",
        valorVenda: "251922.07",
        valorOriginal: 0,
        endereco: "RUA OLIMPIO LEITE,N. 322  LT B, CAMBEBA - CEP: 60822-395, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/59/casa-caixa-em-fortaleza-ce-2029159-imovel-2029159-80882b0d124ae8a14e3c64508fcdab2df42f965b-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 2029159",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8147882,
            lng: -38.4821087
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-lot-parque-agua-fria-3-quartos-4-wc-cozinha-imovel-caixa-economica-federal-cef-1728342-1444406593674-venda-direta-caixa",
        valorVenda: "260980.20",
        valorOriginal: "420000.00",
        endereco: "RUA DR ERNESTO MONTEIRO,N. 2785  0, SAPIRANGACOITE - CEP: 60833-272, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/42/casa-caixa-em-fortaleza-ce-1728342-imovel-1728342-6c0d40fae150a7148f8471edfa2de9706de69695-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1728342",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7978295,
            lng: -38.4703247
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-apartamento-na-praia-do-futuro-em-fortaleza-ce-imovel-2061112",
        valorVenda: "265500.00",
        valorOriginal: 0,
        endereco: "Rua Antônio Atualpa Rodrigues , 100, Praia do Futuro , Fortaleza/CE",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/12/apartamento-em-fortaleza-ce-2061112-imovel-2061112-60995e099352027a5e05a9739a2f18cb69f7343d-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2061112",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.749558,
            lng: -38.4483997
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/aquiraz/residencial-casa-tapera-aquiraz-ce-imovel-2053134",
        valorVenda: "268000.00",
        valorOriginal: 0,
        endereco: "Estrada do Fio (Rua Francisco Pires Sobrinho), 2828, Aquiraz,CE",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/34/casa-em-aquiraz-ce-2053134-imovel-2053134-0f526752b6fd16128e3146b83f4fde3b1c82dee7-m.webp",
        titulo: "Casa em Leilão em Aquiraz / CE - 2053134",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.9194668,
            lng: -38.3788517
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-casa-residencial-parque-agua-fria-distrito-de-messejana-3-dormitorio-s-2-vaga-s-de-garagem-imovel-1558470",
        valorVenda: "269568.00",
        valorOriginal: "384000.00",
        endereco: "Rua Antonio Gentil,  nº 1.775, Casa 18, Condomínio Real Park Sapiranga, Parque Água Fria, Distrito de Messejana, Fortaleza, CE, 60833-695",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/70/casa-em-fortaleza-ce-1558470-imovel-1558470-5c8ab9c277521b18c8843015252be5a9eae5e22e-m.webp",
        titulo: "Casa em Fortaleza / CE - 1558470",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7952935,
            lng: -38.4757701
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/eusebio/residencial-loteamento-hipica-park-4-quartos-area-de-servico-3-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1298447-1444408795193-venda-direta-caixa",
        valorVenda: "274952.86",
        valorOriginal: "474000.00",
        endereco: "RUA 02,N. S/N  QD 11, LT 28, PRECABURA - CEP: 61760-000, EUSEBIO - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/47/casa-caixa-em-eusebio-ce-1298447-imovel-1298447-0267514d9819ae85572318be3b10fa5392aecf94-m.webp",
        titulo: "Casa Caixa em Eusébio / CE - 1298447",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8347974,
            lng: -38.575451
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-res-navegantes-cond-01-3-quartos-2-vagas-na-garagem-varanda-sacada-2-wc-imovel-caixa-economica-federal-cef-1881011-1555522666463-venda-direta-caixa",
        valorVenda: "290000.00",
        valorOriginal: 0,
        endereco: "RUA PADRE CONSTANTINO,N. 19 APTO. 407 3 PAV, TORRE 2, JACARECANGA - CEP: 60310-400, FORTALEZA - CEAR...",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/11/apartamento-caixa-em-fortaleza-ce-1881011-imovel-1881011-059b0acc0f0b1bb94d8e85b42a81253066af4d0e-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1881011",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7164838,
            lng: -38.5457384
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-ed-campus-do-itaperi-residence-2-quartos-2-vagas-na-garagem-area-de-servic-imovel-caixa-economica-federal-cef-2029153-1444407146744-venda-direta-caixa",
        valorVenda: "292000.00",
        valorOriginal: 0,
        endereco: "RUA MAGNOLIA,N. 35 APTO. 508 TIPO A, SERRINHA - CEP: 60741-010, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/53/apartamento-caixa-em-fortaleza-ce-2029153-imovel-2029153-9969cecfc47232f1cbabf90fa2eedfcc84d26166-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2029153",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7834552,
            lng: -38.5534138
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cond-fortune-residence-club-3-quartos-2-vagas-na-garagem-area-de-servico-imovel-caixa-economica-federal-cef-2029160-1555530242219-venda-direta-caixa",
        valorVenda: "296150.50",
        valorOriginal: 0,
        endereco: "RUA DOS AMIGOS,N. 355 APTO. 1307 BL 02, CAMBEBA - CEP: 60822-168, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/60/apartamento-caixa-em-fortaleza-ce-2029160-imovel-2029160-dfdeea1d4afe989283855cefd5724a3606ff8667-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2029160",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8047596,
            lng: -38.4811562
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cj-hab-beira-rio-2-quartos-varanda-sacada-7-wc-sala-dce-cozinha-imovel-caixa-economica-federal-cef-1510231-1444407133766-venda-direta-caixa",
        valorVenda: "296476.48",
        valorOriginal: "498000.00",
        endereco: "RUA 31,N. 1536, VILA VELHA - CEP: 60348-180, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/31/casa-caixa-em-fortaleza-ce-1510231-imovel-1510231-94183c9e7f224747760091bd7fe0468ce2fb74f0-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1510231",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7176158,
            lng: -38.5977586
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-apartamento-fortaleza-ce-imovel-1945624",
        valorVenda: "301000.00",
        valorOriginal: "430000.00",
        endereco: "Rua Batista de Oliveira",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/24/apartamento-em-fortaleza-ce-1945624-imovel-1945624-21a128449b1ba1df7c5fc2d0303775ab9459cbd8-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1945624",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -21.7622218,
            lng: -43.3465285
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cidade-dos-funcionarios-3-quartos-2-vagas-na-garagem-2-wc-2-salas-lavabo-imovel-caixa-economica-federal-cef-1912594-1444420154305-venda-direta-caixa",
        valorVenda: "306548.18",
        valorOriginal: "481000.00",
        endereco: "RUA VICENTE LOPES,N. 427  ., CIDADE DOS FUNCIONARIOS - CEP: 60822-104, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/94/casa-caixa-em-fortaleza-ce-1912594-imovel-1912594-10b4f8d67a7a1af7f4f63070f534b6b134f3d326-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1912594",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.798908199999999,
            lng: -38.4955127
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cond-res-giardini-di-padova-quarto-1-vaga-na-garagem-wc-sala-cozinha-in-imovel-caixa-economica-federal-cef-2029156-1444403202453-venda-direta-caixa",
        valorVenda: "316000.00",
        valorOriginal: 0,
        endereco: "RUA RODRIGO CODES SANDOVAL,N. 250 APTO. T3 TORRE 01, MONDUBIM - CEP: 60711-455, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/56/apartamento-caixa-em-fortaleza-ce-2029156-imovel-2029156-bd85deb653a0ba61fcdeaa7a03559ad55e98688f-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2029156",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8048948,
            lng: -38.5716441
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-bem-viver-cond-clube-res-2-quartos-2-vagas-na-garagem-2-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1944717-1444407144318-venda-direta-caixa",
        valorVenda: "319525.00",
        valorOriginal: 0,
        endereco: "RUA MONSENHOR DANTAS,N. 2555 APTO. 703 TIPO C, 6PAV, JACARECANGA - CEP: 60310-226, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/17/apartamento-caixa-em-fortaleza-ce-1944717-imovel-1944717-b506cf7fa17b630b63070fdefd9be885a8c7f269-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1944717",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.719039,
            lng: -38.5482103
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-casa-em-benfica-fortaleza-ce-imovel-2049331",
        valorVenda: "321400.00",
        valorOriginal: 0,
        endereco: "Rua Antenor Frota Wanderley, 191, Benfica, Fortaleza/CE",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/31/casa-em-fortaleza-ce-2049331-imovel-2049331-290fb53e0f8d699791cc4c5c2726abf79aaafd23-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 2049331",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7464805,
            lng: -38.54022
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/eusebio/residencial-lot-esplanada-do-eusebio-3-quartos-3-wc-sala-cozinha-imovel-caixa-economica-federal-cef-2046155-1444407076827-venda-direta-caixa",
        valorVenda: "327623.77",
        valorOriginal: 0,
        endereco: "RUA ELIZEU OLIVEIRA,N. 151  QD 06, TERRENO DESM 02, GUARIBAS - CEP: 61000-000, EUSEBIO - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/55/casa-caixa-em-eusebio-ce-2046155-imovel-2046155-9e2d5b37203c23b133b1e9f08a00005378182d3a-m.webp",
        titulo: "Casa em Leilão em Eusébio / CE - 2046155",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8742142,
            lng: -38.4595399
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-parangaba-imovel-com-divergencia-de-area-de-area-do-terreno-registrada-em-relacao-ao-c-imovel-caixa-economica-federal-cef-2046154-466-venda-direta-caixa",
        valorVenda: "327929.71",
        valorOriginal: "685000.00",
        endereco: "SETE DE SETEMBRO,N. 141, PARANGABA - CEP: 60720-080, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/54/casa-caixa-em-fortaleza-ce-2046154-imovel-2046154-ad709f020af792c4b268d0e156ae32f8dafc0df1-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 2046154",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7740062,
            lng: -38.563179
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/aquiraz/residencial-prainha-3-quartos-1-vaga-na-garagem-area-de-servico-imovel-caixa-economica-federal-cef-1862019-1444417428360-venda-direta-caixa",
        valorVenda: "330109.18",
        valorOriginal: 0,
        endereco: "RUA 18,N. 1843  QD F LT 3, PRAINHA - CEP: 61700-000, AQUIRAZ - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/19/casa-caixa-em-aquiraz-ce-1862019-imovel-1862019-bf4e4b9ff87121aabac26b11e7deb2cb69345c81-m.webp",
        titulo: "Casa em Leilão em Aquiraz / CE - 1862019",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.901564399999999,
            lng: -38.3495005
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-ed-planalto-aldeota-sul-imovel-caixa-economica-federal-cef-1990501-8444404936483-venda-direta-caixa",
        valorVenda: "334252.26",
        valorOriginal: 0,
        endereco: "RUA ESCRIVAO AZEVEDO,N. 971 APTO. 204 BL 02, CIDADE DOS FUNCIONARIOS - CEP: 60822-520, FORTALEZA - C...",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/01/apartamento-caixa-em-fortaleza-ce-1990501-imovel-1990501-aad49805cd0485eaff05cbb308ecd4f2afe6dac0-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1990501",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7925713,
            lng: -38.4982245
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-casa-378-m-lagoa-redonda-fortaleza-ce-imovel-banco-santander-2065728",
        valorVenda: "343600.00",
        valorOriginal: "655000.00",
        endereco: "Rua Adelino Serra, 31, Lagoa Redonda, Fortaleza, CE",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/property/leilaoimovel-imovel-sem-foto.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 2065728",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8166737,
            lng: -38.4544271
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cond-res-brasil-soares-3-quartos-2-vagas-na-garagem-3-wc-sala-cozinha-imovel-caixa-economica-federal-cef-1731786-1444408287040-venda-direta-caixa",
        valorVenda: "360082.58",
        valorOriginal: "565000.00",
        endereco: "AVENIDA EDILSON BRASIL SOARES,N. 881  CS 09, PARQUE MANIBURA - CEP: 60821-775, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/86/casa-caixa-em-fortaleza-ce-1731786-imovel-1731786-60e4c88396fb0dc95be717c9539b06dcecd331bc-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1731786",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7898704,
            lng: -38.48630740000001
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-ed-ministro-marcos-freire-3-quartos-1-vaga-na-garagem-2-wc-sala-cozinha-imovel-caixa-economica-federal-cef-2005056-1555539803965-venda-direta-caixa",
        valorVenda: "363000.00",
        valorOriginal: 0,
        endereco: "RUA GENERAL SILVA JUNIOR,N. 855 APTO. 703 ., FATIMA - CEP: 60411-200, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/56/apartamento-caixa-em-fortaleza-ce-2005056-imovel-2005056-39826bf7c9d4e952cdaed7d7af8d3761a23309e3-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2005056",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7566756,
            lng: -38.5324977
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-casa-no-bairro-floresta-em-fortaleza-ce-imovel-2061162",
        valorVenda: "373500.00",
        valorOriginal: 0,
        endereco: "Rua Cruz e Sousa , 57, Álvaro Weyne, Fortaleza/CE",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/62/casa-em-fortaleza-ce-2061162-imovel-2061162-5bb1bb6439148a96ee9e9ca214c8705ce6f309b8-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 2061162",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7198779,
            lng: -38.5649301
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-jardins-de-atlantida-2-vagas-na-garagem-indisponibilidade-regularizacao-p-imovel-caixa-economica-federal-cef-1896676-1155900004010-venda-direta-caixa",
        valorVenda: "383000.00",
        valorOriginal: 0,
        endereco: "RUA DOUTOR JOSE FROTA,N. 255 APTO. 402 BL 01, MUCURIPE - CEP: 60165-210, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/76/apartamento-caixa-em-fortaleza-ce-1896676-imovel-1896676-322798378487f9fb5880bba58b871a3cc3662086-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1896676",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7284663,
            lng: -38.4808565
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-lot-cidade-leste-4-quartos-3-varandas-sacadas-3-wc-wc-emp-sala-lavabo-imovel-caixa-economica-federal-cef-1138704-1444406026281-venda-direta-caixa",
        valorVenda: "387426.75",
        valorOriginal: 0,
        endereco: "RUA DOUTOR ITAMAR ESPINDOLA,N. 881-A  QD 02, LT 18, SAPIRANGACOITE - CEP: 60833-482, FORTALEZA - CEA...",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/04/casa-caixa-em-fortaleza-ce-1138704-imovel-1138704-de3931c3e3d18c6ba36e19057dac309188f55acf-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 1138704",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7948022,
            lng: -38.4608994
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-casa-184-m-sapiranga-fortaleza-ce-imovel-itau-unibanco-2058629",
        valorVenda: "406400.00",
        valorOriginal: 0,
        endereco: "Rua Professor Solon Farias, 440, Sapiranga, Fortaleza, CE",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/property/leilaoimovel-imovel-sem-foto.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 2058629",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7918942,
            lng: -38.4771429
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/aquiraz/terreno-lote-residencial-curralinho-desocupado-imovel-1291382",
        valorVenda: "414460.00",
        valorOriginal: 0,
        endereco: "Rua Um, s/n, Enseada Praia, Lote D30, Condomínio Aquiraz Riviera, Curralinho, Aquiraz, CE, 61700-000",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/82/lote-em-aquiraz-ce-1291382-imovel-1291382-4de66ba48babea99cfbd0f53813edbacc981f3bd-m.webp",
        titulo: "Lote em Aquiraz / CE - 1291382",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -1.8132757,
            lng: -49.7981225
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-ed-neptuno-3-quartos-2-vagas-na-garagem-area-de-servico-3-wc-wc-emp-sal-imovel-caixa-economica-federal-cef-1356406-1444406588166-venda-direta-caixa",
        valorVenda: "416734.02",
        valorOriginal: "700000.00",
        endereco: "RUA GENERAL TERTULIANO POTIGUARA,N. 340 APTO. 302, ALDEOTA - CEP: 60135-280, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/06/apartamento-caixa-em-fortaleza-ce-1356406-imovel-1356406-cb78dfad786457df065cd93637bb846954b5491e-m.webp",
        titulo: "Apartamento Caixa em Fortaleza / CE - 1356406",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7431082,
            lng: -38.5011629
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/aquiraz/terreno-cond-riviera-imovel-caixa-economica-federal-cef-1660426-1444401867407-venda-direta-caixa",
        valorVenda: "423814.01",
        valorOriginal: "665000.00",
        endereco: "RUA 4,N. 0  UNID D-18,  ZONA 1, SETOR D, PRAINHA - CEP: 61700-000, AQUIRAZ - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/26/terreno-caixa-em-aquiraz-ce-1660426-imovel-1660426-fa8f4be511daa1d35caf03b91791c3f4da7f457a-m.webp",
        titulo: "Terreno Caixa em Aquiraz / CE - 1660426",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.9528988,
            lng: -38.3299454
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-imovel-residencial-comercial-area-160m-conj-novo-mondubim-fortaleza-ce-imovel-2063520",
        valorVenda: "424000.00",
        valorOriginal: 0,
        endereco: "Rua 107, nº 163 - Conjunto Novo Mondubim - Fortaleza/CE",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/property/leilaoimovel-imovel-sem-foto.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 2063520",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.809455,
            lng: -38.5873308
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-loteamento-jardim-4-quartos-2-vagas-na-garagem-5-wc-wc-emp-3-salas-dce-imovel-caixa-economica-federal-cef-1637716-1188800008435-venda-direta-caixa",
        valorVenda: "425664.03",
        valorOriginal: "715000.00",
        endereco: "RUA JORNALISTA NERTAN MACEDO,N. 417  LT 10, QD 34, COCO - CEP: 60192-450, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/16/casa-caixa-em-fortaleza-ce-1637716-imovel-1637716-b80239d6b424bf96af41ed61a50566120f2b2516-m.webp",
        titulo: "Casa Caixa em Fortaleza / CE - 1637716",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7510624,
            lng: -38.4768507
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-lot-cartier-jose-celio-gurgel-3-quartos-2-vagas-na-garagem-3-wc-sala-coz-imovel-caixa-economica-federal-cef-2046152-1444408728437-venda-direta-caixa",
        valorVenda: "430126.50",
        valorOriginal: 0,
        endereco: "RUA TURMALINA,N. 12  QD 03 LT 01, MONDUBIM - CEP: 60761-472, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/52/casa-caixa-em-fortaleza-ce-2046152-imovel-2046152-0318ce21b5f3d1b4c0c0f21c7da204ed2a8d889a-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 2046152",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8924799,
            lng: -38.5169871
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/aquiraz/terreno-lote-residencial-curralinho-desocupado-imovel-1291384",
        valorVenda: "451030.00",
        valorOriginal: 0,
        endereco: "Rua Um, s/n, Enseada Praia, Lote D49, Condomínio Aquiraz Riviera, Curralinho, Aquiraz, CE, 61700-000",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/84/lote-em-aquiraz-ce-1291384-imovel-1291384-f6619bc3ce85313247ffae1d47e67e32a7240064-m.webp",
        titulo: "Lote em Aquiraz / CE - 1291384",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -1.8132757,
            lng: -49.7981225
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-casa-224-67-m2-de-area-privativa-224-66m2-de-area-do-terreno-3-qts-3-wcs-wc-emp-sl-lavabo-dc-imovel-caixa-economica-federal-cef-2039855",
        valorVenda: "457591.67",
        valorOriginal: "718000.00",
        endereco: "RUA ENG CLEBER DINIZ,N. 351  CS 1300  TIPO A, JOSE DE ALENCAR - CEP: 60830-632, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/55/casa-em-none-none-2039855-imovel-2039855-4aa7843c1196fe1c4c20df11e8384c21c7bd5b3a-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 2039855",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8135417,
            lng: -38.4703797
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cond-carmel-desing-3-quartos-2-vagas-na-garagem-3-wc-wc-emp-sala-lavabo-imovel-caixa-economica-federal-cef-1934176-1600000221752-venda-direta-caixa",
        valorVenda: "457591.67",
        valorOriginal: "718000.00",
        endereco: "RUA ENG CLEBER DINIZ,N. 351  CS 1300  TIPO A, JOSE DE ALENCAR - CEP: 60830-632, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/76/casa-caixa-em-fortaleza-ce-1934176-imovel-1934176-8ae4ae4e1c52bdeb59a2eb1cb7ac4f1d33de2478-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 1934176",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8135417,
            lng: -38.4703797
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/aquiraz/terreno-lote-residencial-curralinho-desocupado-imovel-1291381",
        valorVenda: "487600.00",
        valorOriginal: 0,
        endereco: "Rua Um, s/n, Enseada Praia, Lote D103, Condomínio Aquiraz Riviera, Curralinho, Aquiraz, CE, 61700-000",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/81/lote-em-aquiraz-ce-1291381-imovel-1291381-f110ab50d814d044b8b4ece5de0bac6fd0e9e4c7-m.webp",
        titulo: "Lote em Aquiraz / CE - 1291381",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -1.8132757,
            lng: -49.7981225
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cond-alameda-jardim-3-quartos-2-vagas-na-garagem-2-wc-sala-cozinha-imovel-caixa-economica-federal-cef-2003210-1444407717024-venda-direta-caixa",
        valorVenda: "493000.00",
        valorOriginal: 0,
        endereco: "RUA GUSTAVO AUGUSTO LIMA,N. 1041 APTO. 403 TIPO A, EDSON QUEIROZ - CEP: 60810-330, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/10/apartamento-caixa-em-fortaleza-ce-2003210-imovel-2003210-df6e8122a37bc9f59a5b735fd4d082b7b8d222c6-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2003210",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7635134,
            lng: -38.4901253
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/aquiraz/residencial-cond-porto-riviera-3-quartos-2-vagas-na-garagem-varanda-sacada-area-de-se-imovel-caixa-economica-federal-cef-1218909-1555512336347-venda-direta-caixa",
        valorVenda: "506034.17",
        valorOriginal: "850000.00",
        endereco: "AVENIDA LITORANEA,N. 5297  CS 01, PORTO DAS DUNAS - CEP: 61700-000, AQUIRAZ - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/09/casa-caixa-em-aquiraz-ce-1218909-imovel-1218909-2c6a86508abc1acd182a7bef28fbe26173076172-m.webp",
        titulo: "Casa Caixa em Aquiraz / CE - 1218909",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.85454,
            lng: -38.3830108
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/aquiraz/residencial-casa-padrao-2-vaga-s-de-garagem-desocupado-imovel-banco-santander-2065817",
        valorVenda: "525600.00",
        valorOriginal: "802000.00",
        endereco: "Rua Tainha, 92, Porto Das Dunas Iiia Etapa, Mariuba, Aquiraz, CE",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/17/casa-em-aquiraz-ce-2065817-imovel-2065817-1504ffa953bbccaa4ee85f6c152479b5b3ee8420-m.webp",
        titulo: "Casa em Leilão em Aquiraz / CE - 2065817",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8460466,
            lng: -38.3937824
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-sitio-carrapicho-2-vagas-na-garagem-imovel-caixa-economica-federal-cef-2029161-1444400713597-venda-direta-caixa",
        valorVenda: "565000.00",
        valorOriginal: 0,
        endereco: "RUA SALVADOR CORREIA DE SA,N. 1010  LTS 13 E 14 QD 14, SAPIRANGA - CEP: 60833-183, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/61/casa-caixa-em-fortaleza-ce-2029161-imovel-2029161-08b1dee922805ba6e6197426f4c84908a96d911c-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 2029161",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7975888,
            lng: -38.4747074
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-apartamento-em-meireles-fortaleza-ce-imovel-2061137",
        valorVenda: "664832.69",
        valorOriginal: 0,
        endereco: "Rua Silva Jatahy, 1060, Meireles, Fortaleza/CE",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/37/apartamento-em-fortaleza-ce-2061137-imovel-2061137-44b2affcc65df40f05fad993d306893f720c3f90-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2061137",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7289183,
            lng: -38.4943541
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-apartamento-em-fortaleza-ce-imovel-caixa-economica-federal-cef-2036383",
        valorVenda: "736098.02",
        valorOriginal: "1155000.00",
        endereco: "RUA PEREIRA DE MIRANDA N. 1620 Apto. 1701 .- ED BRISA DO LESTE",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/83/apartamento-em-fortaleza-ce-2036383-imovel-2036383-4aa410cd9327d63115184df345cb91b39610ce85-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2036383",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: 0,
            lng: 0
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-ed-brisa-do-leste-3-quartos-4-vagas-na-garagem-3-wc-sala-lavabo-cozinha-imovel-caixa-economica-federal-cef-1924146-1444418616607-venda-direta-caixa",
        valorVenda: "736098.02",
        valorOriginal: "1155000.00",
        endereco: "RUA PEREIRA DE MIRANDA,N. 1620 APTO. 1701 ., PAPICU - CEP: 60175-045, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/46/apartamento-caixa-em-fortaleza-ce-1924146-imovel-1924146-8383ed010fcb47ab85c130555e6d928d26fc9d1e-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1924146",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7430101,
            lng: -38.4713165
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-ed-tao-residence-3-quartos-3-vagas-na-garagem-3-wc-sala-dce-cozinha-imovel-caixa-economica-federal-cef-1731785-1197700007517-venda-direta-caixa",
        valorVenda: "755000.00",
        valorOriginal: 0,
        endereco: "RUA DOUTOR GILBERTO STUDART,N. 1949 APTO. 1802 TIPO B2, COCO - CEP: 60192-115, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/85/apartamento-caixa-em-fortaleza-ce-1731785-imovel-1731785-9014233c609a0a1ba0a05ab759aa75bb37ac24ec-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1731785",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7464632,
            lng: -38.4715113
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-aldeota-3-quartos-1-vaga-na-garagem-3-wc-wc-emp-sala-dce-cozinha-pisc-imovel-caixa-economica-federal-cef-1380788-1555519457638-venda-direta-caixa",
        valorVenda: "766195.26",
        valorOriginal: "1287000.00",
        endereco: "A RUI BARBOSA,N. 1645 APTO. 302 AP302,, ALDEOTA - CEP: 60115-221, FORTALEZA - CEARA",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/88/apartamento-caixa-em-fortaleza-ce-1380788-imovel-1380788-ac3c89fd5335ff9d1e88da0d18ae794582b0f905-m.webp",
        titulo: "Apartamento Caixa em Fortaleza / CE - 1380788",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7373357,
            lng: -38.5096859
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-6-casas-c-70-66m-cada-r-frei-orlando-fortaleza-ce-imovel-1715411",
        valorVenda: "894465.00",
        valorOriginal: "1788930.00",
        endereco: "Rua Frei orlando, 626",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/11/casas-em-fortaleza-ce-1715411-imovel-1715411-d8e8e7790f525a757072a2b91f0600194fb18622-m.webp",
        titulo: "Casas em Fortaleza / CE - 1715411",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -23.5563264,
            lng: -46.5345549
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-casa-residencial-ouro-branco-pres-kennedy-fortaleza-ce-imovel-banco-santander-2062310",
        valorVenda: "902358.74",
        valorOriginal: 0,
        endereco: "Rua Ouro Branco, 155, Fortaleza,CE",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/10/casa-em-fortaleza-ce-2062310-imovel-2062310-94f6e266c31aba52c26806e7d875770d001646d5-m.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 2062310",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7330964,
            lng: -38.572815
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/aquiraz/terreno-lote-residencial-curralinho-desocupado-imovel-1291386",
        valorVenda: "950820.00",
        valorOriginal: 0,
        endereco: "Rua Um, s/n, Enseada Praia, Lote D69/D82, Condomínio Aquiraz Riviera, Curralinho, Aquiraz, CE, 61700-000",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/86/lote-em-aquiraz-ce-1291386-imovel-1291386-0ac77528471bc1b4aa347099957a183a8135a7cb-m.webp",
        titulo: "Lote em Aquiraz / CE - 1291386",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -1.8132757,
            lng: -49.7981225
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/aquiraz/residencial-apartamento-residencial-graia-4-dormitorio-s-3-vaga-s-de-garagem-desocupado-imovel-1598512",
        valorVenda: "1001160.00",
        valorOriginal: "1030000.00",
        endereco: "Rua Lais Sidrim Targino, s/n, Quadra 13, Apto Duplex C-02, Bloco J, Condomínio Vila do Porto Resort, Graia, Aquiraz, CE, 61700-000",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/12/apartamento-em-aquiraz-ce-1598512-imovel-1598512-85e49fa55b8ab5a5168c68df32bbb35b8cbd6b49-m.webp",
        titulo: "Apartamento em Aquiraz / CE - 1598512",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.8368093,
            lng: -38.3960876
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-cond-green-life-3-residence-4-vagas-na-garagem-imovel-caixa-economica-federal-cef-1990500-1444411570270-venda-direta-caixa",
        valorVenda: "1091147.90",
        valorOriginal: "1315000.00",
        endereco: "RUA ROMEU ALDIGUERI,N. 101 APTO. 2301 TORRE SUL, VG GAR 068, 106, 147, 148, GUARARAPES - CEP: 60810-...",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/00/apartamento-caixa-em-fortaleza-ce-1990500-imovel-1990500-2f0366cd39b76a8a2d1cdec5b437a0d6200484dc-m.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 1990500",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -21.2552944,
            lng: -50.6449793
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-apartamento-fortaleza-ce-imovel-2062332",
        valorVenda: "1131735.13",
        valorOriginal: 0,
        endereco: "Rua Deputado Moreira da Rocha, 201, Bairro Meireles - Fortaleza/CE",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/property/leilaoimovel-imovel-sem-foto.webp",
        titulo: "Apartamento em Leilão em Fortaleza / CE - 2062332",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7264084,
            lng: -38.5111538
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-imovel-residencial-area-construida-254-17m-cond-resid-mar-a-vista-bairro-lourdes-fortaleza-imovel-1997158",
        valorVenda: "1358578.50",
        valorOriginal: "1811438.00",
        endereco: "Rua Tâmisa, nº 400, casa 10, Condomínio Mar à Vista Fortaleza/CE",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/property/leilaoimovel-imovel-sem-foto.webp",
        titulo: "Casa em Leilão em Fortaleza / CE - 1997158",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7488728,
            lng: -38.4580306
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/eusebio/residencial-casa-a-venda-em-leilao-imovel-banco-santander-2055707",
        valorVenda: "1818787.91",
        valorOriginal: 0,
        endereco: "Alameda das Hortências, 710 - Casa, localizado no Lote 10 da Quadra 07 do Loteamento denominado Jar...",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/07/casa-em-eusebio-ce-2055707-imovel-2055707-3b6c0dc35a0e983f20773f12f82f08727cd8fe4d-m.webp",
        titulo: "Casa em Leilão em Eusébio / CE - 2055707",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -21.9974224,
            lng: -47.8941299
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/eusebio/terreno-sobrado-alphaville-ceara-residencial-03-cod-do-leilao-2024-12-0004-001-residenciais-imovel-2055186",
        valorVenda: "2072810.71",
        valorOriginal: 0,
        endereco: "Alameda Tailândia, s/n",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/86/lote-de-terreno-em-2055186-imovel-2055186-181af7fe083573a659b15564e7dd8a21aaab56fc-m.webp",
        titulo: "Lote De Terreno em Leilão em Eusébio / CE - 2055186",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: 0,
            lng: 0
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-apartamento-duplex-n100-do-edificio-presidente-kennedy-na-av-beira-mar-n1750-praia-de-iracema-imovel-2028985",
        valorVenda: "4200000.00",
        valorOriginal: 0,
        endereco: "Av. Beira Mar nº1750, Praia de Iracema",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/85/apartamento-duplex-em-fortaleza-ce-2028985-imovel-2028985-4cf641abf223b30a9e96093e4763f4787001944c-m.webp",
        titulo: "Apartamento Duplex em Fortaleza / CE - 2028985",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -18.244765,
            lng: -45.2215653
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-apartamento-duplex-n-100-ed-presd-kennedy-av-beira-mar-n-1750-fortaleza-ce-imovel-1715305",
        valorVenda: "4200000.00",
        valorOriginal: 0,
        endereco: "Avenida Beira Mar, 1750",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/05/apartamento-duplex-em-fortaleza-ce-1715305-imovel-1715305-8beb203622b451b6103a026898176ad9ad74b963-m.webp",
        titulo: "Apartamento Duplex em Fortaleza / CE - 1715305",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.7234137,
            lng: -38.5043829
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/aquiraz/terreno-terreno-aquiraz-ce-alienacao-fiduciaria-imovel-2052184",
        valorVenda: "4664165.00",
        valorOriginal: 0,
        endereco: "Av. Torres de Melo, CE 04, “Berra Bode”, Aquiraz/CE",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/84/terreno-em-aquiraz-ce-2052184-imovel-2052184-aaa69e2dd2ff327e55f22e8503641506820e2ed9-m.webp",
        titulo: "Terreno em Leilão em Aquiraz / CE - 2052184",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 1,
        descricaoTipoImovel: "Casa",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -3.9056161,
            lng: -38.3873584
        }
    },
    {
        url: "https:/www.leilaoimovel.com.br/imovel/ce/fortaleza/residencial-apartamento-duplex-n100-do-edificio-presidente-kennedy-na-av-beira-mar-n1750-praia-de-iracema-imovel-2029641",
        valorVenda: "8400000.00",
        valorOriginal: 0,
        endereco: "Av. Beira Mar nº1750, Praia de Iracema",
        imagemUrl: "https:/image.leilaoimovel.com.br/images/41/apartamento-duplex-em-fortaleza-ce-2029641-imovel-2029641-4cf641abf223b30a9e96093e4763f4787001944c-m.webp",
        titulo: "Apartamento Duplex em Leilão em Fortaleza / CE - 2029641",
        estado: "CE",
        cidade: "Eusebio",
        tipoNegocio: 1,
        descricaoTipoNegocio: "Leilão",
        tipoImovel: 2,
        descricaoTipoImovel: "Apartamento",
        area: 0,
        areaTerreno: 0,
        vendido: 0,
        location: {
            lat: -18.244765,
            lng: -45.2215653
        }
    }

];