import { Box, Grid } from "@mui/material";
import { useState } from "react";
import ImprimirCartas from "../../components/ImprimirCartas";
import { Form, useForm } from '../../components/UseForm';
import Controls from '../../components/controls/Controls';
import ListaCartasTexto from "../../util/files/ListaCartasTexto";
import * as Cores from '../../util/Cores'
import * as Constantes from '../../util/constantes'

const initialValues = {
    nome: '',
    natureza: 0,
    raridade: 0,
    edicao: 0,
    jogo: 1
}

export default function FiltroPesquisa(props) {
    const [mostrarFiltros, setMostrarFiltros] = useState(true)
    const [erroFiltros, setErroFiltros] = useState(false)
    const { consultar, isMobile, listaNatureza, setSort, listaEdicao, listaRaridade, showPage, userDetails, listaVisualizacao, listaJogo, consultarCombos, page, setPage, alterarLoad } = props
    const onClickFiltro = () => {
        setMostrarFiltros(!mostrarFiltros)
    }
    const validate = (fieldValues = values) => {
        if ((userDetails.id === 0 && apenasJogoSelecionado()) || values.jogo === 0) {
            return false
        }
        return true
    }

    const consultarLista = async (e) => {
        if(e!==undefined) e.preventDefault()
        try {
            if (validate()) {
                alterarLoad(true)
                let filtro = {
                    ...values, raridade: values.raridade.toString(), natureza: values.natureza.toString(), clienteId: userDetails.id, colecaoCompleta: false,
                    somenteEstoque: false,
                    pagina: page - 1,
                    tamanhoPagina: Constantes.CARD_PER_PAGE_MOBILE
                }
                await consultar(filtro)
                setSort(1)
                setErroFiltros(false)
                setMostrarFiltros(false)
                setPage(1)
            } else {
                setErroFiltros(true)
            }
        } catch (e) {
            console.log(e)
        } finally {
            alterarLoad(false)
        }
    }

    const apenasJogoSelecionado = () => {
        return values.raridade === 0 && values.nome === "" && values.natureza === 0 && values.edicao === 0
    }

    const handleInputChangeJogo = (e) => {
        let j = e.target.value
        setValues({ ...values, jogo: j, edicao: 0, natureza: 0, edicao: 0 })
        if (j !== 0) {
            consultarCombos(j)
        }
    }
    const {
        values,
        handleInputChange,
        erros,
        resetForm,
        setValues
    } = useForm(initialValues, true, validate)

    return (
        <Box sx={{ background: 'white', padding: '10px', marginTop: isMobile && '-3px', height: isMobile ? '15%' : (mostrarFiltros ? '180px' : '90px') }}>
            <Box>
                {erroFiltros &&
                    <Box sx={{ background: Cores.COR_FUNDO_ERRO }}>
                        Preencha algum dos filtros!
                    </Box>
                }
                {showPage ?
                    <Form>
                        <Grid container spacing={2} sx={{ display: 'flex' }}>
                            <Grid item md={8} xs={6}>
                                <Controls.InputNomeCarta
                                    name='nome'
                                    label='Nome da Carta'
                                    value={values.nome}
                                    onChange={handleInputChange}
                                    error={erros.nome}
                                    onClickFiltro={onClickFiltro}
                                />
                            </Grid>
                            <Grid item md={4} xs={4} sx={{ display: isMobile ? 'none' : 'contents', }}>
                                <Box sx={{ display: 'flex', justifyContent: 'left', paddingTop: '16px', marginLeft: '2%', alignItems: 'center' }}>
                                    <Controls.Button
                                        text='Pesquisar'
                                        type='submit'
                                        onClick={(e) => consultarLista(e)}
                                    />
                                    <Controls.Button
                                        sx={{ marginInline: '5%' }}
                                        text='Limpar'
                                        color='primary'
                                        variant='outlined'
                                        onClick={() => resetForm()}
                                    />
                                    <ImprimirCartas
                                        documento={<ListaCartasTexto cartas={listaVisualizacao} />}
                                        filename="listaCartasTexto"
                                        texto="Imagem"
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={6} sx={{ display: isMobile ? 'flex' : 'none', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Box sx={{ display: 'flex' }}>
                                    <Controls.Button
                                        text='Pesquisar'
                                        onClick={() => consultarLista()}
                                    />
                                </Box>
                                <ImprimirCartas
                                    documento={<ListaCartasTexto cartas={listaVisualizacao} />}
                                    filename="listaCartasTexto"
                                    texto="Imagem"
                                />
                            </Grid>
                        </Grid>
                        {mostrarFiltros ?
                            <Grid sx={{ marginTop: '1%' }} container spacing={2}>
                                <Grid item md={3} xs={6}>
                                    <Controls.Select
                                        name='jogo'
                                        label='Jogo'
                                        value={values.jogo}
                                        onChange={handleInputChangeJogo}
                                        options={listaJogo}
                                        error={erros.jogo}
                                    />
                                </Grid>
                                {values.jogo !== 2 && <Grid item md={3} xs={6}>
                                    <Controls.Select
                                        name='natureza'
                                        label='Natureza'
                                        value={values.natureza}
                                        onChange={handleInputChange}
                                        options={listaNatureza}
                                        error={erros.natureza}
                                    />
                                </Grid>}
                                <Grid item md={3} xs={6}>
                                    <Controls.Select
                                        name='raridade'
                                        label='Raridade'
                                        value={values.raridade}
                                        onChange={handleInputChange}
                                        options={listaRaridade}
                                        error={erros.raridade}
                                    />
                                </Grid>
                                <Grid item md={3} xs={6}>
                                    <Controls.Select
                                        name='edicao'
                                        label='Edição'
                                        value={values.edicao}
                                        onChange={handleInputChange}
                                        options={listaEdicao}
                                        error={erros.edicao}
                                    />
                                </Grid>
                                {isMobile && <Grid item md={3} xs={6}>
                                    <Controls.Button
                                        sx={{ marginInline: '6%' }}
                                        text='Limpar'
                                        variant='outlined'
                                        color='primary'
                                        onClick={() => resetForm()}
                                    />
                                </Grid>}
                            </Grid>
                            : null
                        }
                    </Form>
                    : null
                }
            </Box >
        </Box>
    )
}