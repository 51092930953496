import { Box, Typography } from "@mui/material";
import ListaCartaDeck from "./ListaCartaDeck";
import * as Cores from '../../../util/Cores'

export default function AreaCartasDeck(props) {
    const { cardsDeck, setCardsDeck, adicionarCarta, isLoggedIn, userDetails, isMobile, deckSelecionado } = props

    const removerCarta = (e, carta) => {
        e.preventDefault()
        if (cardsDeck.includes(carta)) {
            let index = cardsDeck.indexOf(carta)
            setCardsDeck(cardsDeck.map((c, i) => {
                if (index === i && c.quantidade - 1 > 0) {
                    return ({ ...c, quantidade: c.quantidade - 1 })
                } else if (index !== i) {
                    return c
                }
            }).filter(c => c !== undefined))
        }
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography color={Cores.COR_PRINCIPAL} variant={isMobile ? "h6" : "h5"}>{deckSelecionado.nome}</Typography>
                </Box>
                <Typography color={Cores.COR_PRINCIPAL} variant={isMobile ? "h6" : "h5"} sx={{ marginRight: '10%', display: "flex", justifyContent: 'center' }}>
                    {cardsDeck.reduce((a,v) => a = a + v.quantidade, 0)  + '/60'}
                </Typography>

            </Box>
            < ListaCartaDeck
                cardsDeck={cardsDeck}
                setCardsDeck={setCardsDeck}
                isLoggedIn={isLoggedIn}
                adicionarCarta={adicionarCarta}
                removerCarta={removerCarta}
                userDetails={userDetails}
                titulo={'Pokémon'}
                tipo={""}
                isMobile={isMobile}
            />
        </Box >
    )
}